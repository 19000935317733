<template>
    <foreignObject
        :x="x"
        :y="y"
        :width="sitemapAddFormSize.width"
        :height="sitemapAddFormSize.height"
    >
        <div xmlns="http://www.w3.org/1999/xhtml" class="sitemap-add-form-box">
            <p class="sitemap-add-form-label">ページ名</p>
            <input
                type="text"
                class="sitemap-add-form-field"
                placeholder="ページ名を入力"
                ref="titleField"
                v-model="title"
                @keydown.enter="onEnter"
            />
            <p class="sitemap-add-form-label sitemap-add-form-label-middle">
                ページタイプ
            </p>
            <select
                class="sitemap-add-form-field"
                v-model="pageTypeId"
                @keydown.enter="onEnter"
            >
                <option v-for="pageType in pageTypes" :value="pageType.id">
                    {{ pageType.title }}
                </option>
            </select>
            <div class="sitemap-add-form-button-container">
                <button
                    ref="submitButton"
                    class="sitemap-add-form-button"
                    @click="onClickSubmit"
                    :disabled="!isValid() || isSubmitting"
                >
                    確定
                </button>
            </div>
        </div>
    </foreignObject>
</template>

<script>
export const sitemapAddFormSize = { width: 240, height: 281 };

export default {
    name: 'SitemapAddForm',

    props: ['x', 'y', 'onSubmit', 'pageTypes', 'editingPage', 'defaultData'],

    data() {
        return {
            title: null,
            pageTypeId: null,
            isSubmitting: false,
            sitemapAddFormSize,
        };
    },

    mounted() {
        if (this.pageTypes && this.pageTypes.length > 0) {
            // 先頭のページタイプをデフォルトで選択
            this.pageTypeId = this.pageTypes[0].id;
        }

        if (this.defaultData) {
            this.title = this.defaultData.title;
            this.pageTypeId = this.defaultData.pageTypeId;
        } else if (this.editingPage) {
            // 編集時
            this.title = this.editingPage.text;
            this.pageTypeId = this.editingPage.pageTypeId;
        }

        this.$refs.titleField.focus();
    },

    methods: {
        isValid() {
            return (
                this.title !== null &&
                this.title !== '' &&
                this.pageTypeId !== null
            );
        },

        onClickSubmit() {
            if (!this.isValid()) {
                return;
            }

            this.isSubmitting = true;
            this.onSubmit(this.title, this.pageTypeId);
        },

        onEnter() {
            this.$refs.submitButton.focus();
        },
    },
};
</script>

<style scoped>
.sitemap-add-form-box {
    background-color: white;
    border: solid 2px #607475;
    padding: 11px 18px 28px 16px;
}

.sitemap-add-form-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #334142;
    margin: 0 0 8px 0;
}

.sitemap-add-form-label-middle {
    margin-top: 8px;
}

.sitemap-add-form-field {
    width: 200px;
    height: 50px;
    border-radius: 8px;
    border: solid 1px #607475;
    font-size: 16px;
    padding: 11px;
}

.sitemap-add-form-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sitemap-add-form-button {
    width: 160px;
    height: 40px;
    background-color: #f87f3b;
    color: white;
    margin-top: 14px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    border-radius: 8px;
}

.sitemap-add-form-button:disabled {
    opacity: 0.5;
}
</style>
