<template>
  <div class="__block-depth-2">
    <div class="c-pagenation-projects mgb-024">
      <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
      <!-- check previousPageUrl is null -->
      <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
      <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
      <!-- END check previousPageUrl null -->
      <!-- check nextPageUrl is null -->
      <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
      <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
      <!-- END check nextPageUrl is null -->
    </div>
    <div class="u-table-wrapper u-table-wrapper--scroll __table">
      <div class="u-table-list-item">
        <div class="__table-header">
          <div class="__row">
            <span class="__cell __cell--head __project u-align-left">案件名 / カテゴリ</span>
            <span class="__cell __cell--head __status u-align-center">ステータス</span>
            <span class="__cell __cell--head __count u-align-center">予算</span>
            <span class="__cell __cell--head __close u-align-center">募集終了日</span>
            <span class="__cell __cell--head"></span>
          </div>
        </div>
        <div class="__table-body">
          <!-- loop start-->
          <div class="__row __hover-highlight" v-for="(data, index) in lists.data" :key="index">
            <span class="__cell __cell--data __project">
              <span class="__category">
                {{ data.recruit.recruiting_status_option.display_name }}
              </span>
              <a class="u-text-bold" :href="offerRecruitDetail(data.recruit.id)">
                {{ data.recruit.title }}
              </a>
            </span>
            <span class="__cell __cell--data u-align-center __status">
              <span>{{ data.offer_status_option.display_name_recruiter }}</span>
            </span>
            <span class="__cell __cell--data u-align-center __budget">
              <span>{{ data.estimate }}</span>
            </span>
            <span class="__cell __cell--data u-align-center __close">
              <span>{{ moment(data.recruit.recruiting_end_date).format('YYYY/MM/DD') }}</span>
            </span>
            <span class="__cell __cell--data u-align-right">
              <a class="u-button-default u-button-default--small u-button-default--secondary" :href="offerApplicationDetail(data.id)">詳細</a>
            </span>
          </div>
          <!-- loop end-->
        </div>
      </div>
    </div>
    <div class="c-pagenation-projects mgt-024">
      <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
      <!-- check previousPageUrl is null -->
      <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
      <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
      <!-- END check previousPageUrl null -->
      <!-- check nextPageUrl is null -->
      <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
      <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
      <!-- END check nextPageUrl is null -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    offers: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      lists: {},
    }
  },
  async created () {
    this.moment = moment;
    this.initData();
  },
  methods: {
    initData() {
      this.lists = this.offers;
    },
    async loadData(page = 1){
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			await axios.get(route('contractor.offer.application', {page: page}))
				.then((res) => {
					this.lists = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
    offerRecruitDetail(id) {
      return route('contractor.offer.detail', { recruit: id });
    },
    offerApplicationDetail(id) {
      return route('contractor.offer.application.detail', { offer: id })
    }
  },
}
</script>

<style>

</style>