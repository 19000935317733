<template>
  <form action="" method="">
    <div class="__block-depth-2 __block-depth-2--form-input-block">
      <div
        v-show="isEdit"
        class="__block-depth-3 __block-depth-3--bulk-registration-block"
      >
        <div class="u-flex-left-center __box">
          <span class="__text">チェックした項目を</span>
          <span class="__select">
            <span
              class="
                u-form-select-default
                u-form-input-select
                u-form-input-select--medium
                __input
              "
            >
              <select ref="bulk_registration_assign_member">
                <option v-for="(contractor_user, index) of contractor_users" :key="index" :value="contractor_user.id">
                  {{ contractor_user.name }}
                </option>
              </select>
            </span>
          </span>
          <span class="__text">さんに</span>
          <span class="__button">
            <button
              class="
                u-button-default
                u-button-default--small
                u-button-default--secondary
              "
              type="button"
              @click="bulkRegistrationAssign()"
            >
              一括でアサイン
            </button>
          </span>
        </div>
      </div>
      <div class="__block-depth-3 __block-depth-3--table-block">
        <div v-show="isEdit">
          <div class="u-table-wrapper u-table-wrapper--scroll __table">
            <table
              class="
                u-table-list-item
                u-table-list-item--extra-narrow
                u-table-list-item--multi
                u-table-assignment
              "
            >
              <thead>
                <tr>
                  <th class="__item">
                    <div class="u-flex-left-center">
                      <div class="u-form-input-group">
                        <label
                          class="u-form-checkbox-default u-form-input-checkbox"
                        >
                          <input
                            type="checkbox"
                            name="bulk_registration"
                            ref="bulk_registration"
                            @change="bulkRegistrationToggle()"
                          /><span class="__text"></span>
                        </label>
                      </div>
                      <span class="__text">項目</span>
                    </div>
                  </th>
                  <th class="__prescribed u-align-center">所定工数</th>
                  <th class="__assignment u-align-center">
                    アサインメンバー・割当工数
                  </th>
                  <th class="__total u-align-right">
                    <span class="__large">割当工数合計</span
                    ><span class="__small">／設定工数合計</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <assignment-table-item-row
                  v-for="item in items"
                  :key="item.itemId"
                  :current-item="item"
                  @update-total="totalResult"
                  @bulk-item="bulkRegistrationOff()"
                  :contractor-users="contractor_users"
                  :validation-scope="validationScope"
                  @delete-item="addDeleteId"
                />
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="!isEdit"
          @click="changeEditMode(true)"
          class="p-assignment-editable-area p-assignment-editable-area--block"
        >
          <div class="u-table-wrapper u-table-wrapper--scroll __table">
            <table
              class="
                u-table-list-item
                u-table-list-item--extra-narrow
                u-table-list-item--multi
                u-table-assignment
              "
            >
              <thead>
                <tr>
                  <th class="__item u-align-left">項目</th>
                  <th class="__prescribed u-align-center">所定工数</th>
                  <th class="__assignment u-align-center">
                    アサインメンバー・割当工数
                  </th>
                  <th class="__total u-align-right">
                    <span class="__large">割当工数合計</span
                    ><span class="__small">／設定工数合計</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.itemId">
                  <td colspan="4" style="padding: 0">
                    <table class="u-table-assignment-item-row">
                      <tbody>
                        <tr>
                          <td class="__item u-align-left">
                            <span class="__text u-text-bold">{{
                              item.itemName
                            }}</span>
                          </td>
                          <td class="__prescribed u-align-center u-text-bold">
                            {{ item.itemPrescribed }}人日
                          </td>
                          <td class="__assignment">
                            <ul class="__list">
                              <li
                                v-for="assign in item.itemAssign"
                                :key="assign.assignId"
                                class="__item u-flex-center-center"
                              >
                                <span class="__menber u-align-center">
                                  {{ getContractorUserName(assign.member) }}
                                </span>
                                <span class="__man-hours u-align-center mgl-016"
                                  >{{ assign.manHours }}人日</span
                                >
                              </li>
                            </ul>
                          </td>
                          <td
                            class="__total u-align-right u-text-bold"
                            :rowspan="item.itemOptions.length + 1"
                          >
                            <span class="__large"
                              >{{ convertDecimal(totalItemManHours(item.itemId)) }}人日</span
                            ><span class="__small"
                              >／{{
                                convertDecimal(totalItemPrescribed(item.itemId))
                              }}人日</span
                            >
                          </td>
                        </tr>
                        <tr
                          v-for="option in item.itemOptions"
                          :key="option.optionId"
                        >
                          <td colspan="3" style="padding: 0">
                            <table class="u-table-assignment-option-row">
                              <tr>
                                <td class="__item pdl-032">
                                  {{ option.optionName }}
                                </td>
                                <td class="__prescribed u-align-center">
                                  {{ option.optionPrescribed }}人日
                                </td>
                                <td class="__assignment">
                                  <ul class="__list">
                                    <li
                                      v-for="assign in option.optionAssign"
                                      :key="assign.assignId"
                                      class="__item u-flex-center-center"
                                    >
                                      <span class="__menber u-align-center">
                                        {{ getContractorUserName(assign.member) }}
                                      </span>
                                      <span
                                        class="
                                          __man-hours
                                          u-align-center
                                          mgl-016
                                        "
                                        >{{ assign.manHours }}人日</span
                                      >
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="__block-depth-3 __block-depth-3--result-block">
        <div class="u-flex-right-center">
          <dl class="u-list-format-report __list">
            <div class="__item __total">
              <dt class="__term">割当済み工数</dt>
              <dd class="__body">{{ convertDecimal(totalAssign) }}人日</dd>
            </div>
            <div class="__item __subtotal">
              <dt class="__term">未割当工数</dt>
              <dd class="__body">{{ convertDecimal(notAssign) }}人日</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="__block-depth-2 __block-depth-2--form-submit-block">
      <div v-if="isEdit" class="u-flex-center-center">
        <button type="button" class="u-button-default u-button-default--large u-button-default--negative __button" @click="revertChanges()">キャンセル</button>
        <input
          class="
            u-button-default u-button-default--large u-button-default--primary __button
          "
          type="submit"
          value="更新"
          @click.prevent="submit"
        />
          <!-- @click="changeEditMode()" -->
      </div>
      <div v-else class="u-flex-center-center">
        <a :href="generateRoute(project.line_number)" class="u-button-default u-button-default--large u-button-default--secondary __button">案件情報・サイトマップ</a>
      </div>
    </div>
  </form>
</template>

<script>
import { toRaw } from 'vue';
// -- vuelidate
import useVuelidate from '@vuelidate/core'
// -- END vuelidate
import AssignmentTableItemRow from "../components/assignment/AssignmentTableItemRow.vue";
export default {
  setup () {
    const validation_scope = 'assigment'
    return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
  },
  components: {
    AssignmentTableItemRow,
  },
  props: {
    project: {
      type: Object,
      default: {}
    },
    pages: {
      type: Array,
      default: []
    },
    optional_costs: {
      type: Array,
      default: []
    },
    contractor_users: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      totalAssign: 0,
      notAssign: 0,
      items: [],
      isEdit: false,
      // -- add by backend
      validationScope: this.validation_scope,
      arrDelete: {
        optional_cost_assignments: [],
        page_assignments: [],
        page_optional_cost_assignments: [],
      },
      // -- END add by backend
      itemTemporarily: [],
    };
  },
  async created() {
    await this.fetchData();
    await this.totalResult();
  },
  methods: {
    changeEditMode(createTemporarily = false) {
      this.isEdit = !this.isEdit;
      // check param createTemporarily
      // if true, create itemTemporarily data from data.items and input it to data.itemTemporarily
      if (createTemporarily) {
        this.itemTemporarily = JSON.parse(JSON.stringify(this.items));
      }
    },
    // create method with name revertChanges
    // if called, revert data.items to data.itemTemporarily
    revertChanges() {
      this.items = JSON.parse(JSON.stringify(this.itemTemporarily));
      this.changeEditMode();
      this.v$.$reset()
    },
    totalItemManHours(itemId) {
      const itemManHours = this.items
        .filter((item) => {
          return item.itemId === itemId;
        })
        .map((assains) => {
          return assains.itemAssign
            .map((assign) => {
              return assign.manHours;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const optionManHours = this.items
        .filter((item) => {
          return item.itemId === itemId;
        })
        .map((targetItem) => {
          return targetItem.itemOptions
            .map((options) => {
              return options.optionAssign
                .map((option) => {
                  return option.manHours;
                })
                .reduce((prev, current) => {
                  return prev + current;
                }, 0);
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        });
      return Number(itemManHours) + Number(optionManHours);
    },
    totalItemPrescribed(itemId) {
      const itemPrescribed = this.items
        .filter((item) => {
          return item.itemId === itemId;
        })
        .map((targetItem) => {
          return targetItem.itemPrescribed;
        });
      const optionPrescribed = this.items
        .filter((item) => {
          return item.itemId === itemId;
        })
        .map((targetItem) => {
          return targetItem.itemOptions
            .map((options) => {
              return options.optionPrescribed;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        });
      return Number(itemPrescribed) + Number(optionPrescribed);
    },
    async totalResult() {
      const itemsManHours = this.items
        .map((item) => {
          return item.itemAssign
            .map((assign) => {
              return assign.manHours;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const optionsManHours = this.items
        .map((item) => {
          return item.itemOptions
            .map((option) => {
              return option.optionAssign
                .map((assign) => {
                  return assign.manHours;
                })
                .reduce((prev, current) => {
                  return prev + current;
                }, 0);
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const itemsPrescribed = this.items
        .map((item) => {
          return item.itemPrescribed;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const optionsPrescribed = this.items
        .map((item) => {
          return item.itemOptions
            .map((option) => {
              return option.optionPrescribed;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      this.totalAssign = itemsManHours + optionsManHours;
      this.notAssign =
        itemsPrescribed + optionsPrescribed - itemsManHours - optionsManHours >
        0
          ? itemsPrescribed +
            optionsPrescribed -
            itemsManHours -
            optionsManHours
          : 0;
    },
    bulkRegistrationToggle() {
      if (this.$refs.bulk_registration.checked) {
        this.items.forEach((item) => {
          item.itemOptions.forEach((option) => {
            option.isBulkAssign = true;
          });
          item.isBulkAssign = true;
        });
      } else {
        this.items.forEach((item) => {
          item.isBulkAssign = false;
          item.itemOptions.forEach((option) => {
            option.isBulkAssign = false;
          });
        });
      }
    },
    bulkRegistrationOff() {
      const itemsBulkAssign = this.items.map((item) => {
        return item.isBulkAssign;
      });
      const optionsBulkAssain = this.items.map((item) => {
        return item.itemOptions.map((option) => {
          return option.isBulkAssign;
        });
      });
      if ([...itemsBulkAssign, ...optionsBulkAssain].flat().includes(false)) {
        this.$refs.bulk_registration.checked = false;
      }
    },
    bulkRegistrationAssign() {
      const assignMember = this.$refs.bulk_registration_assign_member.value;
      this.items.forEach((item) => {
        if (item.isBulkAssign) {
          // item.itemAssign.forEach((assign) => {
          //   assign.member = assignMember;
          // });
          // -- add by backend
          let newAssigment = {
            assignId: item.itemAssign.length+1,
            cost_id: item.cost_id,
            manHours: null,
            member: assignMember,
            paId: "",
            type: item.type == 'pages' ? 'page_assignments' : 'optional_cost_assignment'
          };
          item.itemAssign.push(newAssigment)
          // -- END add by backend
        }
        item.itemOptions.forEach((option) => {
          if (option.isBulkAssign) {
            // option.optionAssign.forEach((assign) => {
            //   assign.member = assignMember;
            // });
            // -- add by backend
            let newOptionAssigment = {
              assignId: item.itemOptions.length+1,
              cost_id: option.cost_id,
              manHours: null,
              member: assignMember,
              pocaId: "",
            };
            option.optionAssign.push(newOptionAssigment)
            // -- END add by backend
          }
        });
      });
    },
    async fetchData() {
      this.items = [];

      // -- loop pages
      this.pages.forEach((page, p_index) => {
        let formPage = {
          pId: page.id, // pages->id
          itemId: p_index,
          itemName: page.title, // pages->title
          itemPrescribed: page.cost.cost, // pages->costs . "人日"
          isBulkAssign: false,
          itemAssign: [],
          itemOptions: [],
          type: 'pages', // to indicate this that from pages
          cost_id: page.cost_id,
        };

        // -- set itemAssign
       page.cost.assignments.forEach((page_assignment, pa_index) => {
          let formItemAssign = {
            paId: page_assignment.id, // pages->cost->assignments.id
            assignId: pa_index,
            member: page_assignment.contractor_user_id, // pages->cost->assignments.contractor_user_id
            manHours: page_assignment.cost, // pages->cost->assignments.cost
            type: 'page_assignments', // to indicate this that from page_assignments
            isOptionCostAssignment: 0,
            cost_id: page_assignment.cost_id, // pages->cost->assignments.cost_id
          };
          formPage.itemAssign.push(formItemAssign);
        });
        // -- END set itemAssign

        // -- set itemOptions
        page.page_optional_costs.forEach((page_optional_cost, poc_index) => {
          let formItemOptions = {
            pocId: page_optional_cost.id, // pages->page_optional_costs.id
            optionId: poc_index,
            optionName: page_optional_cost.title, // pages->page_optional_costs.title
            optionPrescribed: page_optional_cost.cost.cost, // pages->page_optional_costs.cost . "人日"
            isBulkAssign: false,
            optionAssign: [],
            cost_id: page_optional_cost.cost_id,
          };

          // -- set optionAssign
          page_optional_cost.cost.assignments.forEach((page_optional_cost_assignment, poca_index) => {
            let formOptionAssign = {
              pocaId: page_optional_cost_assignment.id, // pages->page_optional_costs->cost->assignment.id
              assignId: poca_index,
              member: page_optional_cost_assignment.contractor_user_id, // pages->page_optional_costs->cost->assignment.contractor_user_id
              manHours: page_optional_cost_assignment.cost, // pages->page_optional_costs->cost->assignment.cost
              type: 'page_optional_cost_assignments', // to indicate this that from page_optional_cost_assignments
              cost_id: page_optional_cost_assignment.cost_id, // pages->page_optional_costs->cost->assignment.cost_id
            };
            formItemOptions.optionAssign.push(formOptionAssign);
          });
          // -- END set optionAssign

          formPage.itemOptions.push(formItemOptions);
        });
        // -- END set itemOptions

        this.items.push(formPage);
      });
      // -- END loop pages

      // --  continuation of itemId from loop pages
      let last_itemId = this.pages.length;
      // --  END continuation of itemId from loop pages

      // -- loop optional_costs
      this.optional_costs.forEach((optional_cost, oc_index) => {
        let formOptionalCost = {
          pId: optional_cost.id,
          itemId: last_itemId,
          itemName: optional_cost.title, // optional_costs.title
          itemPrescribed: optional_cost.cost.cost, // optional_costs.cost . "人日"
          isBulkAssign: false,
          itemAssign: [],
          itemOptions: [], // always empty
          type: 'optional_cost', // to indicate this that from optional_cost
          cost_id: optional_cost.cost_id,
        };

        // -- set itemAssign
        optional_cost.cost.assignments.forEach((optional_cost_assignment, oca_index) => {
          let formItemAssign = {
            paId: optional_cost_assignment.id, // optional_costs->cost->assignments.id
            assignId: oca_index,
            member: optional_cost_assignment.contractor_user_id, // optional_costs->cost->assignments.contractor_user_id
            manHours: optional_cost_assignment.cost, // optional_costs->cost->assignments.cost
            type: 'optional_cost_assignments', // to indicate this that from optional_cost_assignment
            isOptionCostAssignment: 1,
            cost_id: optional_cost_assignment.cost_id, // optional_costs->cost->assignments.cost_id
          };
          formOptionalCost.itemAssign.push(formItemAssign);
        });
        // -- END set itemAssign

        this.items.push(formOptionalCost);

        // -- increament last_itemId
        last_itemId++;
        // -- END increament last_itemId

      });
      // -- END loop optional_costs
    },
    // -- add by backend
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- set form
        let form = {
          items: this.items,
          deletes: this.arrDelete
        };
        // -- END form

        await axios.put(route('contractor.api.assignment.update'), form)
          .then((response) => {
            if (response.status == 200) {
              this.changeEditMode();
              this.resetDeleteId();
            }
          })
          .catch((error) => {
            console.log(error)
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
    },
    // -- for rounding 2 decimal
    convertDecimal(data) {
      return +(Math.round(data + "e+2")  + "e-2");
    },
    // -- END for rounding 2 decimal
    getContractorUserName(id) {
      let userDetail = this.contractor_users.find(user => user.id == id);
      return userDetail.name;
    },
    addDeleteId(data) {
      switch (data.type) {
        case 'page_assignments':
          if (data.paId != '') this.arrDelete.page_assignments.push(data.paId);
          break;
        case 'page_optional_cost_assignments':
          if (data.pocaId != '') this.arrDelete.page_optional_cost_assignments.push(data.pocaId);
          break;
        case 'optional_cost_assignments':
          if (data.paId != '') this.arrDelete.optional_cost_assignments.push(data.paId);
          break;
        default:
          break;
      }
    },
    resetDeleteId() {
      this.arrDelete.optional_cost_assignments = [];
      this.arrDelete.page_assignments = [];
      this.arrDelete.page_optional_cost_assignments = [];
    },
    // -- END add by backend
    generateRoute(line_number){
        return route('contractor.project.show', { project: line_number });
    },
  },
};
</script>
