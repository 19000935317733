'use strict';

export function Modal({triggers, element, closes}) {
  const modalTriggers = document.querySelectorAll(triggers);
  const modalElement = document.querySelector(element);
  const modalCloses = document.querySelectorAll(closes);
  if(modalElement) {
    modalTriggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        modalElement.classList.add('js-modal--shown');
      });
    });
    modalCloses.forEach((close) => {
      close.addEventListener('click', () => {
        modalElement.classList.remove('js-modal--shown');
      });
    });
  }
}