<template>
  <div class="__block-depth-2">
		<div class="u-box-content-default">
			<div class="p-user-account-icon mgt-024">
				<div class="u-form-file-group js-local-file-upload u-flex-left-center">
					<figure class="__thumbnail u-flex-shrink-0">
                        <img class="__image" :src="form.profile_image_url.url" alt="">
                    </figure>
					<button class="__delete" type="button" @click.prevent="resetImage">
                        <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
                    </button>
					<div class="__input u-flex-shrink-1 u-flex-grow-1 u-flex-column-reverse">
						<div class="__controller">
							<input type="file" id="input_file"
                                ref="file"
                                accept="image/gif,image/jpeg,image/jpg,image/png"
                                @change="onFileSelected"
                            >
							<label class="u-form-file-upload" for="input_file">
								<span class="__icon">
									<img class="__image" src="" alt="">
								</span>
								<span class="__text">ファイルを選択</span>
							</label>
							<span class="__file-name">ファイルが選択されていません</span>
						</div>
						<h2 class="u-text-bold mgb-016">プロフィール画像設定</h2>
					</div>
				</div>
			</div>
			<dl class="u-list-input-label-set __list mgt-024">
				<div class="__item">
					<dt class="__term">名前</dt>
					<dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
						    <input type="text" placeholder="例）大阪 太郎" name="" v-model="form.name">
                        </span>
                        <!-- error message -->
                        <div v-for="(error, index) of v$.form.name.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                    </dd>
				</div>
				<div class="__item">
					<dt class="__term">メールアドレス</dt>
					<dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                            <input type="email" placeholder="例）info@example.com" name="" v-model="form.email">
                        </span>
                        <!-- error message -->
                        <div v-for="(error, index) of v$.form.email.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                    </dd>
				</div>
				<div class="__item">
					<dt class="__term">パスワード</dt>
					<dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
							<input type="password" placeholder="ログイン用のパスワードを入力" name="" v-model="form.password">
                        </span>
                        <!-- error message -->
                        <div v-for="(error, index) of v$.form.password.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                    </dd>
				</div>
				<div class="__item">
					<dt class="__term">パスワード（確認用）</dt>
					<dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
							<input type="password" placeholder="確認のため再度入力" name="" v-model="form.re_password">
                        </span>
                        <!-- error message -->
                        <div v-for="(error, index) of v$.form.re_password.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                    </dd>
				</div>
				<div class="__item">
					<dt class="__term">役職</dt>
					<dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="例）ウェブディレクター" name="" v-model="form.position">
                        </span>
                        <!-- error message -->
                        <div v-for="(error, index) of v$.form.position.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                    </dd>
				</div>
				<div class="__item">
					<dt class="__term">性別</dt>
					<dd class="__body">
						<div class="u-form-input-group">
							<label class="u-form-radio-default u-form-input-radio">
								<input type="radio" name="sex" value="0" v-model="form.gender">
                                <span class="__text">男性</span>
							</label>
							<label class="u-form-radio-default u-form-input-radio">
								<input type="radio" name="radio1" value="1" v-model="form.gender">
                                <span class="__text">女性</span>
							</label>
							<label class="u-form-radio-default u-form-input-radio">
								<input type="radio" name="radio1" value="2" v-model="form.gender">
                                <span class="__text">未設定</span>
							</label>
						</div>
                        <!-- error message -->
												<div v-for="(error, index) of v$.form.gender.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
					</dd>
				</div>
			</dl>
		</div>
		<div class="u-flex-center-center mgt-048">
			<input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="更新" @click.prevent="submit()">
		</div>
	</div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, email, required, minLength, maxLength, sameAs } from '@vuelidate/validators'
// -- END vuelidate
// -- for custom validate
const regex_password = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9.?\/-]{8,24}$/);
// -- END for custom validate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    contractor_user: {
      type: Object,
      default: {}
    },
  },
  mounted () {
    this.setContractorUser();
  },
  data() {
    return {
      form: {
        profile_image_url: {},
        name: '',
        email: '',
        password: '',
        re_password: '',
        position: '',
        gender: '',
      },
      erros_submit: '',
    }
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('名前は必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `名前は${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`名前は${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        email: {
          required: helpers.withMessage('メールアドレスは必須項目です。', required),
          email: helpers.withMessage('メールアドレスを正しく入力してください', email),
          maxLength: helpers.withMessage(
            ({ $params }) =>`メールアドレスは${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        password: {
          regex_password:  helpers.withMessage('パスワードを正しく入力してください', regex_password),
        },
        re_password: {
          sameAsPassword: helpers.withMessage(
            ({ $params }) => `確認用のパスワードが設定するパスワードと同じではありません`, sameAs(this.form.password)
          ),
          regex_password:  helpers.withMessage('パスワードは半角英数字8文字以上24文字以内で入力してください', regex_password),
        },
        position: {
          minLength: helpers.withMessage(
            ({ $params }) => `役職は${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`役職は${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        gender: {
          required: helpers.withMessage('性別は必須項目です。', required),
        },
      }
    }
  },
  methods: {
    async submit() {
      const isFormCorrect = await this.v$.form.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        const fd = new FormData();
        if (this.form.profile_image_url.file) {
          fd.append('profile_image', this.form.profile_image_url.file)
        }
        if (this.form.profile_image_url.image_status) {
          fd.append('image_status', this.form.profile_image_url.image_status)
        }
        fd.append('name', this.form.name)
        fd.append('email', this.form.email)
        if (this.form.password != '') {
          fd.append('password', this.form.password)
        }
        fd.append('position', this.form.position)
        fd.append('gender', this.form.gender)

        await axios.post(route('contractor.contractor_user.update'), fd)
          .then((response) => {
            if (response.status == 200) {
              window.location.href = route('contractor.contractor_user.edit');
            }
          })
          .catch((error) => {
            console.log(error)
            this.erros_submit = error.response.data;
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }

    },
    onFileSelected(e) {
      if (e && e.target.files[0]) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        const name_file = e.target.files[0].name;
        const image_status = this.contractor_user.profile_image_url != null ? 'update' : 'new';
        this.form.profile_image_url = { url, file, name_file, image_status };
      }
    },
    setContractorUser() {
      this.form.name = this.contractor_user.name;
      this.form.email = this.contractor_user.email;
      this.form.position = this.contractor_user.position == null ? '' : this.contractor_user.position;
      this.form.gender = this.contractor_user.gender == null ? 'default' : this.contractor_user.gender;
      //  set image
      if (this.contractor_user.url != '') {
        this.form.profile_image_url.url = this.contractor_user.url;
      }
      else {
        this.setDefaultImage();
      }
    },
    resetImage() {
      this.v$.form.$reset();
      this.form.profile_image_url = {};
      this.setDefaultImage();
    },
    setDefaultImage() {
      this.form.profile_image_url.url = '/assets/img/site/thumbnail_default_min.png';
      if (this.contractor_user.url != '') {
        this.form.profile_image_url.image_status = 'delete';
      }
    },
  },
}
</script>

<style scoped>
</style>
