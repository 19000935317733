<template>
  <li class="__question">
    <div class="u-box-content-default __box">
      <div class="__block-depth-3">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term">
                <span v-if="sheetType === 'survey'">質問タイトル</span>
                <span v-else-if="sheetType === 'planning'">提案タイトル</span>
            </dt>
            <dd class="__body">
              <div class="u-flex-between-center">
                <div class="u-flex-shrink-1 u-flex-grow-1">
                  <span class="u-form-input-default u-form-input-text-box">
                    <input v-model="items.questionTitle" type="text" :placeholder="[sheetType === 'survey' ? '具体的な質問の内容などを入力' : sheetType === 'planning' ? '具体的な提案の内容などを入力' : '項目を入力']" name="questionTitle">
                  </span>
                  <!-- error message -->
                  <div class="input-errors" v-for="(error, index) of v$.items.questionTitle.$errors" :key="index">
                    <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                  </div>
                  <!-- END error message -->
                </div>
                <div class="u-flex-shrink-0 u-flex-grow-0 __button-box">
                  <button class="__button" type="button" @click="emitDeleteQuestion(items.questionId)">
                    <img class="__image" src="/assets/img/site/icon_delete_small_min.svg" alt="削除">
                  </button>
                </div>
              </div>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">入力タイプ</dt>
            <dd class="__body">
              <div class="u-form-input-group">
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="textbox"><span class="__text">テキストボックス</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="textarea"><span class="__text">テキストエリア</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="file"><span class="__text">ファイル添付</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="date"><span class="__text">日付</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="radio"><span class="__text">ラジオボタン</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="select"><span class="__text">セレクトボックス</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input v-model="items.questionType" type="radio" :name="`type_${items.questionId}`" value="checkbox"><span class="__text">チェックボックス</span>
                </label>
              </div>
            </dd>
          </div>
          <div v-if="enableOptions" class="__item">
            <dt class="__term">選択肢</dt>
            <dd class="__body">
              <option-survey v-for="option in items.questionOptions" :key="`${items.questionId}-${option.optionId}`" :options="option" @delete-option="deleteOption" :validationScope="validationScope"/>
              <div class="u-flex-center-center mgt-016">
                <button class="__button __button--add-question" type="button" @click="addOption()">
                  <img class="__image" src="/assets/img/site/icon_add_secondary_small_min.svg" alt="">
                  <br>
                  <span class="__caption u-text-caption">選択肢を追加</span>
                </button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </li>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core';
  import { helpers, required, minLength, maxLength } from '@vuelidate/validators';
  // -- END vuelidate
  import OptionSurvey from './OptionSurvey.vue';
  export default {
    setup (props) {
      return { v$: useVuelidate({ $scope: props.validationScope }) }
    },
    components: {
      OptionSurvey
    },
    props: {
      items: {
        type: Object,
        default: {}
      },
      validationScope: {
        type: String,
        default: ''
      },
      sheetType: {
        type: String,
        default: ''
      }
    },
    emits: [
      'delete-question'
    ],
    data() {
      return {
        currentOptionsId: 0,
      }
    },
    validations() {
      return {
        items: {
          questionTitle: {
            required: helpers.withMessage('提案タイトルは必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `提案タイトルは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`提案タイトルは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          questionType: {
            required: helpers.withMessage('入力タイプは必須項目です。', required),
          }
        }
      }
    },
    computed: {
      enableOptions() {
        return this.items.questionType === 'radio' || this.items.questionType === 'checkbox' || this.items.questionType === 'select';
      }
    },
    methods: {
      emitDeleteQuestion(id) {
        this.$emit('delete-question', id);
      },
      addOption() {
        this.items.questionOptions.push({
          optionId: ++this.currentOptionsId,
          optionName: null
        });
      },
      deleteOption(payload) {
        if (this.items.questionOptions.length > 1) {
          const result = this.items.questionOptions.filter((item) => {
            return item.optionId !== payload
          });
          this.items.questionOptions = result;
        }
      }
    }
  }
</script>

<style scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
