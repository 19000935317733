<template>
  <form action="" method="">
    <div class="__block-depth-2 __block-depth-2--form-input-block">
      <div class="c-format-request-default c-format-request-estimation c-format-request-default--input">
        <h1 class="__format-headline">
          <span v-if="isEdit.format" class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box">
            <input v-model="titleFormat" type="text" placeholder="見積書" name="" class="u-align-center" @blur="updateEstimate('format')" @keydown.enter.prevent="updateEstimate('format')" @keydown.esc.prevent="changeEditMode('format', true)">
          </span>
          <span v-else @click="changeEditMode('format')" class="p-estimation-editable-area p-estimation-editable-area--inline">{{ titleFormat }}</span>
        </h1>
        <!-- error message -->
          <div class="u-align-center" v-for="(error, index) of v$.titleFormat.$errors" :key="index">
            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
          </div>
        <!-- END error message -->
        <div class="__top-section u-flex-between-top">
          <div class="__left">
            <div class="__to">
              <div v-if="isEdit.to" class="p-estimation-to-edit">
                <div class="u-flex-between-top __row __row--company-to">
                  <div class="__column __column--company">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">見積もり宛名<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="Pyatto株式会社" name="" v-model="estimationTo.company" ref="estimation_to_company" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.company.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__column __column--to">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">表記<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                            <select v-model="estimationTo.to" ref="estimation_to_to">
                              <option value="御中">御中</option>
                              <option value="様">様</option>
                            </select>
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.to.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="u-flex-between-top __row __row--email-tel">
                  <div class="__column __column--email">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">メールアドレス</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="email" placeholder="info@example.com" name="" v-model="estimationTo.email" ref="estimation_to_email" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.email.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__column __column--tel">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">電話番号</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="tel" placeholder="080-1234-5678" name="" v-model="estimationTo.tel" ref="estimation_to_tel" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.tel.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="u-flex-between-top __row __row--zipcode-prefecture-city">
                  <div class="__column __column--zipcode">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">郵便番号</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="000-0000" name="" v-model="estimationTo.zipcode" ref="estimation_to_zipcode" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.zipcode.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__column __column--prefecture">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">都道府県</dt>
                        <dd class="__body">
                          <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                            <select v-model="estimationTo.prefecture" ref="estimation_to_prefecture">
                              <prefecture-options />
                            </select>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__column __column--city">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">市区町村・番地</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="〇〇区〇〇市" name="" v-model="estimationTo.city" ref="estimation_to_city" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.city.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="u-flex-between-top __row __row--address">
                  <div class="__column __column--address">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">建物名・部屋番号など</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="〇〇ビル〇〇階" name="" v-model="estimationTo.address" ref="estimation_to_address" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                          </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.estimationTo.address.$errors" :key="index">
                            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          </div>
                          <!-- END error message -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="u-flex-right-center __row">
                  <button @click.prevent="changeEditMode('to', true)" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                  <button @click.prevent="updateEstimationTo()" class="u-button-default u-button-default--small u-button-default--primary __button">確定</button>
                </div>
              </div>
              <div v-else @click="changeEditMode('to')" class="p-estimation-editable-area p-estimation-editable-area--block">
                <div class="p-estimation-to-display">
                  <h2 class="__client-name">
                    <span class="__company">{{ estimationTo.company }}</span><span class="__dear">{{ estimationTo.to }}</span>
                  </h2>
                  <ul class="__info-list">
                    <li v-if="estimationTo.zipcode" class="__item">〒{{ estimationTo.zipcode }}</li>
                    <li v-if="estimationTo.prefecture || estimationTo.city || estimationTo.address" class="__item">{{ estimationTo.prefecture }}{{ estimationTo.city }}{{ estimationTo.address }}</li>
                    <li v-if="estimationTo.email" class="__item">Email：{{ estimationTo.email }}</li>
                    <li v-if="estimationTo.tel" class="__item">TEL：{{ estimationTo.tel }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <p class="__caption">下記の通りお見積り申し上げます</p>
            <table class="__result-table u-table-outline u-table-format-request-result">
              <tbody>
                <tr>
                  <th>小計</th>
                  <th>消費税</th>
                  <th class="__important">合計お見積り金額</th>
                </tr>
                <tr>
                  <td class="u-align-right">
                    <span class="__number">{{ subTotal }}</span><span class="__yen">円</span>
                  </td>
                  <td class="u-align-right">
                    <span class="__number">{{ tax.all }}</span><span class="__yen">円</span>
                  </td>
                  <td class="u-align-right __important">
                    <span class="__number">{{ grandTotal }}</span><span class="__yen">円</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="__right">
              <div class="__meta">
                  <div>
                      <p>No.{{ estimate.id }}</p>
                      <p>作成日：{{ moment(estimate.updated_at).format('YYYY年MM月DD日') }}</p>
                  </div>
              </div>
              <div v-show="isEdit.from" class="p-estimation-to-edit">
                <dl class="u-list-input-label-set __list">
                    <div class="__item">
                        <dt class="__term">発行者名<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="text" placeholder="例）株式会社Pyatto" name="" ref="estimation_from_company" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisher">
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisher.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者住所<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-area">
                                <textarea placeholder="発行者の住所を入力" name="" ref="estimation_from_address" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherAddress"></textarea>
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherAddress.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者電話番号</dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="tel" placeholder="例）080-1234-5678" name="" ref="estimation_from_tel" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherPhone">
                            </span>
                            <!-- error message -->
                            <!-- for now no need -->
                            <!-- <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherPhone.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div> -->
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者メールアドレス</dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="email" placeholder="例）info@example.com" name="" ref="estimation_from_email" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherEmail">
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherEmail.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者印</dt>
                        <dd class="__body">
                            <div class="u-form-file-group js-local-file-upload u-flex-left-center">
                                <figure class="__thumbnail u-flex-shrink-0 mgr-024">
                                    <img class="__image" :src="estimationFrom.publisherSeal.url" style="max-width: 100px; height: auto;" alt="">
                                </figure>
                                <button class="__delete mgr-024" type="button" @click.prevent="resetImage">
                                    <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
                                </button>
                                <div class="__input u-flex-shrink-1 u-flex-grow-1 u-flex-column-reverse">
                                    <div class="__controller">
                                        <input type="file" id="input_file"
                                            ref="file"
                                            accept="image/gif,image/jpeg,image/jpg,image/png"
                                            @change="onFileSelected"
                                        >
                                        <label class="u-form-file-upload" for="input_file">
                                            <span class="__icon">
                                                <img class="__image" src="" alt="">
                                            </span>
                                            <span class="__text">ファイルを選択</span>
                                        </label>
                                        <span class="__file-name">{{ publisherSealFileName }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- error message -->
                            <!-- for now no need -->
                            <!-- <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherSeal.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div> -->
                            <!-- END error message -->
                        </dd>
                    </div>
                </dl>
                <div class="u-flex-right-center __row">
                  <button @click.prevent="changeEditMode('from', true)" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                  <button @click.prevent="updateEstimationFrom()" class="u-button-default u-button-default--small u-button-default--primary __button">確定</button>
                </div>
              </div>
              <div v-show="!isEdit.from" @click="changeEditMode('from')" class="p-estimation-editable-area p-estimation-editable-area--block">
                <div class="u-flex-between-top">
                  <div class="__from">
                      <h3 class="__company">{{ estimationFrom.publisher }}</h3>
                      <address class="__address">
                        {{ estimationFrom.publisherAddress }}
                      </address>
                      <span class="__tel">TEL：{{ estimationFrom.publisherPhone }}</span>
                  </div>
                  <figure class="__stamp" style="max-width: 200px;"><img class="__image" :src="estimationFrom.publisherSeal.url" alt=""></figure>
                </div>
              </div>
          </div>
        </div>
        <div class="__bottom-section">
          <div v-if="isEdit.title" class="__title">
            <span class="u-form-input-default u-form-input-default--medium u-form-input-text-box">
              <input v-model="titleDocument" type="text" placeholder="例）Pyatto株式会社コーポレートサイト制作 お見積" name="" @blur="updateEstimate('title')" @keydown.enter.prevent="updateEstimate('title')" @keydown.esc.prevent="changeEditMode('title', true)">
            </span>
            <!-- error message -->
            <div class="input-errors" v-for="(error, index) of v$.titleDocument.$errors" :key="index">
              <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
            </div>
            <!-- END error message -->
          </div>
          <h2 v-else @click="changeEditMode('title')" class="__title p-estimation-editable-area p-estimation-editable-area--block">{{ titleDocument }}</h2>
          <div class="__format-content">
            <div v-if="isEdit.estimation">
              <div class="__block-depth-3 __block-depth-3--table-block">
                <div class="u-table-wrapper u-table-wrapper--scroll __table">
                  <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-estimation">
                    <estimation-table
                      @update-amount="updateAmount"
                      @update-items="updateItems"
                      @update-taxrate="updateTaxRate"
                      :current-items="items"
                      :tax-display-mode="taxDisplayMode"
                      @submit-item="submitChangeEstimateDetail()"
                      @exit-edit-mode="changeEditMode('estimation', true)"
                      ref="estimationTable"
                      :scope="validationScope"
                      :defaultTaxRate="defaultTaxRate" />
                  </table>
                </div>
              </div>
              <div class="__block-depth-3 __block-depth-3--table-add-block">
                <div class="u-flex-center-center">
                  <button class="u-button-default u-button-default--medium u-button-default--secondary" @click.prevent="addPageCost(project.pageOptionalCosts)">
                    設定工数を見積もりに反映
                  </button>
                </div>
              </div>
              <div class="__block-depth-3 __block-depth-3--table-update-block u-flex-center-center">
                <button @click.prevent="changeEditMode('estimation', true);" class="u-button-default u-button-default--large u-button-default--negative __button">キャンセル</button>
                <button @click.prevent="updateEstimateDetail();" class="u-button-default u-button-default--large u-button-default--primary __button">見積もりを更新</button>
              </div>
            </div>
            <div v-else @click="changeEditMode('estimation'); createTemporaryEstimation()" class="p-estimation-editable-area p-estimation-editable-area--block">
              <div class="__block-depth-3 __block-depth-3--table-block">
                <div class="u-table-wrapper u-table-wrapper--scroll __table">
                  <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-estimation">
                    <thead>
                      <tr>
                        <th class="__item u-align-left">品目</th>
                        <th class="__count">数量</th>
                        <th class="__measure">単位</th>
                        <th class="__unit">単価</th>
                        <th class="__subtotal u-align-right">品目小計</th>
                        <th class="__total u-align-right">品目金額</th>
                      </tr>
                    </thead>
                    <tbody v-for="item in items" :key="item.itemId">
                      <tr>
                        <td class="__item u-text-bold">{{ item.itemName }}</td>
                        <td class="__count u-align-center">{{ item.itemCount }}</td>
                        <td class="__measure u-align-center">{{ item.itemMeasure }}</td>
                        <td class="__unit u-align-center">{{ item.itemUnit }}</td>
                        <td class="__subtotal u-align-right">{{ Number(item.itemSubtotal).toLocaleString() }}</td>
                        <td class="__total u-align-right" :rowspan="item.itemOptions.length + 1">{{ Number(item.itemTotal).toLocaleString() }}</td>
                      </tr>
                      <tr v-for="option in item.itemOptions" :key="option.optionId" class="__indent-left-no-sort __option-row">
                        <td class="__item">{{ option.optionName }}</td>
                        <td class="__count u-align-center">{{ option.optionCount }}</td>
                        <td class="__measure u-align-center">{{ option.optionMeasure }}</td>
                        <td class="__unit u-align-center">{{ option.optionUnit }}</td>
                        <td class="__subtotal u-align-right">{{ Number(option.optionSubtotal).toLocaleString() }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="__block-depth-3 __block-depth-3--result-block">
              <div class="__total-wrap u-flex-right-center">
                <div v-if="isEdit.result" class="p-estimation-result-edit">
                  <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">小計<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                            <input type="number" class="u-align-right" placeholder="" name="" v-model="result.subTotal" ref="result_subtotal">
                            <p v-for="(error, index) of v$.result.subTotal.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                      <div class="__item">
                        <dt class="__term">消費税（10%）<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                            <input type="number" class="u-align-right" placeholder="" name="" v-model="result.taxRate10" ref="result_tax_rate_10">
                            <p v-for="(error, index) of v$.result.taxRate10.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                      <div class="__item">
                        <dt class="__term">消費税（8%）<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                            <input type="number" class="u-align-right" placeholder="" name="" v-model="result.taxRate8" ref="result_tax_rate_8">
                            <p v-for="(error, index) of v$.result.taxRate8.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                      <div class="__item">
                        <dt class="__term">消費税（軽減8%）<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                            <input type="number" class="u-align-right" placeholder="" name="" v-model="result.taxReduced" ref="result_tax_reduced">
                            <p v-for="(error, index) of v$.result.taxReduced.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                      <div class="__item">
                        <dt class="__term">ご請求金額<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                            <input type="number" class="u-align-right" placeholder="" name="" v-model="result.total" ref="result_total">
                            <p v-for="(error, index) of v$.result.total.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                  </dl>
                  <div class="u-flex-right-center __row mgt-024">
                      <button @click="changeEditMode('result'); resetResult()" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                      <button @click.prevent="updateEstimate('result')" class="u-button-default u-button-default--small u-button-default--primary __button mgl-016">確定</button>
                  </div>
                </div>
                <div v-else @click="changeEditMode('result'); createTemporaryResult()" class="p-estimation-editable-area p-estimation-editable-area--inline" style="width: 480px;">
                  <dl class="u-list-format-total __list">
                    <div class="__item __subtotal">
                      <dt class="__term">小計</dt>
                      <dd class="__body"> <span class="__number">{{ subTotal }}</span><span class="__yen">円</span></dd>
                    </div>
                    <div v-if="taxableAmountByTaxRate().taxRate10 > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                      <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（10%対象額 {{ taxableAmount.taxRate10 }}円）</span></dt>
                      <dd class="__body"> <span class="__number">{{ tax.taxRate10 }}</span><span class="__yen">円</span></dd>
                    </div>
                    <div v-if="taxableAmountByTaxRate().taxRate8 > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                      <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（8%対象額 {{ taxableAmount.taxRate8 }}円）</span></dt>
                      <dd class="__body"> <span class="__number">{{ tax.taxRate8 }}</span><span class="__yen">円</span></dd>
                    </div>
                    <div v-if="taxableAmountByTaxRate().taxReduced > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                      <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（軽減税8%対象額 {{ taxableAmount.taxReduced }}円）</span></dt>
                      <dd class="__body"> <span class="__number">{{ tax.taxReduced }}</span><span class="__yen">円</span></dd>
                    </div>
                    <div class="__item __total">
                      <dt class="__term">ご請求金額<span class="__small">（税込）</span></dt>
                      <dd class="__body"> <span class="__number">{{ grandTotal }}</span><span class="__yen">円</span></dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div class="__block-depth-3 __block-depth-3--notice-block">
              <dl class="u-list-input-label-set __list">
                <div class="__item">
                  <dt class="__term">備考</dt>
                  <dd v-if="isEdit.message" class="__body">
                    <span class="u-form-input-default u-form-input-text-area">
                      <textarea v-model="message" placeholder="見積もりの補足説明などを入力" name="" @blur="updateEstimate('message')" @keydown.esc.prevent="changeEditMode('message', true)"></textarea>
                    </span>
                    <!-- error message -->
                    <div class="input-errors" v-for="(error, index) of v$.message.$errors" :key="index">
                      <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                    </div>
                    <!-- END error message -->
                  </dd>
                  <dd v-else @click="changeEditMode('message')" class="__body p-estimation-editable-area p-estimation-editable-area--block2">
                    <p class="p-estimation-message-display display-textarea-data">{{ message }}</p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="__block-depth-2 __block-depth-2--form-submit-block">
      <div v-if="!isEdit.format &&
        !isEdit.to &&
        !isEdit.from &&
        !isEdit.title &&
        !isEdit.message &&
        !isEdit.estimation" class="u-flex-center-center">
        <input class="js-modal-open u-button-default u-button-default--large u-button-default--secondary __button" type="button" value="この見積もりから請求"
          @click.prevent="confirmDuplicateEstimate()">
        <input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="PDFデータ出力" @click.prevent="exportPdf()">
      </div>
    </div>
  </form>
  <estimation-float-modal 
    :pages="project.pageOptionalCosts" 
    :optional_costs="project.optional_costs"
    :total_all_page_cost="total_all_page_cost" 
    :is-edit-estimation="isEdit.estimation" 
    @add-page-cost="addPageCost" />
  <modal-create-invoice-reflect-estimate
    :projectLineNumber="project.line_number"
    :estimateLineNumber="estimate.line_number"
    ref="modalCreateInvoiceReflectEstimate" />
</template>

<script>
  import moment from 'moment';
  import { toRaw } from 'vue';
  import { CommaSeparatedNumber } from '../../modules/comma-separated-number.js';
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, email, required, minLength, maxLength } from '@vuelidate/validators'
  // -- END vuelidate
  // -- components
  import PrefectureOptions from '../components/common/PrefectureOptions.vue';
  import EstimationTable from '../components/estimation/EstimationTable.vue';
  import EstimationFloatModal from '../components/estimation/EstimationFloatModal.vue';
  // -- END components
  export default {
    setup () {
      const validation_scope = 'estimate'
      return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
    },
    components: {
      PrefectureOptions,
      EstimationTable,
      EstimationFloatModal
    },
    props: [
      'dataEstimate',
      'project',
      'total_all_page_cost',
      'tax_data',
      'all_tax_option',
      'defaultTaxRate',
      'documentSettingPublisherSeal',
      'documentSettingPublisherSealUrl',
    ],
    data() {
      return {
        estimate: {},
        isEdit: {
          format: false, // head_title
          to: false,
          title: false, // title
          message: false, // note
          estimation: false,
          from: false
        },
        taxRate: 0.1,
        taxDisplayMode: 'not_included', // 税別表示：'not_included', 税込表示：'included', 税込表示（免税）：'tax_free'
        taxRounding: 'round_up', // 切り上げ：'round_up', 切り捨て：'round_down', 四捨五入：'round_off'
        message: null,
        items: [],
        itemsTemporary: [],
        titleDocument: 'Pyatto株式会社コーポレートサイト制作 お見積', // title
        titleFormat: '見積書', // head_title
        estimationTo: {
          company: 'Pyatto株式会社',
          to: '御中',
          email: null,
          tel: null,
          zipcode: null,
          prefecture: '',
          city: null,
          address: null
        },
        calculatedTotal: 0,
        calculatedTotalTemporary: 0,
        result: {
          subTotal: 0,
          taxRate10: 0,
          taxRate8: 0,
          taxReduced: 0,
          total: 0
        },
        resultTemporary: {
          subTotal: null,
          taxRate10: null,
          taxRate8: null,
          taxReduced: null,
          total: null
        },
        // -- add by backend
        validationScope: this.validation_scope,
        estimationFrom: {
          publisher: '',
          publisherAddress: '',
          publisherPhone: null,
          publisherEmail: null,
          publisherSeal: {},
        },
        publisherSealFileName: '',
        // -- END add by backend

      }
    },
    validations() {
      return {
        titleDocument: {
          required: helpers.withMessage('見積もり件名は必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `見積もり件名は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`見積もり件名は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        titleFormat: {
          required: helpers.withMessage('見積もりフォーマットタイトルは必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `見積もりフォーマットタイトルは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`見積もりフォーマットタイトルは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        message: {
          maxLength: helpers.withMessage(
            ({ $params }) =>`備考は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        estimationTo: {
          company: {
            required: helpers.withMessage('見積もり宛名は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `見積もり宛名は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`見積もり宛名は、${$params.max}文字以下で入力してください。`, maxLength(191)
            )
          },
          to: {
            required: helpers.withMessage('表記は必須項目です。', required),
          },
          email: {
            email: helpers.withMessage('メールアドレスは、有効なメールアドレス形式で指定してください。', email),
            minLength: helpers.withMessage(
              ({ $params }) => `メールアドレスは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`メールアドレスは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          tel: {
            minLength: helpers.withMessage(
              ({ $params }) => `電話番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`電話番号は、${$params.max}文字以下で入力してください。`, maxLength(20)
            )
          },
          zipcode: {
            minLength: helpers.withMessage(
              ({ $params }) => `郵便番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`郵便番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
            )
          },
          // prefecture: {}, // for now no need validation
          city: {
            minLength: helpers.withMessage(
              ({ $params }) => `市区町村・番地は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`市区町村・番地は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          address: {
            minLength: helpers.withMessage(
              ({ $params }) => `建物名・部屋番号などは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`建物名・部屋番号などは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          }
        },
        result: {
          subTotal: {
            required: helpers.withMessage('小計は必須項目です。', required),
          },
          taxRate10: {
            required: helpers.withMessage('消費税（10%）は必須項目です。', required),
          },
          taxRate8: {
            required: helpers.withMessage('消費税（8%）は必須項目です。', required),
          },
          taxReduced: {
            required: helpers.withMessage('消費税（軽減8%）は必須項目です。', required),
          },
          total: {
            required: helpers.withMessage('ご請求金額は必須項目です。', required),
          }
        },
        estimationFrom: {
          publisher: {
            required: helpers.withMessage(`発行者名は必須項目です。`, required), 
          },
          publisherAddress: {
            required: helpers.withMessage(`発行者住所は必須項目です。`, required), 
          },
          // publisherPhone: {}, // for now no need
          publisherEmail: {
            email: helpers.withMessage('メールアドレスを正しく入力してください', email),
          },
        },
      }
    },
    computed: {
      subTotal() {
        // 小計
        return CommaSeparatedNumber({
          number: this.result.subTotal
        });
      },
      tax() {
        // 税別消費税額
        return {
            taxRate8: CommaSeparatedNumber({ number: this.result.taxRate8 }),
            taxRate10: CommaSeparatedNumber({ number: this.result.taxRate10 }),
            taxReduced: CommaSeparatedNumber({ number: this.result.taxReduced }),
            all: CommaSeparatedNumber({ number: this.result.taxRate8 + this.result.taxRate10 + this.result.taxReduced }),
        }
      },
      taxableAmount() {
        // 税別対象額
        return {
            taxRate8: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate8 }),
            taxRate10: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate10 }),
            taxReduced: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxReduced }),
            all: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate8 + this.taxableAmountByTaxRate().taxRate10 + this.taxableAmountByTaxRate().taxReduced }),
        }
      },
      grandTotal() {
        // 請求合計
        return CommaSeparatedNumber({
          number: this.result.total
        });
      },
    },
    async created() {
      this.moment = moment;
      this.estimate = this.dataEstimate;
      await this.fetchData();
      await this.initTotal();
      await this.syncCalculateToResult();
      await console.log('8%tax item&option subtotal：',this.taxableAmountByTaxRate().taxRate8);
      await console.log('10%tax item&option subtotal：',this.taxableAmountByTaxRate().taxRate10);
      await console.log('Reduced tax item&option subtotal：',this.taxableAmountByTaxRate().taxReduced);
      // -- add by backend
      this.setEstimateTo();
      this.setEstimate();
      this.setTaxRate();
      this.setResult();
      this.setEstimateFrom();
      this.setFileName(this.estimate.publisher_seal);
      // -- END add by backend
    },
    methods: {
      updateAmount(payload) {
        this.calculatedTotal = payload;
        this.result.subTotal = payload;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTotal();
      },
      updateTaxRate() {
        this.calculatedTotal = this.result.subTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTotal();
      },
      updateItems(payload) {
        this.items = payload;
        this.calculatedTotal = this.result.subTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTotal();
      },
      createTemporaryResult() {
        this.resultTemporary = JSON.parse(JSON.stringify(this.result));
      },
      resetResult() {
        this.result = this.resultTemporary;
      },
      async changeEditMode(key, isCancel = false) {
        let result = true;
        if (isCancel) {
          switch (key) {
            case 'estimation':
              this.cancelEstimation();
              console.log('run cancelEstimation')
              break;
            case 'to':
              this.setEstimateTo();
              console.log('run setEstimateTo')
              break;
            case 'from':
              this.setEstimateFrom();
              console.log('run setEstimateFrom')
              break;
            default:
              this.setEstimate();
              console.log('run setEstimate')
              break;
          }
        }

        if (result) this.isEdit[key] = !this.isEdit[key];
      },
      rounding(price) {
        if(this.taxRounding === 'round_up') {
            return Math.ceil(price);
        } else if(this.taxRounding === 'round_down') {
            return Math.floor(price);
        } else if(this.taxRounding === 'round_off') {
            return Math.round(price);
        }
      },
      taxableAmountByTaxRate() {
        const optionsAll = [];
        this.items.forEach((item) => {
            item.itemOptions.forEach((option) => {
                optionsAll.push(option)
            });
        });
        const itemsSubTotalTaxRate8 = this.items.filter((item) => {
            return item.itemTaxType === '8%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const itemsSubTotalTaxRate10 = this.items.filter((item) => {
            return item.itemTaxType === '10%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const itemsSubTotalTaxReduced = this.items.filter((item) => {
            return item.itemTaxType === '軽減8%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxRate8 = optionsAll.filter((option) => {
            return option.optionTaxType === '8%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxRate10 = optionsAll.filter((option) => {
            return option.optionTaxType === '10%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxReduced = optionsAll.filter((option) => {
            return option.optionTaxType === '軽減8%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        return {
            taxRate8: Number(itemsSubTotalTaxRate8 + optionsSubTotalTaxRate8),
            taxRate10: Number(itemsSubTotalTaxRate10 + optionsSubTotalTaxRate10),
            taxReduced: Number(itemsSubTotalTaxReduced + optionsSubTotalTaxReduced)
        }
      },
      async updateEstimationTo() {
        const isFormCorrect = await this.v$.estimationTo.$validate();
        if (isFormCorrect) {
          let isChange = this.checkValueChange('to');
          if(isChange) {
            this.submitChangeEstimate('to');
            this.estimationTo.company = this.$refs.estimation_to_company.value;
            this.estimationTo.to = this.$refs.estimation_to_to.value;
            this.estimationTo.email = this.$refs.estimation_to_email.value;
            this.estimationTo.tel = this.$refs.estimation_to_tel.value;
            this.estimationTo.zipcode = this.$refs.estimation_to_zipcode.value;
            this.estimationTo.prefecture = this.$refs.estimation_to_prefecture.value;
            this.estimationTo.city = this.$refs.estimation_to_city.value;
            this.estimationTo.address = this.$refs.estimation_to_address.value;
          }
          else {
            this.changeEditMode('to');
          }
        }
      },
      cancelEstimation() {
        this.items = this.itemsTemporary;
        this.calculatedTotal = this.calculatedTotalTemporary;
        this.result.subTotal = this.calculatedTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        // this.recalculationTaxWithHolding();
        this.recalculationTotal();
      },
      createTemporaryEstimation() {
        this.itemsTemporary = JSON.parse(JSON.stringify(this.items));
        this.calculatedTotalTemporary = this.calculatedTotal;
      },
      updateTemporaryEstimation() {
        this.itemsTemporary = this.items;
        this.calculatedTotalTemporary = this.calculatedTotal;
      },
      syncCalculateToResult() {
        this.result.subTotal = this.calculatedTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTotal();
      },
      recalculationTaxRate10() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxRate10 = this.rounding(this.taxableAmountByTaxRate().taxRate10 * 0.1)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxRate10 = this.rounding((this.taxableAmountByTaxRate().taxRate10 / 1.1) * 0.1)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxRate10 = 0
        }
      },
      recalculationTaxRate8() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxRate8 = this.rounding(this.taxableAmountByTaxRate().taxRate8 * 0.08)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxRate8 = this.rounding((this.taxableAmountByTaxRate().taxRate8 / 1.08) * 0.08)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxRate8 = 0
        }
      },
      recalculationTaxReduced() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxReduced = this.rounding(this.taxableAmountByTaxRate().taxReduced * 0.08)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxReduced = this.rounding((this.taxableAmountByTaxRate().taxReduced / 1.08) * 0.08)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxReduced = 0
        }
      },
      recalculationTotal() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.total = this.calculatedTotal + this.rounding((this.taxableAmountByTaxRate().taxRate10 * 0.1)) + this.rounding((this.taxableAmountByTaxRate().taxRate8 * 0.08)) + this.rounding((this.taxableAmountByTaxRate().taxReduced * 0.08))
        } else {
            this.result.total = this.calculatedTotal
        }
      },
      async fetchData() {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- get data
        await axios.get(route('contractor.api.estimate_detail.list', {estimate_id: this.estimate.id}))
          .then((response) => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                this.items = response.data;

                if (this.items.length > 0) {
                  this.calculatedTotal = this.items.map((item) => {
                      return item.itemTotal
                  }).reduce((prev, current) => {
                      return prev + current
                  })
                }
              }
            }
          })
          .catch((error) => {
            console.log(error)
          });
        // -- END get data

        // -- hide loader
        loader.hide();
        // -- END hide loader
      },
      initTotal() {
        console.log('initTotal')
        const result = this.items.map((item) => {
          return item.itemTotal;
        }).reduce((prev, current) => {
          return prev + current
        }, 0);
        console.log(result)
        this.result.subTotal = result;
      },
      // -- add by backend
      setEstimate() {
        // set estimate.title
        this.titleDocument = this.estimate.title;
        // set estimate.head_title
        this.titleFormat = this.estimate.head_title;
        // set estimate.note
        this.message = this.estimate.note;
      },
      setEstimateTo() {
        this.estimationTo.company = this.estimate.customer_name;
        this.estimationTo.to = this.estimate.customer_compellation;
        this.estimationTo.email = this.estimate.customer_email;
        this.estimationTo.tel = this.estimate.customer_phone;
        this.estimationTo.zipcode = this.estimate.customer_postcode;
        this.estimationTo.prefecture = this.estimate.customer_prefecture;
        this.estimationTo.city = this.estimate.customer_city_subarea;
        this.estimationTo.address = this.estimate.customer_rest_of_address;
      },
      /**
       * for no this not use,
       * because the data mapping has been done on the use case file
       * this function is just in case if you want to go back to using mapping on vue
      */
      setEstimateDetail(estimateDetails) {
        let itemIndex = 0;
        estimateDetails.forEach(estimate_detail => {
          let data_estimate_detail = {
              id: '',
              itemId: itemIndex,
              itemName: '品目サンプル',
              itemCount: 1,
              itemMeasure: '式',
              itemUnit: 1000,
              itemSubtotal: 1000,
              itemType: '通常',
              itemTotal: 1500,
              itemOptions: [],
              itemTaxType: '10%',
          }
          let item_sub_total = 0;
          let total_option_sub_total = 0;

          let optionIndex = 0;
          estimate_detail.estimate_detail_optionals.forEach(estimate_detail_optional => {
            let option_sub_total = Math.ceil(parseFloat(estimate_detail_optional.cost) * parseFloat(estimate_detail_optional.unit_price));
            let option = {
              id: estimate_detail_optional.id,
              optionId: optionIndex,
              optionName: estimate_detail_optional.title,
              optionCount: estimate_detail_optional.cost,
              optionMeasure: estimate_detail_optional.unit,
              optionUnit: estimate_detail_optional.unit_price,
              optionSubtotal: option_sub_total,
              optionType: estimate_detail_optional.product_class_id,
              optionTaxType: this.getTaxOption('taxRate', estimate_detail_optional.tax_rate_option),
            }
            total_option_sub_total += option_sub_total;
            data_estimate_detail.itemOptions.push(option)
            optionIndex++;
          });

          item_sub_total += Math.ceil(parseFloat(estimate_detail.cost) * parseFloat(estimate_detail.unit_price));
          data_estimate_detail.id = estimate_detail.id;
          data_estimate_detail.itemName = estimate_detail.title;
          data_estimate_detail.itemCount = estimate_detail.cost;
          data_estimate_detail.itemMeasure = estimate_detail.unit;
          data_estimate_detail.itemUnit = estimate_detail.unit_price;
          data_estimate_detail.itemSubtotal = item_sub_total;
          data_estimate_detail.itemType = estimate_detail.product_class_id;
          data_estimate_detail.itemTaxType = this.getTaxOption('taxRate', estimate_detail.tax_rate_option);
          data_estimate_detail.itemTotal = parseFloat(item_sub_total) + parseFloat(total_option_sub_total);

          this.items.push(data_estimate_detail);
          itemIndex++;
        });
      },
      checkValueChange(key) {
        let isChange = false;
        switch (key) {
          case 'format': // for estimate.head_title
            if (this.estimate.head_title != this.titleFormat) {
              isChange = true;
            }
            break;
          case 'title': // for estimate.title
            if (this.estimate.title != this.titleDocument) {
              isChange = true;
            }
            break;
          case 'message': // for estimate.title
            if (this.estimate.note != this.message) {
              isChange = true;
            }
            break;
          case 'estimation': // for estimate.title
            // -- check if this.estimate.estimate_detail isEqual this.items
            // check length first
            if (this.itemsTemporary.length != this.items.length) {
              isChange = true;
              break;
            }

            // check itemOptions
            this.itemsTemporary.forEach((item, item_index) => {

              // check itemsTemporary object
              let isItemChange = this.shallowEqual(item, this.items[item_index]);
              if (!isItemChange) {
                isChange = true;
                return;
              }

              // check itemOptions.length
              if (item.itemOptions.length != this.items[item_index].itemOptions.length) {
                isChange = true;
                return;
              }

              // check value for each item.itemOptions
              item.itemOptions.forEach((option, option_index) => {
                let isOptionChange = this.shallowEqual(option, this.items[item_index]['itemOptions'][option_index]);
                if(!isOptionChange) {
                  isChange = true;
                  return;
                }
              });

              // check if isChange change to true
              if(isChange) {
                return;
              }
            });
            // -- END check if this.estimate.estimate_detail isEqual this.items
            break;
          case 'to': // for customer data on estimates table
            const arrEstimationTo = [
              'company', 'to', 'email', 'tel', 'zipcode', 'prefecture', 'city', 'address',
            ];
            const arrEstimationCustomer = [
              'customer_name', 'customer_compellation', 'customer_email', 'customer_phone', 'customer_postcode', 'customer_prefecture', 'customer_city_subarea', 'customer_rest_of_address'
            ];

            for (let index = 0; index < arrEstimationTo.length; index++) {
              if (this.estimationTo[arrEstimationTo[index]] != this.estimate[arrEstimationCustomer[index]]) {
                isChange = true;
              }
            }
            break;
          case 'result':
            const arrResultKey = [
              'subTotal',
              'taxRate10',
              'taxRate8',
              'taxReduced',
              'total',
            ];

            arrResultKey.forEach(value => {
              if (this.result[value] != this.resultTemporary[value]) {
                isChange = true;
              }
            });
            break;
          case 'from': // for customer data on estimates table
            const arrEstimationFrom = [
              'publisher',
              'publisherAddress',
              'publisherPhone',
              'publisherEmail',
              'publisherSeal',
            ];
            const arrEstimation = [
              'publisher',
              'publisher_address',
              'publisher_phone',
              'publisher_email',
              'publisher_seal',
            ];

            for (let index = 0; index < arrEstimationFrom.length; index++) {
              if (this.estimationFrom[arrEstimationFrom[index]] != this.estimate[arrEstimation[index]]) {
                isChange = true;
              }
            }
            break;
          default:
            break;
        }
        return isChange;
      },
      shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        // -- remove itemOptions if exist
        let index1 = keys1.indexOf('itemOptions');
        if (index1 > -1) {
          keys1.splice(index1, 1);
        }
        let index2 = keys2.indexOf('itemOptions');
        if (index2 > -1) {
          keys2.splice(index2, 1);
        }
        // -- END remove itemOptions if exist

        if (keys1.length !== keys2.length) {
          return false;
        }
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
        return true;
      },
      async updateEstimate(key) {
        let isFormCorrect = true;
        switch (key) {
          case 'format':
            isFormCorrect = await this.v$.titleFormat.$validate();
            break;
          case 'title':
            isFormCorrect = await this.v$.titleDocument.$validate();
            break;
          case 'message':
            isFormCorrect = await this.v$.message.$validate();
            break;
          case 'result':
            isFormCorrect = await this.v$.result.$validate();
            break;
          default:
            break;
        }

        if (isFormCorrect) {
          let isChange = this.checkValueChange(key);
          if(isChange) {
            this.submitChangeEstimate(key);
          }
          else {
            this.changeEditMode(key);
          }
        }
      },
      async updateEstimateDetail() {
        const isFormCorrect = await this.v$.$validate();
        if (isFormCorrect) {
          let isChange = this.checkValueChange('estimation');
          if(isChange) {
            this.submitChangeEstimateDetail();
          }
          else {
            this.changeEditMode('estimation');
          }
        }
        else {
          if(this.v$.estimationTo.$errors.length > 0) this.isEdit.to = true;
          if(this.v$.result.$errors.length > 0) this.isEdit.result = true;
          if(this.v$.estimationFrom.$errors.length > 0) this.isEdit.from = true;
        }
      },
      async submitChangeEstimate(key) {
        let isSuccess = true;

        let form = {};
        switch (key) {
          case 'format':
            form.type_action = 'update_head_title';
            form.head_title = this.titleFormat;
            break;
          case 'title':
            form.type_action = 'update_title';
            form.title = this.titleDocument;
            break;
          case 'message':
            form.type_action = 'update_message';
            form.note = this.message;
            break;
          case 'to':
            form.type_action = 'update_to';
            form.customer_name = this.estimationTo.company;
            form.customer_compellation = this.estimationTo.to;
            form.customer_email = this.estimationTo.email;
            form.customer_phone = this.estimationTo.tel;
            form.customer_postcode = this.estimationTo.zipcode;
            form.customer_prefecture = this.estimationTo.prefecture;
            form.customer_city_subarea = this.estimationTo.city;
            form.customer_rest_of_address = this.estimationTo.address;
            break;
          case 'result':
            form.type_action = 'update_result';
            form.subtotal = this.result.subTotal;
            form.total_tax10 = this.result.taxRate10;
            form.total_tax8 = this.result.taxRate8;
            form.total_reduced_tax8 = this.result.taxReduced;
            form.total_withholding_tax = 0;
            form.total_amount = this.result.total;
            break;
          default:
            break;
        }

        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        await axios.post(route('contractor.api.estimate.update', {id: this.estimate.id}), form)
          .then((response) => {
            if (response.status == 200) {
              let record = response.data.record;
              // -- update estimate data
              if(key == 'to') {
                this.estimate.customer_name = record.customer_name;
                this.estimate.customer_compellation = record.customer_compellation;
                this.estimate.customer_email = record.customer_email;
                this.estimate.customer_phone = record.customer_phone;
                this.estimate.customer_postcode = record.customer_postcode;
                this.estimate.customer_prefecture = record.customer_prefecture;
                this.estimate.customer_city_subarea = record.customer_city_subarea;
                this.estimate.customer_rest_of_address = record.customer_rest_of_address;
              }
              else if(key == 'result') {
                this.estimate.subtotal = record.subtotal;
                this.estimate.total_tax10 = record.total_tax10;
                this.estimate.total_tax8 = record.total_tax8;
                this.estimate.total_reduced_tax8 = record.total_reduced_tax8;
                this.estimate.total_withholding_tax = record.total_withholding_tax;
                this.estimate.total_amount = record.total_amount;
              }
              else {
                this.estimate.title = record.title;
                this.estimate.head_title = record.head_title;
                this.estimate.total_amount = record.total_amount;
                this.estimate.note = record.note;
                this.estimate.total_estimate_detail = record.total_estimate_detail;
              }
              // -- END update estimate data

              // -- change edit mode
              this.changeEditMode(key)
              // -- END change edit mode
            }
          })
          .catch((error) => {
            console.log(error)
            isSuccess = false;
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader

        return isSuccess;
      },
      async submitChangeEstimateDetail() {
        let isSuccess = true;

        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        let form = {
          items: this.items,
          total: this.result
        };

        await axios.put(route('contractor.api.estimate_detail.updateByEstimateId', {estimate_id: this.estimate.id}), form)
          .then((response) => {
            if (response.status == 200) {
              this.items = response.data.record; // reset items
              // this.fetchData();
              // this.setEstimateDetail(response.data.record);

              // -- update temporary estimate
              this.updateTemporaryEstimation();
              // -- END update temporary estimate

              // -- change edit mode
              this.changeEditMode('estimation')
              // -- END change edit mode
            }
          })
          .catch((error) => {
            console.log(error)
            isSuccess = false;
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader

        return isSuccess;
      },
      addPageCost(data) {
        console.log(data)
        this.$refs.estimationTable.addItemRowWithValue(toRaw(data))
      },
      /**
       * fo to invoice create page
       * and reflect this estimate
      */
      duplicateEstimate() {
        window.location.href = route('contractor.project.invoice.create', {project: this.project.line_number, estimate: this.estimate.line_number});
      },
      confirmDuplicateEstimate() {
        this.$refs.modalCreateInvoiceReflectEstimate.changeModalClass('js-modal--shown')
      },
      exportPdf() {
        window.open(route('contractor.estimate.pdf', {estimate: this.estimate.id}));
      },
      setTaxRate() {
        this.taxRate = this.tax_data.rate;
      },
      getTaxOption(type, value) {
        let findTax = this.all_tax_option[type].find((item) => {
            return item.value == value;
        });
        return findTax.name;
      },
      setResult() {
        ({
          subtotal: this.result.subTotal,
          total_tax10: this.result.taxRate10,
          total_tax8: this.result.taxRate8,
          total_reduced_tax8: this.result.taxReduced,
          total_amount: this.result.total,
        } = this.estimate);
      },
      // -- set estimate publisher data
      setEstimateFrom() {
        this.estimationFrom.publisher = this.estimate.publisher;
        this.estimationFrom.publisherAddress = this.estimate.publisher_address;
        this.estimationFrom.publisherPhone = this.estimate.publisher_phone;
        this.estimationFrom.publisherEmail = this.estimate.publisher_email;
        this.estimationFrom.publisherSeal.url = null;

        // -- set image
        if (this.estimate.publisher_seal != null) {
          this.estimationFrom.publisherSeal.url = this.estimate.publisher_seal_url;
        }
        else {
          this.setDefaultImage();
        }
        // -- END set image
      },
      onFileSelected(e) {
        if (e && e.target.files[0]) {
          const file = e.target.files[0];
          const url = URL.createObjectURL(file);
          const name_file = e.target.files[0].name;
          const image_status = this.estimate.publisher_seal != null ? 'update' : 'new';
          this.estimationFrom.publisherSeal = { url, file, name_file, image_status };
        }
      },
      resetImage() {
        this.v$.estimationFrom.$reset();
        this.estimationFrom.publisherSeal = {};
        this.setDefaultImage();
      },
      setDefaultImage() {
        if (this.documentSettingPublisherSeal != null) {
          const publisherSealUrl = this.documentSettingPublisherSealUrl;
          this.estimationFrom.publisherSeal.url = publisherSealUrl;
        }
        else {
          this.estimationFrom.publisherSeal.url = '/assets/img/site/image_stamp_none_min.png';
        }

        if (this.estimate.publisher_seal != null) {
          this.estimationFrom.publisherSeal.image_status = 'delete';
        }
      },
      setFileName(publisher_seal) {
        if (publisher_seal === null) {
          this.publisherSealFileName = 'ファイルが選択されていません';
        }
        else {
          const arrFileName = publisher_seal.split("/");
          this.publisherSealFileName = arrFileName[4];
        }
      },
      // -- END set estimate publisher data
      async updateEstimationFrom() {
        const isFormCorrect = await this.v$.estimationFrom.$validate();
        if (isFormCorrect) {
          let isChange = this.checkValueChange('from');
          if(isChange) {
            this.submitChangeEstimateFrom();

            this.estimationFrom.publisher = this.$refs.estimation_from_company.value;
            this.estimationFrom.publisherAddress = this.$refs.estimation_from_address.value;
            this.estimationFrom.publisherPhone = this.$refs.estimation_from_tel.value;
            this.estimationFrom.publisherEmail = this.$refs.estimation_from_email.value;
          }
          else {
            this.changeEditMode('to');
          }
        }
      },
      async submitChangeEstimateFrom() {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- prepare formData
        const form = new FormData();

        // -- publisher
        form.append('type_action', 'update_publisher')
        form.append('publisher', this.estimationFrom.publisher)
        form.append('publisher_address', this.estimationFrom.publisherAddress)
        if (this.estimationFrom.publisherPhone !== null) {
          form.append('publisher_phone', this.estimationFrom.publisherPhone)
        }
        if (this.estimationFrom.publisherEmail !== null) {
          form.append('publisher_email', this.estimationFrom.publisherEmail)
        }

        if (this.estimationFrom.publisherSeal.file) {
          form.append('publisher_seal_image', this.estimationFrom.publisherSeal.file)
        }
        if (this.estimationFrom.publisherSeal.image_status) {
          form.append('image_status', this.estimationFrom.publisherSeal.image_status)
        }
        // -- END publisher
        // -- END prepare formData

        // -- submit
        await axios.post(route('contractor.api.estimate.update', {id: this.estimate.id}), form)
        .then((response) => {
          if (response.status == 200) {
            this.setFileName(response.data.record.publisher_seal);

            let record = response.data.record;
            this.estimate.publisher = record.publisher;
            this.estimate.publisher_address = record.publisher_address;
            this.estimate.publisher_phone = record.publisher_phone;
            this.estimate.publisher_email = record.publisher_email;
            this.estimate.publisher_seal = record.publisher_seal;
            this.estimate.publisher_seal_url = record.publisher_seal_url;

            // -- change edit mode
            this.changeEditMode('from')
            // -- END change edit mode
          }
        })
          .catch((error) => {
            console.log(error)
          });
        // -- END submit

        // -- hide loader
        loader.hide();
        // -- END hide loader

      },
      // -- END add by backend
    },
  }
</script>
