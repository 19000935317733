<template>
  <div class="c-modal js-modal" :class="modalClass">
    <div class="__overlay">
      <div class="__panel __panel--small p-modal-estimation">
        <div class="u-box-modal-defalut">
          <h3 class="__title">請求方法指定</h3>
          <div class="__content">
            <form :action="generateRoute()" method="POST">
              <input type="hidden" name="_token" :value="csrf">
              <input type="hidden" name="estimate[projectLineNumber]" :value="projectLineNumber">
              <input type="hidden" name="estimate[estimateLineNumber]" :value="estimateLineNumber">
              <p class="__text">見積もり明細と異なる内容で請求する場合は、一部請求を選択していただき、請求金額の割合を設定してください。</p>
              <div class="u-form-input-group __list">
                <label class="u-form-radio-default u-form-input-radio">
                  <input 
                    class="js-invoice-rate-trigger" 
                    type="radio" 
                    name="estimate[invoiceType]" 
                    value="as_detailed" 
                    v-model="invoiceType"
                    @change="changeInvoiceType">
                  <span class="__text">見積もり明細で請求</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input 
                    class="js-invoice-rate-trigger" 
                    type="radio" 
                    name="estimate[invoiceType]" 
                    value="partial_billing" 
                    v-model="invoiceType"
                    @change="changeInvoiceType">
                  <span class="__text">明細の一部を請求</span>
                </label>
              </div>
              <div class="__rate js-invoice-rate-edit" :style="styleBillRate">
                <div class="u-flex-left-center">
                  <span class="__heading u-text-bold u-flex-shrink-0">請求割合</span>
                  <span class="__input u-form-input-default u-flex-shrink-1">
                    <input class="u-align-right" type="number" placeholder="" name="estimate[billRate]" v-model="billRate">
                  </span>
                  <span class="__heading u-flex-shrink-0">％を請求</span>
                </div>
              </div>
              <div class="__button-wrap u-flex-center-center">
                <button 
                  class="js-modal-close u-button-default u-button-default--large u-button-default--negative __button" 
                  type="button" 
                  @click.prevent="changeModalClass">
                  閉じる
                </button>
                <input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="請求書を作成">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      projectLineNumber: {
        type: Number,
        required: true
      },
      estimateLineNumber: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        invoiceType: 'as_detailed',
        billRate: 100,
        modalClass: '',
        styleBillRate: {
          display: 'none',
        },
        modalLoading: null,
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // csrf
      }
    },
    created () {
      this.modalLoading = this.$loading;
    },
    methods: {
      changeModalClass(className = '') {
        this.modalClass = className;
      },
      changeInvoiceType() {
        // check as_detailed or partial_billing
        if (this.invoiceType == 'as_detailed') {
          this.styleBillRate.display = 'none';
        }
        else {
          this.styleBillRate.display = 'block';
        }
      },
      generateRoute() {
        return route('contractor.invoice.reflect.estimate');
      },
    },
  }
</script>