<template>
  <article class="p-section-01">
    <section class="l-wrapper">
      <div class="l-container">
        <div class="l-inner">
          <div class="__block-depth-1">
            <div class="l-multi-column-section">
              <div class="l-side-menu-section l-side-menu-section--wide">
                <div class="c-search-filter-offers mgb-024">
                  <form action="" method="get">
                    <!-- filter form_filter.is_include_end_recruitment_project -->
                    <div class="u-form-input-group">
                      <label class="u-form-checkbox-default u-form-input-checkbox">
                        <input type="checkbox" name="closed_include" value="true" v-model="form_filter.is_include_end_recruitment_project">
                        <span class="__text">募集終了案件を含める</span>
                      </label>
                    </div>
                    <!-- filter form_filter.is_include_end_recruitment_project -->
                    <dl class="u-list-input-label-set __list mgt-024">
                      <!-- filter keyword -->
                      <div class="__item">
                        <dt class="__term">キーワード</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="検索キーワードを入力" name="" v-model="form_filter.keyword">
                          </span>
                        </dd>
                      </div>
                      <!-- END filter keyword -->
                      <!-- filter form_filter.estimate -->
                      <div class="__item">
                        <dt class="__term">予算</dt>
                        <dd class="__body">
                          <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                            <select v-model="form_filter.estimate">
                              <option value=""></option>
                              <option value="〜20万円">〜20万円</option>
                              <option value="21万円〜50万円">21万円〜50万円</option>
                              <option value="51万円〜100万円">51万円〜100万円</option>
                              <option value="101万円〜300万円">101万円〜300万円</option>
                              <option value="300万円以上">300万円以上</option>
                            </select>
                          </span>
                        </dd>
                      </div>
                      <!-- filter form_filter.estimate -->
                      <!-- filter form_filter.project_start_date -->
                      <div class="__item">
                        <dt class="__term">案件開始目安</dt>
                        <dd class="__body">
                          <div class="u-flex-between-center">
                            <span class="__box u-flex-shrink-1 u-flex-grow-1">
                              <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                <input type="date" placeholder="案件開始目安の範囲を入力" name="" v-model="form_filter.project_start_date.min">
                              </span>
                            </span>
                            <span class="__box u-flex-shrink-0 mgl-016">から</span>
                          </div>
                          <div class="u-flex-between-center mgt-016">
                            <span class="__box u-flex-shrink-1 u-flex-grow-1">
                              <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                <input type="date" placeholder="案件開始目安の範囲を入力" name="" v-model="form_filter.project_start_date.max">
                              </span>
                            </span>
                            <span class="__box u-flex-shrink-0 mgl-016">まで</span>
                          </div>
                        </dd>
                      </div>
                      <!-- END filter form_filter.project_start_date -->
                      <!-- filter form_filter.project_due_date -->
                      <div class="__item">
                        <dt class="__term">納期目安</dt>
                        <dd class="__body">
                          <div class="u-flex-between-center">
                            <span class="__box u-flex-shrink-1 u-flex-grow-1">
                              <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                <input type="date" placeholder="納期目安の範囲を入力" name="" v-model="form_filter.project_due_date.min">
                              </span>
                            </span>
                            <span class="__box u-flex-shrink-0 mgl-016">から</span>
                          </div>
                          <div class="u-flex-between-center mgt-016">
                            <span class="__box u-flex-shrink-1 u-flex-grow-1">
                              <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                <input type="date" placeholder="納期目安の範囲を入力" name="" v-model="form_filter.project_due_date.max">
                              </span>
                            </span>
                            <span class="__box u-flex-shrink-0 mgl-016">まで</span>
                          </div>
                        </dd>
                      </div>
                      <!-- END filter form_filter.project_due_date -->
                      <div class="__item">
                        <dt class="__term">案件カテゴリ</dt>
                        <dd class="__body">
                          <div class="u-form-input-group">
                            <!-- loop recruiting_category_options -->
                            <label class="u-form-checkbox-default u-form-input-checkbox"
                              v-for="(recruiting_category_option, recruiting_category_options_id) in recruiting_category_options" :key="recruiting_category_options_id">
                              <input type="checkbox" name="offers_category" :value="recruiting_category_options_id" v-model="form_filter.recruiting_category_options_id">
                              <span class="__text">{{ recruiting_category_option }}</span>
                            </label>
                            <!-- END loop recruiting_category_options -->
                          </div>
                        </dd>
                      </div>
                    </dl>
                    <div class="u-flex-center-center __button-group">
                      <input class="u-button-default u-button-default--small u-button-default--negative __button" type="reset" value="リセット" @click.prevent="resetFilter">
                      <input class="u-button-default u-button-default--small u-button-default--primary __button" type="submit" value="検索" @click.prevent="loadData">
                    </div>
                  </form>
                </div>
                <nav class="l-side-menu-navigation l-side-menu-navigation--settings">
                  <ul class="__list">
                    <li class="__item"><a class="__button" :href="generateRoute('contractor.offer.index')"><span class="__text">案件マッチング</span></a></li>
                    <li class="__item"><a class="__button" :href="generateRoute('contractor.offer.recruitment')"><span class="__text">募集案件</span></a></li>
                    <li class="__item"><a class="__button" :href="generateRoute('contractor.offer.application')"><span class="__text">応募案件</span></a></li>
                  </ul>
                </nav>
              </div>
              <div class="l-contents-column-section">
                <h1 class="u-heading-content-title mgb-032">案件一覧</h1>
                <div class="c-pagenation-projects mgb-024">
                  <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
                  <!-- check previousPageUrl is null -->
                  <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
                  <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
                  <!-- END check previousPageUrl null -->

                  <!-- check nextPageUrl is null -->
                  <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
                  <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
                  <!-- END check nextPageUrl is null -->
                </div>
                <ul class="u-list-archive-offers __list">
                  <!-- loop start-->
                  <li class="__item" :class="checkRecruitingEndDate(data.recruiting_end_date) ? '' : '__item--closed' " v-for="(data, index) in lists.data" :key="index">
                    <a class="__link" :href="recruitDetail('contractor.offer.detail', data.id)"></a>
                    <div class="__content">
                      <span class="__status">{{ data.recruiting_status_option.display_name }}</span>
                      <h2 class="__offers-name">{{ data.title }}</h2>
                      <div class="__box u-flex-between-center">
                        <dl class="__date">
                          <div class="__group u-flex-left-center">
                            <dt class="__label">案件開始目安</dt>
                            <dd class="__body">{{ moment(data.project_start_date).format('YYYY/MM/DD') }}</dd>
                          </div>
                          <div class="__group u-flex-left-center">
                            <dt class="__label">納期目安</dt>
                            <dd class="__body">{{ moment(data.project_due_date).format('YYYY/MM/DD') }}</dd>
                          </div>
                        </dl>
                        <div class="__info u-flex-between-center">
                          <span class="__category">{{ data.recruiting_category_option.display_name }}</span>
                          <span class="__budget u-flex-right-center">
                            <span class="__label">予算</span>
                            <span class="__price">{{ data.estimate }}</span>
                          </span>
                        </div>
                      </div>
                      <span class="__close mgt-016" v-if="checkRecruitingEndDate(data.recruiting_end_date)">
                        募集終了日：{{ moment(data.recruiting_end_date).format('YYYY/MM/DD') }}
                      </span>
                      <span class="__close mgt-016" v-else>
                        この案件の募集は終了しています
                      </span>
                    </div>
                  </li>
                  <!-- END loop start-->
                </ul>
                <div class="c-pagenation-projects mgt-024">
                  <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
                  <!-- check previousPageUrl is null -->
                  <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
                  <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
                  <!-- END check previousPageUrl null -->

                  <!-- check nextPageUrl is null -->
                  <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
                  <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
                  <!-- END check nextPageUrl is null -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    recruits: {
      type: Object,
      default: {}
    },
    recruiting_category_options: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      lists: {},
      form_filter: {
        is_include_end_recruitment_project: false,
        keyword: '', // for now pending
        estimate: '',
        project_start_date: {
          min: '',
          max: '',
        },
        project_due_date: {
          min: '',
          max: '',
        },
        recruiting_category_options_id: [],
      },
      current_date: '',
    }
  },
  async created () {
    this.moment = moment;
    this.lists = this.recruits; // init data
    this.current_date = moment().format('YYYY-MM-DD');
  },
  methods: {
    async loadData(page = 1){
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			this.form_filter.page = page;
			await axios.get(route('contractor.offer.index', {page: page, data: this.form_filter}))
				.then((res) => {
					this.lists = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
    searchFilter() {
      console.log('search filter')
    },
    resetFilter() {
      console.log('reset filter')
      this.form_filter = {
        is_include_end_recruitment_project: false,
        keyword: '', // fuzzy search
        estimate: '',
        project_start_date: {
          min: '',
          max: '',
        },
        project_due_date: {
          min: '',
          max: '',
        },
        recruiting_category_options_id: []
      };
    },
    recruitDetail(key, id) {
      return route(key, { recruit: id });
    },
    generateRoute(key) {
      return route(key);
    },
    checkRecruitingEndDate(date) {
      const checkDate = moment(date).format('YYYY/MM/DD');
      if (moment(this.current_date).isBefore(checkDate)) {
        return true;
      }
      return false;
    }
  },
}
</script>

<style>

</style>