<template>
  <section class="l-wrapper p-signup-bottom-section">
    <div class="l-container">
      <div class="l-inner">
        <div class="__block-depth-1">
          <form>
            <div class="__block-depth-2">
              <h2 class="__title u-text-catchcopy u-text-white">新規アカウント登録</h2>
              <dl class="u-list-input-label-set __list">
                <div class="__item">
                  <dt class="__term u-text-white">メールアドレス</dt>
                  <dd class="__body">
                    <span class="u-form-input-default u-form-input-text-box">
                      <input type="email" placeholder="info@example.com" v-model="form.email" @focus="focusEmail()">
                    </span>
                    <!-- error message -->
                    <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                      <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                    </div>
                    <div class="input-errors" v-for="(error, index) of errors" :key="index">
                      <p class="mgt-008 u-text-varidate-label">{{ error }}</p>
                    </div>
                    <!-- END error message -->
                  </dd>
                </div>
              </dl>
              <div class="u-flex-center-center mgt-024">
                <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="アカウント作成" @click.prevent="submit()">
              </div>
              <p class="u-align-center u-text-white mgt-064 __text">
                アカウント作成にあたり当サービスの<a :href="generateRoute('privacy')">利用規約</a>及び<a :href="generateRoute('terms')">プライバシーポリシー</a>に<br>同意したものとみなされます。
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, required, email } from '@vuelidate/validators'
  // -- END vuelidate
  export default {
    setup () {
      return { v$: useVuelidate() }
    },
    data() {
      return {
        form: {
          email: ''
        },
        errors: []
      }
    },
    validations() {
      return {
        form: {
          email: {
            email: helpers.withMessage('メールアドレスを正しく入力してください', email),
            required: helpers.withMessage(`メールアドレスは必須項目です。`, required),
          },
        },
      }
    },
    methods: {
      async submit() {
        // -- reset error message
        this.errors = [];
        // -- END reset error message

        const isFormCorrect = await this.v$.$validate()
        if (isFormCorrect) {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          // store with not api route
          await axios.post(route('contractor.signup.submit'), this.form)
            .then((response) => {
              if (response.status == 200) {
                window.location.href = route('contractor.signup.thanks');
              }
            })
            .catch((error) => {
              if (error.response.status == 504) {
                this.errors.push('504 '+error.response.statusText);
              }
              else {
                this.errors.push(error.response.data.message);
              }

              // -- loader hide
              loader.hide();
              // -- END loader hide
            });
        }
      },
      focusEmail() {
        this.errors = []; // reset errors
      },
      generateRoute(route_name) {
        let result = '';
        switch (route_name) {
          case 'privacy':
            result =  route('contractor.static.privacy.policy');
            break;
          case 'terms':
            result =  route('contractor.static.terms.use');
            break;
          default:
            result =  route('contractor.static.privacy.policy');
            break;
        }
        return result;
      }
    },
  }
</script>

<style lang="css" scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
