<template>
  <tr>
    <td colspan="10" style="padding: 0;">
      <table class="u-table-estimation-item-row">
        <tbody>
          <tr class="js-table-order-highlight-row-group" ref="table_order_highlight_row_group">
            <th class="__order">
              <span class="__order-handle"></span>
            </th>
            <td class="__item">
              <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box"
                :class="v$.itemValue.itemName.$errors.length > 0 ? 'border-danger' : '' ">
                <input v-model="itemValue.itemName" type="text" placeholder="品目を入力" name="" @keydown.enter.prevent="emitSubmitItem()"  @keydown.esc.prevent="emitExitEditMode()">
              </span>
            </td>
            <td class="__count">
              <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium"
                :class="v$.itemValue.itemCount.$errors.length > 0 ? 'border-danger' : '' ">
                <input v-model="itemValue.itemCount" class="u-align-center" type="number" placeholder="" name="" @blur="calculateItemSubtotal(); updateItemTotal()" @keydown.enter.prevent="emitSubmitItem()"  @keydown.esc.prevent="emitExitEditMode()">
              </span>
            </td>
            <td class="__measure">
              <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box"
                :class="v$.itemValue.itemMeasure.$errors.length > 0 ? 'border-danger' : '' ">
                <input v-model="itemValue.itemMeasure" type="text" placeholder="個、式" name="" @keydown.enter.prevent="emitSubmitItem()"  @keydown.esc.prevent="emitExitEditMode()">
              </span>
            </td>
            <td class="__unit">
              <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium"
                :class="v$.itemValue.itemUnit.$errors.length > 0 ? 'border-danger' : '' ">
                <input v-model="itemValue.itemUnit" class="u-align-right" type="number" placeholder="" name="" @blur="calculateItemSubtotal(); updateItemTotal()" @keydown.enter.prevent="emitSubmitItem()"  @keydown.esc.prevent="emitExitEditMode()">
              </span>
            </td>
            <td class="__subtotal u-align-right">
              <span class="__number">{{ commaSeparetedItemSubtotal }}</span>
            </td>
            <td class="__type">
              <span class="u-form-select-default u-form-input-select u-form-input-select--medium"
                :class="v$.itemValue.itemType.$errors.length > 0 ? 'border-danger' : '' ">
                <select v-model="itemValue.itemType" @change="calculateChange(itemValue.itemType); calculateItemSubtotal(); updateItemTotal()">
                  <option value="1">通常</option>
                  <option value="2">割引</option>
                </select>
              </span>
            </td>
            <td class="__tax-type">
              <span v-if="itemValue.itemType == '1' && taxDisplayMode !== 'tax_free'" class="u-form-select-default u-form-input-select u-form-input-select--medium">
                <select v-model="itemValue.itemTaxType" @change="emitUpdateItemTaxRate()">
                  <option value="10%">10%</option>
                  <option value="軽減8%">軽減8%</option>
                  <option value="8%">8%</option>
                  <option value="対象外">対象外</option>
                </select>
              </span>
              <p v-else class="u-align-center">
                <span v-if="taxDisplayMode === 'tax_free'">免税</span>
                <span v-else>-</span>
              </p>
            </td>
            <td class="__delete u-align-center">
              <button class="__button __button--delete" type="button" @click="emitDeleteItemRow(itemValue.itemId); emitUpdateItemTaxRate()">
                <img class="__image" src="/assets/img/site/icon_delete_small_min.svg" alt="削除">
              </button>
            </td>
            <td class="__total u-align-right" :rowspan="[itemValue.itemOptions.length <= 0 ? 2 : 3]">
              <span class="__number">{{ commaSeparetedItemTotal }}</span>
            </td>
          </tr>
          <tr>
            <td class="pdt-000"></td>
            <td class="pdt-000" colspan="8">
              <div class="u-flex-left-center u-flex-wrap">
                <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.itemValue.itemName.$errors" :key="index">{{ error.$message }}</p>
                <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.itemValue.itemCount.$errors" :key="index">{{ error.$message }}</p>
                <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.itemValue.itemMeasure.$errors" :key="index">{{ error.$message }}</p>
                <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.itemValue.itemUnit.$errors" :key="index">{{ error.$message }}</p>
                <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.itemValue.itemType.$errors" :key="index">{{ error.$message }}</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" style="padding: 0;">
              <draggable
                :list="this.itemValue.itemOptions"
                item-key="optionId"
                class="__draggable-elements"
                ghost-class="ghost"
                handle=".__order-handle"
                data-id-attr="colspan"
                @start="dragging = true"
                @end="dragging = false"
                tag="table"
              >
                <template #item="{element}">
                  <estimation-table-option-row 
                    :option-order="index" 
                    :option-value="element" 
                    @delete-option="deleteOptionRow" 
                    @update-option="updateItemTotal" 
                    @submit-item="emitSubmitItem()" 
                    @exit-edit-mode="emitExitEditMode()" 
                    @update-taxrate-option="emitUpdateItemTaxRate" 
                    :is-created="isCreated" 
                    :tax-display-mode="taxDisplayMode"
                    :scope="scope" />
                </template>
              </draggable>
            </td>
          </tr>
          <tr>
            <td class="u-align-center" colspan="10">
              <button class="__button __button--add-option" type="button" @click="addOptionRow()"><img class="__image" src="/assets/img/site/icon_add_secondary_small_min.svg" alt="追加"></button><br><span class="__caption u-text-caption">オプション品目を追加</span>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
  import useVuelidate from '@vuelidate/core'
  import { helpers, required } from '@vuelidate/validators'
  import draggable from 'vuedraggable';
  import { CommaSeparatedNumber } from '../../../modules/comma-separated-number.js';
  import { TableOrderHighlight } from '../../../modules/table-order-highlight.js';
  import EstimationTableOptionRow from '../estimation/EstimationTableOptionRow.vue';
  // -- for custom validate
  // const decimalCost = helpers.regex(/^-?\d+(\.\d{1,2})?$/);
  const decimal42 = helpers.regex(/^-?\d{1,2}(\.\d{1,2})?$/); // for decimal (4,2)
  const decimal112 = helpers.regex(/^-?\d{1,9}(\.\d{1,2})?$/); // for decimal (11,2)
  // -- END for custom validate
  export default {
    setup (props) {
      return { v$: useVuelidate({ $scope: props.scope }) }
    },
    validations() {
      return {
        itemValue: {
          itemName: {
            required: helpers.withMessage('品目は必須項目です。', required)
          },
          itemCount: {
            required: helpers.withMessage('数量は必須項目です。', required),
            decimal42:  helpers.withMessage('数量 は整数4桁, 少数2桁で入力してください。', decimal42)
          },
          itemMeasure: {
            required: helpers.withMessage('単位は必須項目です。', required),
          },
          itemUnit: {
            required: helpers.withMessage('単価は必須項目です。', required),
            decimal112:  helpers.withMessage('単価 should be decimal (11,2)', decimal112)
          },
          itemType: {
            required: helpers.withMessage('種別は必須項目です。', required)
          },
          itemTaxType: {
            required: helpers.withMessage('税区分は必須項目です。', required)
          },
        }
      }
    },
    components: {
      draggable,
      EstimationTableOptionRow
    },
    props: {
      isCreated: {
        type: Boolean,
        default: true
      },
      itemOrder: {
        type: Number,
        default: 0
      },
      itemValue: {
        type: Object,
        default: {}
      },
      taxDisplayMode: {
        type: String,
        default: ''
      },
      // -- add by backend
      scope: {
        type: String,
        default: ''
      },
      defaultTaxRate: {
        type: String,
        default: '10%'
      },
      // -- END add by backend
    },
    emits: [
      'delete-item',
      'submit-item',
      'exit-edit-mode'
    ],
    data() {
      return {
        options: [],
        currentOptionId: this.itemValue.itemOptions.length
      }
    },
    computed: {
      calculateItemTotal() {
        const optionTotal = this.itemValue.itemOptions.map((itemOption) => {
          return itemOption.optionSubtotal;
        }).reduce((prev, current) => {
          return prev + current
        }, 0);
        return Math.ceil(optionTotal + this.itemValue.itemSubtotal);
      },
      commaSeparetedItemSubtotal() {
        return CommaSeparatedNumber({
          number: this.itemValue.itemSubtotal
        });
      },
      commaSeparetedItemTotal() {
        return CommaSeparatedNumber({
          number: this.calculateItemTotal
        });
      }
    },
    mounted() {
      TableOrderHighlight({
        rowGroupSelector: '.js-table-order-highlight-row-group',
        rowSelector: '.js-table-order-highlight-row'
      });
      if(!this.isCreated) {
        this.currentOptionId = this.itemValue.itemOptions.length;
      }
    },
    methods: {
      emitDeleteItemRow(itemId) {
        this.$emit('delete-item', itemId);
      },
      addOptionRow() {
        this.itemValue.itemOptions.push({
          id: '',
          optionId: this.currentOptionId,
          optionName: null,
          optionCount: 0,
          optionMeasure: null,
          optionUnit: 0,
          optionSubtotal: 0,
          optionType: '1',
          optionTaxType: this.defaultTaxRate,
        });
        this.options = this.itemValue.itemOptions;
        this.currentOptionId++
      },
      deleteOptionRow(payload) {
        const filtered = this.itemValue.itemOptions.filter((option) => {
          return option.optionId !== payload;
        });
        this.itemValue.itemOptions = filtered;
        this.options = this.itemValue.itemOptions;
      },
      calculateItemSubtotal() {
        const result = Math.ceil(this.itemValue.itemCount * this.itemValue.itemUnit);
        this.itemValue.itemSubtotal = result;
      },
      calculateChange(mode) {
        if(mode === '2') {
          this.itemValue.itemUnit = -(this.itemValue.itemUnit);
          this.itemValue.itemTaxType = '対象外';
        } else {
          this.itemValue.itemUnit = Math.abs(this.itemValue.itemUnit);
          this.itemValue.itemTaxType = '10%';
        }
      },
      updateItemTotal() {
        this.itemValue.itemTotal = this.calculateItemTotal;
      },
      emitUpdateItemTaxRate() {
        this.$emit('update-taxrate-item')
      },
      // -- add by backend
      emitSubmitItem() {
        this.$emit('submit-item');
      },
      emitExitEditMode() {
        this.$emit('exit-edit-mode');
      },
      // -- END add by backend
    }
  }
</script>

<style scoped>
  .alert-danger {
    color: red;
  }
  .border-danger {
    border-color: red;
  }
</style>
