<template>
    <polyline
        :points="points"
        fill="none"
        stroke="#607475"
        stroke-width="2"
        :style="isShowDialog ? 'opacity: 0.15' : ''"
    />
</template>

<script>
export default {
    name: 'SitemapLine',
    props: [
        'currentNode',
        'parentNode',
        'nodeDefaultSize',
        'nodeDistance',
        'isMoving',
        'isShowDialog',
    ],

    computed: {
        points() {
            const currentX = this.isMoving ? this.currentNode.x + 32 : this.currentNode.x
            const currentY = this.isMoving ? this.currentNode.y + 32 : this.currentNode.y
            const parentX = this.isMoving ? this.parentNode.x + 32 : this.parentNode.x
            const parentY = this.isMoving ? this.parentNode.y + 32 : this.parentNode.y
            const parentWidth = this.parentNode.width
            const defaultHeight = this.nodeDefaultSize.height
            const distanceX = this.nodeDistance.x
            const points = [
                [currentX, currentY + defaultHeight / 2],
                [currentX - distanceX / 2, currentY + defaultHeight / 2],
                [parentX + parentWidth + distanceX / 2, parentY + defaultHeight / 2],
                [parentX + parentWidth, parentY + defaultHeight / 2],
            ]
            let attribute = '';

            for (const point of points) {
                attribute = `${attribute} ${point[0]},${point[1]}`
            }

            return attribute
        }
    }
};
</script>
