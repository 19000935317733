<template>
	<div class="u-box-content-default mgt-032 __box">
		<h3 class="u-heading-box-title mgt-024">応募内容</h3>
		<div class="__applicant-content mgt-024">
			<div class="__content display-textarea-data">
				{{ current_user_offer.content }}
			</div>
			<div class="__meta u-flex-between-center mgt-024">
				<div class="__palnning">
					<dl class="u-list-input-label-set __list">
						<div class="__item">
							<dt class="__term">提案見積金額</dt>
							<dd class="__body"><span class="__budget">{{ current_user_offer.estimate }}</span></dd>
						</div>
					</dl>
				</div>
				<div class="__attachment">
					<dl class="u-list-input-label-set __list">
						<div class="__item">
							<dt class="__term">添付ファイル</dt>
							<dd class="__body">
								<button class="__file-link" @click.prevent="downloadFile()" v-if="current_user_offer.attached_file_url != null">
									{{ getattAchedFileName() }}
								</button>
								<span v-else>
									-
								</span>
							</dd>
						</div>
					</dl>
				</div>
			</div>
			<div class="__status mgt-024">
				<span class="__main">現在応募中です（{{ current_user_offer.offer_status_option.display_name_applicant }}）</span>
				<span class="__sub">発注者より商談の連絡がありましたら個別に対応を進めましょう（ステータスに対応したテキストを表示）</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		current_user_offer: {
			type: Object,
			default: {}
		},
	},
	data() {
		return {}
	},
	created () {
  },
	methods: {
		getattAchedFileName() {
			const arrFileName = this.current_user_offer.attached_file_url.split('/');
			return arrFileName[2];
		},
		downloadFile() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			
			const arrFileName = this.current_user_offer.attached_file_url.split('/');

			// -- donwload file
			axios({
				url: route('contractor.offer.file.download', { filename: arrFileName[2] }),
				method: 'GET',
				responseType: 'blob',
			}).then((res) => {
				var fileURL = window.URL.createObjectURL(new Blob([res.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', arrFileName[2]);
				document.body.appendChild(fileLink);
				fileLink.click();

				// -- hide loader
				loader.hide();
				// -- END hide loader
			}).catch((error) => {
				console.log(error)

				// -- hide loader
				loader.hide();
				// -- END hide loader
			});
			// -- END donwload file

			// old
      // return route('contractor.offer.file.download', { filename: arrFileName[2] })
    },
	},
}
</script>

<style>

</style>