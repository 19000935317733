<template>
  <div class="__block-depth-4">
    <div class="u-box-content-default __repeat-box">
      <div class="__block-depth-5 __block-depth-5--project">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term">案件名</dt>
            <dd class="__body">
              <div v-if="items.projectName.isEdit">
                <span
                  class="
                    u-form-select-default
                    u-form-input-select
                    u-form-input-select--large
                  "
                >
                  <select
                    v-model="items.projectName.projectId"
                    @blur="
                      changeEditMode(items.projectName, 'projectName');
                      emitUpdateItem(
                        currentItemsId,
                        'projectName',
                        items.projectName,
                      );
                    "
										@change="changeProject($event)"
                  >
                    <option :value="project.id" v-for="(project, index) in projects" :key="index" :disabled="project.deleted_at != null">
											{{ project.name }}
                    </option>
                  </select>
                </span>
								<!-- error message -->
                <p v-for="(error, index) of v$.items.projectName.projectId.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                  {{ error.$message }}
                </p>
								<!-- END error message -->
              </div>
              <div
                v-else
                class="p-survey-editable-area p-survey-editable-area--block"
                @click="
                  changeEditMode(items.projectName, 'projectName');
                  emitUpdateItem(
                    currentItemsId,
                    'projectName',
                    items.projectName
                  );
                "
              >
                {{ items.projectName.value }}
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div
        class="__block-depth-5 __block-depth-5--work-time u-flex-between-top"
      >
        <div class="__block-depth-6 __block-depth-6--work">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">作業対象項目</dt>
              <dd class="__body">
                <div v-if="items.workHistory.isEdit">
                  <span
                    class="
                      u-form-select-default
                      u-form-input-select
                      u-form-input-select--large
                    "
                  >
                    <select
                      v-model="items.workHistory.assigmentId"
                      @blur="
                        changeEditMode(items.workHistory, 'workHistory');
                        emitUpdateItem(
                          currentItemsId,
                          'workHistory',
                          items.workHistory
                        );
                      "
											@change="changeWorkHistory($event)"
                    >
                      <option :value="assigment.assigmentId" v-for="(assigment, index) in assigmentList" :key="index">
												{{ assigment.title }}
											</option>
                    </select>
                  </span>
									<!-- error message -->
                  <p v-for="(error, index) of v$.items.workHistory.assigmentId.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                    {{ error.$message }}
                  </p>
									<!-- END error message -->
                </div>
                <div
                  v-else
                  class="p-survey-editable-area p-survey-editable-area--block"
                  @click="
                    changeEditMode(items.workHistory, 'workHistory');
                    emitUpdateItem(
                      currentItemsId,
                      'workHistory',
                      items.workHistory
                    );
                  "
                >
                  {{ items.workHistory.value }}
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div class="__block-depth-6 __block-depth-6--time">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">作業時間実績</dt>
              <dd class="__body">
                <div v-if="items.workResult.isEdit" class="u-flex-between-top">
                  <div
                    class="
                      __block-depth-7 __block-depth-7--hour
                      u-flex-between-center
                    "
                  >
                    <span
                      class="
                        u-form-select-default
                        u-form-input-select
                        u-form-input-select--large
                        u-flex-shrink-1
                      "
                    >
                      <select
                        v-model="items.workResult.hours"
                        @change="
                          changeEditMode(items.workResult);
                          emitUpdateItem(
                            currentItemsId,
                            'workResult',
                            items.workResult
                          );
                        "
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                      </select>
                    </span>
                    <span class="__text u-flex-shrink-0">時間</span>
                  </div>
                  <div
                    class="
                      __block-depth-7 __block-depth-7--minute
                      u-flex-between-center
                    "
                  >
                    <span
                      class="
                        u-form-select-default
                        u-form-input-select
                        u-form-input-select--large
                        u-flex-shrink-1
                      "
                    >
                      <select
                        v-model="items.workResult.minutes"
                        @change="
                          changeEditMode(items.workResult);
                          emitUpdateItem(
                            currentItemsId,
                            'workResult',
                            items.workResult
                          );
                        "
                      >
                        <option value="0">0</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                      </select>
                    </span>
                    <span class="__text u-flex-shrink-0">　分</span>
                  </div>
                </div>
                <div
                  v-else
                  class="p-survey-editable-area p-survey-editable-area--block"
                  @click="
                    changeEditMode(items.workResult);
                    emitUpdateItem(
                      currentItemsId,
                      'workResult',
                      items.workResult
                    );
                  "
                >
                  {{ items.workResult.hours }}時間{{
                    items.workResult.minutes
                  }}分
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="__block-depth-5 __block-depth-5--memo">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term" @click.prevent="changeEditMode(items.notice, 'notice')">
							備考
						</dt>
            <dd class="__body">
              <div v-if="items.notice.isEdit">
                <span class="u-form-input-default u-form-input-text-area">
                  <textarea
                    v-model="items.notice.value"
                    placeholder="作業報告に関する備考などを入力してください"
                    name=""
                    @blur="
                      changeEditMode(items.notice, 'notice');
                      emitUpdateItem(currentItemsId, 'notice', items.notice);
                    "
                  ></textarea>
                </span>
              </div>
              <div
                v-else
                class="p-survey-editable-area p-survey-editable-area--block"
                @click="
                  changeEditMode(items.notice, 'notice');
                  emitUpdateItem(currentItemsId, 'notice', items.notice);
                "
              >
                {{ items.notice.value !== null ? items.notice.value : '-' }}
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <button
        v-if="
          !items.projectName.isEdit &&
          !items.workHistory.isEdit &&
          !items.workResult.isEditt &&
          !items.notice.isEdit
        "
        class="__button __button--delete"
        type="button"
        @click="emitDeleteItem(items.itemId)"
      >
        <img
          class="__image"
          src="/assets/img/site/icon_delete_small_min.svg"
          alt="削除"
        />
      </button>
    </div>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
// -- END vuelidate
export default {
	setup(props) {
    return { v$: useVuelidate({ $scope: props.validationScope }) };
  },
  props: {
    currentItems: {
      type: Object,
      default: {},
    },
    currentItemsId: {
      type: Number,
      default: 0,
    },
		projects: {
			type: Array,
			default: []
		},
		work_targets: {
			type: Array,
			default: []
		},
		validationScope: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: [],
			assigmentList: [],
    };
  },
	validations() {
    return {
      items: {
				projectName: {
					projectId: {
						required: helpers.withMessage(
							"案件名は必須項目です。",
							required
						),
					},
				},
				workHistory: {
					assigmentId: {
						required: helpers.withMessage(
							"作業対象項目は必須項目です。",
							required
						),
					}
				},
				workResult: {
					hours: {
						required: helpers.withMessage(
							"時間は必須項目です。",
							required
						),
					},
					minutes: {
						required: helpers.withMessage(
							"分は必須項目です。",
							required
						),
					}
				}
			}
    };
  },
  created() {
    this.items = this.currentItems;
  },
  methods: {
    changeEditMode(key, type = null) {
			/** 
			 * check, if value null and not in items.notice dont change isEdit
			*/
			if (type == 'projectName') {
				if (key.projectId != null) {
					key.isEdit = !key.isEdit;
				}
			}
			else if(type == 'workHistory') {
				if (key.assigmentId != null) {
					key.isEdit = !key.isEdit;
				}
			}
			else {
				key.isEdit = !key.isEdit;
			}
    },
    emitUpdateItem(itemId) {
      this.$emit("item-update", {
        id: itemId,
        value: this.items,
      });
    },
    emitDeleteItem(itemId) {
			console.log(itemId)
      this.$emit("item-delete", itemId);
    },
		changeProject(event) {
			const project = this.projects.find(project => project.id == event.target.value);
			this.items.projectName.value = project.name;

			// -- reset items.workHistory.assigmentId 
			// and value, change isEdit to true
			this.items.workHistory = {
				assigmentId: null,
				isEdit: true,
				value: null
			};
			// -- END reset items.workHistory.assigmentId

			// -- set assigmentList
			const filtered = this.work_targets.filter((item) => {
        return item.project_id == project.id;
      });
      this.assigmentList = filtered;
			// -- END set assigmentList
		},
		changeWorkHistory(event) {
			const work_target = this.work_targets.find(work_target => work_target.cost_id == event.target.value);
			this.items.workHistory.value = work_target.title;
		}
  },
};
</script>
