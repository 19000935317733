<template>
<article class="p-section-01">
  <section class="l-wrapper">
    <div class="l-container">
      <div class="l-inner">
        <div class="__block-depth-1">
          <div class="__block-depth-2 __block-depth-2--report-block">
            <h2 class="u-heading-content-title">個人日報</h2>
            <ul class="u-list-date-box mgt-024 __calendar">
              <!-- loop personal_daily -->
              <li class="__item" v-for="(daily, index) in personalDaily" :key="index">
                <a class="__outer-link" :href="goToDailyReportManagement(daily.date)"></a>
                <div class="__box" :class="getBoxClass(daily.date, daily.dailyReportCount)">
                  <span class="__head">
                    <span class="__year">{{ moment(daily.date, 'YYYY-MM-DD').format('YYYY年') }}</span>
                    <span class="__date">{{ moment(daily.date, 'YYYY-MM-DD').format('MM月DD日（dd）') }}</span>
                  </span>
                  <span class="__body">
                    <span class="__text" v-if="daily.dailyReportCount > 0">
                      {{ daily.dailyReportCount }}時間
                    </span>
                    <span class="__text" v-else>未登録</span>
                    <a class="__link" :href="goToDailyReportManagement(daily.date)">編集する</a>
                  </span>
                </div>
              </li>
              <!-- END loop personal_daily -->
            </ul>
            <div class="c-pager mgt-016">
              <span class="__next">
                <button class="__button __button--next" @click.prevent="filterWeek(1)"></button>
                <span class="__text">過去の日付へ</span>
              </span>
              <span class="__prev">
                <button class="__button __button--prev" @click.prevent="filterWeek(-1)"></button>
                <span class="__text">先の日付へ</span>
              </span>
            </div>
          </div>
          <div class="__block-depth-2 __block-depth-2--analysis-block">
            <h2 class="u-heading-content-title">案件別分析</h2>
            <div class="c-pagenation-projects mgt-024">
              <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
              <!-- check previousPageUrl is null -->
              <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
              <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
              <!-- END check previousPageUrl null -->
              <!-- check nextPageUrl is null -->
              <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
              <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
              <!-- END check nextPageUrl is null -->
            </div>
            <div class="u-table-wrapper u-table-wrapper--scroll __table">
              <div class="u-table-list-item">
                <div class="__table-header">
                  <div class="__row">
                    <span class="__cell __cell--head u-align-left">案件名</span>
                    <span class="__cell __cell--head u-align-center">予定工数</span>
                    <span class="__cell __cell--head u-align-center">実績工数</span>
                    <span class="__cell __cell--head u-align-center">予算消化率</span>
                    <span class="__cell __cell--head"></span>
                  </div>
                </div>
                <div class="__table-body">
                  <!-- loop start-->
                  <div class="__row __hover-highlight" v-for="(data, index) in lists.data" :key="index">
                    <span class="__cell __cell--head __project">
                      <a class="u-text-bold" :href="goToProjectDetail(data.line_number)">
                        {{ data.name }}
                      </a>
                    </span>
                    <span class="__cell __cell--data u-align-center">
                      <span>{{ budgetList[index].totalProjectCost }}人日</span>
                    </span>
                    <span class="__cell __cell--data u-align-center">
                      <span>{{ budgetList[index].totalManHour }}人日</span>
                    </span>
                    <span class="__cell __cell--data u-align-center">
                      <span class="u-text-bold">{{ budgetList[index].budgetRate }}%</span>
                    </span>
                    <span class="__cell __cell--data u-align-right">
                      <a class="u-button-default u-button-default--small u-button-default--secondary" :href="goToProjectAnalysis(data.line_number)">詳細</a>
                    </span>
                  </div>
                  <!-- loop end-->
                </div>
              </div>
            </div>
            <div class="c-pagenation-projects mgt-016">
              <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
              <!-- check previousPageUrl is null -->
              <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
              <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
              <!-- END check previousPageUrl null -->
              <!-- check nextPageUrl is null -->
              <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
              <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
              <!-- END check nextPageUrl is null -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
</template>

<script>
import moment from 'moment';
export default {
  components: {},
  props: {
    projects: {
      type: Object,
      default: {}
    },
    personal_daily: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      lists: {},
      budgetList: [],
      currentWeek: 0,
      personalDaily: this.personal_daily,
    };
  },
  async created() {
    // -- set moment js to JA
    this.moment = moment;
    moment.locale('ja');
    // -- END set moment js to JA
    this.initData();
  },
  methods: {
    initData() {
      this.lists = this.projects;
      this.setBudget();
    },
    async loadData(page = 1) {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			await axios.get(route('contractor.productivity.index', {page: page}))
				.then((res) => {
					this.lists = res.data.lists;
          this.setBudget();
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
    getTotalProjectCost(data) {
      // -- get total pages->cost.cost and page->page_optional_costs->cost.cost
      let totalPageCost = 0;
      let totalPageOptionalCost = 0;
      data.page.forEach(page => {
        totalPageCost+=page.cost.cost;
        totalPageOptionalCost+=page.total_page_optional_cost;
      });
      // -- END get total pages->cost.cost and page->page_optional_costs->cost.cost

      // -- get total optional_costs->cost.cost
      let totalOptionalCost = 0;
      data.optional_costs.forEach(optional_cost => {
        totalOptionalCost+=optional_cost.cost.cost;
      });
      // -- END get total optional_costs->cost.cost

      return totalPageCost + totalPageOptionalCost + totalOptionalCost;
    },
    getTotalManHour(data) {
      // -- get total man hour, minute from page and page_optional_costs
      let pageManHour = 0;
      let pageManMinute = 0;
      let pageOptionalManHour = 0;
      let pageOptionalManMinute = 0;
      data.page.forEach(page => {
        page.cost.assignments.forEach(assignment => {
          assignment.daily_reports.forEach(daily_report => {
            pageManHour+=daily_report.worktime_hour;
            pageManMinute+=daily_report.worktime_minute;
          });
        });

        page.page_optional_costs.forEach(page_optional_cost => {
          page_optional_cost.cost.assignments.forEach(assignment => {
            assignment.daily_reports.forEach(daily_report => {
              pageOptionalManHour+=daily_report.worktime_hour;
              pageOptionalManMinute+=daily_report.worktime_minute;
            });
          });
        });
      });
      // -- END get total man hour, minute from page and page_optional_costs

      // -- get total man hour, minute from optional_costs
      let optionalCostManHour = 0;
      let optionalCostManMinute = 0;
      data.optional_costs.forEach(optional_cost => {
        optional_cost.cost.assignments.forEach(assignment => {
          assignment.daily_reports.forEach(daily_report => {
            optionalCostManHour+=daily_report.worktime_hour;
            optionalCostManMinute+=daily_report.worktime_minute;
          });
        });
      });
      // -- END get total man hour, minute from optional_costs

      // -- sum all
      let totalHour = pageManHour + pageOptionalManHour + optionalCostManHour;
      let totalMinute = (pageManMinute + pageOptionalManMinute + optionalCostManMinute)/60;

      return this.convertDecimal(totalHour+totalMinute);
      // -- END sum all
    },
    setBudget() {
      // -- reset this.budgetList
      this.budgetList = [];
      // -- END reset this.budgetList

      this.lists.data.forEach((project, pIndex) => {
        let getTotalProjectCost = this.getTotalProjectCost(project);
        let getTotalManHour = this.getTotalManHour(project);
        let formBudget = {
          totalProjectCost: getTotalProjectCost,
          totalManHour: getTotalManHour,
          budgetRate: getTotalManHour == 0 ? 0 : this.convertDecimal((getTotalProjectCost/getTotalManHour)*100)
        };
        this.budgetList.push(formBudget);
      });
    },
    convertDecimal(data) {
      return +(Math.round(data + "e+2")  + "e-2");
    },
    getBoxClass(date, count) {
      const currentDate = moment().format('YYYY-MM-DD');
      if (date == currentDate) {
        return '__box--highlight';
      }
      else {
        if (count > 0) {
          return '__box--active';
        }
      }
    },
    goToDailyReportManagement(date) {
      return route('contractor.management.daily_report.index', { date: date });
    },
    async filterWeek(count) {
      const filterWeek = this.currentWeek+(count);
      this.currentWeek+=(count);
      // -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			await axios.get(route('contractor.productivity.index', {week: filterWeek}))
				.then((res) => {
          console.log(res)
					this.personalDaily = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
    },
    goToProjectDetail(project) {
      return route('contractor.project.show', { project: project });
    },
    goToProjectAnalysis(project) {
      return route('contractor.management.analysis.index', { project: project });
    }
  },
};
</script>
