'use strict';

export function PreventEvent({target, eventType}) {
  const targets = document.querySelectorAll(target);
  if(eventType === 'click') {
    targets.forEach((target) => {
      target.addEventListener('click', (event) => {
        event.preventDefault();
      });
    });
  }
}