<template>
  <main class="l-main p-page p-project-page p-app-page p-service-page" v-if="!isSitemapFullscreen">
        <nav class="l-breadcrumb-section">
            <div class="l-container">
                <div class="l-inner">
                    <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                        <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                        <li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
                        <li class="__list"><a class="__link" href="#">{{ project.name }}</a></li>
                    </ul>
                </div>
            </div>
        </nav>
		<article class="p-page-title">
			<section class="l-wrapper">
				<div class="l-container">
					<div class="l-inner">
						<div class="__block-depth-1">
							<div class="__block-depth-2 u-flex-between-center">
								<h1 class="u-heading-page-title __title">案件情報・サイトマップ</h1>
								<form action="" method="post">
									<input 
										class="u-button-default u-button-default--medium u-button-default--negative __button js-modal-open-confirm-delete" 
										type="button" 
										value="この案件を削除">
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</article>
		<article class="p-section-01">
			<section class="l-wrapper">
				<div class="l-container">
					<div class="l-inner">
						<div class="__block-depth-1 __block-depth-1--top-block">
							<div class="__block-depth-2 u-flex-between-center">
								<div class="__block-depth-3 __block-depth-3--title-block u-flex-between-center u-flex-shrink-1 u-flex-grow-1">
									<div class="__block-depth-4 __block-depth-4--1st">
										<span class="u-text-label-status" :class="'u-text-label-status--'+project_status_option.status_color">
											{{ project_status_option.display_name }}
										</span>
									</div>
									<div class="__block-depth-4 __block-depth-4--text-block u-flex-grow-1">
										<a v-show="!is_project_name_edit" class="u-text-link __link" :href="generateRoute('setting-customer', customer.line_number)">
											{{ customer.name }}
										</a>
										<h1 class="u-text-headline">
											<span v-show="!is_project_name_edit">{{ project.name }}</span>
											<span class="u-form-input-default u-form-input-text-box" v-show="is_project_name_edit">
                                                <input type="text" placeholder="例）株式会社Pyattoコーポレートサイト制作" name="project_name" v-model="project.name">
                                            </span>
										</h1>
									</div>
								</div>
								<div class="__block-depth-3 __block-depth-3--edit-block u-flex-shrink-0">
									<button class="u-button-default u-button-default--small u-button-default--primary" type="button" v-show="!is_project_name_edit" @click.prevent="changeProjectName()">
										案件名を編集
									</button>
									<button class="u-button-default u-button-default--small u-button-default--primary" type="button" v-show="is_project_name_edit" @click.prevent="updateProjectName()" :class="isDisabled ? 'u-button-default--disabled' : ''" :disabled="isDisabled">
										反映
									</button>
								</div>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--bottom-block">
								<div class="u-box-content-default c-siteflow-map-canvas" style="padding: 0 !important;">
									<!-- サイトマップ -->
									<sitemap
										:project="project"
										:is-expand="!accordionSiteMap"
										:is-fullscreen="false"
										:on-click-fullscreen="onClickSitemapFullscreen"
									/>
								</div>
								<button
									:class="`c-siteflow-map-accordion u-button-toggle-accordion u-block-center ${!accordionSiteMap ? 'u-button-toggle-accordion--close' : null}`"
									@click="changeAccordion('site_map')">
									More
								</button>
						</div>
						<div class="u-flex-right-center mgt-008">
							<a class="u-button-default u-button-default--small u-button-default--secondary __button" :href="generateRoute('sitemap-pdf')">
								PDF Sitemap
							</a>
						</div>
					</div>
				</div>
			</section>
		</article>
		<!-- section 01 end-->
		<article class="p-section-02">
			<section class="l-wrapper">
				<div class="l-container">
					<div class="l-inner">
						<div class="__block-depth-1 __block-depth-1--1st">
							<div class="u-box-content-default">
								<div class="__block-depth-2 u-flex-between-center">
									<div class="__block-depth-3 __block-depth-3--result-block">
										<div class="p-project-total-man-hours u-flex-left-baseline __title">
											<span class="__label">合計工数</span>
											<span class="__number"> {{ convertDecimal(total_all_page_cost) }} </span>
											<span class="__label">人日</span>
										</div>
									</div>
									<div class="__block-depth-3 __block-depth-3--edit-block">
										<a class="u-button-default u-button-default--small u-button-default--secondary __button" :href="generateRoute('assignment-show')">
											設定工数の割当
										</a>
										<a class="u-button-default u-button-default--small u-button-default--secondary __button" :href="generateRoute('cost-show')">
											工数計算
										</a>
									</div>
								</div>
								<p class="p-project-current-task-message u-text-leadcopy __heading">このプロジェクトは受注済みです、納品を目指して制作や開発を進めていきましょう</p>
								<div class="p-project-todo __list mgt-024">
									<div class="__item">
										<div class="__term">タスクリスト</div>
										<div class="__body js-accordion-panel" :class="accordionProjectTask ? 'js-accordion-panel--shown' : '' ">
											<ul class="u-list-todo-check __list-child">
												<li class="__item-child" v-for="(project_task_data) in project_task" :key="project_task_data.id">
													<label class="u-form-checkbox-list">
														<input type="checkbox" :value="project_task_data.id" v-model="form_project_task"
															@change="changeProjectTaskIsDone(project_task_data.id)">
														<span class="__text">{{ project_task_data.display_name }}</span>
													</label>
												</li>
											</ul>
										</div>
										<button class="js-accordion-trigger c-siteflow-map-accordion u-button-toggle-accordion u-block-center __switch" @click="changeAccordion('project_task')">
											More
										</button>
									</div>
								</div>
								<div class="__button-group u-flex-center-center">
									<button class="js-modal-open u-button-default u-button-default--medium u-button-default--primary __button"
										v-for="optionStatus in arr_option_project_status"
										:key="optionStatus.id"
										@click.prevent="showModal(optionStatus.id, optionStatus.display_name)"
									>
										{{ optionStatus.display_name }}に更新
									</button>
								</div>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--2nd"></div>
					</div>
				</div>
			</section>
		</article>
		<!-- section 02 end-->
		<article class="p-section-03">
			<section class="l-wrapper">
				<div class="l-container">
					<div class="l-inner">
						<div class="__block-depth-1 __block-depth-1--1st">
							<h2 class="u-heading-content-title u-heading-content-title--add-link __heading">
								<span class="__text">ヒアリングシート</span>
								<a class="u-button-default u-button-default--small u-button-default--secondary __link" :href="generateRoute('survey_format')">新規作成</a>
							</h2>
							<div class="u-table-wrapper u-table-wrapper--scroll __table">
								<div class="u-table-list-item">
									<div class="__table-body">
										<!-- loop start-->
										<a class="__row __link" :href="generateRoute('survey_format-show', survey_format_data.line_number)" v-for="survey_format_data in survey_format" :key="survey_format_data.id">
											<span class="__cell __cell--data">
												<span class="__date">{{ moment(survey_format_data.updated_at).format('YYYY/MM/DD') }}</span>
											</span>
											<span class="__cell __cell--data">
												<p class="u-text-bold">
													{{ survey_format_data.title }}
												</p>
											</span>
										</a>
										<!-- loop end-->
										<!-- if no data -->
										<div class="mgt-064" style="text-align: center;" v-if="survey_format.length == 0">
											ヒアリングシートが登録されていません
										</div>
										<!-- END if no data -->
									</div>
								</div>
							</div>
							<div v-if="survey_format.length != 0" class="u-flex-right-center mgt-008">
								<button class="u-text-submit __submit" type="button" @click.prevent="getMoreData(count_getmore, true, 'survey_format')">さらに表示</button>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--2nd">
							<h2 class="u-heading-content-title u-heading-content-title--add-link __heading">
								<span class="__text">提案シート</span>
								<a class="u-button-default u-button-default--small u-button-default--secondary __link" :href="generateRoute('planning_format')">新規作成</a>
							</h2>
							<div class="u-table-wrapper u-table-wrapper--scroll __table">
								<div class="u-table-list-item">
									<div class="__table-body">
										<!-- loop start-->
										<a class="__row __link" :href="generateRoute('planning_format-show', planning_format_data.line_number)"
											v-for="planning_format_data in planning_format" :key="planning_format_data.id">
											<span class="__cell __cell--data">
												<span class="__date">{{ moment(planning_format_data.updated_at).format('YYYY/MM/DD') }}</span>
											</span>
											<span class="__cell __cell--data">
												<p class="u-text-bold">
													{{ planning_format_data.title }}
												</p>
											</span>
										</a>
										<!-- loop end-->
										<!-- if no data -->
										<div class="mgt-064" style="text-align: center;" v-if="planning_format.length == 0">
											提案シートが登録されていません
										</div>
										<!-- END if no data -->
									</div>
								</div>
							</div>
							<div v-if="planning_format.length != 0" class="u-flex-right-center mgt-008">
								<button class="u-text-submit __submit" type="button" @click.prevent="getMoreData(count_getmore, true, 'planning_format')">さらに表示</button>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--3rd">
							<h2 class="u-heading-content-title u-heading-content-title--add-link __heading">
								<span class="__text">見積もり</span>
								<a class="u-button-default u-button-default--small u-button-default--secondary __link" :href="generateRoute('estimate')">新規作成</a>
							</h2>
							<div class="u-table-wrapper u-table-wrapper--scroll __table">
								<div class="u-table-list-item">
									<div class="__table-body">
										<!-- loop start-->
										<a class="__row __link" :href="generateRoute('estimate-show', estimate_data.line_number)" v-for="estimate_data in estimate" :key="estimate_data.id">
											<span class="__cell __cell--data">
												<span class="__date">{{ moment(estimate_data.updated_at).format('YYYY/MM/DD') }}</span>
											</span>
											<span class="__cell __cell--data">
												<p class="u-text-bold">
													{{ estimate_data.title }}
												</p>
											</span>
											<span class="__cell __cell--data u-align-right">
												<p class="u-text-bold u-text-leadcopy">{{ formatCurrency(estimate_data.total_amount) }}円</p>
											</span>
										</a>
										<!-- loop end-->
										<!-- if no data -->
										<div class="mgt-064" style="text-align: center;" v-if="estimate.length == 0">
											見積もりが登録されていません
										</div>
										<!-- END if no data -->
									</div>
								</div>
							</div>
							<div v-if="estimate.length != 0" class="u-flex-right-center mgt-008">
								<button class="u-text-submit __submit" type="button" @click.prevent="getMoreData(count_getmore, true, 'estimate')">さらに表示</button>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--4th">
							<h2 class="u-heading-content-title u-heading-content-title--add-link __heading">
								<span class="__text">請求書</span>
								<a class="u-button-default u-button-default--small u-button-default--secondary __link" :href="generateRoute('invoice')">新規作成</a>
							</h2>
							<div class="u-table-wrapper u-table-wrapper--scroll __table">
								<div class="u-table-list-item">
									<div class="__table-body">
										<!-- loop start-->
										<a class="__row __link" :href="generateRoute('invoice-show', invoice_data.line_number)" v-for="invoice_data in invoice" :key="invoice_data.id">
											<span class="__cell __cell--data">
												<span class="__date">{{ moment(invoice_data.updated_at).format('YYYY/MM/DD') }}</span>
											</span>
											<span class="__cell __cell--data">
												<p class="u-text-bold">
													{{ invoice_data.title }}
												</p>
											</span>
											<span class="__cell __cell--data u-align-right">
												<p class="u-text-bold u-text-leadcopy">{{ formatCurrency(invoice_data.total_amount) }}円</p>
											</span>
										</a>
										<!-- loop end-->
										<!-- if no data -->
										<div class="mgt-064" style="text-align: center;" v-if="invoice.length == 0">
											請求書が登録されていません
										</div>
										<!-- END if no data -->
									</div>
								</div>
							</div>
							<div v-if="invoice.length != 0" class="u-flex-right-center mgt-008">
								<button class="u-text-submit __submit" type="button" @click.prevent="getMoreData(count_getmore, true, 'invoice')">さらに表示</button>
							</div>
						</div>
						<div class="__block-depth-1 __block-depth-1--5th">
							<h2 class="u-heading-content-title u-heading-content-title--add-link __heading"><span class="__text">マッチング募集要件</span><a class="u-button-default u-button-default--small u-button-default--secondary __link" :href="generateRoute('recruit')">新規作成</a></h2>
							<div class="u-table-wrapper u-table-wrapper--scroll __table">
								<div class="u-table-list-item">
									<div class="__table-body">
										<!-- loop start-->
										<a class="__row __link" :href="generateRoute('recruit-show', recruit_data.line_number)" v-for="recruit_data in recruit" :key="recruit_data.id">
											<span class="__cell __cell--data">
												<span class="__date">{{ moment(recruit_data.updated_at).format('YYYY/MM/DD') }}</span>
											</span>
											<span class="__cell __cell--data">
												<p class="u-text-bold">{{ recruit_data.title }}</p>
											</span>
										</a>
										<!-- loop end-->
										<!-- if no data -->
										<div class="mgt-064" style="text-align: center;" v-if="recruit.length == 0">
											マッチング募集要件が登録されていません
										</div>
										<!-- END if no data -->
									</div>
								</div>
							</div>
							<div v-if="recruit.length != 0" class="u-flex-right-center mgt-008">
								<button class="u-text-submit __submit" type="button" @click.prevent="getMoreData(count_getmore, true, 'recruit')">さらに表示</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</article>
		<!-- section 03 end-->
		<div class="c-modal js-modal" :class="is_show_modal ? 'js-modal--shown' : ''">
      <div class="__overlay"></div>
			<div class="__panel __panel--small p-modal-project">
				<div class="u-box-modal-defalut">
					<h3 class="__title">案件ステータス更新</h3>
					<div class="__content">
						<form action="" method="post">
							<p class="__text">この案件のステータスを「{{form_update_project_status_name}}」にする日付を設定してステータスを更新してください。</p>
							<dl class="u-list-input-label-set __list">
								<div class="__item">
									<dt class="__term">ステータス更新日</dt>
									<dd class="__body">
										<span class="u-form-input-default u-form-input-date u-form-input-default--add-icon">
											<input type="date" placeholder="検索範囲の開始日" v-model="form_project_status_update_at">
											<div style="color: red;" v-show="validation_project_status_update_at">
												Date is required
											</div>
										</span>
									</dd>
								</div>
							</dl>
							<div class="__button-wrap u-flex-center-center">
								<button class="js-modal-close u-button-default u-button-default--large u-button-default--negative __button" type="button" @click.prevent="showModal()">
									閉じる
								</button>
								<input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="更新" @click.prevent="changeProjectStatus()">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</main>
	<nav class="l-breadcrumb-section">
			<div class="l-container">
					<div class="l-inner">
							<ul class="u-list-breadcrumb u-list-breadcrumb--settings">
									<li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
									<li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
									<li class="__list"><a class="__link" href="#">{{ project.name }}</a></li>
							</ul>
					</div>
			</div>
	</nav>

	<!-- サイトマップのフルスクリーン表示用 -->
	<main class="l-main p-page p-project-page p-app-page p-service-page" v-if="isSitemapFullscreen">
		<sitemap
			:project="project"
			:is-expand="false"
			:is-fullscreen="true"
			:on-click-fullscreen="onClickSitemapFullscreen"
		/>
	</main>

	<!-- modal confirmation -->
	<modal-confirm-delete
    modal-title="確認"
    modal-content="本当に削除しますか？"
    is-confirm-delete="1"
    :delete-id="project.id"
    :route-delete="project.routeDelete"
    :route-redirect="project.routeRedirect" />
	<!-- END modal confirmation -->

</template>

<script>
import axios from "axios";
import moment from 'moment';
import Sitemap from '../components/sitemap/Sitemap';

export default {
	props: {
		project: {
			type: Object,
			default: {}
		},
		customer: {
			type: Object,
			default: {}
		},
		total_all_page_cost: {
			type: Number,
			default: 0
		},
		option_update_status: {
			type: Array,
			default: []
		}
	},
    components: {
        Sitemap,
    },
  data(){
    return {
			project_task: this.project.project_task,
			survey_format: this.project.survey_format,
			planning_format: this.project.planning_format,
			estimate: this.project.estimates,
			invoice: this.project.invoices,
			recruit: this.project.recruits,
			arr_data: ['survey_format','planning_format','estimate','invoice', 'recruit'],
			count_getmore: 5,
			accordionProjectTask: true,
			accordionSiteMap: true,
			isSitemapFullscreen: false,
			width_input_container: 'none',
      is_project_name_edit: false,
			is_show_modal: false,
			isDisabled: false,
			errors: '',
			validation_project_status_update_at: false,
			form_project_status_update_at: '',
			form_project_task: [],
			arr_option_project_status: [],
			form_update_project_status: null,
			project_status_option: {},
			form_update_project_status_name: null,
    }
  },
  computed:{
    /*...*/
  },
  watch:{
    'project.name': function (newVal, oldVal){
			if (newVal == '') {
				this.isDisabled = true;
			}
			else {
				this.isDisabled = false;
			}
     },
		 'is_project_name_edit': function (newVal, oldVal) {
			if (newVal) {
				this.width_input_container = '1013px'; // for now its static size
			}
			else {
				this.width_input_container = 'none';
			}
		 }
  },
  methods: {
		convertDecimal(data) {
			 return +(Math.round(data + "e+2")  + "e-2");
		},
		// -- update project name
    async updateProjectName() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			let form = {
				name: this.project.name
			};

			try {
          await axios.put(route('contractor.api.project.update', { id: this.project.id }), form)
      } catch (e) {
				if (e.response.status === 422) {
					this.errors = e.response.data.errors
				}
      }

			if (this.errors == '') {
				this.changeProjectName()
			}

			// -- loader hide
			loader.hide()
			// -- END loader hide
    },
		// -- END update project name

		// -- get more data
		async getMoreData(count, is_except_id = false, type) {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			let except_id = [];
			if (is_except_id) {
				switch (type) {
					case 'survey_format':
						if (this.survey_format.length > 0) {
							for (let index = 0; index < this.survey_format.length; index++) {
								except_id.push(this.survey_format[index].id);
							}
						}
						break;
					case 'planning_format':
						if (this.planning_format.length > 0) {
							for (let index = 0; index < this.planning_format.length; index++) {
								except_id.push(this.planning_format[index].id);
							}
						}
						break;
					case 'estimate':
						if (this.estimate.length > 0) {
							for (let index = 0; index < this.estimate.length; index++) {
								except_id.push(this.estimate[index].id);
							}
						}
						break;
					case 'invoice':
						if (this.invoice.length > 0) {
							for (let index = 0; index < this.invoice.length; index++) {
								except_id.push(this.invoice[index].id);
							}
						}
						break;
					case 'recruit':
						if (this.recruit.length > 0) {
							for (let index = 0; index < this.recruit.length; index++) {
								except_id.push(this.recruit[index].id);
							}
						}
						break;
					default:
						break;
				}
			}

			await axios.get(route('contractor.api.'+type+'.more'),  { params: { project_id: this.project.id, count: count, except_id: except_id } })
				.then((response) => {
					console.log(response)
					if (response.status == 200 && response.data.data.length > 0) {
						response.data.data.forEach(val => {
							this[type].push(val);
						});
					}
					// -- loader hide
					loader.hide();
					// -- END loader hide
				})
				.catch((error) => {
					console.log(error)
					// -- loader hide
					loader.hide();
					// -- END loader hide
				})
    },
		// -- END get more data

		// -- delete project
		async deleteProject() {
			if (!window.confirm('Are you sure?')) {
				return
			}
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			await axios.delete(route('contractor.api.project.destroy', { id: this.project.id }))
				.then((response) => {
					if (response.status == 204) {
						window.location.href = route('contractor.project.index');
					}
				})
				.catch((error) => {
					console.log(error)
				});
		},
		// -- END delete project

		// -- check project_task->is_done
		checkProjectIsDone() {
			this.project_task.forEach(val => {
				if (val.is_done == 1) {
					this.form_project_task.push(val.id);
				}
			});
		},
		// -- END check project_task->is_done

		// -- change projects->name
		changeProjectName() {
			this.is_project_name_edit = !this.is_project_name_edit;
		},
		// -- END change projects->name

		// -- format currency
		formatCurrency(value) {
			let val = (value/1).toFixed(1).replace(',', '.')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.0', '');
    },
		// -- END format currency

		// -- change project_task is_done
		async changeProjectTaskIsDone(id) {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			let project_task = this.project_task.find(val => val.id == id);
			project_task.is_done = project_task.is_done == 1 ? 0 : 1;

			await axios.put(route('contractor.api.project_taskupdate.isdone', { id: project_task.id }))
				.then((response) => {
					if (response.status == 200) {
						// console.log(response.data.message)
					}
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
		// -- END change project_task is_done

		// -- change accordion project_task
		changeAccordion(type) {
			if (type == 'project_task') {
				this.accordionProjectTask = !this.accordionProjectTask
			}
			else if(type == 'site_map') {
				this.accordionSiteMap = !this.accordionSiteMap
			}
		},
		// -- change accordion project_task

		onClickSitemapFullscreen() {
			this.isSitemapFullscreen = !this.isSitemapFullscreen;

			// フルスクリーン時は一時的にフッタを非表示に
			const elements = document.getElementsByTagName('footer')
			for (const footer of elements) {
				footer.style.display = this.isSitemapFullscreen ? 'none' : 'block';
			}
		},

		// -- show modal
		showModal(id = null, name = null) {
            console.log(this.arr_option_project_status);
			this.is_show_modal = !this.is_show_modal;
			this.form_update_project_status = id;
            this.form_update_project_status_name = name;
		},
		// -- END show modal

		// -- change project status
		async changeProjectStatus() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			if (!this.form_project_status_update_at) {
        this.validation_project_status_update_at = true;
      }
			else {
				this.validation_project_status_update_at = false;
				let project_form = {
					status_id: this.form_update_project_status,
					status_updated_at: this.form_project_status_update_at
				};
				await axios.put(route('contractor.api.project.update', { id: this.project.id }), project_form)
					.then((response) => {
						if (response.status == 200) {
							// -- update display project_status_option
							this.setProjectStatusOption(response.data.record.project_status_option, response.data.record.status_color)
							// -- END update display project_status_option

							// -- update options for project.status_id
							this.arr_option_project_status = response.data.project_status_options;
							// -- END update options for project.status_id

							// -- modal hide
							this.showModal();
							// -- END modal hide

							// -- loader hide
							loader.hide();
							// -- END loader hide
						}
					})
					.catch((error) => {
						console.log(error)
					});
			}
		},
		// -- END change project status

		// -- for go to create page
		generateRoute(type, line_number = null) {
			let route_page = '';
			switch (type) {
				case 'survey_format':
					route_page = route('contractor.project.survey_format.create', { project: this.project.line_number });
					break;
				case 'survey_format-show':
					route_page = route('contractor.project.survey_format.show', { project: this.project.line_number, survey_format: line_number });
					break;
				case 'planning_format':
					route_page = route('contractor.project.planning_format.create', { project: this.project.line_number });
					break;
				case 'planning_format-show':
					route_page = route('contractor.project.planning_format.show', { project: this.project.line_number, planning_format: line_number });
					break;
				case 'estimate':
					route_page = route('contractor.project.estimate.create', { project: this.project.line_number });
					break;
				case 'invoice':
					route_page = route('contractor.project.invoice.create', { project: this.project.line_number });
					break;
				case 'estimate-show':
					route_page = route('contractor.project.estimate.show', { project: this.project.line_number, estimate: line_number });
					break;
				case 'invoice-show':
					route_page = route('contractor.project.invoice.show', { project: this.project.line_number, invoice: line_number });
					break;
				case 'recruit':
					route_page = route('contractor.project.recruit.create', { project: this.project.line_number });
					break;
				case 'recruit-show':
					route_page = route('contractor.project.recruit.show', { project: this.project.line_number, recruit: line_number });
					break;
				case 'assignment-show':
					route_page = route('contractor.project.assignment.show', { project: this.project.line_number });
					break;
				case 'cost-show':
					route_page = route('contractor.cost.show', { project: this.project.line_number });
					break;
				case 'sitemap-pdf':
					route_page = route('contractor.project.page.tree.pdf', { project: this.project.line_number });
					break;
				case 'setting-customer':
					route_page = route('contractor.settings.customer.show', { customer: line_number });
					break;
				default:
					route_page = route('contractor.project.estimate.create', { project: this.project.line_number });
					break;
			}
			return route_page;
		},
		// -- END for go to create page

        changePage(data) {
			let routeTo = route(data);
            return routeTo;
		},
		setProjectStatusOption(project_status_option, status_color) {
			this.project_status_option = project_status_option;
			this.project_status_option.status_color = status_color;
		},
  },
  created(){
    this.moment = moment;
		this.setProjectStatusOption(this.project.project_status_option, this.project.status_color);
  },
  mounted(){
		this.checkProjectIsDone();
		this.arr_option_project_status = this.option_update_status;
  }
};
</script>

<style>

</style>
