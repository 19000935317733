<template>
  <div class="c-modal"
    :class="[modalClass, withJs]">
		<div class="__overlay">
			<div class="__panel __panel--small p-modal-tax-settings">
				<div class="u-box-modal-defalut">
					<h3 class="__title">{{ modalTitle }}</h3>
					<div class="__content">
						<form>
							<p class="__text">{{ modalContent }}</p>
							<div class="__button-wrap u-flex-center-center">
                  <!-- just for 'Attantion' -->
                  <button 
                    class="u-button-default u-button-default--large u-button-default--negative __button js-modal-close-confirm-delete" 
                    type="button"
                    v-if="isConfirmDelete == '0' && deleteDirectly == '1'">
                    閉じる
                  </button>
                  <button 
                    class="u-button-default u-button-default--large u-button-default--negative __button" 
                    type="button"
                    v-if="isConfirmDelete == '0' && deleteDirectly == '0'"
                    @click="changeModalClass()">
                    閉じる
                  </button>
                  <!-- END just for 'Attantion' -->

                  <!-- for confirm delete -->
                  <button 
                    class="u-button-default u-button-default--large u-button-default--negative __button js-modal-close-confirm-delete" 
                    type="button"
                    v-if="isConfirmDelete == '1' && deleteDirectly == '1'">
                    いいえ
                  </button>
                  <button 
                    class="u-button-default u-button-default--large u-button-default--negative __button js-modal-close-confirm-delete" 
                    type="button"
                    v-if="isConfirmDelete == '1' && deleteDirectly == '0'"
                    @click="changeModalClass()">
                    いいえ
                  </button>

                  <input 
                    class="u-button-default u-button-default--large u-button-default--primary __button js-modal-close-confirm-delete" 
                    type="button" 
                    value="はい"
                    v-if="isConfirmDelete == '1'"
                    @click="deleteDirectly == '1' ? submit() : acceptDialog()">
                  <!-- END for confirm delete -->
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    props: {
      modalTitle: {
        type: String,
        default: '確認',
      },
      modalContent: {
        type: String,
        default: '本当に削除しますか？'
      },
      /** 
       * sorry for this, 
       * i plan use boolean but its not work properly,
       * sometimes it reads string '1' if get value true,
       * so i use string
      */
      isConfirmDelete: {
        type: String, 
        default: '1' 
      },
      deleteId: {
        type: [String, Number],
        required: false
      },
      routeDelete: {
        type: String,
         default: '',
        required: false
      },
      routeRedirect: {
        type: String,
        default: ''
      },
      deleteDirectly: {
        type: String,
        default: '1'
      },
      /* Specify callback when save is clicked
      * For example, if we want to trigger api call, when save is clicked
      * put the method on cb
      */
      accept: {
        type: Object,
        default: function() {
          return {
            cb: () => {},
          };
        },
      },
      withJs: {
        type: String,
        default: 'js-modal-confirm-delete'
      }
    },
    data() {
      return {
        modalClass: '',
      }
    },
    methods: {
      async submit() {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        await axios.delete(route(this.routeDelete, { id: this.deleteId }))
          .then((response) => {
            if (response.status == 204) {
              if (this.routeRedirect != '') {
                window.location.href = this.routeRedirect;
              }
            }
          })
          .catch((error) => {
            console.log(error)
            // -- loader hide
            loader.hide();
            // -- END loader hide
          });

      },
      // -- just return boolean
      /** 
       * will only return true, 
       * because the function to delete will use a function outside this modal
      */
      async acceptDialog() {
        await this.accept.cb();
        if (this.withJs == '') {
          this.changeModalClass('');
        }
      },
      changeModalClass(className = '') {
        this.modalClass = className;
      }
      // -- END just return boolean
    },
  }
</script>