'use strict';

export function LocalFileUpload({element}) {
  const inputElements = document.querySelectorAll(element);
  if(inputElements) {
    inputElements.forEach((inputElement) => {
        const preview = inputElement.children[0].children[0];
        const input = inputElement.children[2].children[0].children[0];
        const fileName = inputElement.children[2].children[0].children[2];
        const reset = inputElement.children[1];
        input.addEventListener('change', (event) => {
            const fileData = event.target.files[0];
            if(fileData.type.match('application/octet-stream')) {
                alert('ファイル形式が対応していません');
                preview.setAttribute('src', '');
                preview.style.display = 'none';
                preview.parentNode.style.display = 'none';
                reset.style.display = 'none';
                input.value = '';
                fileName.innerText = 'ファイルが選択されていません';
            } else {
                if(fileData.type.match('image/*')) {
                    const fileReader = new FileReader();
                    fileReader.addEventListener('load', () => {
                        preview.setAttribute('src', fileReader.result);
                        preview.style.display = 'inline';
                        preview.parentNode.style.display = 'inline-block';
                        reset.style.display = 'inline-block';
                        fileName.innerText = fileData.name;
                        console.log('ファイルデータの読み込みが完了しました');
                    });
                    if(fileData) {
                        fileReader.readAsDataURL(fileData);
                        console.log('ファイルデータのセットが完了しました');
                    }
                } else {
                    const fileReader = new FileReader();
                    fileReader.addEventListener('load', () => {
                        preview.setAttribute('src', '');
                        preview.style.display = 'none';
                        preview.parentNode.style.display = 'none';
                        reset.style.display = 'inline-block';
                        fileName.innerText = fileData.name;
                        console.log('ファイルデータの読み込みが完了しました');
                    });
                    if(fileData) {
                        fileReader.readAsDataURL(fileData);
                        console.log('ファイルデータのセットが完了しました');
                    }
                }
            }
        });
        reset.addEventListener('click', () => {
            preview.setAttribute('src', '');
            preview.parentNode.style.display = 'none';
            reset.style.display = 'none';
            input.value = '';
            fileName.innerText = 'ファイルが選択されていません';
        });
    });
  }
}
