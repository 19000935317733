<template>
  <div class="u-box-content-default">
    <table class="u-table-sheet mgt-024 p-recruiting-result">
      <tbody>
        <tr>
          <th>案件名</th>
          <td>
            <div v-if="isEdit.projectName" class="__input">
              <span class="u-form-input-default u-form-input-text-box">
                <input
                  v-model="projectName"
                  type="text"
                  placeholder="案件名を入力"
                  name=""
                  @blur="updateRecruit('projectName')"
                />
              </span>
              <!-- error message -->
              <p v-for="(error, index) of v$.projectName.$errors" :key="index" class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
              <div class="" v-if="isEditCheckSubmit.projectName !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.projectName  }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              @click="changeEditMode('projectName')"
              class="
                __item __item--text-box
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
            >
              {{ projectName }}
            </div>
          </td>
        </tr>
        <tr>
          <th>募集カテゴリ</th>
          <td>
            <div class="__item __item--radio">
              <div class="u-form-input-group">
                <label class="u-form-radio-default u-form-input-radio"
                  v-for="(recruiting_category_option, index) of recruiting_category_options" :key="index"
                >
                  <input
                    v-model="category"
                    type="radio"
                    name="category"
                    :value="index"
                    @change="updateEditMode('category', true)"
                  />
                  <span class="__text">{{ recruiting_category_option }}</span>
                </label>
                <!-- error message -->
                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.category.$errors" :key="index">{{ error.$message }}</p>
                <div class="" v-if="isEditCheckSubmit.category !== undefined">
                    <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.category }}</p>
                </div>
                <!-- END error message -->
              </div>
              <div class="u-flex-left-center">
                <button
                  v-if="isEdit.category"
                  class="
                    u-button-default
                    u-button-default--small
                    u-button-default--primary
                    mgt-016
                  "
                  type="button"
                  @click="updateRecruit('category')"
                >
                  更新
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>予算</th>
          <td>
            <div v-if="isEdit.budget" class="__input">
              <span
                class="
                  u-form-select-default
                  u-form-input-select
                  u-form-input-select--large
                "
              >
                <select v-model="budget" @blur="updateRecruit('budget')">
                  <option value="〜20万円">〜20万円</option>
                  <option value="21〜50万円">21〜50万円</option>
                  <option value="51〜100万円">51〜100万円</option>
                  <option value="101〜300万円">101〜300万円</option>
                  <option value="301万円〜">301万円〜</option>
                </select>
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.budget.$errors" :key="index">{{ error.$message }}</p>
              <div v-if="isEditCheckSubmit.budget !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.budget }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              @click="changeEditMode('budget')"
              class="
                __item __item--select
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
            >
              {{ budget }}
            </div>
          </td>
        </tr>
        <tr>
          <th>案件開始日</th>
          <td>
            <div v-if="isEdit.begin" class="__input">
              <span
                class="
                  u-form-input-default u-form-input-date
                  u-form-input-default--add-icon
                  js-input-icon-effect
                "
              >
                <input
                  v-model="begin"
                  type="date"
                  placeholder="案件開始日を入力"
                  name=""
                  @blur="updateRecruit('begin')"
                />
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.begin.$errors" :key="index">{{ error.$message }}</p>
              <div v-if="isEditCheckSubmit.begin !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.begin }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              @click="changeEditMode('begin')"
              class="
                __item __item--date
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
            >
              {{ begin }}
            </div>
          </td>
        </tr>
        <tr>
          <th>納期</th>
          <td>
            <div v-if="isEdit.deadline" class="__input">
              <span
                class="
                  u-form-input-default u-form-input-date
                  u-form-input-default--add-icon
                  js-input-icon-effect
                "
              >
                <input
                  v-model="deadline"
                  type="date"
                  placeholder="納期を入力"
                  name=""
                  @blur="updateRecruit('deadline')"
                />
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.deadline.$errors" :key="index">{{ error.$message }}</p>
              <div v-if="isEditCheckSubmit.deadline !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.deadline }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              @click="changeEditMode('deadline')"
              class="
                __item __item--date
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
            >
              {{ deadline }}
            </div>
          </td>
        </tr>
        <tr>
          <th>募集終了日</th>
          <td>
            <div v-if="isEdit.close" class="__input">
              <span
                class="
                  u-form-input-default u-form-input-date
                  u-form-input-default--add-icon
                  js-input-icon-effect
                "
              >
                <input
                  v-model="close"
                  type="date"
                  placeholder="募集終了日を入力"
                  name=""
                  @blur="updateRecruit('close')"
                />
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.close.$errors" :key="index">{{ error.$message }}</p>
              <div v-if="isEditCheckSubmit.close !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.close }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              class="
                __item __item--date
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
              @click="changeEditMode('close')"
            >
              {{ close }}
            </div>
          </td>
        </tr>
        <tr>
          <th>募集要件詳細</th>
          <td>
            <div v-if="isEdit.notice" class="__input">
              <span class="u-form-input-default u-form-input-text-area">
                <textarea
                  v-model="notice"
                  placeholder="募集要件詳細を入力"
                  name=""
                  @blur="updateRecruit('notice')"
                ></textarea>
              </span>
              <!-- error message -->
              <div v-if="isEditCheckSubmit.notice !== undefined">
                <p class="mgt-008 u-text-varidate-label">{{ isEditCheckSubmit.notice }}</p>
              </div>
              <!-- END error message -->
            </div>
            <div
              v-else
              class="
                __item __item--text-area
                p-recruiting-editable-area p-recruiting-editable-area--block
              "
              @click="changeEditMode('notice')"
            >
              {{ notice == '' ? '-' : notice }}
            </div>
          </td>
        </tr>
        <tr>
          <th>公開用ヒアリングシート</th>
          <td>
            <div class="u-flex-between-center">
              <div class="__box u-flex-shrink-1">
                <!-- modify -->
                <p class="__alert mgb-008 mgr-024" v-if="sheets.survey.status === 'open'">
                  公開対象のヒアリングシートを選択してください
                </p>
                <span
                  class="
                    u-form-select-default
                    u-form-input-select
                    u-form-input-select--large
                    mgb-008
                  "
                  v-if="sheets.survey.status === 'open'"
                >
                  <select v-model="sheets.survey.sheet" @change="changeSurvey()">
                    <option value=""></option>
                    <option :value="survey_format.id" v-for="survey_format in survey_formats" :key="survey_format.id">
                      {{ survey_format.title }}
                    </option>
                  </select>
                </span>
                <!-- END modify -->
                <p
                  v-if="sheets.survey.status === 'close'"
                  class="__alert mgr-024"
                >
                  ヒアリングシートは公開されません、公開する場合には右のチェックを有効化してください
                </p>
                <a
                  v-else
                  class="
                    __edit-link
                    u-button-default
                    u-button-default--small
                    u-button-default--secondary
                  "
                  :style="{ 'pointer-events': sheets.survey.sheet ? 'auto' : 'none' }"
                  :href="sheets.survey.sheet ? generateRoute('survey') : generateRoute('survey', false)"
                  >ヒアリングシートを編集</a
                >
              </div>
              <div class="__box u-flex-shrink-0 u-list-toggle-switch">
                <span
                  class="
                    __toggle
                    js-toggle-switch-effect-recruiting
                    u-flex-right-center
                  "
                >
                  <span
                    class="
                      __status
                      __enabled
                      js-toggle-switch-effect-status
                      mgr-008
                    "
                    :class="sheets.survey.status == 'open' ? '__enabled' : '__disabled'"
                  >
                    {{ sheets.survey.status == 'open' ? '公開する' : '公開しない'}}
                  </span>
                  <span class="u-form-toggle-switch">
                    <input
                      v-model="sheets.survey.status"
                      class="
                        js-toggle-switch-effect-input
                        js-toggle-switch-effect-input--disabled
                      "
                      type="radio"
                      name="survey"
                      value="close"
                      id="off_survey"
                      @change="changeRadioSurvey()"
                    />
                    <label class="__label" for="off_survey"></label>
                    <input
                      v-model="sheets.survey.status"
                      class="
                        js-toggle-switch-effect-input
                        js-toggle-switch-effect-input--enabled
                      "
                      type="radio"
                      name="survey"
                      value="open"
                      id="on_survey"
                      @change="changeRadioSurvey()"
                    />
                    <label class="__label" for="on_survey"></label>
                    <span class="__switch"></span>
                  </span>
                </span>
              </div>
            </div>
            <!-- error message -->
            <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.sheets.survey.sheet.$errors" :key="index">{{ error.$message }}</p>
            <!-- END error message -->
          </td>
        </tr>
        <tr>
          <th>公開用提案シート</th>
          <td>
            <div class="u-flex-between-center">
              <div class="__box u-flex-shrink-1">
                <!-- modify -->
                <p class="__alert mgb-008 mgr-024" v-if="sheets.planning.status === 'open'">
                  公開対象の提案シートを選択してください
                </p>
                <span
                  class="
                    u-form-select-default
                    u-form-input-select
                    u-form-input-select--large
                    mgb-008
                  "
                  v-if="sheets.planning.status === 'open'"
                >
                  <select v-model="sheets.planning.sheet" @change="changePlanning()">
                    <option value=""></option>
                    <option :value="planning_format.id" v-for="planning_format in planning_formats" :key="planning_format.id">
                      {{ planning_format.title }}
                    </option>
                  </select>
                </span>
                <!-- END modify -->
                <p
                  v-if="sheets.planning.status === 'close'"
                  class="__alert mgr-024"
                >
                  提案シートは公開されません、公開する場合には右のチェックを有効化してください
                </p>
                <a
                  v-else
                  class="
                    __open-link
                    u-button-default
                    u-button-default--small
                    u-button-default--secondary
                  "
                  :style="{ 'pointer-events': sheets.planning.sheet ? 'auto' : 'none' }"
                  :href="sheets.planning.sheet ? generateRoute('planning') : generateRoute('planning', false)"
                  >提案シートの公開設定</a
                >
              </div>
              <div class="__box u-flex-shrink-0 u-list-toggle-switch">
                <span
                  class="
                    __toggle
                    js-toggle-switch-effect-recruiting
                    u-flex-right-center
                  "
                >
                  <span
                    class="
                      __status
                      __enabled
                      js-toggle-switch-effect-status
                      mgr-008
                    "
                    :class="sheets.planning.status == 'open' ? '__enabled' : '__disabled'"
                  >
                    {{ sheets.planning.status == 'open' ? '公開する' : '公開しない'}}
                  </span>
                  <span class="u-form-toggle-switch">
                    <input
                      v-model="sheets.planning.status"
                      class="
                        js-toggle-switch-effect-input
                        js-toggle-switch-effect-input--disabled
                      "
                      type="radio"
                      name="planning"
                      value="close"
                      id="off_planning"
                      @change="changeRadioPlanning()"
                    />
                    <label class="__label" for="off_planning"></label>
                    <input
                      v-model="sheets.planning.status"
                      class="
                        js-toggle-switch-effect-input
                        js-toggle-switch-effect-input--enabled
                      "
                      type="radio"
                      name="planning"
                      value="open"
                      id="on_planning"
                      @change="changeRadioPlanning()"
                    />
                    <label class="__label" for="on_planning"></label>
                    <span class="__switch"></span>
                  </span>
                </span>
              </div>
            </div>
            <!-- error message -->
            <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.sheets.planning.sheet.$errors" :key="index">{{ error.$message }}</p>
            <!-- END error message -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="u-flex-center-center mgt-024">
    <span
      class="
        u-button-default u-button-default--large u-button-default--secondary
      "
      @click.prevent="toProjectShow()">
      案件詳細に戻る
    </span>
  </div>
</template>

<script>
import moment from 'moment';
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf, required, maxLength } from '@vuelidate/validators'
// -- END vuelidate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    project: {
      type: Object,
      default: {}
    },
    recruit: {
      type: Object,
      default: {}
    },
    recruiting_category_options: {
      type: Array,
      default: []
    },
    survey_formats: {
      type: Object,
      default: {}
    },
    planning_formats: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      projectName: null,
      category: null,
      budget: null,
      begin: null,
      deadline: null,
      close: null,
      notice: null,
      sheets: {
        survey: {
          sheet: null,
          status: "close",
        },
        planning: {
          sheet: null,
          status: "close",
        },
      },
      isEdit: {
        projectName: false, // recruits.title
        category: false, // recruits.recruiting_category_id
        budget: false, // recruits.estimate
        begin: false, // recruits.project_due_date
        deadline: false, // recruits.project_start_date
        close: false, // recruits.recruiting_end_date
        notice: false, // recruits.detail
        surveySheet: false,
        planningSheet: false,
      },
      isEditCheckSubmit: {},
    };
  },
  validations () {
    const localRules = {
      projectName: {
				required: helpers.withMessage('案件名は必須項目です。', required),
			},
			category: {
				required: helpers.withMessage('募集カテゴリは必須項目です。', required),
			},
			budget: {
				required: helpers.withMessage('予算は必須項目です。', required),
			},
			begin: {
				required: helpers.withMessage('案件開始日は必須項目です。', required),
			},
			deadline: {
				required: helpers.withMessage('納期は必須項目です。', required),
			},
			close: {
				required: helpers.withMessage('募集終了日は必須項目です。', required),
			},
      notice: {
				maxLength: helpers.withMessage(
          ({ $params }) =>`募集要件詳細は、${$params.max}文字以下で入力してください。`, maxLength(255)
        )
			},
      sheets: {
        survey: {
          sheet: {
            requiredIf: helpers.withMessage('公開用ヒアリングシートを選択してください。', requiredIf(this.sheets.survey.status == 'open')),
          },
        },
        planning: {
          sheet: {
            requiredIf: helpers.withMessage('公開用提案シートを選択してください。', requiredIf(this.sheets.planning.status == 'open')),
          },
        },
      }
    }

    return localRules;
  },
  created() {
    this.moment = moment;
    this.fetchData();
  },
  methods: {
    async changeEditMode(key) {
      // -- validate before change isEdit
      let isValid = await this.validateInput(key);
      // -- END validate before change isEdit

      if (isValid) {
        this.isEdit[key] = !this.isEdit[key];
      }
    },
    updateEditMode(key, status) {
      this.isEdit[key] = status;
      this.resetCheckIsEdit(key)
    },
    fetchData() {
      this.projectName = this.recruit.title; // recruit.title
      this.category = this.recruit.recruiting_category_option_id; // recruit.recruiting_category_option_id
      this.budget = this.recruit.estimate; // recruit.estimate
      this.begin = moment(this.recruit.project_start_date).format('YYYY-MM-DD'); // recruit.project_start_date
      this.deadline = moment(this.recruit.project_due_date).format('YYYY-MM-DD'); // recruit.project_due_date
      this.close = moment(this.recruit.recruiting_end_date).format('YYYY-MM-DD'); // recruit.recruiting_end_date
      this.notice = this.recruit.detail; // recruit.detail
      this.sheets.survey.sheet = this.recruit.survey_format_id; // recruit.survey_format_id
      this.sheets.survey.status = this.recruit.survey_format_id != null ? "open" : "close"; // open if survey_format_id not null
      this.sheets.planning.sheet = this.recruit.planning_format_id; // recruit.planning_format_id
      this.sheets.planning.status = this.recruit.planning_format_id != null ? "open" : "close"; // open if planning_format_id not null
    },
    // -- add by backend
    async validateInput(key) {
      // -- key that does not require validation
      let arr_not_validate = ['notice'];
      if (arr_not_validate.includes(key)) {
        return true;
      }
      // -- END key that does not require validation

      // -- array for check key is surveySheet or planningSheet
      let arr_exception_key = ['surveySheet', 'planningSheet'];
      // -- END array for check key is surveySheet or planningSheet

      // -- default value
      let isValid = false;
      // -- END default value

      // -- validate
      if (arr_exception_key.includes(key)) {
        let formatKey =  key.replace('Sheet', '');
        isValid = await this.v$.sheets[formatKey].sheet.$validate();
      }
      else {
        isValid = await this.v$[key].$validate();
      }
      // -- END validate

      return isValid;
    },
    async updateRecruit(key) {
      const isFormCorrect = await this.v$[key].$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- prepare form
        let form = {};
        switch (key) {
          case 'projectName': // recruits.title
            form = {
              title: this.projectName
            };
            break;
          case 'category': // recruits.recruiting_category_id
            form = {
              recruiting_category_id: this.category
            };
            break;
          case 'budget': // recruits.estimate
            form = {
              estimate: this.budget
            };
            break;
          case 'begin': // recruits.project_start_date
            form = {
              project_start_date: this.begin
            };
            break;
          case 'deadline': // recruits.project_due_date
            form = {
              project_due_date: this.deadline
            };
            break;
          case 'close': // recruits.recruiting_end_date
            form = {
              recruiting_end_date: this.close
            };
            break;
          case 'notice': // recruits.detail
            form = {
              detail: this.notice
            };
            break;
        }
        // -- END prepare form
        
        // -- submit
        await axios.put(route('contractor.api.recruit.update', {id: this.recruit.id }), form)
          .then((response) => {
            if (response.status == 200) {
              this.changeEditMode(key);
            }
          })
          .catch((error) => {
            console.log(error)
          });
        // -- END submit

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }

    },
    // -- to make sure all isEdit = false
    checkIsEdit() {
      let result = false;
      const arr_check = [
        'projectName', 'category', 'budget', 'begin', 'deadline', 'close', 'notice', 'surveySheet', 'planningSheet',
      ];
      arr_check.forEach(check => {
        if (this.isEdit[check] == true) {
          result = true;
          this.isEditCheckSubmit[check] = 'Please confirm this first';
        }
      });

      // result == false, reset isEditCheckSubmit
      if (!result) {
        this.isEditCheckSubmit = {};
      }

      return result;
    },
    // -- to make sure all isEdit = false
    // -- reset isEditCheckSubmit
    resetCheckIsEdit(key) {
      delete this.isEditCheckSubmit[key];
    },
    // -- END reset isEditCheckSubmit
    generateRoute(type, isChangePage = true) {
      if (isChangePage) {
        return route('contractor.project.recruit.'+type, { project: this.project.line_number, recruit: this.recruit.line_number });
      }
    },
    async changeSurvey() {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      let form = {
        survey_format_id: this.sheets.survey.status == 'open' ? this.sheets.survey.sheet : null,
      };

      await axios.put(route('contractor.api.recruit.update', { id: this.recruit.id }), form)
        .then((response) => {
          if (response.status == 200) {
            if (!this.sheets.survey.sheet) {
              this.sheets.survey.status = 'close';
            }
          }
        })
        .catch((error) => {
          console.log(error)
        });

      // -- hide loader
      loader.hide();
      // -- END hide loader
    },
    async changePlanning() {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      let form = {
        planning_format_id: this.sheets.planning.status == 'open' ? this.sheets.planning.sheet : null,
      };

      await axios.put(route('contractor.api.recruit.update', { id: this.recruit.id }), form)
        .then((response) => {
          if (response.status == 200) {
            if (!this.sheets.planning.sheet) {
              this.sheets.planning.status = 'close';
            }
          }
        })
        .catch((error) => {
          console.log(error)
        });

      // -- hide loader
      loader.hide();
      // -- END hide loader
    },
    toProjectShow() {
      window.location.href = route('contractor.project.show', { project: this.project.line_number });
    },
    async changeRadioSurvey() {
      if (this.sheets.survey.status == 'close' && this.sheets.survey.sheet != null) {
        await this.changeSurvey();
        this.sheets.survey.sheet = null;
      }
    },
    async changeRadioPlanning() {
      if (this.sheets.planning.status == 'close' && this.sheets.planning.sheet != null) {
        await this.changePlanning();
        this.sheets.planning.sheet = null;
      }
    },
    // -- END add by backend
  },
};
</script>
