<template>
  <form>
    <div class="__block-depth-2">
      <h2 class="__title u-text-catchcopy">パスワード再設定</h2>
      <div class="u-box-content-default __box mgt-064">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term">新しいパスワード</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="password" placeholder="" name="password" v-model="form.newPassword">
              </span>
              <p v-for="(error, index) of v$.form.newPassword.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">新しいパスワード（確認）</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="password" placeholder="確認のため同じパスワードを入力してください" name="password_confirmation" v-model="form.reNewPassword">
              </span>
              <p v-for="(error, index) of v$.form.reNewPassword.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
        </dl>
        <div class="u-flex-center-center mgt-032">
          <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="新しいパスワードを設定" @click.prevent="submit">
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, required, sameAs } from '@vuelidate/validators'
  // -- END vuelidate
  // -- for custom validate
  const regex_password = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9.?\/-]{8,24}$/);
  // -- END for custom validate
  export default {
    setup () {
      return { v$: useVuelidate() }
    },
    props: {
      email: {
        type: String,
        required: true
      },
      token: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        form: {
          newPassword: '',
          reNewPassword: '',
        },
        errors: []
      }
    },
    validations() {
      return {
        form: {
         newPassword: {
            required: helpers.withMessage(`パスワードは必須項目です。`, required), 
            regex_password:  helpers.withMessage(`現在のパスワードが間違っています。`, regex_password),
         },
         reNewPassword: {
          sameAsPassword: helpers.withMessage(
            ({ $params }) => `同じパスワードを入力してください。`, sameAs(this.form.newPassword)
          ),
          regex_password:  helpers.withMessage(`Confirm Password not valid`, regex_password),
         }
        },
      }
    },
    methods: {
      async submit() {
        // -- reset error message
        this.errors = [];
        // -- END reset error message

        const isFormCorrect = await this.v$.$validate()
        if (isFormCorrect) {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          let form = {
            token: this.token,
            email: this.email,
            password: this.form.newPassword,
            password_confirmation: this.form.newPassword
          };
          // store with not api route
          await axios.post(route('contractor.reset.password.submit'), form)
            .then((response) => {
              if (response.status == 200) {
                window.location.href = route('contractor.reset.password.thanks');
              }
            })
            .catch((error) => {
              if (error.response.status == 504) {
                this.errors.push('504 '+error.response.statusText);
              }
              else {
                this.errors.push(error.response.data.message);
              }

              // -- loader hide
              loader.hide();
              // -- END loader hide
            });
        }
      },
    },
  }
</script>
