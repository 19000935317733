<template>
  <form action="" method="post">
    <input class="u-button-default u-button-default--medium u-button-default--secondary mgr-016 __button" type="submit" value="見積もりを複製"
      @click.prevent="duplicateEstimate()">
    <input class="u-button-default u-button-default--medium u-button-default--negative __button js-modal-open-confirm-delete" type="button" value="見積もりを削除">
  </form>
  <!-- modal confirmation -->
  <modal-confirm-delete
    modal-title="確認"
    modal-content="本当に削除しますか？"
    is-confirm-delete="1"
    :delete-id="estimate.id"
    :route-delete="routeDelete"
    :route-redirect="routeRedirect" />
  <!-- END modal confirmation -->
</template> 

<script>
  export default {
    props: ['estimate', 'routeDelete', 'routeRedirect'],
    data() {
      return {
        // totalPageCost: 0
      }
    },
    mounted () {
      // 
    },
    methods: {
      async duplicateEstimate() {
        // -- loader show
        let loader = this.$loading.show({
          canCancel: false,
        });
        // -- END loader show
        
        let form = {
          duplicate_estimate_id: this.estimate.id
        };
        await axios.post(route('contractor.api.estimate.duplicate'), form)
          .then((response) => {
            if (response.status == 201) {
              window.location.href = route('contractor.project.estimate.show', {project: this.estimate.project_id, estimate: response.data.record.line_number});
            }
          })
          .catch((error) => {
            console.log(error)
          });

          // -- hide loader
          loader.hide();
          // -- END hide loader
      },
    },
  }
</script>