<template>
  <div class="l-contents-column-section">
    <div class="__block-depth-2">
      <div class="u-box-content-default">
        <h2 class="u-heading-box-title mgt-024">メンバー権限設定</h2>
        <div class="u-flex-left-center mgt-024">
          <div class="u-form-input-group">
            <label class="u-form-radio-default u-form-input-radio">
              <input type="radio" name="permissions" value="1" v-model="form.is_admin">
              <span class="__text">管理者</span>
            </label>
            <label class="u-form-radio-default u-form-input-radio">
              <input type="radio" name="permissions" value="0" v-model="form.is_admin">
              <span class="__text">一般</span>
            </label>
          </div>
          <p class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.form.is_admin.$errors" :key="index">{{ error.$message }}</p>
        </div>
        <div class="mgt-024">
          <p class="u-text-caption">※一般：一般ユーザー権限の説明文が入ります</p>
          <p class="u-text-caption">※管理者：管理者ユーザー権限の説明文が入ります</p>
        </div>
      </div>
      <div class="u-box-content-default mgt-024">
        <h2 class="u-heading-box-title mgt-024">メンバープロフィール設定</h2>
        <div class="p-user-account-icon mgt-024">
          <div class="u-form-file-group js-local-file-upload u-flex-left-center">
            <figure class="__thumbnail u-flex-shrink-0">
              <img class="__image" :src="form.profile_image_url.url" alt="">
            </figure>
            <button class="__delete" type="button" @click.prevent="resetImage">
              <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
            </button>
            <div class="__input u-flex-shrink-1 u-flex-grow-1 u-flex-column-reverse">
              <div class="__controller">
                <input type="file" id="input_file"
                  ref="file"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  @change="onFileSelected"
                  >
                <label class="u-form-file-upload" for="input_file">
                  <span class="__icon">
                    <img class="__image" src="" alt="">
                  </span>
                  <span class="__text">ファイルを選択</span>
                </label>
                <span class="__file-name">ファイルが選択されていません</span>
              </div>
              <h2 class="u-text-bold mgb-016">プロフィール画像設定</h2>
            </div>
          </div>
        </div>
        <dl class="u-list-input-label-set __list mgt-024">
          <div class="__item">
            <dt class="__term">名前</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="text" placeholder="例）大阪 太郎" name="name" v-model="form.name">
              </span>
              <p v-for="(error, index) of v$.form.name.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">メールアドレス</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="email" placeholder="例）info@example.com" name="email" v-model="form.email">
              </span>
              <p v-for="(error, index) of v$.form.email.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
              <p class="mgt-008 mgr-008 u-text-varidate-label" v-if="erros_submit.email">{{ erros_submit.email }}</p>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">パスワード</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="password" placeholder="ログイン用のパスワードを入力" name="password" v-model="form.password">
              </span>
              <p v-for="(error, index) of v$.form.password.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
          <div class="__item" v-if="contractor_user != ''">
            <dt class="__term">パスワード（確認用）</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="password" placeholder="確認のため再度入力" name="re_password" v-model="form.re_password">
              </span>
              <p v-for="(error, index) of v$.form.re_password.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">役職</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="text" placeholder="例）ウェブディレクター" name="position" v-model="form.position">
                <p v-for="(error, index) of v$.form.position.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
              </span>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">性別</dt>
            <dd class="__body">
              <div class="u-form-input-group">
                <label class="u-form-radio-default u-form-input-radio">
                  <input type="radio" name="gender" value="0" v-model="form.gender">
                  <span class="__text">男性</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input type="radio" name="gender" value="1" v-model="form.gender">
                  <span class="__text">女性</span>
                </label>
                <label class="u-form-radio-default u-form-input-radio">
                  <input type="radio" name="gender" value="2" v-model="form.gender">
                  <span class="__text">未設定</span>
                </label>
              </div>
              <p v-for="(error, index) of v$.form.gender.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </dd>
          </div>
        </dl>
      </div>
      <div class="u-flex-center-center mgt-048">
        <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="更新" @click.prevent="submitSettingMember()">
      </div>
    </div>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, email, required, minLength, maxLength, sameAs } from '@vuelidate/validators'
// -- END vuelidate
// -- for custom validate
const regex_password = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9.?\/-]{8,24}$/);
// -- END for custom validate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    contractor_user: {
      default: ''
    },
    page_type: {
      default: 'create'
    }
  },
  mounted () {
    this.setContractorUser();
  },
  data() {
    return {
      form: {
        is_admin: 1,
        profile_image_url: {},
        name: '',
        email: '',
        password: '',
        re_password: '',
        position: '',
        gender: 2,
      },
      erros_submit: [],
    }
  },
  validations() {
    const localRules = {
      form: {
        is_admin: {
          required: helpers.withMessage('メンバー権限設定は必須項目です。', required),
        },
        // profile_image_url: {}, // for now no need validation on this form
        name: {
          required: helpers.withMessage('名前は必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `名前は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`名前は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        email: {
          required: helpers.withMessage('メールアドレスは必須項目です。', required),
          email: helpers.withMessage('メールアドレスは、有効なメールアドレス形式で指定してください。', email),
          minLength: helpers.withMessage(
            ({ $params }) => `メールアドレスは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`メールアドレスは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        // password: {}, // adjusted according to the type of page
        position: {
          minLength: helpers.withMessage(
            ({ $params }) => `役職は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`役職は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        gender: {
          required: helpers.withMessage('性別は必須項目です。', required),
        },
      }
    }

    // if on edit page
    if (this.contractor_user != '') {
      localRules.form.password = {
        regex_password:  helpers.withMessage('現在のパスワードが間違っています。', regex_password),
      }
      localRules.form.re_password = {
        sameAsPassword: helpers.withMessage(
          ({ $params }) => `同じパスワードを入力してください。`, sameAs(this.form.password)
        ),
        regex_password:  helpers.withMessage('Confirm Password not valid', regex_password),
      };
    }
     // if on create page
    else {
      localRules.form.password = {
        required: helpers.withMessage('パスワードは必須項目です。', required),
        regex_password:  helpers.withMessage('現在のパスワードが間違っています。', regex_password),
      };
    }

    return localRules;
  },
  methods: {
    async submitSettingMember() {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      const isFormCorrect = await this.v$.form.$validate();
      if (isFormCorrect) {
        console.log('lolos')
        const fd = new FormData();
        fd.append('is_admin', this.form.is_admin)
        if (this.form.profile_image_url.file) {
          fd.append('profile_image', this.form.profile_image_url.file)
        }
        if (this.form.profile_image_url.image_status) {
          fd.append('image_status', this.form.profile_image_url.image_status)
        }
        fd.append('page_type', this.page_type)
        fd.append('name', this.form.name)
        fd.append('email', this.form.email)
        if (this.form.password != '') {
          fd.append('password', this.form.password)
        }
        fd.append('position', this.form.position)
        fd.append('gender', this.form.gender)

        if (this.page_type == 'create') {
          await axios.post(route('contractor.api.setting.member.store'), fd)
            .then((response) => {
              if (response.status == 201) {
                window.location.href = route('contractor.settings.member');
              }
            })
            .catch((error) => {
              this.erros_submit = error.response.data;
            });
        }
        else {
          await axios.post(route('contractor.api.setting.member.update', { contractor_user: this.contractor_user.id }), fd)
            .then((response) => {
              if (response.status == 200) {
                window.location.href = route('contractor.settings.member');
              }
            })
            .catch((error) => {
              this.erros_submit = error.response.data;
            });
        }
      }

      // -- hide loader
      loader.hide();
      // -- END hide loader
    },
    onFileSelected(e) {
      if (e && e.target.files[0]) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        const name_file = e.target.files[0].name;
        const image_status = this.page_type == 'edit' ? 'update' : 'new';
        this.form.profile_image_url = { url, file, name_file, image_status };
      }
    },
    setDefaultImage() {
        this.form.profile_image_url.url = '/assets/img/site/thumbnail_default_min.png';
        if (this.page_type == 'edit') {
          this.form.profile_image_url.image_status = 'delete';
        }
    },
    resetImage() {
      this.v$.form.$reset();
      this.form.profile_image_url = {};
      this.setDefaultImage();
    },
    setContractorUser() {
      if (this.page_type == 'edit') {
        this.form.is_admin = this.contractor_user.is_admin;
        this.form.name = this.contractor_user.name;
        this.form.email = this.contractor_user.email;
        this.form.password = '';
        this.form.re_password = '';
        this.form.position = this.contractor_user.position == null ? '' : this.contractor_user.position;
        this.form.gender = this.contractor_user.gender;
        //  set image
        this.form.profile_image_url.url = this.contractor_user.url == '' ? '/assets/img/site/thumbnail_default_min.png' : this.contractor_user.url;
      }
      else {
        this.setDefaultImage();
      }
    },
  },
}
</script>

<style lang="css" scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
