<template>
    <tr>
        <td colspan="2" style="padding: 0;">
            <table class="u-table-flow-option-row">
                <tbody style="border-bottom: none;">
                    <tr>
                        <td class="__item u-align-left">
                            <div class="u-flex-between-center"><span class="u-form-input-default u-form-input-text-box">
                                <input v-model="option.optionName" type="text" placeholder="ページに紐づく追加オプションを入力" name=""></span>
                            </div>
                            <div class="u-align-left">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.option.optionName.$errors" :key="index">{{ error.$message }}</p>
                            </div>
                        </td>
                        <td class="__subtotal u-align-center">
                            <div class="u-flex-between-center">
                                <span class="u-form-input-default __man-hours">
                                    <input v-model="option.manHours" class="u-align-right" type="number" placeholder="工数入力" name="" @blur="emitTotalPageManHours()">
                                </span>
                                <span class="__text">人日</span>
                                <button class="__button __button--delete" type="button" @click="emitDeleteOption({
                                    optionId: option.optionId,
                                    pageId: currentPageId
                                }); emitTotalPageManHours()">
                                    <img class="__image" src="/assets/img/site/icon_delete_small_min.svg" alt="削除">
                                </button>
                            </div>
                            <div class="u-align-left">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.option.manHours.$errors" :key="index">{{ error.$message }}</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
    // -- vuelidate
    import useVuelidate from "@vuelidate/core";
    import { helpers, required } from "@vuelidate/validators";
    // -- END vuelidate
    // -- for custom validate
    const decimal42 = helpers.regex(/^-?\d{1,4}(\.\d{1,2})?$/); // for decimal (4,2)
    // -- END for custom validate
    export default {
        setup(props) {
            return { v$: useVuelidate({ $scope: props.validationScope }) };
        },
        props: {
            currentOption: {
                type: () => {},
                default: {}
            },
            currentPageId: {
                type: Number,
                default: 0
            },
            validationScope: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                option: {}
            }
        },
        validations() {
            return {
                option: {
                    optionName: {
                        required: helpers.withMessage("ページ名は必須項目です。", required),
                    },
                    manHours: {
                        required: helpers.withMessage("設定工数は必須項目です。", required),
                        decimal42: helpers.withMessage("設定工数 は整数4桁, 少数2桁で入力してください。", decimal42),
                    }
                }
            }
        },
        created() {
            this.option = this.currentOption;
        },
        methods: {
            emitTotalPageManHours() {
                this.$emit('update-manhours', null);
            },
            emitDeleteOption(idObject) {
                this.$emit('delete-option', idObject);
            }
        }
    }
</script>
