<template>
  <main class="l-main p-page p-news-archive-page p-app-page p-service-page">
    <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" href="#">お知らせ</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <article class="p-page-title">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center">
                <h1 class="u-heading-page-title __title">お知らせ</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 __block-depth-2--result-list-block">
                <div class="__block-depth-3">
                  <div class="u-table-wrapper u-table-wrapper--scroll __table">
                    <div class="u-table-list-item">
                      <div class="__table-body">
                        <!-- loop start-->
                        <a class="__row __link" :href="detailNews(data.id)" v-for="(data, index) in lists.data" :key="index">
                          <span class="__cell __cell--data">
                            <span class="__date">
                              {{ moment(data.created_at).format('YYYY/MM/DD') }}
                            </span>
                          </span>
                          <span class="__cell __cell--data">
                            <p class="u-text-bold mgt-008">
                              {{ data.title }}
                            </p>
                          </span>
                        </a>
                        <!-- loop end-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="__block-depth-2 __block-depth-2--result-pager-block u-flex-right-center">
                <div class="c-pagenation-projects">
                  <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>

                  <!-- check previousPageUrl is null -->
                  <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
                  <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
                  <!-- END check previousPageUrl null -->

                  <!-- check nextPageUrl is null -->
                  <span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
                  <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
                  <!-- END check nextPageUrl is null -->

                </div>
              </div>

              <div class="u-flex-center-center mgt-064">
                <a class="u-button-default u-button-default--medium u-button-default--negative" :href="backToDashboard()">
                  ダッシュボードに戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <!-- section 02 end-->
  </main>
  <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" href="#">お知らせ</a></li>
                </ul>
            </div>
        </div>
  </nav>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
  props: ['news', 'filter'],
  data() {
    return {
      lists: {},
    }
  },
  async created(){
    this.moment = moment;
		this.initData();
  },
  methods: {
    initData() {
      this.lists = this.news;
    },
    async loadData(page = 1){
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			await axios.get(route('contractor.new.index', {page: page}))
				.then((res) => {
					this.lists = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
		backToDashboard() {
			return route('contractor.dashboard');
		},
    detailNews(id) {
      return route('contractor.new.show', { new: id});
    },
    changePage(data) {
		let routeTo = route(data);
        return routeTo;
	},
  },
}
</script>

<style scoped>
</style>
