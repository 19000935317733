<template>
  <main class="l-main p-page p-project-create-page p-app-page p-service-page">
    <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
                    <li class="__list"><a class="__link" href="#">新規案件登録</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <article class="p-page-title">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center">
                <h1 class="u-heading-page-title __title">新規案件登録</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="u-box-content-default">
                <form action="" method="post">
                  <div class="__block-depth-2 __block-depth-2--name-block">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">案件名<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="例）株式会社Pyattoコーポレートサイト制作" v-model="form.name" data-cy="name">
                          </span>
                          <!-- error message name -->
                          <!-- <p v-if="form_errors.name" class="mgt-008 u-text-varidate-label">案件名を入力してください</p> -->
                          <p v-for="(error, index) of v$.form.name.$errors" :key="index" class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          <!-- END error message name -->
                          <p class="__caption">この案件に設定する顧客情報を設定してください。登録済みの顧客情報を選択していただくか、新しく顧客情報を登録して設定してください。なお、顧客情報は顧客管理ページから編集可能です。</p>
                        </dd>
                        <dd class="__body mgt-016">
                          <div class="u-form-input-group">
                            <label class="u-form-radio-default u-form-input-radio">
                              <input type="radio" name="project_create_type" v-model="form.is_new_customer" @change="isNewCustomerChange()" value="1" data-cy="is_new_customer_true">
                              <span class="__text">新規取引先として顧客を登録する</span>
                            </label>
                            <label class="u-form-radio-default u-form-input-radio">
                              <input type="radio" name="project_create_type" v-model="form.is_new_customer" @change="isNewCustomerChange()" value="0" data-cy="is_new_customer_false">
                              <span class="__text">既存取引先から顧客を登録する</span>
                            </label>
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div v-if="form.is_new_customer == 0" class="__block-depth-2 __block-depth-2--search-block">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">登録済みの顧客名を検索</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <Select2 v-model="form.registered_customer" 
                              :options="map_list_customer"
                              style="border: none;"
                              :disabled="form.is_new_customer == 1 ? true : false"
                              :settings="{ width: '100%' }"
                              @select="selectListCustomer($event)"
                              name="registered_customer"
                              data-cy="registered_customer" />
                          </span>
                          <p v-if="form_errors.registered_customer" class="mgt-008 u-text-varidate-label">登録済みの顧客が選択されていません</p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--client-block">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">顧客名<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="顧客名を入力" name="customer_name" v-model="form.customer.name" data-cy="customer_name">
                          </span>
                          <!-- error message name -->
                          <!-- <p v-if="form_errors.customer_name" class="mgt-008 u-text-varidate-label">顧客名を入力してください</p> -->
                          <p v-for="(error, index) of v$.form.customer.name.$errors" :key="index" class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                          <!-- END error message name -->
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--mail-tel-block">
                    <div class="__block-depth-3 u-flex-between-center">
                      <div class="__block-depth-4 __block-depth-4--mail-block">
                        <dl class="u-list-input-label-set __list">
                          <div class="__item">
                            <dt class="__term">メールアドレス</dt>
                            <dd class="__body">
                              <span class="u-form-input-default u-form-input-text-box">
                                <input type="email" placeholder="例）info@example.com" name="customer_email" v-model="form.customer.email" data-cy="customer_email">
                              </span>
                              <!-- Error Message -->
                              <p v-for="(error, index) of v$.form.customer.email.$errors" :key="index" class="mgt-008 u-text-varidate-label">
                                {{ error.$message }}
                              </p>
                              <!-- END Error Message -->
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div class="__block-depth-4 __block-depth-4--tell-block">
                        <dl class="u-list-input-label-set __list">
                          <div class="__item">
                            <dt class="__term">電話番号</dt>
                            <dd class="__body">
                              <span class="u-form-input-default u-form-input-text-box">
                                <input type="tel" placeholder="例）080-1234-5678" name="customer_phone" v-model="form.customer.phone" data-cy="customer_phone">
                              </span>
                              <!-- Error Message -->
                              <p v-for="(error, index) of v$.form.customer.phone.$errors" :key="index" class="mgt-008 u-text-varidate-label">
                                {{ error.$message }}
                              </p>
                              <!-- END Error Message -->
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--address-group-block">
                    <div class="__block-depth-3 u-flex-between-center">
                      <div class="__block-depth-4 __block-depth-4--top-block">
                        <div class="__block-depth-5 u-flex-between-center">
                          <div class="__block-depth-6 __block-depth-6--zipcode-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">郵便番号</dt>
                                <dd class="__body">
                                  <span class="u-form-input-default u-form-input-text-box">
                                    <input type="text" placeholder="例）123-4567" name="customer_postcode" v-model="form.customer.postcode" data-cy="customer_postcode">
                                  </span>
                                  <!-- Error Message -->
                                  <p v-for="(error, index) of v$.form.customer.postcode.$errors" :key="index" class="mgt-008 u-text-varidate-label">
                                    {{ error.$message }}
                                  </p>
                                  <!-- END Error Message -->
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div class="__block-depth-6 __block-depth-6--area-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">都道府県</dt>
                                <dd class="__body"> <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                                    <select v-model="form.customer.prefecture" name="customer_prefecture" data-cy="customer_prefecture">
                                      <option value="北海道">北海道</option>
                                      <option value="青森県">青森県</option>
                                      <option value="秋田県">秋田県</option>
                                      <option value="岩手県">岩手県</option>
                                      <option value="山形県">山形県</option>
                                      <option value="宮城県">宮城県</option>
                                      <option value="福島県">福島県</option>
                                      <option value="新潟県">新潟県</option>
                                      <option value="群馬県">群馬県</option>
                                      <option value="栃木県">栃木県</option>
                                      <option value="茨城県">茨城県</option>
                                      <option value="千葉県">千葉県</option>
                                      <option value="埼玉県">埼玉県</option>
                                      <option value="東京都">東京都</option>
                                      <option value="神奈川県">神奈川県</option>
                                      <option value="山梨県">山梨県</option>
                                      <option value="長野県">長野県</option>
                                      <option value="静岡県">静岡県</option>
                                      <option value="愛知県">愛知県</option>
                                      <option value="岐阜県">岐阜県</option>
                                      <option value="三重県">三重県</option>
                                      <option value="富山県">富山県</option>
                                      <option value="石川県">石川県</option>
                                      <option value="福井県">福井県</option>
                                      <option value="滋賀県">滋賀県</option>
                                      <option value="京都府">京都府</option>
                                      <option value="大阪府">大阪府</option>
                                      <option value="奈良県">奈良県</option>
                                      <option value="和歌山県">和歌山県</option>
                                      <option value="兵庫県">兵庫県</option>
                                      <option value="岡山県">岡山県</option>
                                      <option value="鳥取県">鳥取県</option>
                                      <option value="島根県">島根県</option>
                                      <option value="広島県">広島県</option>
                                      <option value="山口県">山口県</option>
                                      <option value="香川県">香川県</option>
                                      <option value="徳島県">徳島県</option>
                                      <option value="高知県">高知県</option>
                                      <option value="愛媛県">愛媛県</option>
                                      <option value="福岡県">福岡県</option>
                                      <option value="佐賀県">佐賀県</option>
                                      <option value="長崎県">長崎県</option>
                                      <option value="熊本県">熊本県</option>
                                      <option value="大分県">大分県</option>
                                      <option value="宮崎県">宮崎県</option>
                                      <option value="鹿児島県">鹿児島県</option>
                                      <option value="沖縄県">沖縄県</option>
                                    </select></span></dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div class="__block-depth-4 __block-depth-4--bottom-block">
                        <dl class="u-list-input-label-set __list">
                          <div class="__item">
                            <dt class="__term">市区町村・番地</dt>
                            <dd class="__body">
                              <span class="u-form-input-default u-form-input-text-box">
                                <input type="text" placeholder="例）○○市○○町1-2-3" name="customer_city_subarea" v-model="form.customer.city_subarea" data-cy="customer_city_subarea">
                              </span>
                              <!-- Error Message -->
                              <p v-for="(error, index) of v$.form.customer.city_subarea.$errors" :key="index" class="mgt-008 u-text-varidate-label">
                                {{ error.$message }}
                              </p>
                              <!-- END Error Message -->
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--address-other-block">
                    <div class="__block-depth-3 u-flex-between-center">
                      <div class="__block-depth-4 __block-depth-4--other-block">
                        <dl class="u-list-input-label-set __list">
                          <div class="__item">
                            <dt class="__term">建物名・部屋番号など</dt>
                            <dd class="__body">
                              <span class="u-form-input-default u-form-input-text-box">
                                <input type="text" placeholder="例）○○ビル2階○○号" name="customer_rest_of_address" v-model="form.customer.rest_of_address" data-cy="customer_rest_of_address">
                              </span>
                              <!-- Error Message -->
                              <p v-for="(error, index) of v$.form.customer.rest_of_address.$errors" :key="index" class="mgt-008 u-text-varidate-label">
                                {{ error.$message }}
                              </p>
                              <!-- END Error Message -->
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div class="__block-depth-4 __block-depth-4--manager-block">
                        <dl class="u-list-input-label-set __list">
                          <div class="__item">
                            <dt class="__term">担当者名</dt>
                            <dd class="__body">
                              <span class="u-form-input-default u-form-input-text-box">
                                <input type="text" placeholder="例）担当 太郎" name="customer_compellation" v-model="form.customer.compellation" data-cy="customer_compellation">
                              </span>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--submit-block">
                    <div class="__block-depth-3 u-flex-center-center">
                      <input class="js-prevent-event-click u-button-default u-button-default--large u-button-default--primary" type="submit" value="登録" @click.prevent="checkDataCustomer()">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <!-- section 01 end -->

    <!-- modal -->
    <div class="c-modal js-modal" :class="is_modal_show ? 'js-modal--shown' : ''">
      <div class="__overlay"></div>
      <div class="__panel __panel--medium p-modal-project-create">
        <div class="u-box-modal-defalut">
          <h3 class="__title">既存顧客情報の更新確認</h3>
          <div class="__content">
            <form action="" method="post">
              <p class="__text">既存顧客データの内容が下記の通り変更されている項目が見つかりました。今回の案件登録で設定した顧客情報の内容で更新しますか？</p>
              <div class="u-table-wrapper __table">
                <table class="u-table-list-item u-table-list-item--narrow">
                  <thead>
                    <tr>
                      <th class="u-align-left">項目</th>
                      <th class="u-align-left">現在の登録内容</th>
                      <th class="u-align-left">更新後の登録内容</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="u-align-left u-text-bold">顧客名</th>
                      <td class="customer_name_data">{{ old_data_customer.name }}</td>
                      <td class="customer_name_form">{{ form.customer.name }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">メールアドレス</th>
                      <td class="customer_email_data">{{ old_data_customer.email }}</td>
                      <td class="customer_email_form">{{ form.customer.email }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">電話番号</th>
                      <td class="customer_phone_data">{{ old_data_customer.phone }}</td>
                      <td class="customer_phone_form">{{ form.customer.phone }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">郵便番号</th>
                      <td class="customer_postcode_data">{{ old_data_customer.postcode }}</td>
                      <td class="customer_postcode_form">{{ form.customer.postcode }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">都道府県</th>
                      <td class="customer_prefecture_data">{{ old_data_customer.prefecture }}</td>
                      <td class="customer_prefecture_form">{{ form.customer.prefecture }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">市区町村・番地</th>
                      <td class="customer_city_subarea_data">{{ old_data_customer.city_subarea }}</td>
                      <td class="customer_city_subarea_form">{{ form.customer.city_subarea }}</td>
                    </tr>
                    <tr>
                      <th class="u-align-left u-text-bold">建物名・部屋番号など</th>
                      <td class="customer_rest_of_address_data">{{ old_data_customer.rest_of_address }}</td>
                      <td class="customer_rest_of_address_form">{{ form.customer.rest_of_address }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="__button-wrap u-flex-center-center">
                <button class="js-modal-close u-button-default u-button-default--large u-button-default--negative __button" type="button" @click.prevent="submitEvent(0)">更新しない</button>
                <input id="modal_input_submit" class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="上記内容で更新" @click.prevent="submitEvent(1)">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- modal end -->
  </main>
    <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
                    <li class="__list"><a class="__link" href="#">新規案件登録</a></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, required, email, minLength, maxLength } from '@vuelidate/validators'
// -- END vuelidate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    //
  },
  mounted () {
    this.setMapListCustomer();
    this.resetFormError();
  },
  props: {
    list_customer: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      map_list_customer: [],
      form_errors: '',
      form: {
        is_new_customer: '1',
        registered_customer: '',
        name: '', // project.name
        status_id: 1,
        customer: {
          is_update: '0',
          id: '',
          name: '',
          email: '',
          phone: '',
          postcode: '',
          prefecture: '',
          city_subarea: '',
          rest_of_address: '',
          compellation: '',
        }
      },
      new_data_customer: '',
      old_data_customer: {
          id: '',
          name: '',
          email: '',
          phone: '',
          postcode: '',
          prefecture: '',
          city_subarea: '',
          rest_of_address: '',
        },
      is_modal_show: false
    }
  },
  validations() {
    return {
      form: {
        name : {
          required: helpers.withMessage('案件名を入力してください', required),
          maxLength: helpers.withMessage(
            ({ $params }) =>`案件名は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        customer: {
          name: {
            required: helpers.withMessage('顧客名を入力してください', required),
            minLength: helpers.withMessage(
              ({ $params }) => `顧客名は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`顧客名は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          email: {
            email: helpers.withMessage('メールアドレスは、有効なメールアドレス形式で指定してください。', email),
          },
          phone: {
            minLength: helpers.withMessage(
              ({ $params }) => `電話番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`電話番号は、${$params.max}文字以下で入力してください。`, maxLength(20)
            )
          },
          postcode: {
            minLength: helpers.withMessage(
              ({ $params }) => `電話番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`電話番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
            )
          },
          city_subarea: {
            minLength: helpers.withMessage(
              ({ $params }) => `市区町村・番地は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`市区町村・番地は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          rest_of_address: {
            minLength: helpers.withMessage(
              ({ $params }) => `建物名・部屋番号などは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`建物名・部屋番号などは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          }
        }
      },
    }
  },
  methods: {
    setMapListCustomer() {
      if (this.list_customer.length > 0) {
        this.list_customer.forEach(val => {
          this.map_list_customer.push({
            id: val.id,
            text: val.name
          })
        });
      }
    },
    async submitEvent(is_update = 0) {
      // -- loader show
			let loader = this.$loading.show();
			// -- END loader show
      this.form.customer.is_update = is_update;
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        // store with not api route
        await axios.post(route('contractor.api.project.store'), this.form)
          .then((response) => {
            if (response.status == 201) {
              window.location.href = route('contractor.project.index');
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }

      // -- hide modal
      this.is_modal_show = false;
      // -- END hide modal

      // -- loader hide
			loader.hide()
			// -- END loader hide

    },
    selectListCustomer({id, text}){
      let seleted_customer = this.list_customer.find(customer => customer.id == id);
      this.form.customer = {
        id: seleted_customer.id,
        name: text,
        email: seleted_customer.email,
        phone: seleted_customer.phone,
        postcode: seleted_customer.postcode,
        prefecture: seleted_customer.prefecture,
        city_subarea: seleted_customer.city_subarea,
        rest_of_address: seleted_customer.rest_of_address,
        compellation: seleted_customer.compellation,
      }
    },
    isNewCustomerChange() {
      if (this.form.is_new_customer == 1) {
        this.resetFormCustomer()
      }
    },
    resetFormCustomer() {
      this.form.registered_customer = ''
      this.form.customer = {
        name: '',
        email: '',
        phone: '',
        postcode: '',
        prefecture: '',
        city_subarea: '',
        rest_of_address: '',
        compellation: '',
      }
    },
    resetFormError() {
      this.form_errors = {
        name: false,
        customer_name: false,
        registered_customer: false
      }
    },
    async checkDataCustomer() {
      this.resetFormError();
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        if (this.form.is_new_customer == 0) {
          // get data from form
          let new_data = this.form.customer;
          // get data from list_customer
          let get_data_customer = this.list_customer.find(customer => customer.id == this.form.registered_customer);
          let old_data = {
            id: get_data_customer.id,
            name: get_data_customer.name,
            email: get_data_customer.email,
            phone: get_data_customer.phone,
            postcode: get_data_customer.postcode,
            prefecture: get_data_customer.prefecture,
            city_subarea: get_data_customer.city_subarea,
            rest_of_address: get_data_customer.rest_of_address,
            compellation: get_data_customer.compellation,
          }
          let is_equal = this.shallowEqual(new_data, old_data);
          if (!is_equal) {
            this.setCompareCustomer(new_data, old_data);
            this.is_modal_show = true
          }
          else {
            this.submitEvent();
          }
        }
        else {
          this.submitEvent();
        }
      }
    },
    shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }
      return true;
    },
    setCompareCustomer(data1, data2) {
      this.old_data_customer = data2;
    },
    changePage(data) {
        let routeTo = route(data);
        return routeTo;
    },
  },
}
</script>

<style scoped>
  /* for remove border on select2 */
  .select2-container--default .select2-selection--single {
	  border: none;
  }
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
