'use strict';

export function ToggleSwitchEffect({elements, stringEnabled, stringDisabled, isConfirm}) {
    const switches = document.querySelectorAll(elements);
    if(switches) {
        switches.forEach((element) => {
            const off = element.querySelector('.js-toggle-switch-effect-input--disabled');
            const on = element.querySelector('.js-toggle-switch-effect-input--enabled');
            const status = element.querySelector('.js-toggle-switch-effect-status');
            off.addEventListener('change', (event) => {
                if(isConfirm) {
                    event.preventDefault();
                    if(window.confirm('登録済みの項目を無効にすると登録されている内容もリセットされます。無効にしますか？'))　{
                        if(event.target.checked) {
                            status.classList.remove('__enabled')
                            status.classList.add('__disabled');
                            status.innerText = stringDisabled;
                        }
                    } else {
                        window.alert('変更がキャンセルされました');
                        event.target.nextElementSibling.nextElementSibling.checked = true;
                    }
                } else {
                    if(event.target.checked) {
                        status.classList.remove('__enabled')
                        status.classList.add('__disabled');
                        status.innerText = stringDisabled;
                    }
                }
            });
            on.addEventListener('change', (event) => {
                if(event.target.checked) {
                    status.classList.remove('__disabled')
                    status.classList.add('__enabled');
                    status.innerText = stringEnabled;
                }
            })
        });
    }
}
