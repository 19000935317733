<template>
  <div class="u-box-content-default">
    <form>
      <div class="__block-depth-2 __block-depth-2--name-category-block">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term">案件名</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input
                  v-model="projectName"
                  type="text"
                  placeholder="例）株式会社Pyattoコーポレートサイト制作"
                  name=""
                />
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.projectName.$errors" :key="index">{{ error.$message }}</p>
              <!-- END error message -->
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">募集カテゴリ</dt>
            <dd class="__body">
              <div class="u-form-input-group">
                <label class="u-form-radio-default u-form-input-radio" v-for="(recruiting_category_option, index) of recruiting_category_options" :key="index">
                  <input
                    v-model="category"
                    type="radio"
                    name="recruiting_create_type"
                    :value="index"
                  />
                  <span class="__text">{{ recruiting_category_option }}</span>
                </label>
              </div>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.category.$errors" :key="index">{{ error.$message }}</p>
              <!-- END error message -->
            </dd>
          </div>
        </dl>
      </div>
      <div
        class="
          __block-depth-2 __block-depth-2--budget-begin-block
          u-flex-between-center
        "
      >
        <div class="__block-depth-3 __block-depth-3--budget-block">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">予算</dt>
              <dd class="__body">
                <span
                  class="
                    u-form-select-default
                    u-form-input-select
                    u-form-input-select--large
                  "
                >
                  <select v-model="budget">
                    <option value="〜20万円">〜20万円</option>
                    <option value="21〜50万円">21〜50万円</option>
                    <option value="51〜100万円">51〜100万円</option>
                    <option value="101〜300万円">101〜300万円</option>
                    <option value="301万円〜">301万円〜</option>
                  </select>
                </span>
                <!-- error message -->
                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.budget.$errors" :key="index">{{ error.$message }}</p>
                <!-- END error message -->
              </dd>
            </div>
          </dl>
        </div>
        <div class="__block-depth-3 __block-depth-3--begin-block">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">案件開始日</dt>
              <dd class="__body">
                <span
                  class="
                    u-form-input-default u-form-input-date
                    u-form-input-default--add-icon
                    js-input-icon-effect
                  "
                >
                  <input
                    v-model="begin"
                    type="date"
                    placeholder="案件開始日を入力"
                    name=""
                  />
                </span>
                <!-- error message -->
                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.begin.$errors" :key="index">{{ error.$message }}</p>
                <!-- END error message -->
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div
        class="
          __block-depth-2 __block-depth-2--deadline-close-block
          u-flex-between-center
        "
      >
        <div class="__block-depth-3 __block-depth-3--deadline-block">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">納期</dt>
              <dd class="__body">
                <span
                  class="
                    u-form-input-default u-form-input-date
                    u-form-input-default--add-icon
                    js-input-icon-effect
                  "
                >
                  <input
                    v-model="deadline"
                    type="date"
                    placeholder="納期を入力"
                    name=""
                  />
                </span>
                <!-- error message -->
                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.deadline.$errors" :key="index">{{ error.$message }}</p>
                <!-- END error message -->
              </dd>
            </div>
          </dl>
        </div>
        <div class="__block-depth-3 __block-depth-3--close-block">
          <dl class="u-list-input-label-set __list">
            <div class="__item">
              <dt class="__term">募集終了日</dt>
              <dd class="__body">
                <span
                  class="
                    u-form-input-default u-form-input-date
                    u-form-input-default--add-icon
                    js-input-icon-effect
                  "
                >
                  <input
                    v-model="close"
                    type="date"
                    placeholder="募集終了日を入力"
                    name=""
                  />
                </span>
                <!-- error message -->
                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.close.$errors" :key="index">{{ error.$message }}</p>
                <!-- END error message -->
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="__block-depth-2 __block-depth-2--notice-block">
        <dl class="u-list-input-label-set __list">
          <div class="__item">
            <dt class="__term">募集要件詳細</dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-area">
                <textarea
                  v-model="notice"
                  placeholder="その他募集要件に関する内容を入力"
                  name=""
                ></textarea>
              </span>
              <!-- error message -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.notice.$errors" :key="index">{{ error.$message }}</p>
              <!-- END error message -->
            </dd>
          </div>
        </dl>
      </div>
      <div class="__block-depth-2 __block-depth-2--sheet-block">
        <p class="__caption">
          作成済みのヒアリングシート、並びに提案シートの情報を募集要項に公開する場合には、シートの公開を有効化して、対象のシートを選択してください
        </p>
        <dl class="u-list-input-label-set __list mgt-024">
          <div class="__item">
            <dt class="__term">公開用ヒアリングシート</dt>
            <dd class="__body">
              <div class="u-flex-between-center">
                <div class="__box u-flex-shrink-1">
                  <span
                    v-if="sheets.survey.status === 'open'"
                    class="
                      u-form-select-default
                      u-form-input-select
                      u-form-input-select--medium
                    "
                  >
                    <select v-model="sheets.survey.sheet">
                      <option v-for="(survey_format, index) of survey_formats" :key="index" :value="survey_format.id">{{ survey_format.title }}</option>
                    </select>
                  </span>
                  <p
                    v-else-if="sheets.survey.status === 'close'"
                    class="__alert"
                  >
                    公開する場合には有効化して対象のヒアリングシートを選択してください
                  </p>
                  <!-- error message -->
                  <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.sheets.survey.sheet.$errors" :key="index">{{ error.$message }}</p>
                  <!-- END error message -->
                </div>
                <div class="__box u-flex-shrink-0 u-list-toggle-switch">
                  <span
                    class="
                      __toggle
                      js-toggle-switch-effect-recruiting
                      u-flex-right-center
                    "
                  >
                    <span
                      class="
                        __status
                        js-toggle-switch-effect-status
                        mgr-016
                      "
                      :class="[sheets.survey.status === 'open' ? '__enabled' : '__disabled']"
                      >{{ sheets.survey.status === 'open' ? '公開する' : '公開しない' }}</span
                    >
                    <span class="u-form-toggle-switch">
                      <input
                        v-model="sheets.survey.status"
                        class="
                          js-toggle-switch-effect-input
                          js-toggle-switch-effect-input--disabled
                        "
                        type="radio"
                        name="survey"
                        value="close"
                        id="off_survey"
                        @change="toggleStatus('survey', 'close')"
                      />
                      <label class="__label" for="off_survey"></label>
                      <input
                        v-model="sheets.survey.status"
                        class="
                          js-toggle-switch-effect-input
                          js-toggle-switch-effect-input--enabled
                        "
                        type="radio"
                        name="survey"
                        value="open"
                        id="on_survey"
                        checked
                        @change="toggleStatus('survey', 'open')"
                      />
                      <label class="__label" for="on_survey"></label>
                      <span class="__switch"></span>
                    </span>
                  </span>
                </div>
              </div>
            </dd>
          </div>
          <div class="__item">
            <dt class="__term">公開用提案シート</dt>
            <dd class="__body">
              <div class="u-flex-between-center">
                <div class="__box u-flex-shrink-1">
                  <span
                    v-if="sheets.planning.status === 'open'"
                    class="
                      u-form-select-default
                      u-form-input-select
                      u-form-input-select--medium
                    "
                  >
                    <select v-model="sheets.planning.sheet">
                      <option v-for="(planning_format, index) of planning_formats" :key="index" :value="planning_format.id">{{ planning_format.title }}</option>
                    </select>
                  </span>
                  <p v-if="sheets.planning.status === 'close'" class="__alert">
                    公開する場合には有効化して対象の提案シートを選択してください
                  </p>
                  <!-- error message -->
                  <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.sheets.planning.sheet.$errors" :key="index">{{ error.$message }}</p>
                  <!-- END error message -->
                </div>
                <div
                  class="
                    __box
                    u-flex-shrink-0 u-flex-grow-1 u-list-toggle-switch
                  "
                >
                  <span
                    class="
                      __toggle
                      js-toggle-switch-effect-recruiting
                      u-flex-right-center
                    "
                  >
                    <span
                      class="
                        __status
                        js-toggle-switch-effect-status
                        mgr-016
                      "
                      :class="[sheets.planning.status === 'open' ? '__enabled' : '__disabled']"
                      >{{ sheets.planning.status === 'open' ? '公開する' : '公開しない' }}</span
                    >
                    <span class="u-form-toggle-switch">
                      <input
                        v-model="sheets.planning.status"
                        class="
                          js-toggle-switch-effect-input
                          js-toggle-switch-effect-input--disabled
                        "
                        type="radio"
                        name="planning"
                        value="close"
                        id="off_planning"
                        @change="toggleStatus('planning', 'close')"
                      />
                      <label class="__label" for="off_planning"></label>
                      <input
                        v-model="sheets.planning.status"
                        class="
                          js-toggle-switch-effect-input
                          js-toggle-switch-effect-input--enabled
                        "
                        type="radio"
                        name="planning"
                        value="open"
                        id="on_planning"
                        checked
                        @change="toggleStatus('planning', 'open')"
                      />
                      <label class="__label" for="on_planning"></label>
                      <span class="__switch"></span>
                    </span>
                  </span>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div class="__block-depth-2 __block-depth-2--submit-block">
        <div class="__block-depth-3 u-flex-center-center">
          <input
            class="
              js-modal-open js-prevent-event-click
              u-button-default u-button-default--large u-button-default--primary
            "
            type="submit"
            value="登録"
			@click.prevent="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf, required } from '@vuelidate/validators'
// -- END vuelidate
export default {
	setup () {
    return { v$: useVuelidate() }
  },
	props: {
    project: {
			type: Object,
			default: {}
		},
		survey_formats: {
			type: Array,
			default: []
		},
		planning_formats: {
			type: Array,
			default: []
		},
		recruiting_category_options: {
			type: Array,
			default: []
		}
	},
  data() {
    return {
      projectName: this.project.name,
      category: '1',
      budget: "〜20万円",
      begin: null,
      deadline: null,
      close: null,
      notice: null,
      sheets: {
        survey: {
          sheet: null,
          status: "open",
        },
        planning: {
          sheet: null,
          status: "open",
        },
      },
    };
  },
  validations() {
    const localRules = {
      projectName: {
				required: helpers.withMessage('案件名は必須項目です。', required),
			},
			category: {
				required: helpers.withMessage('募集カテゴリは必須項目です。', required),
			},
			budget: {
				required: helpers.withMessage('予算は必須項目です。', required),
			},
			begin: {
				required: helpers.withMessage('案件開始日は必須項目です。', required),
			},
			deadline: {
				required: helpers.withMessage('納期は必須項目です。', required),
			},
			close: {
				required: helpers.withMessage('募集終了日は必須項目です。', required),
			},
			notice: {
				required: helpers.withMessage('募集要件詳細は必須項目です。', required),
			},
      sheets: {
        survey: {
          sheet: {
            requiredIf: helpers.withMessage('公開用ヒアリングシートを選択してください。', requiredIf(this.sheets.survey.status == 'open')),
          },
        },
        planning: {
          sheet: {
            requiredIf: helpers.withMessage('公開用提案シートを選択してください。', requiredIf(this.sheets.planning.status == 'open')),
          },
        },
      }
    }

    return localRules;
  },
  methods: {
    toggleStatus(sheetType, status) {
      if (sheetType === "survey") {
        this.sheets.survey.status = status === "open" ? "open" : "close";
      } else if (sheetType === "planning") {
        this.sheets.planning.status = status === "open" ? "open" : "close";
      }
    },
		async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
				// -- loader show
				let loader = this.$loading.show();
				// -- END loader show
				let form = {
          project_id: this.project.id,
					title: this.projectName,
					recruiting_category_option_id: this.category,
					estimate: this.budget,
					project_due_date: this.deadline,
					project_start_date: this.begin,
					recruiting_end_date: this.close,
					detail: this.notice,
					survey_format_id: this.sheets.survey.status == 'open' ? this.sheets.survey.sheet : null,
					planning_format_id: this.sheets.planning.status == 'open' ? this.sheets.planning.sheet : null,
				};
        await axios.post(route('contractor.project.recruit.store'), form)
          .then((response) => {
            if (response.status == 201) {
              // -- need confirm
              if (this.sheets.survey.status == 'open') {
                window.location.href = route('contractor.project.recruit.survey', { project: this.project.line_number, recruit: response.data.record.line_number });
              }
              else if(this.sheets.planning.status == 'open') {
                window.location.href = route('contractor.project.recruit.planning', { project: this.project.line_number, recruit: response.data.record.line_number });
              }
              else {
                window.location.href = route('contractor.project.recruit.show', { project: this.project.line_number, recruit: response.data.record.line_number });
              }
              // -- END need confirm
            }
          })
          .catch((error) => {
            console.log(error)
          });

				// -- hide loader
      	loader.hide();
      	// -- END hide loader
      }
    },
  },
};
</script>
