<template>
  <div class="l-container">
    <div class="l-inner">
      <div class="__block-depth-1">
        <form>
          <div class="__block-depth-2">
            <h2 class="__title u-text-catchcopy">登録アカウント情報入力</h2>
            <div class="u-box-content-default __box mgt-064">
              <dl class="u-list-input-label-set __list">
                <div class="__item">
                  <dt class="__term">会社名・組織名</dt>
                  <dd class="__body">
                    <span class="u-form-input-default u-form-input-text-box">
                      <input type="text" placeholder="例）Pyatto株式会社" name="contractor_name" v-model="form.contractor.name">
                    </span>
                    <!-- error message -->
                    <p v-for="(error, index) of v$.form.contractor.name.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                      {{ error.$message }}
                    </p>
                    <!-- END error message -->
                  </dd>
                </div>
                <div class="__item">
                  <dt class="__term">お名前</dt>
                  <dd class="__body">
                    <span class="u-form-input-default u-form-input-text-box">
                      <input type="text" placeholder="例）開発 太郎" name="contractor_user[name]" v-model="form.contractor_user.name">
                    </span>
                    <!-- error message -->
                    <p v-for="(error, index) of v$.form.contractor_user.name.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                      {{ error.$message }}
                    </p>
                    <!-- END error message -->
                  </dd>
                </div>
                <div class="__item">
                  <dt class="__term">メールアドレス</dt>
                  <dd class="__body">
                    <span class="u-text-bold">{{ form.contractor_user.email }}</span>
                    <input type="hidden" name="contractor_user[email]">
                  </dd>
                </div>
                <div class="__item">
                  <dt class="__term">パスワード</dt>
                  <dd class="__body">
                    <span class="u-form-input-default u-form-input-text-box">
                      <input type="password" placeholder="" name="contractor_user[password]" v-model="form.contractor_user.password">
                    </span>
                    <!-- error message -->
                    <p v-for="(error, index) of v$.form.contractor_user.password.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                      {{ error.$message }}
                    </p>
                    <!-- END error message -->
                  </dd>
                </div>
                <div class="__item">
                  <dt class="__term">パスワード（確認）</dt>
                  <dd class="__body">
                    <span class="u-form-input-default u-form-input-text-box">
                      <input type="password" placeholder="確認のため同じパスワードを入力してください" v-model="form.contractor_user.re_password">
                    </span>
                    <!-- error message -->
                    <p v-for="(error, index) of v$.form.contractor_user.re_password.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                      {{ error.$message }}
                    </p>
                    <!-- END error message -->
                  </dd>
                </div>
              </dl>
              <div class="u-flex-center-center mgt-032">
                <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="入力内容を確認" @click.prevent="submit">
              </div>
              <p class="u-align-center mgt-032 __text">アカウント作成にあたり当サービスの<a :href="generateRoute('privacy')" target="_blank">利用規約</a>及び<a :href="generateRoute('terms')" target="_blank">プライバシーポリシー</a>に<br>同意したものとみなされます。</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, email, required, minLength, maxLength, sameAs } from '@vuelidate/validators'
  // -- END vuelidate
  // -- for custom validate
  const regex_password = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9.?\/-]{8,24}$/);
  // -- END for custom validate
  export default {
    setup () {
      return { v$: useVuelidate() }
    },
    created () {
      this.initData();
    },
    props: {
      verify_user: {
        type: Object,
        default: {}
      },
      is_contractor_user: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        form: {
          contractor: {
            name: '',
          },
          contractor_user: {
            name: '',
            email: '',
            password: '',
            re_password: ''
          },
          is_contractor_user: 0
        },
        errors: []
      }
    },
    validations() {
      return {
        form: {
          contractor: {
            name: {
              required: helpers.withMessage(`会社名・組織名は必須項目です。`, required),
            }
          },
          contractor_user: {
            name: {
              required: helpers.withMessage(`お名前は必須項目です。`, required), 
            },
            password: {
              required: helpers.withMessage(`パスワードは必須項目です。`, required), 
              regex_password:  helpers.withMessage(`英数字大文字・小文字・数字を1文字ずつ入力してください。`, regex_password),
            },
            re_password: {
              sameAsPassword: helpers.withMessage(
                ({ $params }) => `確認用のパスワードが一致しません`, sameAs(this.form.contractor_user.password)
              ),
              // regex_password:  helpers.withMessage(`英数字大文字・小文字・数字を1文字ずつ入力してください。`, regex_password), // for now no use
            }
          }
        },
      }
    },
    methods: {
      async submit() {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        const isFormCorrect = await this.v$.$validate()
        if (isFormCorrect) {
          // store with not api route
          await axios.post(route('contractor.register.verify.submit', { token: this.verify_user.token }), this.form)
            .then((response) => {
              console.log(response)
              if (response.status == 201) {
                window.location.href = route('contractor.register.check', { token: this.verify_user.token });
              }
            })
            .catch((error) => {
              console.log(error)
            });
        }

        // -- loader hide
			  loader.hide()
			  // -- END loader hide
      },
      generateRoute(route_name) {
        let result = '';
        switch (route_name) {
          case 'privacy':
            result =  route('contractor.static.privacy.policy');
            break;
          case 'terms':
            result =  route('contractor.static.terms.use');
            break;
          default:
            result =  route('contractor.static.privacy.policy');
            break;
        }
        return result;
      },
      initData() {
        this.form.contractor_user.email = this.verify_user.email;

        if (this.is_contractor_user) {
          // -- contractor
          this.form.contractor.name = this.verify_user.contractor_user.contractor.name;
          this.form.contractor.id = this.verify_user.contractor_user.contractor.id;
          // -- END contractor

          // -- contract_user
          this.form.contractor_user.id = this.verify_user.contractor_user.id;
          this.form.contractor_user.name = this.verify_user.contractor_user.name;
          // -- END contract_user

          this.form.is_contractor_user = 1;
        }
      },
    },
  }
</script>

<style lang="css" scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>