'use strict';

export function AccountProfilePanel({trigger, element}) {
  const panelTrigger = document.querySelector(trigger);
  const panelElement = document.querySelector(element);
  if(panelElement) {
    panelTrigger.addEventListener('click', () => {
        panelElement.classList.toggle('__shown');
    });
  }
}
