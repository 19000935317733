'use strict';

export function InputIconEffect({element}) {
  const targetInputs = document.querySelectorAll(element);
  targetInputs.forEach((targetInput) => {
    if(targetInput.children.value) {
      targetInput.classList.add('__active');
    } else {
      targetInput.classList.remove('__active');
    }
    targetInput.children[0].addEventListener('focus', (event) => {
      event.target.parentNode.classList.add('__active');
    });
    targetInput.children[0].addEventListener('blur', (event) => {
      if(!event.target.value) {
        targetInput.classList.remove('__active');
      }
    });
  });
}