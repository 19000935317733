<template>
  <form action="" method="post">
    <div class="__block-depth-3 __block-depth-3--repeat-area">
      <item-page-type
        v-for="(item) in items"
        :key="item.itemId"
        :current-item="item"
        :item-index="item.isDefault"
        @delete-item="deleteItem"
		    :validation-scope="validation_scope"
      />
      <div class="u-flex-center-center mgt-064">
        <button class="__button __button--add" type="button" @click="addItem()">
          <img
            class="__image"
            src="/assets/img/site/icon_add_secondary_large_min.svg"
            alt=""
          /><br />
          <span class="__caption u-text-caption">
            ページタイプを追加
          </span>
        </button>
      </div>
    </div>
    <div class="__block-depth-3 __block-depth-3--submit-area">
      <div class="u-flex-center-center mgt-064">
        <a
          class="
            u-button-default u-button-default--large u-button-default--negative
            __button
          "
          :href="backToSetting()"
        >
          戻る
		</a>
        <input
          class="
            u-button-default u-button-default--large u-button-default--primary
            __button
          "
          type="submit"
          value="登録"
		  @click.prevent="submit"
        />
      </div>
    </div>
  </form>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
// -- END vuelidate
import ItemPageType from "../components/settings/ItemPageType.vue";
export default {
	setup () {
    const validation_scope = 'pageType'
    return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
  },
  components: { ItemPageType },
	props: {
		page_types: {
			type: Array,
			default: []
		},
	},
  data() {
    return {
      items: [],
      currentItemId: 0,
			arrDelete: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteItem(payload) {
			this.setArrDelete(payload);
      const result = this.items.filter((item) => {
        return item.itemId !== payload;
      });
      this.items = result;
    },
    addItem() {
      this.items.push({
        ptId: '',
        itemId: this.currentItemId,
        pageTypeName: null,
        labelColor: {
          type: "red",
          code: "#d50000",
        },
      });
      this.currentItemId++;
    },
    fetchData(data = []) {
			// -- set items
      const pageTypes = data.length > 0 ? data : this.page_types;
			this.items = pageTypes;
			// -- END set items

      this.currentItemId = this.items.length;
    },
		// -- add by backend
		async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- set form
        let form = {
          items: this.items,
          deletes: this.arrDelete
        };
        // -- END form

        await axios.post(route('contractor.api.setting.pagetype.store'), form)
          .then((response) => {
            if (response.status == 201) {
              this.fetchData(response.data.record);
              // reset array delete id
              this.arrDelete = [];
            }
          })
          .catch((error) => {
            console.log(error)
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
    },
		getTypeLabelColor(colorHex) {
			/**
			 * Noted Color :
			 * #d50000 => red
			 * #ff6d00 => orange
			 * #00c853 => green
			 * #304ffe => blue
			 * #aa00ff => purple
			 *
			 * if not found return 'custom'
			*/
			let type = '';
			switch (colorHex) {
				case '#d50000':
					type = 'red';
					break;
				case '#ff6d00':
					type = 'orange';

					break;
				case '#00c853':
					type = 'green';

					break;
				case '#304ffe':
					type = 'blue';

					break;
				case '#aa00ff':
					type = 'purple';
					break;
				default:
					type = 'custom';
					break;
			}

			return type;
		},
		setArrDelete(payload) {
			let pageType = this.items.find(item => item.itemId == payload);
			if (pageType.ptId != '') {
				this.arrDelete.push(pageType.ptId);
			}
		},
    backToSetting() {
      return route('contractor.settings.index');
    }
		// -- END add by backend
  },
};
</script>
