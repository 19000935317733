<template>
  <ul class="u-list-date-box mgt-024 __calendar">
    <!-- loop -->
    <li class="__item" v-for="report in reports" :key="report.id">
      <a class="__outer-link" :href="goToDailyReport(report.date)"></a>
      <div class="__box" :class="getBoxClass(report.date, report.dailyReportCount)">
        <span class="__head">
          <span class="__year">{{ moment(report.date).format('YYYY年') }}</span>
          <span class="__date">{{ moment(report.date).format('MM月DD日 （dd）') }}</span>
        </span>
        <span class="__body">
          <span class="__text" v-if="report.dailyReportCount > 0">{{ report.totalManHour }}時間</span>
          <span class="__text" v-else>未登録</span>
          <a class="__link" :href="goToDailyReport(report.date)">登録する</a>
        </span>
      </div>
    </li>
    <!-- END loop -->
  </ul>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    reports: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      //
    }
  },
  created () {
    this.moment = moment;
    moment.locale('ja');
  },
  methods: {
    getBoxClass(date, count) {
      const currentDate = moment().format('YYYY-MM-DD');
      if (date == currentDate) {
        return '__box--highlight';
      }
      else {
        if (count > 0) {
          return '__box--active';
        }
      }
    },
    goToDailyReport(date) {
      return route('contractor.management.daily_report.index', { date: date});
    },
  },
};
</script>

<style>
</style>