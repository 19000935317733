<template>
  <main class="l-main p-page p-invoice-create-page p-app-page p-service-page">
    <article class="p-page-title"> 
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center"> 
                <h1 class="u-heading-page-title __title">請求書新規作成</h1>
                <invoice-button-document-setting 
                  :default_documen_setting="invoice.documentSetting" 
                  :documen_setting="documentSetting"
                  :all_tax_option="invoice.allTaxOption" 
                  :page_type="page_type"
                  @update_tax_option="updateTaxOption"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <form action="" method="">
                <invoice-create 
                  :project="invoice.project" 
                  :tax_rates="invoice.tax_rates" 
                  :estimate="invoice.estimate" 
                  :taxt_option="taxOption"
                  :defaultTaxRate="defaultTaxRate"
                  :documentSettingPublisherSeal="invoice.documentSetting.publisher_seal"
                  :documentSettingPublisherSealUrl="invoice.documentSetting.publisher_seal_url" />
              </form>
            </div>
          </div>
        </div>
      </section>
    </article>
    <!-- section 01 end -->
  </main>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      default: {}
    },
    page_type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      taxOption: {
        taxCalculationMethod: 0,
        taxDisplayOption: 0,
        taxRateOption: 0,
        taxWithholdingOption: 0,
      },
      documentSetting: {},
      defaultTaxRate: '10%'
    }
  },
  created () {
    this.setTaxOption();
    if (this.invoice.estimate != '') {
      this.setDocumentSetting();
    }
    this.setDefaultTaxRate(this.invoice.documentSetting.tax_rate_option);
  },
  methods: {
    setTaxOption() {
      this.taxOption.taxCalculationMethod = this.invoice.documentSetting.tax_calculation_method;
      this.taxOption.taxDisplayOption = this.invoice.documentSetting.tax_display_option;
      this.taxOption.taxRateOption = this.invoice.documentSetting.tax_rate_option;
      this.taxOption.taxWithholdingOption = this.invoice.documentSetting.tax_withholding_option;
    },
    updateTaxOption(payload) {
      console.log(payload)
      this.taxOption = payload;

      // for update defaultTaxRate
      this.setDefaultTaxRate(payload.taxRateOption);
    },
    setDocumentSetting() {
      this.documentSetting = {
        tax_display_option: this.invoice.estimate.tax_display_option,
        tax_rate_option: this.invoice.estimate.tax_rate_option,
        tax_calculation_method: this.invoice.estimate.tax_calculation_method,
        tax_withholding_option: this.invoice.estimate.tax_withholding_option,
      };
    },
    setDefaultTaxRate(value = 0) {
      const taxRate = this.invoice.allTaxOption.taxRate.find(tax => tax.value == value);
      this.defaultTaxRate = taxRate.name;
    }
  },
}
</script>