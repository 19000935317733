<template>
  <main class="l-main p-page p-project-create-page p-app-page p-service-page">
    <article class="p-page-title">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center">
                <h1 class="u-heading-page-title __title">Test Crawl</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="u-box-content-default">
                <form>
                  <div class="__block-depth-2 __block-depth-2--name-block">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">URL</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="URL to be crawled" v-model="form.crawlerUrl">
                            <p v-for="(error, index) of v$.form.crawlerUrl.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--name-block">
                    <dl class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">Depth</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="number" placeholder="depth of URL" v-model="form.depth">
                            <p v-for="(error, index) of v$.form.depth.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--submit-block">
                    <div class="__block-depth-3 u-flex-center-center">
                      <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="Start" @click.prevent="submitEvent">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <!-- section 01 end-->
  </main>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue, maxValue, url } from '@vuelidate/validators'
// -- END vuelidate

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        crawlerUrl: '',
        depth: 2
      }
    }
  },
   validations() {
    return {
      form: {
        crawlerUrl: {
          required: helpers.withMessage('URL is required', required),
          url: helpers.withMessage('URL is not valid', url),
        },
        depth: {
          required: helpers.withMessage('Depth is required', required),
          minValue: helpers.withMessage(
            ({ $params }) => `Please enter at least ${$params.min} for Depth.`, minValue(1)
          ),
          maxValue: helpers.withMessage(
            ({ $params }) =>`Please enter Depth in ${$params.max} or less.`, maxValue(5)
          )
        },
      },
    }
  },
  methods: {
    async submitEvent() {
      // -- loader show
			let loader = this.$loading.show();
			// -- END loader show

      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        // store with not api route
        await axios.post(route('contractor.api.crawler.export.csv'), this.form)
          .then((response) => {
            console.log(response)
            if (response.status == 200) {
              window.location.href = route('contractor.crawler.thanks');
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }

      // -- loader hide
			loader.hide()
			// -- END loader hide
    },
  },
};
</script>