<template>
  <div class="l-contents-column-section">
    <div class="__block-depth-2">

      <div class="u-box-content-default">
        <h2 class="u-heading-box-title mgt-024">顧客設定</h2>
        <dl class="u-list-input-label-set __list mgt-024">
          <div class="__item">
            <dt class="__term">顧客名<span class="u-form-label-required"></span></dt>
            <dd class="__body">
              <span class="u-form-input-default u-form-input-text-box">
                <input type="text" placeholder="顧客名を入力" name="" v-model="form.name">
              </span>
              <!-- error message name -->
              <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.name.$errors" :key="index">
                {{ error.$message }}
              </p>
              <!-- END error message name -->
            </dd>
          </div>

          <div class="__item">
            <div class="__block-depth-3 u-flex-between-center">
              <div class="__block-depth-4 __block-depth-4--mail-block">
                <div class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">メールアドレス</dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="email" placeholder="例）info@example.com" name="" v-model="form.email">
                      </span>
                      <!-- error message name -->
                      <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.email.$errors" :key="index">
                        {{ error.$message }}
                      </p>
                      <!-- END error message name -->
                    </dd>
                  </div>
                </div>
              </div>
              <div class="__block-depth-4 __block-depth-4--tell-block">
                <div class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">電話番号</dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="tel" placeholder="例）080-1234-5678" name="" v-model="form.phone">
                      </span>
                      <!-- error message name -->
                      <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                        {{ error.$message }}
                      </p>
                      <!-- END error message name -->
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="__item">
            <div class="__block-depth-3 u-flex-between-center">
              <div class="__block-depth-4 __block-depth-4--mail-block">
                <div class="__block-depth-5 u-flex-between-center">
                  <div class="__block-depth-6 __block-depth-6--zipcode-block">
                    <div class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">郵便番号</dt>
                        <dd class="__body">
                          <span class="u-form-input-default u-form-input-text-box">
                            <input type="text" placeholder="例）123-4567" name="" v-model="form.postcode">
                          </span>
                          <!-- error message name -->
                          <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.postcode.$errors" :key="index">
                            {{ error.$message }}
                          </p>
                          <!-- END error message name -->
                        </dd>
                      </div>
                    </div>
                  </div>
                  <div class="__block-depth-6 __block-depth-6--zipcode-block">
                    <div class="u-list-input-label-set __list">
                      <div class="__item">
                        <dt class="__term">都道府県</dt>
                        <dd class="__body">
                          <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                            <select v-model="form.prefecture">
                              <option value="北海道">北海道</option>
                              <option value="青森県">青森県</option>
                              <option value="秋田県">秋田県</option>
                              <option value="岩手県">岩手県</option>
                              <option value="山形県">山形県</option>
                              <option value="宮城県">宮城県</option>
                              <option value="福島県">福島県</option>
                              <option value="新潟県">新潟県</option>
                              <option value="群馬県">群馬県</option>
                              <option value="栃木県">栃木県</option>
                              <option value="茨城県">茨城県</option>
                              <option value="千葉県">千葉県</option>
                              <option value="埼玉県">埼玉県</option>
                              <option value="東京都">東京都</option>
                              <option value="神奈川県">神奈川県</option>
                              <option value="山梨県">山梨県</option>
                              <option value="長野県">長野県</option>
                              <option value="静岡県">静岡県</option>
                              <option value="愛知県">愛知県</option>
                              <option value="岐阜県">岐阜県</option>
                              <option value="三重県">三重県</option>
                              <option value="富山県">富山県</option>
                              <option value="石川県">石川県</option>
                              <option value="福井県">福井県</option>
                              <option value="滋賀県">滋賀県</option>
                              <option value="京都府">京都府</option>
                              <option value="大阪府">大阪府</option>
                              <option value="奈良県">奈良県</option>
                              <option value="和歌山県">和歌山県</option>
                              <option value="兵庫県">兵庫県</option>
                              <option value="岡山県">岡山県</option>
                              <option value="鳥取県">鳥取県</option>
                              <option value="島根県">島根県</option>
                              <option value="広島県">広島県</option>
                              <option value="山口県">山口県</option>
                              <option value="香川県">香川県</option>
                              <option value="徳島県">徳島県</option>
                              <option value="高知県">高知県</option>
                              <option value="愛媛県">愛媛県</option>
                              <option value="福岡県">福岡県</option>
                              <option value="佐賀県">佐賀県</option>
                              <option value="長崎県">長崎県</option>
                              <option value="熊本県">熊本県</option>
                              <option value="大分県">大分県</option>
                              <option value="宮崎県">宮崎県</option>
                              <option value="鹿児島県">鹿児島県</option>
                              <option value="沖縄県">沖縄県</option>
                            </select>
                          </span>
                        </dd>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="__block-depth-4 __block-depth-4--tell-block">
                <div class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">市区町村・番地</dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="text" placeholder="例）○○市○○町1-2-3" name="" v-model="form.city_subarea">
                      </span>
                      <!-- error message name -->
                      <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.city_subarea.$errors" :key="index">
                        {{ error.$message }}
                      </p>
                      <!-- END error message name -->
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="__item">
            <div class="__block-depth-3 u-flex-between-center">
              <div class="__block-depth-4 __block-depth-4--mail-block">
                <div class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">建物名・部屋番号など</dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="text" placeholder="例）○○ビル2階○○号" name="" v-model="form.rest_of_address">
                      </span>
                      <!-- error message name -->
                      <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.form.rest_of_address.$errors" :key="index">
                        {{ error.$message }}
                      </p>
                      <!-- END error message name -->
                    </dd>
                  </div>
                </div>
              </div>
              <div class="__block-depth-4 __block-depth-4--tell-block">
                <div class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">担当者名</dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="text" placeholder="例）担当 太郎" name="" v-model="form.compellation">
                      </span>
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dl>
      </div>

      <div class="u-flex-center-center mgt-048">
        <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="更新" @click.prevent="submitEvent()">
      </div>

    </div>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, email, required, minLength, maxLength } from '@vuelidate/validators'
// -- END vuelidate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    customer: {
      type: Object,
      default: {}
    }
  },
  created () {
    this.setCustomer();
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        postcode: '',
        prefecture: '',
        city_subarea: '',
        rest_of_address: '',
        compellation: '',
      },
    }
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('顧客名は必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `名前は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`名前は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        email: {
          email: helpers.withMessage('メールアドレスは、有効なメールアドレス形式で指定してください。', email),
          minLength: helpers.withMessage(
            ({ $params }) => `メールアドレスは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`メールアドレスは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        phone: {
          minLength: helpers.withMessage(
            ({ $params }) => `電話番号は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`電話番号は、${$params.max}文字以下で入力してください。`, maxLength(20)
          )
        },
        postcode: {
          minLength: helpers.withMessage(
            ({ $params }) => `郵便番号は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`郵便番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
          )
        },
        city_subarea: {
          minLength: helpers.withMessage(
            ({ $params }) => `市区町村・番地は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`市区町村・番地は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        rest_of_address: {
          minLength: helpers.withMessage(
            ({ $params }) => `建物名・部屋番号などは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`建物名・部屋番号などは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
      },
    }
  },
  methods: {
    setCustomer() {
      this.form.name = this.customer.name;
      this.form.email = this.customer.email;
      this.form.phone = this.customer.phone;
      this.form.postcode = this.customer.postcode;
      this.form.prefecture = this.customer.prefecture;
      this.form.city_subarea = this.customer.city_subarea;
      this.form.rest_of_address = this.customer.rest_of_address;
      this.form.compellation = this.customer.compellation;
    },
    async submitEvent() {
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // store with not api route
        await axios.put(route('contractor.api.customer.update', { id: this.customer.id }), this.form)
          .then((response) => {
            console.log(response)
            if (response.status == 200) {
              window.location.href = route('contractor.settings.customer');
            }
          })
          .catch((error) => {
            console.log(error)
          });

        // -- loader hide
        loader.hide();
        // -- END loader hide
      }
    },
  },
}
</script>