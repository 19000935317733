<template>
  <form :action="generateRoute()" method="POST">
		<input type="hidden" name="_token" :value="csrf">
		<input type="hidden" name="user_plan_detail_id" v-model="userPlanDetailId">
    <div class="u-box-content-default">
      <p class="__text mgt-024">{{ generateConfirmationMessage() }}</p>
      <div class="u-flex-between-stretch __plan-item-wrap">

				<input type="radio" name="plan" :value="plan.nextPlan.user_plan.plan_id" id="light" v-model="selectPlan" :checked="selectPlan == plan.nextPlan.user_plan.plan_id" />
				<div class="__plan-item">
					<h2 class="__lead u-align-center">
						<span>{{ plan.nextPlan.nameEN }}</span>
					</h2>
					<p class="__description pdb-024 mgt-016 u-align-center">
						<span class="__big">{{ numberWithCommas(plan.nextPlan.price) }} 円</span>
						<span class="__normal">&nbsp;/&nbsp;{{ plan.paymentPeriod }}</span>
					</p>
				</div>

      </div>
    </div>
    <div class="__block-depth-2">
      <div class="u-flex-center-center mgt-048">
        <input
          class="
            u-button-default u-button-default--large u-button-default--primary
          "
          type="submit"
          :value="generateButtonText()"
					@click.prevent="submitUpdate()"
					data-cy="btn-update_plan_confirm"
        />
				<button ref="submitForm" type="submit" style="display: none;">submit</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
		plan: {
			type: Object,
			default: {}
		}
	},
  data() {
    return {
			currentPlan: '',
			selectPlan: '',
			userPlanDetailId: '',
			csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // csrf
		};
  },
  created() {
		this.currentPlan = this.plan.currentPlan;
		this.setInputValue();
  },
  methods: {
		getPrice(plan_name) {
			const plan = this.plan.listPlan.find(plan => plan.name.toLowerCase() == plan_name);
			return this.numberWithCommas(plan.price);
		},
		numberWithCommas(x) {
    	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		},
		generateRoute() {
        return route('contractor.api.setting.account.user.plan.detail');
    },
		generateConfirmationMessage() {
			if (this.plan.isUpgrade) {
				return "Would you like to change plan to "+this.plan.nextPlan.nameEN+" plan ?";
			}
			else {
				if (this.plan.displayCancelButton) {
					return "Would you like to cancel schedule ? ";
				}
				else {
					return "Would you like to schedule a change plan to "+this.plan.nextPlan.nameEN+" plan ?";
				}
			}
		},
		generateButtonText() {
			if (this.plan.displayCancelButton) {
				return 'キャンセル';
			}
			else {
				return '更新';
			}
		},
		setInputValue() {
			this.selectPlan = this.plan.nextPlan.user_plan.plan_id;
			this.userPlanDetailId = this.plan.nextPlan.id;
		},
		submitUpdate() {
			if (this.plan.isUpgrade) {
				this.$refs.submitForm.click();
			}
			else {
				this.submitUpdateAccount();
			}
		},
		// if downgrade update directly
		async submitUpdateAccount() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

      // -- prepare form
			let form = {
				contractors: {
					plan_id: this.plan.nextPlan.user_plan.id,
          plan_detail_id: this.plan.nextPlan.id,
				},
        change_type: this.plan.displayCancelButton ? 'cancel_next_phase' : 'next_phase'
			};
      // -- END prepare form

			// -- submit
			await axios.put(route('contractor.api.setting.account.update'), form)
				.then((response) => {
					if (response.status == 200) {
						this.currentPlan = parseInt(this.selectPlan);
						// -- hide loader
						loader.hide();
						// -- END hide loader

						window.location.href = route('contractor.settings.account');
					}
				})
				.catch((error) => {
					console.log(error)
					// -- hide loader
					loader.hide();
					// -- END hide loader
				});
			// -- END submit
    },
	},
};
</script>
