<template>
    <div class="__block-depth-2 __block-depth-2--repeat-block">
        <table class="u-table-sheet mgt-024 p-survey-result">
            <thead>
                <tr>
                    <th class="u-text-leadcopy" colspan="2">{{ items.itemTitle }}</th>
                </tr>
                <tr v-if="recruitingConfig">
                    <td colspan="2">
                        <div class="u-flex-right-center">
                            <span class="__lead u-text-caption u-text-bold mgr-016">このカテゴリを使用する</span>
                            <div class="u-list-toggle-switch __list">
                                <div>
                                    <span class="__toggle js-toggle-switch-effect">
                                        <span class="__status js-toggle-switch-effect-status" :class="[items.recruitingEnable == 'true' ? '__enabled' : '__disabled']">
																					{{ items.recruitingEnable == 'true' ? '有効です' : '無効です' }}
																				</span>
                                        <span class="u-form-toggle-switch">
                                            <input class="js-toggle-switch-effect-input js-toggle-switch-effect-input--disabled" 
																							type="radio" 
																							:name="`survey_${currentItemsId}[]`" 
																							value="false" 
																							:id="`off_${currentItemsId}`" 
																							v-model="items.recruitingEnable"
																							@change="toggleIsPublicCategory('false')">
                                            <label class="__label" :for="`off_${currentItemsId}`"></label>

                                            <input class="js-toggle-switch-effect-input js-toggle-switch-effect-input--enabled" 
																							type="radio" 
																							:name="`survey_${currentItemsId}[]`" 
																							value="true" 
																							:id="`on_${currentItemsId}`"  
																							v-model="items.recruitingEnable"
																							@change="toggleIsPublicCategory('true')">
                                            <label class="__label" :for="`on_${currentItemsId}`"></label><span class="__switch"></span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(question, index) in items.itemQuestions" :key="question.questionId">
                    <th>{{ question.questionTitle }}</th>
                    <!-- checkbox -->
                    <td v-if="question.answerType === 'checkbox'">
                        <div class="__answer __answer-checkbox">
                            <div class="u-form-input-group mgb-008">
                                <label v-for="option in question.answers.options" :key="option.id" :value="option.value" class="u-form-checkbox-default u-form-input-checkbox">
                                    <input
                                        v-model="question.answers.values"
                                        type="checkbox"
                                        :name="`${currentItemsId}-${question.questionId}-${option.id}`"
                                        :value="option.value"
                                        @change="updateEditMode(question.questionId, true)"
                                    >
                                    <span class="__text">{{ option.value }}</span>
                                </label>
                            </div>
                            <button
                                v-if="question.isEdit"
                                class="u-button-default u-button-default--small u-button-default--primary"
                                type="button"
                                @click="updateAnswer(currentItemsId, question.questionId, question.answers.values, 'checkbox', index)"
                            >更新</button>
                        </div>
                    </td>
                    <!-- radio -->
                    <td v-else-if="question.answerType === 'radio'">
                        <div class="__answer __answer-radio">
                            <div class="u-form-input-group mgb-008">
                                <label v-for="option in question.answers.options" :key="option.id" :value="option.value" class="u-form-radio-default u-form-input-radio">
                                    <input
                                        v-model="question.answers.value"
                                        type="radio"
                                        :name="`${currentItemsId}-${question.questionId}`"
                                        :value="option.value"
                                        @change="updateEditMode(question.questionId, true)"
                                    >
                                    <span class="__text">{{ option.value }}</span>
                                </label>
                            </div>
                        </div>
                        <button
                            v-if="question.isEdit"
                            class="u-button-default u-button-default--small u-button-default--primary"
                            type="button"
                            @click="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'radio', index)"
                        >更新</button>
                    </td>
                    <!-- attachment file -->
                    <td v-else-if="question.answerType === 'file'">
                        <div v-show="question.isEdit">
                            <div class="u-form-file-group js-local-file-upload--survey mgb-008" ref="file_uploader">
                                <figure class="__thumbnail">
                                    <img class="__image" :src="question.answers.previewImage" alt="" ref="file_uploader_preview">
                                </figure>
                                <button
                                    v-show="question.answers.previewImage != null"
                                    class="__delete" type="button"
                                    @click="fileDelete(question.questionId)"
                                    ref="file_uploader_reset"
                                >
                                    <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
                                </button>
                                <div class="__input">
                                    <div class="__controller">
                                        <input
                                            type="file"
                                            :id="`input_file_${currentItemsId}_${question.questionId}`"
                                            :value="question.answers.value"
                                            @change="fileUpload($event, question.questionId, index)"
                                        >
                                        <label class="u-form-file-upload" :for="`input_file_${currentItemsId}_${question.questionId}`">
                                            <span class="__icon"><img class="__image" src="" alt=""></span><span class="__text">ファイルを選択</span>
                                        </label>
                                        <span v-if="question.answers.isImage" class="__file-name">{{ question.answers.fileName }}</span>
                                        <span v-else class="__file-name">ファイルが選択されていません</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="question.isEdit">
                            <div class="u-flex-left-top">
                                <button
                                    class="u-button-default u-button-default--small u-button-default--negative mgr-016"
                                    type="button"
                                    @click="uploadFileDeleteDialog(question.questionId, index)"
                                >登録済みのファイルを削除</button>
                                <button
                                    class="u-button-default u-button-default--small u-button-default--primary mgt-016"
                                    type="button"
                                    @click="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'file', index)"
                                >更新</button>
                            </div>
                            <button
                                class="u-button-default u-button-default--extra-small u-button-default--negative mgt-016"
                                type="button"
                                @click="updateEditMode(question.questionId, false); fileUploadCancel(question.questionId)"
                            >キャンセル</button>
                        </div>
                        <span v-show="!question.isEdit" class="__answer-block">
                            <div>
                                <figure v-show="question.answers.previewImage != null" class="__thumbnail mgb-008">
                                    <img class="__image" :src="question.answers.previewImage" alt="" ref="file_uploader_thumbnail">
                                </figure>
                                <span v-show="question.answers.isImage">{{ question.answers.fileName }}</span>
                                <span v-show="!question.answers.isImage">添付ファイルはありません</span>
                            </div>
                            <div class="u-flex-left-center mgt-008">
                                <button class="u-button-default u-button-default--small u-button-default--secondary mgr-016" type="button" @click="changeEditMode(question.questionId); fileUploadTemp(question.questionId)">編集</button>
                                <button 
                                    v-if="question.answers.isImage" 
                                    class="u-button-default u-button-default--small u-button-default--primary"
                                    @click.prevent="downloadFile(question.answers.fileName)" target="_blank">
                                    ファイルダウンロード
                                </button>
                            </div>
                        </span>
                    </td>
                    <td v-else>
                        <!-- edit mode (isEdit: true) -->
                        <div v-if="question.isEdit" class="__input-block">
                            <!-- textbox -->
                            <span v-if="question.answerType === 'textbox'" class="u-form-input-default u-form-input-text-box">
                                <input
                                    v-model="question.answers.value"
                                    type="text"
                                    placeholder="回答を入力"
                                    name=""
                                    @blur="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'textbox', index)"
                                >
                            </span>
                            <!-- END textbox -->

                            <!-- textarea -->
                            <span v-else-if="question.answerType === 'textarea'" class="u-form-input-default u-form-input-text-area">
                                <textarea
                                    v-model="question.answers.value"
                                    placeholder="回答を入力"
                                    name=""
                                    @blur="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'textarea', index)"
                                ></textarea>
                            </span>
                            <!-- END textarea -->

                            <!-- date -->
                            <span v-else-if="question.answerType === 'date'" class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                <input
                                    v-model="question.answers.value"
                                    type="date"
                                    placeholder="回答を入力"
                                    name=""
                                    @blur="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'date', index)"
                                >
                            </span>
                            <!-- END date -->

                            <!-- select -->
                            <span v-else-if="question.answerType === 'select'" class="u-form-select-default u-form-input-select u-form-input-select--large">
                                <select
                                    v-model="question.answers.value"
                                    @blur="updateAnswer(currentItemsId, question.questionId, question.answers.value, 'select', index)"
                                >
                                    <option v-for="option in question.answers.options" :key="option.id" :value="option.value">{{ option.value }}</option>
                                </select>
                            </span>
                            <!-- END select -->

                            <!-- error message -->
                            <div class="input-errors" v-if="errors[index].is_error">
															<p class="mgt-008 mgr-008 u-text-varidate-label">
																{{ error_message.required }}
															</p>
                            </div>
                            <!-- END error message -->

                        </div>
                        <!-- display mode (isEdit: false) -->
                        <div v-else class="__answer-block">
                            <!-- textbox -->
                            <span
                                v-if="question.answerType === 'textbox'"
                                class="__answer-text-box p-survey-editable-area p-survey-editable-area--block"
                                @click="changeEditMode(question.questionId)"
                            >{{ question.answers.value }}</span>
                            <!-- textarea -->
                            <span
                                v-else-if="question.answerType === 'textarea'"
                                class="__answer-text-area p-survey-editable-area p-survey-editable-area--block display-textarea-data"
                                @click="changeEditMode(question.questionId)"
                            >{{ question.answers.value }}</span>
                            <!-- date -->
                            <span
                                v-else-if="question.answerType === 'date'"
                                class="__answer-date p-survey-editable-area p-survey-editable-area--block"
                                @click="changeEditMode(question.questionId)"
                            >{{ question.answers.value }}</span>
                            <!-- select -->
                            <span
                                v-else-if="question.answerType === 'select'"
                                class="__answer-select p-survey-editable-area p-survey-editable-area--block"
                                @click="changeEditMode(question.questionId)"
                            >{{ question.answers.value }}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
/** 
 * Note :
 * 
 * sorrt, i don't know why but i can't assign value to items->itemQuestions->answers->value
 * so I added a new key isImage on the object,
 * to check if the record has a file
 * 
 * changes that occur in the tag
 * <td v-else-if="question.answerType === 'file'"></td>
 * from : question.answers.value
 * to 	: question.answers.isImage
*/
import { toRaw } from 'vue';
export default {
    props: {
        currentItems: {
            type: Object,
            default: {}
        },
        currentItemsId: {
            type: Number,
            default: 0
        },
        recruitingConfig: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [],
						// -- add by backend
						errors: [],
						error_message: {
							required: '回答は必須項目です。'
						},
						answer_temporary: '',
						file_upload: null
						// -- END add by backend
        }
    },
    created() {
        this.items = this.currentItems;
				this.setError();
    },
    methods: {
        changeEditMode(questionId) {
            this.items.itemQuestions.forEach((question) => {
                if(question.questionId === questionId) {
                    question.isEdit = !question.isEdit;
										// -- add value temporary
										if (question.isEdit == true) {
											let value = question.answers.value;
											this.answer_temporary = value;
										}
										// -- END add value temporary
                }
            });
        },
        updateEditMode(questionId, status) {
            this.items.itemQuestions.forEach((question) => {
                if(question.questionId === questionId) {
                    question.isEdit = status;
                }
            });
        },
        fileUploadTemp(questionId) {
            this.items.itemQuestions.forEach((question) => {
                if(question.questionId === questionId) {
                    question.answers.tempValue = question.answers.value;
                }
            });
        },
        fileUploadCancel(questionId) {
            this.items.itemQuestions.forEach((question) => {
                if(question.questionId === questionId) {
                    // question.answers.value = question.answers.tempValue;
										question.answers.fileName = question.answers.tempFileName;
										question.answers.isImage = question.answers.tempIsImage;
										question.answers.previewImage = question.answers.tempPreviewImage;
										question.answers.isChange = false; // for check if file change
                }
            });
        },
        uploadFileDeleteDialog(questionId, index) {
            if(window.confirm('登録されている画像を削除してもいいですか？（この動作は取り消しできません）')) {
							this.submiteDeleteFile(questionId, index);
            } else {
              window.alert('登録済み画像の削除がキャンセルされました');
            }

        },
        fileUpload(event, questionId) {
            this.$refs.file_uploader.forEach((refs, index) => {
                const preview = this.$refs.file_uploader_preview[index];
                const thumbnail = this.$refs.file_uploader_thumbnail[index];
                const reset = this.$refs.file_uploader_reset[index];
                const fileData = event.target.files[index];
								this.file_upload = event.target.files[index]; // add by backend
                console.log(reset)
                this.items.itemQuestions.forEach((question, index) => {
                    if(question.questionId === questionId) {
                        if(fileData) {
                            if(fileData.type.match('application/octet-stream')) {
                                alert('ファイル形式が対応していません');
                            } else {
                                if(fileData.type.match('image/*')) {
                                    const fileReader = new FileReader();
                                    fileReader.onload = (() => {
                                        question.answers.previewImage = fileReader.result;
                                        preview.style.display = 'inline';
                                        thumbnail.style.display = 'inline';
                                        preview.parentNode.style.display = 'block';
                                        thumbnail.parentNode.style.display = 'block';
                                        reset.style.display = 'inline-block';
                                        console.log('画像データの読み込みが完了しました');
                                        question.answers.value = event.target.value;
                                        question.answers.fileName = fileData.name;
																				question.answers.isChange = true; // for check if file change
                                        // question.answers.tempFileName = fileData.name;
                                    });
                                    if(fileData) {
                                        fileReader.readAsDataURL(fileData);
                                        console.log('画像データのセットが完了しました');
                                    }
                                } else {
                                    const fileReader = new FileReader();
                                    fileReader.onload = (() => {
                                        question.answers.previewImage = null;
                                        preview.style.display = 'none';
                                        preview.parentNode.style.display = 'none';
                                        thumbnail.parentNode.style.display = 'none';
                                        reset.style.display = 'inline-block';
                                        console.log('ファイルデータの読み込みが完了しました');
                                        question.answers.value = event.target.value;
                                        question.answers.fileName = fileData.name;
																				question.answers.isChange = true; // for check if file change
                                        // question.answers.tempFileName = fileData.name;
                                    });
                                    if(fileData) {
                                        fileReader.readAsDataURL(fileData);
                                        console.log('ファイルデータのセットが完了しました');
                                    }
                                }
                            }
                        }
                        reset.onclick = (event) => {
                            question.answers.previewImage = null;
                            question.answers.value = null;
                            question.answers.fileName = 'ファイルが選択されていません';
                            question.answers.tempValue = null;
                            // question.answers.tempFileName = null;
                        }
                    }
                });
            });
        },
        fileDelete(questionId) {
            this.items.itemQuestions.forEach((question) => {
                if(question.questionId === questionId) {
                    question.answers.value = null;
                    question.answers.tempValue = null;
                    // question.answers.tempFileName = null;
                    question.answers.previewImage = null;
										question.answers.isImage = false;
                }
            });
        },
        emitUpdeteItem(itemId, questionId, value) {
            this.$emit('item-update', {
                itemId,
                questionId,
                value
            });
        },
        // -- add by backend
        async updateAnswer(itemId, questionId, value, inputType, index) {
						// -- check if value same
						/** 
						 * this check just for inputType :
						 * 'textbox',
						 * 'textarea',
						 * 'date',
						 * 'select',
						*/
						let arr_input_type_check = [
							'textbox',
							'textarea',
							'date',
							'select',
						];
						if (arr_input_type_check.includes(inputType) && (value == this.answer_temporary)) {
							this.updateEditMode(questionId, false); 
							this.emitUpdeteItem(itemId, questionId, value);
							return;
						}

						// -- check if file change
						if(inputType == 'file' && (this.items.itemQuestions[index].answers.isChange == false)) {
							this.updateEditMode(questionId, false); 
							return;
						}
						// -- END check if file change
						// -- END check if value same

						let formCheck = this.checkAnswer(value, inputType);
						if (formCheck) {
							// hide error message
							this.errors[index].is_error = false;

							// -- loader show
							let loader = this.$loading.show();
							// -- END loader show

							// -- prepare formData
							const fd = new FormData();
							
							switch (inputType) {
									case 'file':
											fd.append('file', this.file_upload); // need confirm
											break;
									case 'radio':
											fd.append('answer', toRaw(value));
											break;
									case 'checkbox':
											fd.append('answer', toRaw(value).join('|'));
											break;
									case 'textbox':
									case 'textarea':
									case 'date':
									case 'select':
											fd.append('answer', value);
											break;
									default:
											break;
							}
							// -- END prepare formData

		
							// -- submit
							await axios.post(route('contractor.api.survey_format_category_detail.update', { id: questionId }), fd)
									.then((response) => {
											if (response.status == 200) {
													this.updateEditMode(questionId, false); 
													this.emitUpdeteItem(itemId, questionId, value)

													// -- update if inputType == file
													if (inputType == 'file') {
														this.setIsImage(response.data.record, index);
														this.items.itemQuestions[index].answers.isChange = false;
													}
													// -- END update if inputType == file
											}
									})
									.catch((error) => {
											console.log(error)
									});
							// -- END submit

							// -- reset answer_temporary
							this.answer_temporary = '';
							// -- END reset answer_temporary

							// -- hide loader
							loader.hide();
							// -- END hide loader
						}
						else {
							this.errors[index].is_error = true;
						}

        },
				setError() {
					for (let index = 0; index < this.items.itemQuestions.length; index++) {
						let error = {
							is_error: false
						};
						this.errors.push(error);
					}
				},
				checkAnswer(value, inputType) {
					if (inputType == 'checkbox') {
						let valueCheck = toRaw(value);
						if (valueCheck.length > 0) {
							return true;
						}
						else {
							return false;
						}
					}
					else {
						if (value != '') {
							return true;
						}
						else {
							if (inputType == 'file') {
								return true;
							}
							return false;
						}
					}
				},
				setIsImage(record, index) {
					let fileName = null;
					if (record.answer != null) {
						let arr_file_name = record.answer.split("/");
						fileName = arr_file_name[2];
					}
					this.items.itemQuestions[index].answers.isImage = record.answer != null ? true : false;
					this.items.itemQuestions[index].answers.tempIsImage = record.answer != null ? true : false;
					this.items.itemQuestions[index].answers.fileName = fileName;
					this.items.itemQuestions[index].answers.tempFileName = fileName;
					this.items.itemQuestions[index].answers.previewImage = record.file_image_show;
					this.items.itemQuestions[index].answers.tempPreviewImage = record.file_image_show;
				},
				downloadFile(filename) {
          // -- loader show
					let loader = this.$loading.show();
					// -- END loader show

					// -- donwload file
					axios({
							url: route('contractor.api.survey_format_category_detail.image.download', { filename: filename }), // File URL Goes Here
							method: 'GET',
							responseType: 'blob',
					}).then((res) => {
							var fileURL = window.URL.createObjectURL(new Blob([res.data]));
							var fileLink = document.createElement('a');
							fileLink.href = fileURL;
							fileLink.setAttribute('download', filename);
							document.body.appendChild(fileLink);
							fileLink.click();

							// -- hide loader
							loader.hide();
							// -- END hide loader
					}).catch((error) => {
						console.log(error)

						// -- hide loader
						loader.hide();
						// -- END hide loader
					});
					// -- END donwload file
				},
				async submiteDeleteFile(questionId, index) {
					// -- loader show
					let loader = this.$loading.show();
					// -- END loader show

					// -- submit
					await axios.delete(route('contractor.api.survey_format_category_detail.image.delete', { id: questionId }))
						.then((response) => {
							if (response.status == 200) {
								this.setIsImage(response.data.record, index);
								// -- END update if inputType == file
								window.alert('登録済み画像が削除されました');
							}
						})
						.catch((error) => {
								console.log(error)
						});
					// -- END submit

					// -- hide loader
					loader.hide();
					// -- END hide loader
				},
				async toggleIsPublicCategory(value) {
					console.log(value)

					// -- loader show
					let loader = this.$loading.show();
					// -- END loader show

					// -- form
					let form = {
						is_public_category: value == 'true' ? 1 : 0
					};
					// -- END form

					// -- submit
					await axios.put(route('contractor.api.recruiting_public_survey_format_category.update', { id: this.items.rpsfc_id }), form)
						.then((response) => {
							if (response.status == 200) {
								console.log(response.data.message)
							}
						})
						.catch((error) => {
							// return value to previous value
							this.items.recruitingEnable = value == 'true' ? 'false' : 'true';
							console.log(error)
						});
					// -- END submit

					// -- hide loader
					loader.hide();
					// -- END hide loader
				},
        // -- add by backend
    }
}
</script>

<style lang="css" scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>