<template>
    <div v-if="isEdit">
        <form action="" method="">
            <div class="__block-depth-2 __block-depth-2--form-input-block">
                <div class="__block-depth-3 __block-depth-3--table-block">
                    <div class="u-table-wrapper u-table-wrapper--scroll __table">
                        <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-flow">
                            <thead>
                                <tr>
                                    <th class="__item u-align-left">ページ名</th>
                                    <th class="__subtotal u-align-center">設定工数</th>
                                    <th class="__total u-align-right">合計工数</th>
                                </tr>
                            </thead>
                            <tbody>
                                <flow-table-pages-item-row
                                    v-for="(page, index) in items.pages"
                                    :key="page.pageId"
                                    :current-item="page"
                                    :order-index="index"
                                    @delete-page="deletePageItem"
                                    @update-manhours="totalManHours()"
									:validation-scope="validation_scope"
                                    :page-count="items.pages.length"
                                    @set-delete-id="setDeleteOptionalCostId"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="__block-depth-3 __block-depth-3--other-block mgt-032">
                    <h2 class="u-heading-content-title">その他追加工数</h2>
                    <div class="u-table-wrapper u-table-wrapper--scroll __table mgt-024">
                        <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-flow">
                            <thead>
                                <tr>
                                    <th class="__item u-align-left">作業項目</th>
                                    <th class="__subtotal u-align-center">設定工数</th>
                                    <th class="__total u-align-right">合計工数</th>
                                </tr>
                            </thead>
                            <tbody>
                                <flow-table-others-item-row
                                    v-for="other in items.others"
                                    :key="other.itemId"
                                    :current-item="other"
                                    @delete-others="deleteOthersItem"
                                    @update-manhours="totalManHours()"
																		:validation-scope="validation_scope"
                                />
                                <tr>
                                    <td class="u-align-center" colspan="3" style="padding: 0;">
                                        <div class="__add">
                                            <button class="__button __button--add-option" type="button" @click="addItemOthers()">
                                                <img class="__image" src="/assets/img/site/icon_add_secondary_small_min.svg" alt="追加">
                                            </button>
                                            <span class="__caption u-text-caption">作業項目を追加</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="__block-depth-3 __block-depth-3--result-block">
                    <div class="u-flex-right-center">
                        <dl class="u-list-format-report __list">
                            <div class="__item __total">
                                <dt class="__term">全体工数</dt>
                                <dd class="__body">{{ manHours }}人日</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="__block-depth-2 __block-depth-2--form-submit-block">
                <div class="u-flex-center-center">
                    <button type="button" class="u-button-default u-button-default--large u-button-default--negative __button" @click="cancelItemsUpdate()">キャンセル</button>
                    <button type="button" class="u-button-default u-button-default--large u-button-default--primary __button" @click="submit">更新</button>
                </div>
            </div>
        </form>
    </div>
    <div v-else class="p-flow-editable-area p-flow-editable-area--block __block-depth-2 __block-depth-2--form-input-block" @click="changeEditMode(); tempItemsCreate()">
        <div class="__block-depth-3 __block-depth-3--table-block">
            <div class="u-table-wrapper u-table-wrapper--scroll __table">
                <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-flow">
                    <thead>
                        <tr>
                            <th class="__item u-align-left">ページ名・項目</th>
                            <th class="__assign u-align-left">アサインメンバー（割当工数）</th>
                            <th class="__subtotal u-align-center">設定工数</th>
                            <th class="__total u-align-right">合計工数</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="page in items.pages" :key="page.pageId">
                            <td colspan="4" style="padding: 0;">
                                <table class="u-table-flow-item-row">
                                    <tbody>
                                        <tr>
                                            <td class="__item u-align-left">
                                                <div class="u-flex-left-center">
                                                    <span class="__text u-text-bold">{{ page.pageName }}</span>
                                                    <span class="__text u-text-caption mgl-016">（{{ page.pageType }}）</span>
                                                </div>
                                            </td>
                                            <td class="__assign u-align-left">
                                                <span v-for="(assign, indexPage) in page.assigns" :key="indexPage" class="__member">{{ assign.member }}（{{ assign.manHours }}）</span>
                                            </td>
                                            <td class="__subtotal u-align-center">{{ page.manHours }}人日</td>
                                            <td class="__total u-text-bold u-align-right" :rowspan="page.options.length + 1">{{ totalPageManHours(page.pageId) }}人日</td>
                                        </tr>
                                        <tr v-for="option in page.options" :key="option.optionId">
                                            <td class="__item u-align-left">
                                                <div class="u-flex-left-center mgl-024">
                                                    <span class="__text">{{ option.optionName }}</span>
                                                    <span class="__text u-text-caption mgl-016">（オプション）</span>
                                                </div>
                                            </td>
                                            <td class="__assign u-align-left">
                                                <span v-for="(assign, indexOption) in option.assigns" :key="indexOption" class="__member">{{ assign.member }}（{{ assign.manHours }}）</span>
                                            </td>
                                            <td class="__subtotal u-align-center">{{ option.manHours }}人日</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr v-for="other in items.others" :key="other.itemId" class="__other-item-row">
                            <td class="__item u-align-left">
                                <div class="u-flex-left-center">
                                    <span class="__text u-text-bold">{{ other.itemName }}</span>
                                </div>
                            </td>
                            <td class="__assign u-align-left">
                                <span v-for="(assign, indexOther) in other.assigns" :key="indexOther" class="__member">{{ assign.member }}（{{ assign.manHours }}）</span>
                            </td>
                            <td class="__total u-text-bold u-align-right" colspan="2">{{ other.manHours }}人日</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="__block-depth-3 __block-depth-3--result-block">
            <div class="u-flex-right-center">
                <dl class="u-list-format-report __list">
                    <div class="__item __total">
                        <dt class="__term">全体工数</dt>
                        <dd class="__body">{{ manHours }}人日</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
    <div v-if="!isEdit" class="u-flex-center-center mgt-064">
        <a :href="generateRoute(project.line_number)" class="u-button-default u-button-default--large u-button-default--secondary __button">案件情報・サイトマップ</a>
    </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
// -- END vuelidate
import FlowTablePagesItemRow from "../components/flow/FlowTablePagesItemRow.vue";
import FlowTableOthersItemRow from "../components/flow/FlowTableOthersItemRow.vue";
export default {
	setup () {
    const validation_scope = 'flow'
    return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
  },
  components: {
    FlowTablePagesItemRow,
    FlowTableOthersItemRow,
  },
	props: {
		pages: {
			type: Array,
			default: []
		},
		optional_costs: {
			type: Array,
			default: []
		},
    project: {
      type: Object,
      default: {}
    }
	},
  data() {
    return {
      items: {
        pages: [],
        others: [],
      },
      manHours: 0,
      currentOthersId: 0,
      isEdit: false,
      tempItems: {},
      tempManHours: 0,
			// -- add by backend
			arrDelete: {
        pages: [],
        page_optional_costs: [],
        optional_costs: []
      },
			// -- END add by backend
    };
  },
  async created() {
    await this.fetchData();
    await this.totalManHours();
  },
  methods: {
    changeEditMode() {
      this.isEdit = !this.isEdit;
    },
    tempItemsCreate() {
      this.tempItems = JSON.parse(JSON.stringify(this.items));
      this.tempManHours = this.manHours;
    },
    cancelItemsUpdate() {
      this.items = this.tempItems;
      this.manHours = this.tempManHours;
      this.isEdit = false;
      this.resetArrDelete(); // reset arrDelete
    },
    totalManHours() {
      const pagesManHours = this.items.pages
        .map((item) => {
          return item.manHours;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const optionsManHours = this.items.pages
        .map((item) => {
          return item.options
            .map((option) => {
              return option.manHours;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const othersManhours = this.items.others
        .map((item) => {
          return item.manHours;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      this.manHours = pagesManHours + optionsManHours + othersManhours;
    },
    addItemOthers() {
      this.items.others.push({
        itemId: this.currentOthersId,
        itemName: null,
        manHours: null,
      });
      this.currentOthersId++;
    },
    deletePageItem(payload) {
      // -- set deleteId
      const page = this.items.pages.find(page => page.pageId == payload);
      this.setDeleteId(page.id, 'pages')
      // -- END set deleteId

      const filtered = this.items.pages.filter((item) => {
        return item.pageId !== payload;
      });
      this.items.pages = filtered;
      this.totalManHours();
    },
    deleteOthersItem(payload) {
      // -- set deleteId
      const other = this.items.others.find(other => other.itemId == payload);
      this.setDeleteId(other.ocId, 'optional_costs')
      // -- END set deleteId

      const filtered = this.items.others.filter((item) => {
        return item.itemId !== payload;
      });
      this.items.others = filtered;
      this.totalManHours();
    },
    totalPageManHours(pageId) {
      const targetPage = this.items.pages.find((page) => {
        return page.pageId === pageId;
      });
      const optionsTotalManHours = targetPage.options
        .map((option) => {
          return option.manHours;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      return targetPage.manHours + optionsTotalManHours;
    },
    fetchData(initPage = null, initOther = null) {
      this.items = {
        pages: [],
        others: [],
      };

			// -- set items.pages
      const pages = initPage != null ? initPage : this.pages;
			pages.forEach((page, p_index) => {
				let formPage = {
					pageId: p_index,
					pageName: page.title,
					pageType: page.page_type.title,
					manHours: page.cost.cost,
					assigns: [],
					options: [],
          id: page.id,
				};

				// -- set pages.assigns
				page.cost.assignments.forEach((page_cost_assigment, pca_index) => {
					let form_assign = {
						member: page_cost_assigment.contractor_user.name,
            manHours: page_cost_assigment.cost,
					};

					formPage.assigns.push(form_assign);
				});
				// -- END set pages.assigns

				// -- set pages.options
				page.page_optional_costs.forEach((page_optional_cost, poc_index) => {
					let form_option = {
						optionId: poc_index,
						optionName: page_optional_cost.title,
						manHours: page_optional_cost.cost.cost,
						assigns: [],
            pocId: page_optional_cost.id,
					};

					// -- set form_option.assigns
					page_optional_cost.cost.assignments.forEach((assignment, poca_index) => {
						let form_poca = {
							member: assignment.contractor_user.name,
							manHours: assignment.cost,
						};
						form_option.assigns.push(form_poca);
					});
					// -- END set form_option.assigns

					formPage.options.push(form_option)

				});
				// -- END set pages.options

				this.items.pages.push(formPage);
			});
			// -- END set items.pages

			// -- set items.others
      const others = initOther != null ? initOther : this.optional_costs;
			others.forEach((optional_cost, oc_index) => {
				let form_option_cost = {
					optionalCostId: optional_cost.id,
					itemId: oc_index,
					itemName: optional_cost.title,
					manHours: optional_cost.cost.cost,
					assigns: [],
          ocId: optional_cost.id,
				};

				// -- set form_option_cost.assigns
				optional_cost.cost.assignments.forEach((optional_cost_assigment, oca_index) => {
					let form_optional_cost_assigment = {
						member: optional_cost_assigment.contractor_user.name,
						manHours: optional_cost_assigment.cost,
					};
					form_option_cost.assigns.push(form_optional_cost_assigment);
				});
				// -- END set form_option_cost.assigns

				this.items.others.push(form_option_cost);
			});
			// -- END set items.others

      this.currentOthersId = this.items.others.length;
    },
		// -- add by backend
		async submit() {
			const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- set form
        let form = {
          items: this.items,
          deletes: this.arrDelete,
          projectId: this.project.id
        };
        // -- END form

        await axios.put(route('contractor.api.cost.update'), form)
          .then((response) => {
            if (response.status == 200) {
              this.fetchData(response.data.record.pages, response.data.record.optional_costs);
							this.changeEditMode();
            }
          })
          .catch((error) => {
            console.log(error)
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
		},
		setDeleteId(id, type) {
      this.arrDelete[type].push(id);
		},
    setDeleteOptionalCostId(data) {
      this.setDeleteId(data.id, data.type)
    },
    resetArrDelete() {
      this.arrDelete = {
        pages: [],
        page_optional_costs: [],
        optional_costs: []
      };
    },
		// -- END add by backend
    generateRoute(line_number){
        return route('contractor.project.show', { project: line_number });
    },
  },
};
</script>
