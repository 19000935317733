<template>
 <div class="">
  <button class="u-button-default u-button-default--medium u-button-default--secondary mgr-016 __button js-modal-open-tax-settings" type="button" @click.prevent="modalShow(1)">
    課税設定
  </button>

  <!-- modal tax -->
	<div class="c-modal js-modal-tax-settings" :class="modal ? 'js-modal--shown' : '' ">
		<div class="__overlay">
			<div class="__panel __panel--small p-modal-tax-settings">
				<div class="u-box-modal-defalut">
					<h3 class="__title">課税設定</h3>
					<div class="__content">
						<form action="" method="post">
							<p class="__text">現在作成もしくは編集している見積もり・請求計算に適用させる税率の設定を行なってください。</p>
							<dl class="u-list-input-label-set __list mgt-024">
								<div class="__item">
									<dt class="__term">消費税設定</dt>
									<dd class="__body">
										<div class="u-form-input-group">
                      <label class="u-form-radio-default u-form-input-radio"
                        v-for="taxDisplayOption in all_tax_option.taxDisplayOption"
                        :key="taxDisplayOption.value">
                        <input type="radio" name="tax_display" v-model="form.taxDisplayOption" :value="taxDisplayOption.value">
                        <span class="__text">{{ taxDisplayOption.name }}</span>
                      </label>
										</div>
									</dd>
								</div>
								<div class="__item">
									<dt class="__term">消費税率設定</dt>
									<dd class="__body">
										<div class="u-form-input-group">
											<label class="u-form-radio-default u-form-input-radio"
                        v-for="taxRate in all_tax_option.taxRate"
                        :key="taxRate.value">
                        <input type="radio" name="tax_rate" v-model="form.taxRateOption" :value="taxRate.value">
                        <span class="__text">{{ taxRate.name }}</span>
                      </label>
										</div>
									</dd>
								</div>
								<div class="__item">
									<dt class="__term">消費税端数処理</dt>
									<dd class="__body">
										<div class="u-form-input-group">
											<label class="u-form-radio-default u-form-input-radio"
                        v-for="taxCalculationMethod in all_tax_option.taxCalculationMethod"
                        :key="taxCalculationMethod.value">
                        <input type="radio" name="tax_rounding" v-model="form.taxCalculationMethod" :value="taxCalculationMethod.value">
                        <span class="__text">{{ taxCalculationMethod.name }}</span>
                      </label>
										</div>
									</dd>
								</div>
								<div class="__item">
									<dt class="__term">源泉徴収税設定</dt>
									<dd class="__body">
										<div class="u-form-input-group">
											<label class="u-form-radio-default u-form-input-radio"
                        v-for="taxWithholdingOption in all_tax_option.taxWithholdingOption"
                        :key="taxWithholdingOption.value">
                        <input type="radio" name="tax_withholding" v-model="form.taxWithholdingOption" :value="taxWithholdingOption.value">
                        <span class="__text">{{ taxWithholdingOption.name }}</span>
                      </label>
										</div>
									</dd>
								</div>
							</dl>
							<div class="__button-wrap u-flex-center-center">
								<button class="js-modal-close-tax-settings u-button-default u-button-default--large u-button-default--negative __button" type="button" @click.prevent="modalShow()">
                  閉じる
                </button>
								<input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="更新" @click.prevent="submit">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- END modal tax -->
 </div>
</template> 

<script>
  export default {
    props: {
      default_documen_setting: {
        type: Object,
        default: {},
        required: true
      },
      documen_setting: {
        type: Object,
        default: {},
        required: false
      },
      all_tax_option : {
        type: Object,
        default: {}
      },
      page_type: {
        type: String,
        default: ''
      },
      estimateId : {
        type: String,
        default: '',
        required: true
      }
    },
    data() {
      return {
        form: {
          taxDisplayOption: '',
          taxRateOption: '',
          taxCalculationMethod: '',
          taxWithholdingOption: '',
        },
        modal: 0
      }
    },
    created () {
      this.setDocumentSetting();
    },
    emits: [
      'update_tax_option'
    ],
    methods: {
      async submit() {
        if (this.page_type == 'create') {
          this.$emit('update_tax_option', this.form);  
        }
        else {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          let form = {
            type_action: 'update_tax',
            tax_display_option: this.form.taxDisplayOption.toString(),
            tax_rate_option: this.form.taxRateOption.toString(),
            tax_calculation_method: this.form.taxCalculationMethod.toString(),
            tax_withholding_option: this.form.taxWithholdingOption.toString(),
          };
          
          await axios.post(route('contractor.api.estimate.update', {id: this.estimateId}), form)
            .then((response) => {
              this.$emit('update_tax_option', this.form);
            })
            .catch((error) => {
              console.log(error)
            });
  
          // -- hide loader
          loader.hide();
          // -- END hide loader
        }

        this.modalShow();
      },
      setDocumentSetting() {
        let ObjectName = 'default_documen_setting';
        if (this.page_type != 'create') {
          ObjectName = 'documen_setting';
        }

        this.form.taxDisplayOption = this[ObjectName].tax_display_option;
        this.form.taxRateOption = this[ObjectName].tax_rate_option;
        this.form.taxCalculationMethod = this[ObjectName].tax_calculation_method;
        this.form.taxWithholdingOption = this[ObjectName].tax_withholding_option;
      },
      modalShow(isShow = 0) {
        this.modal = isShow;
      },
    },
  }
</script>