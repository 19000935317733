<template>
  <main class="l-main p-page p-estimation-page p-app-page p-service-page">
    <article class="p-page-title"> 
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center"> 
                <h1 class="u-heading-page-title __title">見積もり</h1>
                <div class="u-flex-right-center">
                  <estimation-button-document-setting 
                    :default_documen_setting="estimate.documentSetting" 
                    :documen_setting="documentSetting"
                    :all_tax_option="estimate.allTaxOption" 
                    :page_type="page_type"
                    :estimateId="estimate.estimate.id"
                    @update_tax_option="updateTaxOption"/>
                  <estimation-button-duplicate-delete 
                    :estimate="estimate.estimate"
                    :routeDelete="estimate.routeDelete"
                    :routeRedirect="estimate.routeRedirect" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1" id="vue_estimarion">
              <estimate 
                :dataEstimate="estimate.estimate" 
                :project="estimate.estimate.project" 
                :total_all_page_cost="estimate.totalAllPageCost" 
                :tax_data="estimate.tax"
                :all_tax_option="estimate.allTaxOption"
                :defaultTaxRate="defaultTaxRate"
                :documentSettingPublisherSeal="estimate.documentSetting.publisher_seal"
                :documentSettingPublisherSealUrl="estimate.documentSetting.publisher_seal_url" />
            </div>
          </div>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  props: {
    estimate: {
      type: Object,
      default: {}
    },
    page_type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      taxOption: {
        taxCalculationMethod: 0,
        taxDisplayOption: 0,
        taxRateOption: 0,
        taxWithholdingOption: 0,
      },
      documentSetting: {},
      defaultTaxRate: '10%'
    }
  },
  created () {
    this.setTaxOption();
    if (this.page_type != 'create') {
      this.setDocumentSetting();
    }
    this.setDefaultTaxRate(this.estimate.estimate.tax_rate_option);
  },
  methods: {
    setTaxOption() {
      this.taxOption.taxCalculationMethod = this.estimate.documentSetting.tax_calculation_method;
      this.taxOption.taxDisplayOption = this.estimate.documentSetting.tax_display_option;
      this.taxOption.taxRateOption = this.estimate.documentSetting.tax_rate_option;
      this.taxOption.taxWithholdingOption = this.estimate.documentSetting.tax_withholding_option;
    },
    updateTaxOption(payload) {
      this.taxOption = payload;

      // for update defaultTaxRate
      this.setDefaultTaxRate(payload.taxRateOption);
    },
    setDocumentSetting() {
      this.documentSetting = {
        tax_display_option: this.estimate.estimate.tax_display_option,
        tax_rate_option: this.estimate.estimate.tax_rate_option,
        tax_calculation_method: this.estimate.estimate.tax_calculation_method,
        tax_withholding_option: this.estimate.estimate.tax_withholding_option,
      };
    },
    setDefaultTaxRate(value = 0) {
      const taxRate = this.estimate.allTaxOption.taxRate.find(tax => tax.value == value);
      this.defaultTaxRate = taxRate.name;
    }
  },
}
</script>