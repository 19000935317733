'use strict';

export function TableOrderHighlight({rowGroupSelector, rowSelector}) {
  const tableRowGroups = document.querySelectorAll(rowGroupSelector);
  const tableRows = document.querySelectorAll(rowSelector);
  tableRowGroups.forEach((tableRowGroup) => {
    tableRowGroup.addEventListener('mouseenter', (event) => {
      event.target.parentNode.classList.add('__highlight');
    });
    tableRowGroup.addEventListener('mouseleave', (event) => {
      event.target.parentNode.classList.remove('__highlight');
    });
  });
  tableRows.forEach((tableRow) => {
    tableRow.addEventListener('mouseenter', (event) => {
      event.target.classList.add('__highlight');
    });
    tableRow.addEventListener('mouseleave', (event) => {
      event.target.classList.remove('__highlight');
    });
  });
}