<template>
    <div class="u-box-content-default">
        <h4 class="u-heading-box-title mgt-016">ご請求・支払い</h4>
        <div class="u-table-wrapper mgt-024">
            <table class="u-table-sheet">
                <tbody>
                <tr>
                    <th>現在ご契約中のプラン</th>
                    <td>
                    <div class="u-flex-between-top">
                        <span class="mgr-024" v-if="!contractor.isTrial">
                            {{ contractor.contractorPlanDesc }}：{{ contractor.contractorPaymentPeriodDesc }}利用 {{ contractor.isTrial ? '(Trial)' : '' }}<br>
                            【支払い済み】{{ moment(contractor.plan_start_date).format('YYYY/MM/DD') }}〜{{ moment(contractor.plan_expiration_date).format('YYYY/MM/DD') }}
                        </span>
                        <span class="mgr-024" v-else>
                            スタンダードプラン：トライアル <br>
                            {{ moment(contractor.plan_start_date).format('YYYY/MM/DD') }}〜{{ moment(contractor.plan_expiration_date).format('YYYY/MM/DD') }}
                        </span>
                        <div class="u-flex-right-center">
                            <a class="u-button-default u-button-default--small u-button-default--secondary" :href="toEditPlan()" v-if="!contractor.isTrial" data-cy="btn-update_plan">
                                変更
                            </a>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>次回ご請求</th>
                    <td v-if="!contractor.isTrial">{{ moment(contractor.plan_expiration_date).format('YYYY/MM/DD') }}（{{ contractor.nextSchedulePlan }}）</td>
                    <td v-else>-</td>
                </tr>
                <tr>
                    <th>支払い方法・支払い期間</th>
                    <td>
                    <div class="u-flex-between-top">
                        <span class="mgr-024" v-if="!contractor.isTrial">
                            {{ contractor.contractorPaymentMethodDesc }}・{{ contractor.contractorPaymentPeriodDesc }}
                        </span>
                        <span class="mgr-024" v-else>-</span>
                        <div class="u-flex-right-center">
                            <a class="u-button-default u-button-default--small u-button-default--secondary" :href="toEditContract()" v-if="!contractor.isTrial" data-cy="btn-update_contract">
                                変更
                            </a>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>前回のご請求情報</th>
                    <td v-if="!contractor.isTrial">
                        {{ contractor.previousPuschaseDate }} {{ numberWithCommas(contractor.previousPuschaseTotalAmount) }}円 
                        ({{contractor.previousPlan}}) クレジットカード払い
                    </td>
                    <td v-else>-</td>
                </tr>
                </tbody>
            </table>
        </div>
        <h4 class="u-heading-box-title mgt-024">契約者情報</h4>
        <div class="u-table-wrapper mgt-024">
            <table class="u-table-sheet">
                <tbody>
                <tr>
                    <th>契約者名</th>
                    <td>
                        <div v-if="isEdit.name">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input v-model="name" type="text" placeholder="大阪 太郎" @blur="submitUpdateAccount('name', 'contractor_user_name')">
                            </span>
                            <!-- error message -->
                            <p v-for="(error, index) of v$.name.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                                {{ error.$message }}
                            </p>
                            <!-- error message -->
                        </div>
                        <div v-else @click="changeEditMode('name')" class="p-estimation-editable-area p-estimation-editable-area--block">{{ name }}</div>
                    </td>
                </tr>
                <tr>
                    <th>会社・組織名</th>
                    <td>
                        <div v-if="isEdit.group">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input v-model="group" type="text" placeholder="株式会社Pyatto" @blur="submitUpdateAccount('group', 'contractor_name')">
                            </span>
                            <!-- error message -->
                            <p v-for="(error, index) of v$.group.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                                {{ error.$message }}
                            </p>
                            <!-- error message -->
                        </div>
                        <div v-else @click="changeEditMode('group')" class="p-estimation-editable-area p-estimation-editable-area--block">{{ group }}</div>
                    </td>
                </tr>
                <tr>
                    <th>住所</th>
                    <td>
                        <div v-if="isEdit.address">
                            <div class="u-flex-between-top __block __block--zipcode-prefecture-city">
                                <div class="__column __column--zipcode mgr-016">
                                    <dl class="u-list-input-label-set __list">
                                    <div class="__item">
                                        <dt class="__term">郵便番号</dt>
                                        <dd class="__body">
                                            <span class="u-form-input-default u-form-input-text-box">
                                                <input type="text" placeholder="000-0000" v-model="zipcode" ref="account_zipcode">
                                            </span>
                                            <!-- error message -->
                                            <p v-for="(error, index) of v$.zipcode.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                                                {{ error.$message }}
                                            </p>
                                            <!-- error message -->
                                        </dd>
                                    </div>
                                    </dl>
                                </div>
                                <div class="__column __column--prefecture mgr-016">
                                    <dl class="u-list-input-label-set __list">
                                    <div class="__item">
                                        <dt class="__term">都道府県</dt>
                                        <dd class="__body">
                                        <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                                            <select v-model="prefecture" ref="account_prefecture">
                                            <prefecture-options />
                                            </select>
                                        </span>
                                        </dd>
                                    </div>
                                    </dl>
                                </div>
                                <div class="__column __column--city u-flex-grow-1">
                                    <dl class="u-list-input-label-set __list">
                                    <div class="__item">
                                        <dt class="__term">市区町村・番地</dt>
                                        <dd class="__body">
                                            <span class="u-form-input-default u-form-input-text-box">
                                                <input type="text" placeholder="〇〇区〇〇市" name="" v-model="city" ref="account_city">
                                            </span>
                                            <!-- error message -->
                                            <p v-for="(error, index) of v$.city.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                                                {{ error.$message }}
                                            </p>
                                            <!-- error message -->
                                        </dd>
                                    </div>
                                    </dl>
                                </div>
                            </div>
                            <div class="u-flex-between-top __block __block--address">
                                <div class="__column __column--address u-flex-grow-1">
                                    <dl class="u-list-input-label-set __list">
                                        <div class="__item">
                                            <dt class="__term">建物名・部屋番号など</dt>
                                            <dd class="__body">
                                                <span class="u-form-input-default u-form-input-text-box">
                                                    <input type="text" placeholder="〇〇ビル〇〇階" v-model="address" ref="account_address">
                                                </span>
                                                <!-- error message -->
                                                <p v-for="(error, index) of v$.address.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
                                                    {{ error.$message }}
                                                </p>
                                                <!-- error message -->
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div class="u-flex-right-center __block">
                                <button @click="changeEditMode('address'); cancelUpdateAccountAddress()" class="u-button-default u-button-default--small u-button-default--negative __button mgr-016">キャンセル</button>
                                <button @click="submitUpdateAccount('address', 'contractor_address')" class="u-button-default u-button-default--small u-button-default--primary __button">
                                    確定
                                </button>
                            </div>
                        </div>
                        <div v-else @click="changeEditMode('address')" class="p-estimation-editable-area p-estimation-editable-area--block">
                            <span v-if="isAddressNull">
                                -
                            </span>
                            <span v-else>
                                〒{{ zipcode }} {{ prefecture }}{{ city }}{{ address }}
                            </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    // -- vuelidate
    import useVuelidate from '@vuelidate/core'
    import { helpers, required, minLength, maxLength } from '@vuelidate/validators'
    // -- END vuelidate
    import moment from 'moment';
    import PrefectureOptions from '../components/common/PrefectureOptions.vue';
    export default {
        setup () {
            return { v$: useVuelidate() }
        },
        components: {
            PrefectureOptions
        },
        props: {
            // -- add by backend
            contractor: {
                type: Object,
                default: {}
            },
            // -- END add by backend
        },
        data() {
            return {
                isEdit: {
                    name: false,
                    group: false,
                    address: false
                },
                name: null,
                group: null,
                zipcode: null,
                prefecture: '',
                city: null,
                address: null,
                // -- add by backend
                temporaryForm: {},
                isAddressNull: false,
                // -- END add by backend
            }
        },
        validations() {
            return {
                name: {
                    required: helpers.withMessage('契約者名は必須項目です。', required),
                    minLength: helpers.withMessage(
                        ({ $params }) => `契約者名は、${$params.min}文字以上で入力してください。`, minLength(1)
                    ),
                    maxLength: helpers.withMessage(
                        ({ $params }) =>`契約者名は、${$params.max}文字以下で入力してください。`, maxLength(255)
                    )
                },
                group: {
                    required: helpers.withMessage('会社・組織名は必須項目です。', required),
                    minLength: helpers.withMessage(
                        ({ $params }) => `会社・組織名は、${$params.min}文字以上で入力してください。`, minLength(1)
                    ),
                    maxLength: helpers.withMessage(
                        ({ $params }) =>`会社・組織名は、${$params.max}文字以下で入力してください。`, maxLength(255)
                    )
                },
                zipcode: {
                    maxLength: helpers.withMessage(
                        ({ $params }) =>`郵便番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
                    )
                },
                city: {
                    maxLength: helpers.withMessage(
                        ({ $params }) =>`市区町村・番地は、${$params.max}文字以下で入力してください。`, maxLength(255)
                    )
                },
                address: {
                    maxLength: helpers.withMessage(
                        ({ $params }) =>`建物名・部屋番号などは、${$params.max}文字以下で入力してください。`, maxLength(255)
                    )
                },
            }
        },
        created() {
            this.moment = moment;
            this.fetchData();
            this.checkAddress(); // for check contractor address
        },
        methods: {
            changeEditMode(key) {
                this.isEdit[key] = !this.isEdit[key];
                if (this.isEdit[key]) {
                    this.setTemporary(key); // for set temporary form
                }
            },
            updateAccountAddress() {
                this.zipcode = this.$refs.account_zipcode.value;
                this.prefecture = this.$refs.account_prefecture.value;
                this.city = this.$refs.account_city.value;
                this.address = this.$refs.account_address.value;
            },
            fetchData() {
                this.name = this.contractor.contractorUserName;
                this.group = this.contractor.name;
                this.zipcode =  this.contractor.postcode != null ? this.contractor.postcode : '';
                this.prefecture = this.contractor.prefecture;
                this.city = this.contractor.city_subarea;
                this.address = this.contractor.rest_of_address;
            },
            // -- add by backend
            cancelUpdateAccountAddress() {
                this.zipcode = this.temporaryForm.zipcode;
                this.prefecture = this.temporaryForm.prefecture;
                this.city = this.temporaryForm.city;
                this.address = this.temporaryForm.address;

                this.checkAddress();
            },
            /** 
             * check address
             * if all column null (postcode, prefecture, city_subarea, rest_of_address)
             * show '-' so that it can be clicked
            */
            checkAddress() {
                let isNull = [];
                const arrColumn = ['zipcode', 'prefecture', 'city', 'address'];
                arrColumn.forEach(colum => {
                    if (this[colum] == null || this[colum] == '') {
                        isNull.push(true)
                    }
                    else {
                        isNull.push(false)
                    }
                });
                
                this.isAddressNull = isNull.includes(false) ? false : true;
            },
            async submitUpdateAccount(key, type) {
                // -- check validation
                const isFormCorrect = await this.v$.$validate();
                if (isFormCorrect) {
                    // -- loader show
                    let loader = this.$loading.show();
                    // -- END loader show

                    let form = {};
                    // -- prepare form
                    switch (type) {
                        case 'contractor_name':
                            form = {
                                contractors: {
                                    name: this.group,
                                }
                            };
                            break;
                        case 'contractor_address':
                            form = {
                                contractors: {
                                    postcode: this.zipcode,
                                    prefecture: this.prefecture,
                                    city_subarea: this.city,
                                    rest_of_address: this.address,
                                }
                            };
                            break;
                        case 'contractor_user_name':
                            form = {
                                contractor_users: {
                                    name: this.name
                                }
                            };
                            break;
                        default:
                            break;
                    }
                    // -- END prepare form
    
                    // -- submit
                    await axios.put(route('contractor.api.setting.account.update'), form)
                        .then((response) => {
                            if (response.status == 200) {
                                // for update data
                                this.updateData(key, response.data.record);

                                // for check address if all null show '-'
                                if (key == 'address') this.checkAddress();

                                // change editMode
                                this.changeEditMode(key);

                                // -- hide loader
                                loader.hide();
                                // -- END hide loader
                            }
                        })
                        .catch((error) => {
                            // console.log(error)
                            // -- hide loader
                            loader.hide();
                            // -- END hide loader
                        });
                    // -- END submit
                }
                // -- END check validation

            },
            setTemporary(key) {
                switch (key) {
                    case 'name':
                        this.temporaryForm = {
                            name: this.name
                        };
                        break;
                    case 'group':
                        this.temporaryForm = {
                            group: this.group
                        };
                        break;
                    case 'address':
                        this.temporaryForm = {
                            zipcode: this.zipcode,
                            prefecture: this.prefecture,
                            city: this.city,
                            address: this.address,
                        };
                        break;
                    default:
                        break;
                }
            },
            toEditPlan() {
                return route('contractor.settings.account.edit.plan');
            },
            toEditContract() {
                return route('contractor.settings.account.edit.contract');
            },
            updateData(key, res) {
                switch (key) {
                    case 'name':
                        this.name = res.contractorUserName;
                        break;
                    case 'group':
                        this.group = res.name;
                        break;
                    case 'address':
                        this.zipcode = res.postcode;
                        this.prefecture = res.prefecture;
                        this.city = res.city_subarea;
                        this.address = res.rest_of_address;
                        break;
                }
            },
            numberWithCommas(x) {
    	        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		    },
            // -- END add by backend
        }
    }
</script>
