<template>
<div class="__block-depth-2">
  <div class="u-box-content-default">
    <div class="__block-depth-3 __block-depth-3--header-block mgt-024">
      <div class="u-flex-left-center">
        <figure class="__thumbnail">
          <img class="__image" :src="getProfileImage()" alt="">
        </figure>
        <div class="__infomation">
          <h2 class="__name">{{ offer_data.contractor_user.name }}</h2>
          <h3 class="__position">{{ offer_data.contractor_user.position != null ? offer_data.contractor_user.position : '-' }}</h3>
        </div>
      </div>
      <div class="__plan u-flex-right-baseline mgt-016"><span class="__label">提案見積金額</span>
        <h4 class="__price">{{ offer_data.estimate }}</h4>
      </div>
    </div>
    <div class="__block-depth-3 __block-depth-3--content-block mgt-024">
      <div class="__project u-flex-left-center">
        <span class="__label">対象案件名</span>
        <h5 class="__content">{{ offer_data.recruit.title }}</h5>
      </div>
      <div class="__description mgt-024 display-textarea-data">
        {{ offer_data.content }}
      </div>
      <div class="__attachment u-flex-right-center mgt-024">
        <!-- download file -->
        <button class="u-button-default u-button-default--small u-button-default--secondary" 
          @click.prevent="downloadFile()"
          target="_blank"
          v-if="offer_data.attached_file_url != null">
          添付ファイルをダウンロード
        </button>
        <!-- END download file -->
      </div>
    </div>
    <div class="__block-depth-3 __block-depth-3--submit-block mgt-024">
      <div class="__contact" v-show="offer_data.offer_status_option_id === 3">
        <!-- 応募のステータスが応募中の場合に表示 / Displayed when the application status is Applying -->
        <p class="__step-1" style="display: none;">応募者の連絡先は商談中のステータス時に公開されます</p>
        <!-- 応募のステータスが商談中の場合に表示 / Displayed when the status of the application is in negotiation -->
        <p class="__step-2" style="display: block;">
          <span class="__lead">連絡先メールアドレス</span>
          <span class="__email">{{ offer_data.contractor_user.email }}</span>
        </p>
      </div>
      <div class="u-flex-center-center mgt-024 __button-group">
        <button class="u-button-default u-button-default--large u-button-default--secondary __button" 
          v-show="offer_data.offer_status_option_id == 1 || offer_data.offer_status_option_id == 3" 
          @click.prevent="updateOfferStatus(2)">
          お断りの連絡
        </button>
        <!-- 応募のステータスが応募中の場合に表示 / Displayed when the application status is Applying -->
        <button class="u-button-default u-button-default--large u-button-default--primary __button" style="display: block;" 
          v-show="offer_data.offer_status_option_id === 1"
          @click.prevent="updateOfferStatus(3)">
          この応募者と商談
        </button>
        <!-- 応募のステータスが応募中の場合に表示-->
        <button class="u-button-default u-button-default--large u-button-default--primary __button"
          v-show="offer_data.offer_status_option_id == 3" 
          @click.prevent="updateOfferStatus(4)"
        >
          この応募者へ発注
        </button>
      </div>
    </div>
  </div>
  <div class="u-flex-center-center">
    <a class="u-button-default u-button-default--large u-button-default--negative __button mgt-032" :href="backToOfferDetail()">
      案件詳細に戻る
    </a>
  </div>
</div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      offer_data: this.offer,
    }
  },
  methods: {
    backToOfferDetail() {
      return route('contractor.offer.detail', { recruit: this.offer_data.recruit.id });
    },
    getProfileImage() {
      if (this.offer_data.contractor_user.profile_image_url != null) {
        let fileName = this.offer_data.contractor_user.profile_image_url.split('/');
        return route('contractor.api.setting.member.image.show', { filename: fileName[2]});
      }
      return '/assets/img/site/thumbnail_default_min.png';
    },
    async updateOfferStatus(status) {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      // -- prepare formData
      const form = {
        offer_status_option_id: status
      };
      // -- END prepare formData

      // -- submit
      await axios.put(route('contractor.api.offer.update', { id: this.offer_data.id }), form)
        .then((response) => {
          if (response.status == 200) {
            this.offer_data.offer_status_option_id = status;
          }
        })
        .catch((error) => {
          console.log(error)
        });
      // -- END submit

      // -- hide loader
      loader.hide();
      // -- END hide loader
    },
    downloadFile() {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      const arrFileName = this.offer_data.attached_file_url.split('/');
      
      // -- donwload file
      axios({
          url: route('contractor.offer.file.download', { filename: arrFileName[2] }), // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
      }).then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', arrFileName[2]);
          document.body.appendChild(fileLink);
          fileLink.click();

          // -- hide loader
          loader.hide();
          // -- END hide loader
      }).catch((error) => {
        console.log(error)

        // -- hide loader
        loader.hide();
        // -- END hide loader
      });
      // -- END donwload file

      // old
      // return route('contractor.offer.file.download', { filename: arrFileName[2] })
    },
  },
}
</script>

<style>

</style>