'use strict';

export function ShowManHoursPanel({element, openTrigger, closeTrigger}) {
  const panel = document.querySelector(element);
  const buttonOpen = document.querySelector(openTrigger);
  const buttonClose = document.querySelector(closeTrigger);
  if(panel) {
    buttonClose.addEventListener('click', () => {
      panel.classList.remove('c-panel-man-hours-list--open');
    });
    buttonOpen.addEventListener('click', () => {
      panel.classList.add('c-panel-man-hours-list--open');
    });
  }
}