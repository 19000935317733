<template>
  <option value="">選択する</option>
  <option v-for="prefecture in prefectures" :key="prefecture" :value="prefecture">{{ prefecture }}</option>
</template>

<script>
  import { PrefectureList } from '../../../modules/prefecture-list.js';
  export default {
    name: 'PrefectureOptions',
    data() {
      return {
        prefectures: PrefectureList()
      }
    }
  }
</script>