<template>
  <tr>
    <td colspan="3" style="padding: 0">
      <table class="u-table-assignment-option-row">
        <tr>
          <td class="__item">
            <div class="u-flex-left-center">
              <div class="u-form-input-group">
                <label class="u-form-checkbox-default u-form-input-checkbox">
                  <input
                    v-model="option.isBulkAssign"
                    type="checkbox"
                    name="bulk_registration_target"
                    @change="emitBulkRegistrationOff()"
                  /><span class="__text"></span>
                </label>
              </div>
              <span class="__text">{{ option.optionName }}</span>
            </div>
          </td>
          <td class="__prescribed u-align-center">
            {{ option.optionPrescribed }}人日
          </td>
          <td class="__assignment">
            <ul class="__list">
              <li
                v-for="(assign, index) in option.optionAssign"
                :key="assign.assignId"
                class="__item u-flex-between-center u-flex-wrap"
              >
                <span
                  class="
                    u-form-select-default
                    u-form-input-select
                    u-form-input-select--large
                    __menber
                    u-flex-grow-1
                  "
									:class="
                    v$.option.optionAssign.$errors.length > 0
                    ? v$.option.optionAssign.$each.$response.$errors[index].member.length > 0 ? 'backend-border-danger' : ''
                    : ''
                  "
                >
                  <select v-model="assign.member">
                    <option
                      v-for="(contractorUser, index) of contractorUsers"
                      :key="index"
                      :value="contractorUser.id"
                    >
                      {{ contractorUser.name }}
                    </option>
                  </select>
                </span>
                <span
									class="u-form-input-default __man-hours"
									:class="
                    v$.option.optionAssign.$errors.length > 0
                    ? v$.option.optionAssign.$each.$response.$errors[index].manHours.length > 0 ? 'backend-border-danger' : ''
                    : ''
                  "
								>
                  <input
                    v-model="assign.manHours"
                    class="u-align-right"
                    type="number"
                    placeholder="工数入力"
                    name=""
                    @blur="emitTotalManHours()"
                  />
                </span>
                <span class="__text">人日</span>
                <button
                  class="__button __button--delete"
                  type="button"
                  @click="
                    deleteAssign(assign.assignId);
                    emitTotalManHours();
                  "
                >
                  <img
                    class="__image"
                    src="/assets/img/site/icon_delete_small_min.svg"
                    alt="削除"
                  />
                </button>
                <div class="__inner-row-error-messages">
                    <div v-if="v$.option.optionAssign.$errors.length > 0">
                        <p class="mgt-008 u-text-varidate-label" v-for="(memberError, reIndex) of v$.option.optionAssign.$errors[0].$response.$errors[index].member" :key="reIndex">
                            {{ memberError.$message }}
                        </p>
                    </div>
                    <div v-if="v$.option.optionAssign.$errors.length > 0">
                        <p class="mgt-008 u-text-varidate-label" v-for="(manHoursError, reIndex) of v$.option.optionAssign.$errors[0].$response.$errors[index].manHours" :key="reIndex">
                            {{ manHoursError.$message }}
                        </p>
                    </div>
                  </div>
              </li>
            </ul>
            <div class="__add">
              <button
                class="__button __button--add-assign"
                type="button"
                @click="addAssign()"
              >
                <img
                  class="__image"
                  src="/assets/img/site/icon_add_secondary_small_min.svg"
                  alt="追加"
                />
              </button>
              <span class="__caption u-text-caption">アサインを追加</span>
            </div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
// -- vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
// -- END vuelidate
// -- for custom validate
const decimal42 = helpers.regex(/^-?\d{1,4}(\.\d{1,2})?$/); // for decimal (4,2)
const checkOptionAssigment = (value, siblings, parent, parent2) => {
  // check if value is not null, then check if value is unique
  if (value !== null) {
    // check if parent.optionAssign is undifined
    let check = 0;
    if (parent.optionAssign !== undefined) {
      check = parent.optionAssign.filter(assigmen => assigmen.member == value);
    }
    else {
      check = parent2.optionAssign.filter(assigmen => assigmen.member == value);
    }

    if (check.length > 1) {
      return false;
    }
  }
  return true;
};
// -- END for custom validate
export default {
  setup(props) {
    return { v$: useVuelidate({ $scope: props.validationScope }) };
  },
  props: {
    currentOption: {
      type: Object,
      default: {},
    },
    contractorUsers: {
      type: Array,
      default: [],
    },
    validationScope: {
      type: String,
      default: "",
    },
  },
  emits: ["update-manhours", "bulk-option", "delete-option"],
  data() {
    return {
      option: {},
      currentAssignId: 0,
    };
  },
  // create watch, to monitor the changes of the props currentOption.optionAssign
  watch: {
    currentOption: {
      handler: function (newVal, oldVal) {
        this.option = newVal;
        this.currentAssignId = this.option.optionAssign.length;
      },
      deep: true,
    },
  },
  validations() {
    return {
      option: {
        optionAssign: {
					$each: helpers.forEach({
            manHours: {
              required: helpers.withMessage(
                "割当工数は必須項目です。",
                required
              ),
              decimal42: helpers.withMessage(
                "割当工数 は整数4桁, 少数2桁で入力してください。",
                decimal42
              ),
            },
            member: {
              required: helpers.withMessage("アサインメンバーは必須項目です。", required),
              checkOptionAssigment: helpers.withMessage(
              "同じメンバーは割り当てられません。",
              checkOptionAssigment
            ),
            },
					})
        },
      },
    };
  },
  created() {
    this.option = this.currentOption;
    this.currentAssignId = this.option.optionAssign.length;
  },
  methods: {
    addAssign() {
      this.option.optionAssign.push({
        pocaId: '',
        assignId: this.currentAssignId,
        manHours: null,
        member: null,
        cost_id: this.option.cost_id,
      });
      this.currentAssignId++;
    },
    deleteAssign(assignId) {
      // -- add to array delete id
      let findData = this.option.optionAssign.find(assign => assign.assignId == assignId);
      this.$emit("delete-option", findData);
      // -- END add to array delete id
      const filtered = this.option.optionAssign.filter((assign) => {
        return assign.assignId !== assignId;
      });
      this.option.optionAssign = filtered;
    },
    emitTotalManHours() {
      this.$emit("update-manhours");
    },
    emitBulkRegistrationOff() {
      this.$emit("bulk-option");
    },
  },
};
</script>
