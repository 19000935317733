<template>
  <div class="u-table-list-item">
    <div class="__table-header">
      <div class="__row">
        <span class="__cell __cell--head u-align-center"></span>
        <span class="__cell __cell--head u-align-center">ステータス</span>
        <span class="__cell __cell--head u-align-center">最終更新日</span>
        <span class="__cell __cell--head">顧客名・案件名</span>
      </div>
    </div>
    <div class="__table-body">
      <!-- loop start-->
      <a v-for="item in items" :key="item.id" class="__row __link" :href="generateRoute(item.lineNumber)">
        <span class="__cell __cell--head u-align-center">
          <button
            class="__pin u-flex-center-center"
            :class="[item.isPinned ? '__pin--active' : '']"
            @click.prevent="pinning(item.id)"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM23.2735 7.15518C23.5182 7.05275 23.7807 7 24.0459 7C24.3112 7 24.5737 7.05275 24.8184 7.15518C25.0616 7.25702 25.2823 7.40594 25.4677 7.59335L33.0384 15.1641C33.2258 15.3495 33.3747 15.5702 33.4766 15.8134C33.579 16.058 33.6318 16.3206 33.6318 16.5858C33.6318 16.8511 33.579 17.1136 33.4766 17.3583C33.3748 17.6015 33.2258 17.8221 33.0384 18.0075L25.7328 25.3132C26.9006 28.4964 25.0823 31.4906 24.0818 32.813C23.909 33.0425 23.6888 33.2321 23.4361 33.3688C23.183 33.5058 22.9033 33.5865 22.6161 33.6055C22.3289 33.6245 22.041 33.5813 21.7721 33.4788C21.5047 33.377 21.2624 33.2189 21.0613 33.0154L15.046 27.0001L10.7531 31.293C10.3625 31.6836 9.72938 31.6836 9.33885 31.293C8.94833 30.9025 8.94833 30.2694 9.33885 29.8788L13.6318 25.5859L7.59188 19.546L7.59014 19.5442C7.39138 19.3467 7.23635 19.1096 7.1351 18.8483C7.03355 18.5862 6.98838 18.3057 7.00254 18.025C7.01669 17.7443 7.08985 17.4697 7.21724 17.2192C7.34463 16.9686 7.5234 16.7478 7.74188 16.571C9.81028 14.8995 11.8519 14.5242 13.3952 14.5931C14.1581 14.6271 14.7901 14.7687 15.2371 14.9042L15.2954 14.9222L22.6241 7.59345C22.8096 7.40599 23.0303 7.25704 23.2735 7.15518ZM31.6263 16.5804L24.0459 9.00002L24.0406 9.00546L16.253 16.7929C15.9499 17.0961 15.4873 17.1721 15.1033 16.9825L15.0908 16.9767C15.0762 16.97 15.0496 16.9582 15.0117 16.9427C14.9357 16.9117 14.8154 16.8663 14.6567 16.8182C14.3385 16.7217 13.8729 16.6164 13.306 16.5911C12.1901 16.5413 10.6439 16.7975 9 18.1257L9.00305 18.1287L22.4842 31.6099L22.486 31.6074C23.5152 30.2474 24.8051 27.8301 23.6523 25.5346C23.4588 25.1494 23.534 24.6835 23.8388 24.3787L31.6318 16.5858L31.6263 16.5804Z"
              />
            </svg>
          </button>
        </span>
        <span class="__cell __cell--head u-align-center">
          <span
            class="u-text-label-status"
            :class="`u-text-label-status--${item.status_color}`"
          >
						{{ item.status_id }}
					</span>
        </span>
        <span class="__cell __cell--data u-align-center">
          <span class="__date">{{ item.created_at }}</span>
        </span>
        <span class="__cell __cell--data">
          <p class="u-text-caption u-line-height-140">
            {{ item.customer_name }}
          </p>
          <p class="u-text-bold mgt-008">{{ item.name }}</p>
        </span>
      </a>
      <!-- loop end-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    projects: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async created() {
		this.moment = moment;
    await this.fetchData();
    await this.orderUpdate();
  },
  methods: {
    orderUpdate() {
      const noPinned = this.items
        .filter((item) => {
          return !item.isPinned;
        })
        .sort((a, b) => {
          return a.created_at > b.created_at ? 1 : -1;
        });
      const pinned = this.items
        .filter((item) => {
          return item.isPinned;
        })
        .sort((a, b) => {
          return a.created_at > b.created_at ? 1 : -1;
        });
      this.items = [...pinned, ...noPinned];
    },
    pinning(id) {
			const target = this.items.find((item) => {
				return item.id === id;
      });
			this.updatePriority(id, !target.isPinned);
      target.isPinned = !target.isPinned;
      this.orderUpdate();
    },
    fetchData() {
			this.items = this.projects;
    },
		generateRoute(line_number){
			return route('contractor.project.show', { project: line_number });
		},
		async updatePriority(id, value) {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			let form = {
				is_priority: value
			};
			await axios.put(route('contractor.api.project.update', { id: id}), form)
				.catch((error) => {
					console.log(error)
				});

			// -- hide loader
			loader.hide();
			// -- END hide loader
		}
  },
};
</script>
