<template>
  <form action="" method="post">
    <input class="u-button-default u-button-default--medium u-button-default--negative __button js-modal-open-confirm-delete" type="button" value="請求書を削除">
  </form>
  <!-- modal confirmation -->
  <modal-confirm-delete
    modal-title="確認"
    modal-content="本当に削除しますか？"
    is-confirm-delete="1"
    :delete-id="invoice.id"
    :route-delete="routeDelete"
    :route-redirect="routeRedirect" />
  <!-- END modal confirmation -->
</template> 

<script>
  export default {
    props: [
      'invoice',
      'routeDelete',
      'routeRedirect',
    ],
  }
</script>