<template>
  <div class="u-flex-between-center __option">
    <div class="u-flex-shrink-1 u-flex-grow-1">
      <span class="u-form-input-default u-form-input-text-box">
        <input v-model="options.optionName" type="text" placeholder="選択肢の内容を入力" name="optionName">
      </span>
      <!-- error message -->
      <div class="input-errors" v-for="(error, index) of v$.options.optionName.$errors" :key="index">
        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
      </div>
      <!-- END error message -->
    </div>
    <div class="u-flex-shrink-0 u-flex-grow-0 __button-box">
      <button class="__button" type="button" @click="emitDeleteOption(options.optionId)">
        <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
      </button>
    </div>
  </div>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core';
  import { helpers, required, minLength, maxLength } from '@vuelidate/validators';
  // -- END vuelidate
  export default {
    setup (props) {
      return { v$: useVuelidate({ $scope: props.validationScope }) }
    },
    props: {
      options: {
        type: Object,
        default: {}
      },
      validationScope: {
        type: String,
        default: ''
      }
    },
    validations() {
      return {
        options: {
          optionName: {
            required: helpers.withMessage('選択肢は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `選択肢は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`選択肢は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          }
        }
      }
    },
    emits: [
      'delete-option'
    ],
    methods: {
      emitDeleteOption(id) {
        this.$emit('delete-option', id);
      },
    }
  }
</script>

<style scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
