<template>
  <div class="__block-depth-2 __block-depth-2--form-input-block">
    <div class="c-format-request-default c-format-request-estimation c-format-request-default--input" id="vue_invoice_create">
      <h1 class="__format-headline">
        <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box">
          <input v-model="titleFormat" type="text" placeholder="請求書" name="titleFormat" class="u-align-center">
        </span>
      </h1>
      <!-- error message -->
      <div class="u-align-center" v-for="(error, index) of v$.titleFormat.$errors" :key="index">
        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
      </div>
      <!-- END error message -->
      <div class="__top-section u-flex-between-top">
        <div class="__left">
          <div class="__to">
            <div v-if="isEdit.to" class="p-estimation-to-edit">
              <div class="u-flex-between-top __row __row--company-to">
                <div class="__column __column--company">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">請求書宛名<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="text" placeholder="Pyatto株式会社" name="estimationToCompany" v-model="estimationTo.company" ref="estimation_to_company" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.company.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="__column __column--to">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">表記<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                          <select v-model="estimationTo.to" ref="estimation_to_to" name="estimationToTo">
                            <option value="御中">御中</option>
                            <option value="様">様</option>
                          </select>
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.to.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="u-flex-between-top __row __row--email-tel">
                <div class="__column __column--email">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">メールアドレス</dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="email" placeholder="info@example.com" name="estimationToEmail" v-model="estimationTo.email" ref="estimation_to_email" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.email.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="__column __column--tel">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">電話番号</dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="tel" placeholder="080-1234-5678" name="estimationToTel" v-model="estimationTo.tel" ref="estimation_to_tel" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.tel.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="u-flex-between-top __row __row--zipcode-prefecture-city">
                <div class="__column __column--zipcode">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">郵便番号</dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="text" placeholder="000-0000" name="estimationToZipcode" v-model="estimationTo.zipcode" ref="estimation_to_zipcode" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                      </dd>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationTo.zipcode.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </div>
                  </dl>
                </div>
                <div class="__column __column--prefecture">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">都道府県</dt>
                      <dd class="__body">
                        <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                          <select v-model="estimationTo.prefecture" ref="estimation_to_prefecture" name="estimationToPrefecture">
                            <prefecture-options />
                          </select>
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="__column __column--city">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">市区町村・番地</dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="text" placeholder="〇〇区〇〇市" name="estimationToCity" v-model="estimationTo.city" ref="estimation_to_city" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.city.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="u-flex-between-top __row __row--address">
                <div class="__column __column--address">
                  <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">建物名・部屋番号など</dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-text-box">
                          <input type="text" placeholder="〇〇ビル〇〇階" name="estimationToAddress" v-model="estimationTo.address" ref="estimation_to_address" @keydown.enter.prevent="updateEstimationTo()" @keydown.esc.prevent="changeEditMode('to', true)">
                        </span>
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.estimationTo.address.$errors" :key="index">
                          <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                        </div>
                        <!-- END error message -->
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="u-flex-right-center __row">
                <button @click.prevent="changeEditMode('to', true)" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                <button @click.prevent="updateEstimationTo()" class="u-button-default u-button-default--small u-button-default--primary __button" data-cy="updateEstimationTo">確定</button>
              </div>
            </div>
            <div v-else @click.prevent="changeEditMode('to')" class="p-estimation-editable-area p-estimation-editable-area--block">
              <div class="p-estimation-to-display">
                <h2 class="__client-name">
                  <span class="__company">{{ estimationTo.company }}</span><span class="__dear">{{ estimationTo.to }}</span>
                </h2>
                <ul class="__info-list">
                  <li v-if="estimationTo.zipcode" class="__item">〒{{ estimationTo.zipcode }}</li>
                  <li v-if="estimationTo.prefecture || estimationTo.city || estimationTo.address" class="__item">{{ estimationTo.prefecture }}{{ estimationTo.city }}{{ estimationTo.address }}</li>
                  <li v-if="estimationTo.email" class="__item">Email：{{ estimationTo.email }}</li>
                  <li v-if="estimationTo.tel" class="__item">TEL：{{ estimationTo.tel }}</li>
                </ul>
              </div>
            </div>
          </div>
          <p class="__caption">下記の通りご請求申し上げます</p>
          <table class="__result-table u-table-outline u-table-format-request-result">
            <tbody>
              <tr>
                <th>小計</th>
                <th>消費税</th>
                <th v-if="hasTaxWithHolding">源泉徴収税額</th>
                <th class="__important">ご請求金額</th>
              </tr>
              <tr>
                <td class="u-align-right">
                  <span class="__number">{{ subTotal }}</span><span class="__yen">円</span>
                </td>
                <td class="u-align-right">
                  <span class="__number">{{ tax.all }}</span><span class="__yen">円</span>
                </td>
                <td v-if="hasTaxWithHolding" class="u-align-right">
                    <span class="__number">{{ taxWithHolding }}</span><span class="__yen">円</span>
                </td>
                <td class="u-align-right __important">
                  <span class="__number">{{ grandTotal }}</span><span class="__yen">円</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isEdit.payment" class="p-estimation-payment-edit">
            <div class="u-flex-between-top __row __row--date">
              <div class="__column __column--date">
                <dl class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">お振込期日<span class="u-form-label-required"></span></dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                        <input type="date" placeholder="" name="estimationToPaymentPaymentDate" v-model="estimationToPayment.paymentDate" ref="estimation_to_payment_date" @keydown.enter.prevent="updateEstimationPayment()" @keydown.esc.prevent="changeEditMode('payment', true)">
                      </span>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationToPayment.paymentDate.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="u-flex-between-top __row __row--bank-branch">
              <div class="__column __column--bank">
                <dl class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">お振込先金融機関名<span class="u-form-label-required"></span></dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="text" placeholder="" name="estimationToPaymentBankName" v-model="estimationToPayment.bankName" ref="estimation_to_bank_name" @keydown.enter.prevent="updateEstimationPayment()" @keydown.esc.prevent="changeEditMode('payment', true)">
                      </span>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationToPayment.bankName.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </dd>
                  </div>
                </dl>
              </div>
              <div class="__column __column--branch">
                <dl class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">支店名<span class="u-form-label-required"></span></dt>
                    <dd class="__body">
                      <span class="u-form-input-default u-form-input-text-box">
                        <input type="text" placeholder="" name="estimationToPaymentBankBranch" v-model="estimationToPayment.bankBranch" ref="estimation_to_bank_branch" @keydown.enter.prevent="updateEstimationPayment()" @keydown.esc.prevent="changeEditMode('payment', true)">
                      </span>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationToPayment.bankBranch.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="u-flex-between-top __row __row--type-account">
              <div class="__column __column--type">
                <dl class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">口座種別<span class="u-form-label-required"></span></dt>
                    <dd class="__body">
                      <span class="u-form-select-default u-form-input-select u-form-input-select--large">
                        <select v-model="estimationToPayment.accountType" ref="estimation_to_account_type" name="estimationToPaymentAccountType">
                          <option value="普通">普通</option>
                          <option value="当座">当座</option>
                        </select>
                      </span>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationToPayment.accountType.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </dd>
                  </div>
                </dl>
              </div>
              <div class="__column __column--account">
                <dl class="u-list-input-label-set __list">
                  <div class="__item">
                    <dt class="__term">口座番号<span class="u-form-label-required"></span></dt>
                    <dd class="__body">
                      <span class="u-form-input-default">
                        <input type="number" placeholder="" name="estimationToPaymentAccount" v-model="estimationToPayment.account" ref="estimation_to_account" @keydown.enter.prevent="updateEstimationPayment()" @keydown.esc.prevent="changeEditMode('payment', true)">
                      </span>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.estimationToPayment.account.$errors" :key="index">
                        <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                      </div>
                      <!-- END error message -->
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="u-flex-right-center __row">
              <button @click.prevent="changeEditMode('payment', true)" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
              <button @click.prevent="updateEstimationPayment()" class="u-button-default u-button-default--small u-button-default--primary __button" data-cy="updateEstimationPayment">確定</button>
            </div>
          </div>
          <div v-else @click.prevent="changeEditMode('payment')" class="p-estimation-editable-area p-estimation-editable-area--block">
            <table class="__payment-table u-table-fill u-table-format-request-payment">
              <tbody>
                <tr>
                  <th class="u-align-left">お振込期日</th>
                  <td>{{ moment(estimationToPayment.paymentDate).format('YYYY年MM月DD日') }}</td>
                </tr>
                <tr>
                  <th class="u-align-left">お振込先</th>
                  <td>
                    {{ estimationToPayment.bankName }}{{ estimationToPayment.bankBranch }} {{ estimationToPayment.accountType }} {{ estimationToPayment.account }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="__right">
            <div v-show="isEdit.from" class="p-estimation-to-edit">
                <dl class="u-list-input-label-set __list">
                    <div class="__item">
                        <dt class="__term">発行者名<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="text" placeholder="例）株式会社Pyatto" name="estimationFromPublisher" ref="estimation_from_company" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisher">
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisher.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者住所<span class="u-form-label-required"></span></dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-area">
                                <textarea placeholder="発行者の住所を入力" name="estimationFromPublisherAddress" ref="estimation_from_address" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherAddress"></textarea>
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherAddress.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者電話番号</dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="tel" placeholder="例）080-1234-5678" name="estimationFromPublisherPhone" ref="estimation_from_tel" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherPhone">
                            </span>
                            <!-- error message -->
                            <!-- for now no need -->
                            <!-- <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherPhone.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div> -->
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者メールアドレス</dt>
                        <dd class="__body">
                            <span class="u-form-input-default u-form-input-text-box">
                                <input type="email" placeholder="例）info@example.com" name="estimationFromPublisherEmail" ref="estimation_from_email" @keydown.esc.prevent="changeEditMode('from', true)" v-model="estimationFrom.publisherEmail">
                            </span>
                            <!-- error message -->
                            <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherEmail.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div>
                            <!-- END error message -->
                        </dd>
                    </div>
                    <div class="__item">
                        <dt class="__term">発行者印</dt>
                        <dd class="__body">
                            <div class="u-form-file-group js-local-file-upload u-flex-left-center">
                                <figure class="__thumbnail u-flex-shrink-0 mgr-024">
                                    <img class="__image" :src="estimationFrom.publisherSeal.url" style="max-width: 100px; height: auto;" alt="">
                                </figure>
                                <button class="__delete mgr-024" type="button" @click.prevent="resetImage">
                                    <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
                                </button>
                                <div class="__input u-flex-shrink-1 u-flex-grow-1 u-flex-column-reverse">
                                    <div class="__controller">
                                        <input type="file" id="input_file"
                                            ref="file"
                                            accept="image/gif,image/jpeg,image/jpg,image/png"
                                            @change="onFileSelected"
                                        >
                                        <label class="u-form-file-upload" for="input_file">
                                            <span class="__icon">
                                                <img class="__image" src="" alt="">
                                            </span>
                                            <span class="__text">ファイルを選択</span>
                                        </label>
                                        <span class="__file-name">{{ publisherSealFileName }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- error message -->
                            <!-- for now no need -->
                            <!-- <div class="input-errors">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.estimationFrom.publisherSeal.$errors" :key="index">
                                  {{ error.$message }}
                                </p>
                            </div> -->
                            <!-- END error message -->
                        </dd>
                    </div>
                </dl>
                <div class="u-flex-right-center __row">
                  <button @click.prevent="changeEditMode('from', true)" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                  <button @click.prevent="updateEstimationFrom()" class="u-button-default u-button-default--small u-button-default--primary __button" data-cy="updateEstimationFrom">確定</button>
                </div>
            </div>
            <div v-show="!isEdit.from" @click="changeEditMode('from')" class="p-estimation-editable-area p-estimation-editable-area--block">
                <div class="u-flex-between-top">
                    <div class="__from">
                        <h3 class="__company">{{ estimationFromFinal.publisher }}</h3>
                        <address class="__address">
                          {{ estimationFromFinal.publisherAddress }}
                        </address>
                        <span class="__tel">TEL：{{ estimationFromFinal.publisherPhone }}</span>
                    </div>
                    <figure class="__stamp" style="max-width: 200px;"><img class="__image" :src="estimationFromFinal.publisherSeal.url" alt=""></figure>
                </div>
            </div>
        </div>
      </div>
      <div class="__bottom-section">
        <div class="__title">
          <span class="u-form-input-default u-form-input-default--medium u-form-input-text-box">
            <input v-model="titleDocument" type="text" placeholder="Pyatto株式会社コーポレートサイト制作 お見積" name="titleDocument">
          </span>
          <!-- error message -->
          <div class="input-errors" v-for="(error, index) of v$.titleDocument.$errors" :key="index">
            <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
          </div>
          <!-- END error message -->
        </div>
        <div class="__format-content">
          <div class="__block-depth-3 __block-depth-3--table-block">
            <div class="u-table-wrapper u-table-wrapper--scroll __table">
              <table class="u-table-list-item u-table-list-item--extra-narrow u-table-list-item--multi u-table-estimation">
                <estimation-table
                  @update-amount="updateAmount"
                  @update-items="updateItems"
                  :current-items="items"
                  :scope="validationScope"
                  :defaultTaxRate="defaultTaxRate" />
              </table>
            </div>
          </div>
          <div class="__block-depth-3 __block-depth-3--result-block">
            <div class="__total-wrap u-flex-right-center">
              <div v-if="isEdit.result" class="p-estimation-result-edit">
                <dl class="u-list-input-label-set __list">
                    <div class="__item">
                      <dt class="__term">小計<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultSubTotal" v-model="result.subTotal" ref="result_subtotal">
                          <p v-for="(error, index) of v$.result.subTotal.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                    <div class="__item">
                      <dt class="__term">消費税（10%）<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultTaxRate10" v-model="result.taxRate10" ref="result_tax_rate_10">
                          <p v-for="(error, index) of v$.result.taxRate10.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                    <div class="__item">
                      <dt class="__term">消費税（8%）<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultTaxRate8" v-model="result.taxRate8" ref="result_tax_rate_8">
                          <p v-for="(error, index) of v$.result.taxRate8.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                    <div class="__item">
                      <dt class="__term">消費税（軽減8%）<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultTaxReduced" v-model="result.taxReduced" ref="result_tax_reduced">
                          <p v-for="(error, index) of v$.result.taxReduced.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                    <div class="__item">
                      <dt class="__term">源泉徴収税額<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultTaxWithHolding" v-model="result.taxWithHolding" ref="result_tax_wuth_holding">
                          <p v-for="(error, index) of v$.result.taxWithHolding.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                    <div class="__item">
                      <dt class="__term">ご請求金額<span class="u-form-label-required"></span></dt>
                      <dd class="__body">
                        <span class="u-form-input-default u-form-input-default--block u-form-input-default--medium">
                          <input type="number" class="u-align-right" placeholder="" name="resultTotal" v-model="result.total" ref="result_total">
                          <p v-for="(error, index) of v$.result.total.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                      </dd>
                    </div>
                </dl>
                <div class="u-flex-right-center __row mgt-024">
                    <button @click="changeEditMode('result'); resetResult()" class="u-button-default u-button-default--small u-button-default--negative __button">キャンセル</button>
                    <button @click.prevent="updateEstimationResult()" class="u-button-default u-button-default--small u-button-default--primary __button mgl-016">確定</button>
                </div>
              </div>
              <div v-else @click="changeEditMode('result'); createTemporaryResult()" class="p-estimation-editable-area p-estimation-editable-area--inline" style="width: 480px;">
                <dl class="u-list-format-total __list">
                  <div class="__item __subtotal">
                    <dt class="__term">小計</dt>
                    <dd class="__body"> <span class="__number">{{ subTotal }}</span><span class="__yen">円</span></dd>
                  </div>
                  <div v-if="taxableAmountByTaxRate().taxRate10 > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                    <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（10%対象額 {{ taxableAmount.taxRate10 }}円）</span></dt>
                    <dd class="__body"> <span class="__number">{{ tax.taxRate10 }}</span><span class="__yen">円</span></dd>
                  </div>
                  <div v-if="taxableAmountByTaxRate().taxRate8 > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                    <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（8%対象額 {{ taxableAmount.taxRate8 }}円）</span></dt>
                    <dd class="__body"> <span class="__number">{{ tax.taxRate8 }}</span><span class="__yen">円</span></dd>
                  </div>
                  <div v-if="taxableAmountByTaxRate().taxReduced > 0 && taxDisplayMode !== 'tax_free'" class="__item __tax">
                    <dt class="__term"><span v-if="taxDisplayMode === 'included'">内</span>消費税<span class="u-text-small pdl-008">（軽減税8%対象額 {{ taxableAmount.taxReduced }}円）</span></dt>
                    <dd class="__body"> <span class="__number">{{ tax.taxReduced }}</span><span class="__yen">円</span></dd>
                  </div>
                  <div v-if="hasTaxWithHolding" class="__item __tax-withholding">
                    <dt class="__term">源泉徴収税額</dt>
                    <dd class="__body"> <span class="__number">{{ taxWithHolding }}</span><span class="__yen">円</span></dd>
                  </div>
                  <div class="__item __total">
                    <dt class="__term">ご請求金額<span class="__small">（税込）</span></dt>
                    <dd class="__body"> <span class="__number">{{ grandTotal }}</span><span class="__yen">円</span></dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div class="__block-depth-3 __block-depth-3--notice-block">
            <dl class="u-list-input-label-set __list">
              <div class="__item">
                <dt class="__term">備考</dt>
                <dd class="__body">
                  <span class="u-form-input-default u-form-input-text-area">
                    <textarea v-model="message" placeholder="請求書の補足説明などを入力" name="message"></textarea>
                  </span>
                  <!-- error message -->
                  <div class="input-errors" v-for="(error, index) of v$.message.$errors" :key="index">
                    <p class="mgt-008 u-text-varidate-label">{{ error.$message }}</p>
                  </div>
                  <!-- END error message -->
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="__block-depth-2 __block-depth-2--form-submit-block">
    <div class="u-flex-center-center">
      <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="上記の内容で作成" @click.prevent="submitEvent()">
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { CommaSeparatedNumber } from '../../modules/comma-separated-number.js';
  import useVuelidate from '@vuelidate/core'
  import { helpers, email, required, minLength, maxLength } from '@vuelidate/validators';
  // -- components
  import PrefectureOptions from '../components/common/PrefectureOptions.vue';
  import EstimationTable from '../components/estimation/EstimationTable.vue';
  // -- END components
  // -- for custom validate
  const unsignedInteger = helpers.regex(/^\d+$/); // for unsigned integer
  // -- END for custom validate
  export default {
    setup () {
      const validation_scope = 'invoice'
      return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
    },
    components: {
      EstimationTable,
      PrefectureOptions
    },
    props: [
      'project',
      'tax_rates',
      'estimate',
      'taxt_option',
      'defaultTaxRate',
      'documentSettingPublisherSeal',
      'documentSettingPublisherSealUrl',
    ],
    data() {
      return {
        total: 0,
        taxRate: 0.1, // curently still static value
        isReconstructionTax: true, // 復興税有無
        taxWithHoldingRateLessThanOneMillion: 0.1021, // 100万以下に適用される源泉税率
        taxWithHoldingRateExcessOneMillion: 0.2042, // 100万超過に適用される源泉税率
        hasTaxWithHolding: true, // 源泉徴収有無
        taxDisplayMode: 'not_included', // 税別表示：'not_included', 税込表示：'included', 税込表示（免税）：'tax_free'
        taxRounding: 'round_up', // 切り上げ：'round_up', 切り捨て：'round_down', 四捨五入：'round_off'
        message: null,
        items: [],
        titleDocument: 'Pyatto株式会社コーポレートサイト制作 請求書', // title
        titleFormat: '請求書', // head_title

        calculatedTotal: 0,
        calculatedTotalTemporary: 0,
        result: {
            subTotal: 0,
            taxRate10: null,
            taxRate8: null,
            taxReduced: null,
            taxWithHolding: null,
            total: null
        },
        resultTemporary: {
            subTotal: 0,
            taxRate10: null,
            taxRate8: null,
            taxReduced: null,
            taxWithHolding: null,
            total: null
        },

        invoicePublishedOn: moment().endOf('month').format('YYYY年DD月MM日'), // need confirmation
        validationScope: this.validation_scope,
        isEdit: {
          to: false, // for invoice customer column
          payment: false, // for invoice payment column
          result: false, //  for invoice result (total include tax)
          from: false
        },
        estimationTo: {
          company: 'Pyatto株式会社',
          to: '御中',
          email: null,
          tel: null,
          zipcode: null,
          prefecture: '',
          city: null,
          address: null,
        },
        // -- add by backend
        estimationToPayment: {
          paymentDate: this.currentEndOfMonth,
          displayPaymentDate: null,
          bankName: 'サンプル銀行',
          bankBranch: 'サンプル支店',
          accountType: '普通',
          account: 12345678,
        },
        estimationToFinal: {
          // -- customer
          company: 'Pyatto株式会社',
          to: '御中',
          email: null,
          tel: null,
          zipcode: null,
          prefecture: '',
          city: null,
          address: null,
          // -- END customer
          // -- payment
          paymentDate: this.currentEndOfMonth,
          displayPaymentDate: null,
          bankName: 'サンプル銀行',
          bankBranch: 'サンプル支店',
          accountType: '普通',
          account: 12345678,
          // -- END payment
        },
        // -- publisher
        estimationFrom: {
          publisher: '',
          publisherAddress: '',
          publisherPhone: '',
          publisherEmail: '',
          publisherSeal: {},
        },
        estimationFromFinal: {
          publisher: '',
          publisherAddress: '',
          publisherPhone: '',
          publisherEmail: '',
          publisherSeal: {},
        },
        publisherSealFileName: '',
        // -- END publisher
        // -- END add by backend
      }
    },
    validations() {
      return {
        titleDocument: {
          required: helpers.withMessage('請求書件名は必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `請求書件名は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`請求書件名は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        titleFormat: {
          required: helpers.withMessage('請求書フォーマットタイトルは必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `請求書フォーマットタイトルは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`請求書フォーマットタイトルは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        message: {
          maxLength: helpers.withMessage(
            ({ $params }) =>`備考は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        estimationTo: {
          company: {
            required: helpers.withMessage('請求書宛名は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `請求書宛名は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`請求書宛名は、${$params.max}文字以下で入力してください。`, maxLength(191)
            )
          },
          to: {
            required: helpers.withMessage('表記は必須項目です。', required),
          },
          email: {
            email: helpers.withMessage('メールアドレスは、有効なメールアドレス形式で指定してください。', email),
            minLength: helpers.withMessage(
              ({ $params }) => `メールアドレスは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`メールアドレスは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          tel: {
            minLength: helpers.withMessage(
              ({ $params }) => `電話番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`電話番号は、${$params.max}文字以下で入力してください。`, maxLength(20)
            )
          },
          zipcode: {
            minLength: helpers.withMessage(
              ({ $params }) => `郵便番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`郵便番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
            )
          },
          // prefecture: {}, // for now no need validation
          city: {
            minLength: helpers.withMessage(
              ({ $params }) => `市区町村・番地は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`市区町村・番地は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          address: {
            minLength: helpers.withMessage(
              ({ $params }) => `建物名・部屋番号などは、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`建物名・部屋番号などは、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          }
        },
        estimationToPayment: {
          paymentDate: {
            required: helpers.withMessage('お振込期日は必須項目です。', required),
          },
          // displayPaymentDate: null, // for now no need
          bankName: {
            required: helpers.withMessage('お振込先金融機関名は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `お振込先金融機関名は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`お振込先金融機関名は、${$params.max}文字以下で入力してください。`, maxLength(20)
            )
          },
          bankBranch: {
            required: helpers.withMessage('支店名は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `支店名は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`支店名は、${$params.max}文字以下で入力してください。`, maxLength(20)
            )
          },
          accountType: {
            required: helpers.withMessage('口座種別は必須項目です。', required),
          },
          account: {
            required: helpers.withMessage('口座番号は必須項目です。', required),
            minLength: helpers.withMessage(
              ({ $params }) => `口座番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`口座番号は、${$params.max}文字以下で入力してください。`, maxLength(8)
            ),
            unsignedInteger:  helpers.withMessage('口座番号には、整数を指定してください。', unsignedInteger)
          },
        },
        result: {
          subTotal: {
            required: helpers.withMessage('小計は必須項目です。', required),
          },
          taxRate10: {
            required: helpers.withMessage('消費税（10%）は必須項目です。', required),
          },
          taxRate8: {
            required: helpers.withMessage('消費税（8%）は必須項目です。', required),
          },
          taxReduced: {
            required: helpers.withMessage('消費税（軽減8%）は必須項目です。', required),
          },
          taxWithHolding: {
            required: helpers.withMessage('源泉徴収税額は必須項目です。', required),
          },
          total: {
            required: helpers.withMessage('ご請求金額は必須項目です。', required),
          }
        },
        estimationFrom: {
          publisher: {
            required: helpers.withMessage(`発行者名は必須項目です。`, required), 
          },
          publisherAddress: {
            required: helpers.withMessage(`発行者住所は必須項目です。`, required), 
          },
          // publisherPhone: {}, // for now no need
          publisherEmail: {
            email: helpers.withMessage('メールアドレスを正しく入力してください', email),
          },
        },
      }
    },
    computed: {
      subTotal() {
        // 小計
        return CommaSeparatedNumber({
          number: this.result.subTotal
        });
      },
      tax() {
        // 税別消費税額
        return {
            taxRate8: CommaSeparatedNumber({ number: this.result.taxRate8 }),
            taxRate10: CommaSeparatedNumber({ number: this.result.taxRate10 }),
            taxReduced: CommaSeparatedNumber({ number: this.result.taxReduced }),
            all: CommaSeparatedNumber({ number: this.result.taxRate8 + this.result.taxRate10 + this.result.taxReduced }),
        }
      },
      taxWithHolding() {
        // 源泉徴収税額
        return CommaSeparatedNumber({
          number: this.result.taxWithHolding
        })
      },
      taxableAmount() {
        // 税別対象額
        return {
            taxRate8: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate8 }),
            taxRate10: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate10 }),
            taxReduced: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxReduced }),
            all: CommaSeparatedNumber({ number: this.taxableAmountByTaxRate().taxRate8 + this.taxableAmountByTaxRate().taxRate10 + this.taxableAmountByTaxRate().taxReduced }),
        }
      },
      grandTotal() {
        // 請求合計
        return CommaSeparatedNumber({
          number: this.result.total
        });
      },
      calcurateTaxWithHoldingRate() {
        if(this.isReconstructionTax) {
            return {
                lessThanOneMillion: this.taxWithHoldingRateLessThanOneMillion,
                excessOneMillion: this.taxWithHoldingRateExcessOneMillion
            }
        } else {
            return {
                lessThanOneMillion: 0.1,
                excessOneMillion: 0.2
            }
        }
      },
    },
    async created() {
      this.moment = moment;
      // await this.fetchData();
      this.setInvoice();
      if (this.estimate != '') {
        await this.setEstimateReflect();
        await this.initTotal();
        await this.syncCalculateToResult();
      }
    },
    methods: {
      async submitEvent() {
        // check validation
        const isFormCorrect = await this.v$.$validate();

        // -- check isEdit, all must be false
        let checkIsEdit = false;
        if (this.isEdit.to == false && this.isEdit.payment == false && this.isEdit.result == false) {
          checkIsEdit =  true;
        }
        // -- check isEdit, all must be false

        if (isFormCorrect && checkIsEdit) {
          // -- loader show
          let loader = this.$loading.show({
            canCancel: false,
          });
          // -- END loader show

          // -- prepare formData
          const form = new FormData();
          form.append('type_action', 'invoice_create')

          form.append('project_id', this.project.id)
          form.append('title', this.titleDocument)
          form.append('head_title', this.titleFormat)
          form.append('note', this.message)
          form.append('published_on', moment(this.invoicePublishedOn, 'YYYY年DD月MM日').format('YYYY-MM-DD'))
          form.append('items', JSON.stringify(this.items))
          form.append('tax_rate', this.taxRate)
          // total: this.total,

          // -- estimationToFinal
          form.append('customer_name', this.estimationToFinal.company)
          form.append('customer_email', this.estimationToFinal.email)
          form.append('customer_phone', this.estimationToFinal.tel)
          form.append('customer_postcode', this.estimationToFinal.zipcode)
          form.append('customer_prefecture', this.estimationToFinal.prefecture)
          form.append('customer_city_subarea', this.estimationToFinal.city)
          form.append('customer_rest_of_address', this.estimationToFinal.address)
          form.append('customer_compellation', this.estimationToFinal.to)

          form.append('payment_due_date', this.estimationToFinal.paymentDate)
          form.append('payment_bank_name', this.estimationToFinal.bankName)
          form.append('payment_bank_branch_name', this.estimationToFinal.bankBranch)
          form.append('payment_bank_account_type', this.estimationToFinal.accountType)
          form.append('payment_bank_account_number', this.estimationToFinal.account)
          // -- END estimationToFinal

          // -- total
          form.append('subtotal', this.result.subTotal == null ? 0 : this.result.subTotal)
          form.append('total_tax10', this.result.taxRate10)
          form.append('total_tax8', this.result.taxRate8)
          form.append('total_reduced_tax8', this.result.taxReduced)
          form.append('total_withholding_tax', this.result.taxWithHolding)
          form.append('total_amount', this.result.total)
          // -- END total

          // -- taxOption
          form.append('tax_display_option', this.taxt_option.taxDisplayOption.toString())
          form.append('tax_rate_option', this.taxt_option.taxRateOption.toString())
          form.append('tax_calculation_method', this.taxt_option.taxCalculationMethod.toString())
          form.append('tax_withholding_option', this.taxt_option.taxWithholdingOption.toString())
          // -- END taxOption

          // -- publisher
          form.append('publisher', this.estimationFrom.publisher)
          form.append('publisher_address', this.estimationFrom.publisherAddress)
          form.append('publisher_phone', this.estimationFrom.publisherPhone)
          form.append('publisher_email', this.estimationFrom.publisherEmail)
          // -- publisherSeal
          if (this.estimationFrom.publisherSeal.file) {
            form.append('publisher_seal_image', this.estimationFrom.publisherSeal.file)
          }
          if (this.estimationFrom.publisherSeal.image_status) {
            form.append('image_status', this.estimationFrom.publisherSeal.image_status)
          }
          // -- END publisherSeal
          // -- END publisher

          // -- prepare formData

          await axios.post(route('contractor.api.invoice.store'), form)
            .then((response) => {
              if (response.status == 201) {
                window.location.href = route('contractor.project.invoice.show', {
                  project: this.project.line_number,
                  invoice: response.data.record.line_number
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

          // -- loader hide
          loader.hide();
          // -- END loader hide
        }
        else {
          if(this.v$.estimationTo.$errors.length > 0) this.isEdit.to = true;
          if(this.v$.estimationToPayment.$errors.length > 0) this.isEdit.payment = true;
          if(this.v$.result.$errors.length > 0) this.isEdit.result = true;
          if(this.v$.estimationFrom.$errors.length > 0) this.isEdit.estimationFrom = true;
        }
      },
      updateAmount(payload) {
        this.calculatedTotal = payload;
        this.result.subTotal = payload;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTaxWithHolding();
        this.recalculationTotal();
      },
      updateTaxRate() {
        this.calculatedTotal = this.result.subTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTotal();
      },
      updateItems(payload) {
        this.items = payload;
        this.calculatedTotal = this.result.subTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTaxWithHolding();
        this.recalculationTotal();
      },
      createTemporaryResult() {
        this.resultTemporary = JSON.parse(JSON.stringify(this.result));
      },
      resetResult() {
        this.result = this.resultTemporary;
      },
      rounding(price) {
        if(this.taxRounding === 'round_up') {
            return Math.ceil(price);
        } else if(this.taxRounding === 'round_down') {
            return Math.floor(price);
        } else if(this.taxRounding === 'round_off') {
            return Math.round(price);
        }
      },
      taxableAmountByTaxRate() {
        const optionsAll = [];
        this.items.forEach((item) => {
            item.itemOptions.forEach((option) => {
                optionsAll.push(option)
            });
        });
        const itemsSubTotalTaxRate8 = this.items.filter((item) => {
            return item.itemTaxType === '8%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const itemsSubTotalTaxRate10 = this.items.filter((item) => {
            return item.itemTaxType === '10%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const itemsSubTotalTaxReduced = this.items.filter((item) => {
            return item.itemTaxType === '軽減8%'
        }).map((item) => {
            return item.itemSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxRate8 = optionsAll.filter((option) => {
            return option.optionTaxType === '8%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxRate10 = optionsAll.filter((option) => {
            return option.optionTaxType === '10%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        const optionsSubTotalTaxReduced = optionsAll.filter((option) => {
            return option.optionTaxType === '軽減8%'
        }).map((option) => {
            return option.optionSubtotal
        }).reduce((prev, current) => {
            return prev + current
        }, 0);
        return {
            taxRate8: Number(itemsSubTotalTaxRate8 + optionsSubTotalTaxRate8),
            taxRate10: Number(itemsSubTotalTaxRate10 + optionsSubTotalTaxRate10),
            taxReduced: Number(itemsSubTotalTaxReduced + optionsSubTotalTaxReduced)
        }
      },
      syncCalculateToResult() {
        this.result.subTotal = this.calculatedTotal;
        this.recalculationTaxRate10();
        this.recalculationTaxRate8();
        this.recalculationTaxReduced();
        this.recalculationTaxWithHolding();
        this.recalculationTotal();
      },
      recalculationTaxRate10() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxRate10 = this.rounding(this.taxableAmountByTaxRate().taxRate10 * 0.1)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxRate10 = this.rounding((this.taxableAmountByTaxRate().taxRate10 / 1.1) * 0.1)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxRate10 = 0
        }
      },
      recalculationTaxRate8() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxRate8 = this.rounding(this.taxableAmountByTaxRate().taxRate8 * 0.08)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxRate8 = this.rounding((this.taxableAmountByTaxRate().taxRate8 / 1.08) * 0.08)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxRate8 = 0
        }
      },
      recalculationTaxReduced() {
        if(this.taxDisplayMode === 'not_included') {
            this.result.taxReduced = this.rounding(this.taxableAmountByTaxRate().taxReduced * 0.08)
        } else if(this.taxDisplayMode === 'included') {
            this.result.taxReduced = this.rounding((this.taxableAmountByTaxRate().taxReduced / 1.08) * 0.08)
        } else if(this.taxDisplayMode === 'tax_free') {
            this.result.taxReduced = 0
        }
      },
      recalculationTaxWithHolding() {
        if(0 < this.result.subTotal && this.hasTaxWithHolding) {
            if(this.result.subTotal <= 1000000) {
                this.result.taxWithHolding = Math.floor(this.calculatedTotal * this.calcurateTaxWithHoldingRate.lessThanOneMillion); // 100万以下
                console.log('100万以下')
            } else {
                const excessOneMillion = (this.result.subTotal - 1000000) * this.calcurateTaxWithHoldingRate.excessOneMillion;
                const lessThanOneMillion = 1000000 * this.calcurateTaxWithHoldingRate.lessThanOneMillion;
                this.result.taxWithHolding = Math.floor(excessOneMillion + lessThanOneMillion); // 100万超過
                console.log('excessOneMillion', excessOneMillion)
                console.log('lessThanOneMillion', lessThanOneMillion)
                console.log('100万超過')
            }
        } else {
            this.result.taxWithHolding = 0
        }
      },
      recalculationTotal() {
        if(this.taxDisplayMode === 'not_included') {
            // 税抜
            if(0 < this.result.subTotal && this.hasTaxWithHolding) {
                // 源泉あり
                if(this.result.subTotal <= 1000000) {
                    // 100万以下
                    this.result.total = this.calculatedTotal + this.rounding((this.taxableAmountByTaxRate().taxRate10 * 0.1)) + this.rounding((this.taxableAmountByTaxRate().taxRate8 * 0.08)) + this.rounding((this.taxableAmountByTaxRate().taxReduced * 0.08)) - Math.floor(this.calculatedTotal * this.calcurateTaxWithHoldingRate.lessThanOneMillion)
                } else {
                    // 100万超過
                    const excessOneMillion = (this.result.subTotal - 1000000) * this.calcurateTaxWithHoldingRate.excessOneMillion;
                    const lessThanOneMillion = 1000000 * this.calcurateTaxWithHoldingRate.lessThanOneMillion;
                    this.result.total = this.calculatedTotal + this.rounding((this.taxableAmountByTaxRate().taxRate10 * 0.1)) + this.rounding((this.taxableAmountByTaxRate().taxRate8 * 0.08)) + this.rounding((this.taxableAmountByTaxRate().taxReduced * 0.08)) - Math.floor(excessOneMillion + lessThanOneMillion)
                }
            } else {
                // 源泉なし
                this.result.total = this.calculatedTotal + this.rounding((this.taxableAmountByTaxRate().taxRate10 * 0.1)) + this.rounding((this.taxableAmountByTaxRate().taxRate8 * 0.08)) + this.rounding((this.taxableAmountByTaxRate().taxReduced * 0.08))
            }
        } else {
            // 税込 or 免税
            if(0 < this.result.subTotal && this.hasTaxWithHolding) {
                // 源泉あり
                if(this.result.subTotal <= 1000000) {
                    // 100万以下
                    this.result.total = this.calculatedTotal - Math.floor(this.calculatedTotal * this.calcurateTaxWithHoldingRate.lessThanOneMillion)
                } else {
                    // 100万超過
                    const excessOneMillion = (this.result.subTotal - 1000000) * this.calcurateTaxWithHoldingRate.excessOneMillion;
                    const lessThanOneMillion = 1000000 * this.calcurateTaxWithHoldingRate.lessThanOneMillion;
                    this.result.total = this.calculatedTotal - Math.floor(excessOneMillion + lessThanOneMillion)
                }
            } else {
                // 源泉なし
                this.result.total = this.calculatedTotal
            }
        }
      },
      fetchData() {
        this.items = [
          {
            itemId: 0,
            itemName: '品目サンプル',
            itemCount: 1,
            itemMeasure: '式',
            itemUnit: 1000,
            itemSubtotal: 1000,
            itemType: 1,
            itemTotal: 1500,
            itemOptions: [
              {
                optionId: 0,
                optionName: 'オプションサンプル',
                optionCount: 1,
                optionMeasure: '個',
                optionUnit: 500,
                optionSubtotal: 500,
                optionType: 1
              }
            ]
          }
        ]
      },
      initTotal() {
        const result = this.items.map((item) => {
          return item.itemTotal;
        }).reduce((prev, current) => {
          return prev + current
        }, 0);
        console.log(result)
        this.calculatedTotal = result;
        console.log(this.calculatedTotal)
      },
      changeEditMode(key, isCancel = false) {
        let result = true;
        if (isCancel) {
          switch (key) {
            case 'to':
              this.v$.estimationTo.$reset();
              this.cancelEstimateTo();
              break;
            case 'payment':
              this.v$.estimationToPayment.$reset();
              this.cancelEstimatePayment();
              break;
            case 'from':
              this.v$.estimationFrom.$reset();
              this.cancelEstimationFrom();
              break;
            default:
              break;
          }
        }

        if (result) this.isEdit[key] = !this.isEdit[key];
      },
      // -- add by backend
      setInvoice() {
        // -- estimationTo
        this.estimationTo.company = this.project.customer_name;
        this.estimationTo.to = this.project.customer_compellation;
        this.estimationTo.email = this.project.customer_email;
        this.estimationTo.tel = this.project.customer_phone;
        this.estimationTo.zipcode = this.project.customer_postcode;
        this.estimationTo.prefecture = this.project.customer_prefecture;
        this.estimationTo.city = this.project.customer_city_subarea;
        this.estimationTo.address = this.project.customer_rest_of_address;
        // -- END estimationTo

        // -- set for estimationToFinal
        let project = this.project;
        this.estimationToFinal.company = project.customer_name;
        this.estimationToFinal.to = project.customer_compellation;
        this.estimationToFinal.email = project.customer_email;
        this.estimationToFinal.tel = project.customer_phone;
        this.estimationToFinal.zipcode = project.customer_postcode;
        this.estimationToFinal.prefecture = project.customer_prefecture;
        this.estimationToFinal.city = project.customer_city_subarea;
        this.estimationToFinal.address = project.customer_rest_of_address;
        // -- END set for estimationToFinal
      },
      async updateEstimationTo() {
        const isFormCorrect = await this.v$.estimationTo.$validate();
        if (isFormCorrect) {
          this.estimationToFinal.company = this.$refs.estimation_to_company.value;
          this.estimationToFinal.to = this.$refs.estimation_to_to.value;
          this.estimationToFinal.email = this.$refs.estimation_to_email.value;
          this.estimationToFinal.tel = this.$refs.estimation_to_tel.value;
          this.estimationToFinal.zipcode = this.$refs.estimation_to_zipcode.value;
          this.estimationToFinal.prefecture = this.$refs.estimation_to_prefecture.value;
          this.estimationToFinal.city = this.$refs.estimation_to_city.value;
          this.estimationToFinal.address = this.$refs.estimation_to_address.value;
          this.changeEditMode('to');
        }
      },
      async updateEstimationPayment() {
        const isFormCorrect = await this.v$.estimationToPayment.$validate();
        if (isFormCorrect) {
          this.estimationToFinal.paymentDate = this.$refs.estimation_to_payment_date.value;
          this.estimationToFinal.bankName = this.$refs.estimation_to_bank_name.value;
          this.estimationToFinal.bankBranch = this.$refs.estimation_to_bank_branch.value;
          this.estimationToFinal.accountType = this.$refs.estimation_to_account_type.value;
          this.estimationToFinal.account = this.$refs.estimation_to_account.value;
          this.changeEditMode('payment');
        }
      },
      async updateEstimationResult() {
        const isFormCorrect = await this.v$.result.$validate();
        if (isFormCorrect) {
          this.changeEditMode('result');
        }
      },
      cancelEstimateTo() {
        this.estimationTo.company = this.estimationToFinal.company;
        this.estimationTo.to = this.estimationToFinal.to;
        this.estimationTo.email = this.estimationToFinal.email;
        this.estimationTo.tel = this.estimationToFinal.tel;
        this.estimationTo.zipcode = this.estimationToFinal.zipcode;
        this.estimationTo.prefecture = this.estimationToFinal.prefecture;
        this.estimationTo.city = this.estimationToFinal.city;
        this.estimationTo.address = this.estimationToFinal.address;
      },
      cancelEstimatePayment() {
        this.estimationToPayment.paymentDate = this.estimationToFinal.paymentDate;
        this.estimationToPayment.displayPaymentDate = this.estimationToFinal.displayPaymentDate;
        this.estimationToPayment.bankName = this.estimationToFinal.bankName;
        this.estimationToPayment.bankBranch = this.estimationToFinal.bankBranch;
        this.estimationToPayment.accountType = this.estimationToFinal.accountType;
        this.estimationToPayment.account = this.estimationToFinal.account;
      },
      // -- create invoice reflect from estimate
      setEstimateReflect() {
        // -- set titleDocument
        this.titleDocument = this.estimate.title
        // -- END set titleDocument

        // -- set message
        this.message = this.estimate.note;
        // -- END set message

        // -- set estimate detail
        this.items = this.estimate.estimate_detail;
        // -- set estimate detail

        // -- set result
        this.setResult();
        // -- END set result

        if (this.items.length > 0) {
          this.calculatedTotal = this.items.map((item) => {
              return item.itemTotal
          }).reduce((prev, current) => {
              return prev + current
          })
        }
      },
      // -- END create invoice reflect from estimate
      setResult() {
        ({
          subtotal: this.result.subTotal,
          total_tax10: this.result.taxRate10,
          total_tax8: this.result.taxRate8,
          total_reduced_tax8: this.result.taxReduced,
          total_withholding_tax: this.result.taxWithHolding,
          total_amount: this.result.total,
        } = this.estimate);
      },
      setEstimationFrom() {
        const project = this.project;
        this.estimationFrom.publisher = project.contractor.name;
        this.estimationFrom.publisherAddress = project.contractor.prefecture + project.contractor.city_subarea + "\n" + project.contractor.rest_of_address;
        this.estimationFrom.publisherPhone = project.contractor.phone;
        this.estimationFrom.publisherEmail = '';

        // -- set for estimationFromFinal
        this.estimationFromFinal.publisher = project.contractor.name;
        this.estimationFromFinal.publisherAddress = project.contractor.prefecture + project.contractor.city_subarea + "\n" + project.contractor.rest_of_address;
        this.estimationFromFinal.publisherPhone = project.contractor.phone;
        this.estimationFromFinal.publisherEmail = '';
        // -- END set for estimationFromFinal
      },
      cancelEstimationFrom() {
        this.estimationFrom.publisher = this.estimationFromFinal.publisher;
        this.estimationFrom.publisherAddress = this.estimationFromFinal.publisherAddress;
        this.estimationFrom.publisherPhone = this.estimationFromFinal.publisherPhone;
        this.estimationFrom.publisherEmail = this.estimationFromFinal.publisherEmail;
        this.estimationFrom.publisherSeal = this.estimationFromFinal.publisherSeal;
      },
      async updateEstimationFrom() {
        const isFormCorrect = await this.v$.estimationFrom.$validate();
        if (isFormCorrect) {
          this.estimationFromFinal.publisher = this.$refs.estimation_from_company.value;
          this.estimationFromFinal.publisherAddress = this.$refs.estimation_from_address.value;
          this.estimationFromFinal.publisherPhone = this.$refs.estimation_from_tel.value;
          this.estimationFromFinal.publisherEmail = this.$refs.estimation_from_email.value;
          this.estimationFromFinal.publisherSeal = this.estimationFrom.publisherSeal

          this.changeEditMode('from');
        }
      },
      onFileSelected(e) {
        if (e && e.target.files[0]) {
          const file = e.target.files[0];
          const url = URL.createObjectURL(file);
          const name_file = e.target.files[0].name;
          const image_status = 'new';
          this.estimationFrom.publisherSeal = { url, file, name_file, image_status };
        }
      },
      resetImage() {
        this.v$.estimationFrom.$reset();
        this.estimationFrom.publisherSeal = {};
        this.setDefaultImage();
      },
      setDefaultImage() {
        if (this.documentSettingPublisherSeal != null) {
          const publisherSealUrl = this.documentSettingPublisherSealUrl;
          this.estimationFrom.publisherSeal.url = publisherSealUrl;
          this.estimationFromFinal.publisherSeal.url = publisherSealUrl;
        }
        else {
          this.estimationFrom.publisherSeal.url = '/assets/img/site/image_stamp_none_min.png';
          this.estimationFromFinal.publisherSeal.url = '/assets/img/site/image_stamp_none_min.png';
        }
      },
      setFileName(publisher_seal) {
        if (publisher_seal == null) {
          this.publisherSealFileName = 'ファイルが選択されていません';
        }
        else {
          const arrFileName = publisher_seal.split("/");
          this.publisherSealFileName = arrFileName[3];
        }
      },
      // -- END add by backend
    },
    // -- set by backend
    mounted(){
      // -- set Publisher
      this.setEstimationFrom();
      this.setDefaultImage();
      this.setFileName(this.documentSettingPublisherSeal);
      // -- END set Publisher
    }
    // -- END set by backend
  }
</script>

<style scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
