<template>
  <main class="l-main p-page p-estimation-create-page p-app-page p-service-page">
    <article class="p-page-title"> 
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center"> 
                <h1 class="u-heading-page-title __title">見積もり新規作成</h1>
                <invoice-button-document-setting 
                  :default_documen_setting="estimate.documentSetting" 
                  :all_tax_option="estimate.allTaxOption" 
                  :page_type="page_type"
                  @update_tax_option="updateTaxOption"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <form>
                <div id="vue_estimation_create">
                  <estimate-create 
                    :project="estimate.project" 
                    :estimate="estimate.estimate" 
                    :total_all_page_cost="estimate.totalAllPageCost"
                    :taxt_option="taxOption"
                    :defaultTaxRate="defaultTaxRate"
                    :documentSettingPublisherSeal="estimate.documentSetting.publisher_seal"
                    :documentSettingPublisherSealUrl="estimate.documentSetting.publisher_seal_url"  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  props: {
    estimate: {
      type: Object,
      default: {}
    },
    page_type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      taxOption: {
        taxCalculationMethod: 0,
        taxDisplayOption: 0,
        taxRateOption: 0,
        taxWithholdingOption: 0,
      },
      defaultTaxRate: '10%'
    }
  },
  created () {
    this.setTaxOption();
    this.setDefaultTaxRate(this.estimate.documentSetting.tax_rate_option);
  },
  methods: {
    setTaxOption() {
      this.taxOption.taxCalculationMethod = this.estimate.documentSetting.tax_calculation_method;
      this.taxOption.taxDisplayOption = this.estimate.documentSetting.tax_display_option;
      this.taxOption.taxRateOption = this.estimate.documentSetting.tax_rate_option;
      this.taxOption.taxWithholdingOption = this.estimate.documentSetting.tax_withholding_option;
    },
    updateTaxOption(payload) {
      this.taxOption = payload;

      // for update defaultTaxRate
      this.setDefaultTaxRate(payload.taxRateOption);
    },
    setDefaultTaxRate(value = 0) {
      const taxRate = this.estimate.allTaxOption.taxRate.find(tax => tax.value == value);
      console.log(taxRate)
      this.defaultTaxRate = taxRate.name;
    }
  },
}
</script>