<template>
    <foreignObject :x="x" :y="y" width="576" height="1" style="overflow: visible;">
        <div xmlns="http://www.w3.org/1999/xhtml" class="sitemap-delete-dialog">
            <p class="sitemap-delete-dialog-message">
                「{{
                    pageName
                }}」のページと、このページに紐づく、すべての下層ページを削除しますか？
            </p>

            <div class="sitemap-delete-dialog-buttons-container">
                <button class="sitemap-delete-dialog-cancel-button" @click="onCancel">
                    キャンセル
                </button>
                <button class="sitemap-delete-dialog-submit-button" @click="onSubmit">
                    削除を実行
                </button>
            </div>
        </div>
    </foreignObject>
</template>

<script>
export default {
    name: 'SitemapDeleteDialog',

    props: ['x', 'y', 'pageName', 'onCancel', 'onSubmit'],
};
</script>

<style scoped>
.sitemap-delete-dialog {
    border: solid 1px #3cbdce;
    border-radius: 8px;
    background-color: #fff;
}

.sitemap-delete-dialog-message {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 16px 22px 0 22px;
}

.sitemap-delete-dialog-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.sitemap-delete-dialog-cancel-button {
    width: 160px;
    height: 40px;
    background-color: #aaa;
    color: white;
    margin-top: 14px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    border-radius: 8px;
    margin-right: 32px;
}

.sitemap-delete-dialog-submit-button {
    width: 160px;
    height: 40px;
    background-color: #f87f3b;
    color: white;
    margin-top: 14px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    border-radius: 8px;
}
</style>
