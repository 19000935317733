<template>
  <div class="u-box-content-default __box">
    <h2 class="__offers-name mgt-024">
      {{ recruit.title }}
    </h2>
    <div class="u-flex-between-center mgt-016">
      <span class="__category">{{ recruit.recruiting_category_option.display_name }}</span>
      <span class="__budget u-flex-right-center">
        <span class="__label">予算</span>
        <span class="__price">{{ recruit.estimate }}</span>
      </span>
    </div>
    <div class="__sheet-content mgt-024" v-if="recruit.planning_format_id != null">
      <h3 class="__sheet-title">{{ recruit.planning_format.title }}</h3>
      <div class="mgt-024 __sheet-tables">
        <!-- loop recruit.planning_format.planning_format_categories -->
        <table class="u-table-sheet p-survey-result" v-for="(planning_format_category, sfc_index) of recruit.planning_format.planning_format_categories" :key="sfc_index">
          <thead v-if="checkRecruitingPublic(planning_format_category.id)">
            <tr>
              <th class="u-text-leadcopy" colspan="2">{{ planning_format_category.title }}</th>
            </tr>
          </thead>
          <tbody v-if="checkRecruitingPublic(planning_format_category.id)">
            <!-- loop recruit.planning_format.planning_format_categories->planning_format_category_details -->
            <tr v-for="(planning_format_category_detail, sfcd_index) of planning_format_category.planning_format_category_details" :key="sfcd_index">
              <th>{{ planning_format_category_detail.title}}</th>
              <!-- check input_type -->
              <td v-if="planning_format_category_detail.answer == null"></td>
              <td v-else-if="planning_format_category_detail.detail_input_type == 'textbox' || planning_format_category_detail.detail_input_type == 'select' ">
                {{ planning_format_category_detail.answer }}
              </td>
              <td class="display-textarea-data" v-else-if="planning_format_category_detail.detail_input_type == 'textarea'">
                {{ planning_format_category_detail.answer }}
              </td>
              <td v-else-if="planning_format_category_detail.detail_input_type == 'file'">
                <div v-if="checkFileType(planning_format_category_detail.answer)">
                  <img :src="planning_format_category_detail.file_image_show" alt="">
                </div>
                <div class="mgb-016">
                  {{ getFileName(planning_format_category_detail.answer) }}
                  <!-- （画像以外の場合は上記イメージプレビューなしでファイル名のみ表示） -->
                </div>
                <button 
                  class="u-button-default u-button-default--small u-button-default--primary" 
                  @click.prevent="downloadFile(planning_format_category_detail.answer)" 
                  target="_blank">
                  添付ファイルをダウンロード
                </button>
              </td>
              <td v-else-if="planning_format_category_detail.detail_input_type == 'date'">
                {{ moment(planning_format_category_detail.answer).format('YYYY/MM/DD') }}
              </td>
              <td v-else-if="planning_format_category_detail.detail_input_type == 'radio'">
                <div class="__answer __answer--radio">
                  <div class="u-form-input-group">
                    <!-- loop radio -->
                    <label class="u-form-radio-default u-form-input-radio"
                      v-for="(planning_format_category_detail_content, sfcdc_index) of planning_format_category_detail.planning_format_category_detail_contents"
                      :key="sfcdc_index"
                    >
                      <input type="radio" name="radio-{{ planning_format_category_detail.id }}" value="{{ planning_format_category_detail_content.content }}" 
                        :checked="checkAnswerRadio(planning_format_category_detail.answer, planning_format_category_detail_content.content)"
                        disabled
                      >
                      <span class="__text">{{ planning_format_category_detail_content.content }}</span>
                    </label>
                    <!-- END loop radio -->
                  </div>
                </div>
              </td>
              <td v-else-if="planning_format_category_detail.detail_input_type == 'checkbox'">
                <div class="u-form-input-group">
                  <!-- loop checkbox -->
                  <label class="u-form-checkbox-default u-form-input-checkbox"
                    v-for="(planning_format_category_detail_content, sfcdc_index) of planning_format_category_detail.planning_format_category_detail_contents"
                    :key="sfcdc_index"
                  >
                    <input type="checkbox" name="checkbox-{{ planning_format_category_detail.id }}" value="{{ planning_format_category_detail_content.content }}" 
                      :checked="checkAnswerCheckbox(planning_format_category_detail.answer, planning_format_category_detail_content.content)"
                      disabled
                    >
                    <span class="__text">{{ planning_format_category_detail_content.content }}</span>
                  </label>
                  <!-- END loop checkbox -->
                </div>
              </td>
              <td v-else>
                {{ planning_format_category_detail.answer }}
              </td>
              <!-- END check input_type -->
            </tr>
            <!-- END loop recruit.planning_format.planning_format_categories->planning_format_category_details -->
          </tbody>
        </table>
        <!-- END loop recruit.planning_format.planning_format_categories -->
        
      </div>
    </div>
    <div class="u-flex-center-center mgt-024">
      <a class="u-button-default u-button-default--large u-button-default--secondary __button" :href="generateRoute()">案件詳細に戻る</a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    recruit: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      // key: value
    }
  },
  async created() {
    this.moment = moment;
  },
  methods: {
    generateRoute() {
      return route('contractor.offer.detail', { recruit: this.recruit.id });
    },
    getFileName(asnwer) {
      let arrFileName = asnwer.split('/');
      return arrFileName[2];
    },
    checkFileType(asnwer) {
      /** 
       * check filte type
       * if type is image like ['png', 'jpeg', 'svg', 'jpg']
       * return TRUE
       * else return FALSE
      */
      const arrType = ['png', 'jpeg', 'svg', 'jpg'];
      let fileName = this.getFileName(asnwer);
      let fileType = fileName.split('.');
      if (arrType.includes(fileType[1])) {
        return true;
      }
      return false;
    },
    checkAnswerRadio(answer, content) {
      if (answer == content) {
        return true;
      }
      return false;
    },
    checkAnswerCheckbox(answer, content) {
      const arrAnswer = answer.split('|');
      if (arrAnswer.includes(content)) {
        return true;
      }
      return false;
    },
    downloadFile(answer) {
      // -- loader show
      let loader = this.$loading.show();
      // -- END loader show

      const fileName = this.getFileName(answer);
      
      // -- donwload file
      axios({
          url: route('contractor.api.planning_format_category_detail.image.download', { filename: fileName }), // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
      }).then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();

          // -- hide loader
          loader.hide();
          // -- END hide loader
      }).catch((error) => {
        console.log(error)

        // -- hide loader
        loader.hide();
        // -- END hide loader
      });
      // -- END donwload file

      // old
      // return route('contractor.api.planning_format_category_detail.image.download', { filename: fileName })
    },
    /** 
     * for check if recruiting_public_planning_format_categories.is_public_category == 1
    */
    checkRecruitingPublic(planning_id) {
      const publicSurvey = this.recruit.recruiting_public_planning_format_categories;
      const filter = publicSurvey.find(planning => planning.planning_format_category_id  == planning_id);
      return filter.is_public_category;
    },
  },
}
</script>