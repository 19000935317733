<template>
    <div
        :class="`${
            isFullscreen ? 'sitemap-container-fullscreen' : 'sitemap-container'
        } vld-parent`"
        ref="sitemapContainer"
    >
        <loading
            :active="isLoading"
            background-color="#ffffff"
            :can-cancel="false"
            :is-full-page="false"
        />

        <div class="sitemap-fullscreen-button-container">
            <img
                :src="
                    isFullscreen
                        ? '/assets/img/site/icon_defaultscreen.svg'
                        : '/assets/img/site/icon_fullscreen.svg'
                "
                class="sitemap-fullscreen-button"
                :style="`top: calc(${svgContainerHeight} - 32px - 40px);`"
                @click.prevent="onClickFullscreen"
            />
        </div>

        <div
            class="sitemap-svg-container"
            :style="`height: ${svgContainerHeight};`"
            ref="svgContainer"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                :width="width"
                :height="height"
                :viewBox="`0 0 ${width} ${height}`"
                ref="svgRoot"
            >
                <rect
                    :width="width"
                    :height="height"
                    fill="white"
                    @mouseup="endMove"
                ></rect>

                <!-- 背景 -->
                <svg
                    x="24"
                    y="24"
                    :width="width - 24 * 2"
                    :height="height - 24 * 2"
                    v-if="backgroundRows"
                >
                    <template v-for="index in backgroundRows">
                        <rect
                            :x="(index - 1) * 280"
                            y="0"
                            width="280"
                            :height="height - 24 * 2"
                            :fill="
                                (index - 1) % 2 === 0 ? '#E0F6F5' : '#E8F9FC'
                            "
                        />
                    </template>
                </svg>

                <template v-if="editingNodes === null">
                    <template v-for="columnNodes of nodes">
                        <template
                            v-for="node of columnNodes.filter(
                                (columnNode) => columnNode !== null
                            )"
                            :key="node.id"
                        >
                            <sitemap-node
                                :node="node"
                                :on-down="beginMove"
                                :on-up="endMove"
                                :on-click-add-child="onClickAddChild"
                                :on-click-delete="onClickDelete"
                                :is-moving="false"
                                :is-show-dialog="isShowDialog"
                            />

                            <template
                                v-if="
                                    node.from !== null &&
                                    typeof nodes[node.from.column][
                                        node.from.row
                                    ] !== 'undefined'
                                "
                            >
                                <sitemap-line
                                    :current-node="node"
                                    :parent-node="
                                        nodes[node.from.column][node.from.row]
                                    "
                                    :node-default-size="nodeDefaultSize"
                                    :node-distance="nodeDistance"
                                    :is-show-dialog="isShowDialog"
                                />
                            </template>
                        </template>
                    </template>
                </template>

                <!-- ノード編集中の表示 -->
                <template v-else-if="editingNodes !== null">
                    <template v-for="columnNodes of editingNodes">
                        <template
                            v-for="node of columnNodes.filter(
                                (columnNode) => columnNode !== null
                            )"
                            :key="node.id"
                        >
                            <sitemap-node
                                :node="node"
                                :on-down="beginMove"
                                :on-up="endMove"
                                :is-moving="false"
                            />

                            <template
                                v-if="
                                    node.from !== null &&
                                    typeof nodes[node.from.column][
                                        node.from.row
                                    ] !== 'undefined'
                                "
                            >
                                <sitemap-line
                                    :current-node="node"
                                    :parent-node="
                                        nodes[node.from.column][node.from.row]
                                    "
                                    :node-default-size="nodeDefaultSize"
                                    :node-distance="nodeDistance"
                                />
                            </template>
                        </template>
                    </template>

                    <!-- ドロップ可能な場合にハイライト -->
                    <rect
                        v-if="highlightDroppable !== null"
                        :x="highlightDroppable.x"
                        :y="highlightDroppable.y"
                        :width="highlightDroppable.width"
                        :height="highlightDroppable.height"
                        :fill="highlightDroppable.color"
                        fill-opacity="40%"
                    />
                </template>

                <!-- 移動中のノード群 -->
                <template v-if="movingNodes !== null">
                    <svg :x="movingNodes.x" :y="movingNodes.y">
                        <template
                            v-for="columnNodes of movingNodes.movingNodes"
                        >
                            <template
                                v-for="node of columnNodes.filter(
                                    (columnNode) => columnNode !== null
                                )"
                                :key="node.id"
                            >
                                <sitemap-node
                                    :node="node"
                                    :on-down="beginMove"
                                    :on-up="endMove"
                                    :is-moving="true"
                                />

                                <template
                                    v-if="
                                        node.from !== null &&
                                        typeof movingNodes.movingNodes[
                                            node.from.column
                                        ][node.from.row] !== 'undefined'
                                    "
                                >
                                    <sitemap-line
                                        :current-node="node"
                                        :parent-node="
                                            movingNodes.movingNodes[
                                                node.from.column
                                            ][node.from.row]
                                        "
                                        :node-default-size="nodeDefaultSize"
                                        :node-distance="nodeDistance"
                                        :is-moving="true"
                                    />
                                </template>
                            </template>
                        </template>
                    </svg>
                </template>

                <!-- ダイアログ背景 -->
                <rect
                    width="100%"
                    height="100%"
                    fill="white"
                    fill-opacity="0.01"
                    @click="closeDialog"
                    v-if="showFormDialog !== null || showDeleteDialog !== null"
                />

                <!-- ノード追加フォーム -->
                <sitemap-add-form
                    :x="showFormDialog.x"
                    :y="showFormDialog.y"
                    :on-submit="submitForm"
                    :page-types="pageTypes"
                    :editing-page="showFormDialog.editingPage"
                    :default-data="showFormDialog.defaultData"
                    v-if="showFormDialog !== null"
                />

                <!-- 削除ダイアログ -->
                <sitemap-delete-dialog
                    :x="showDeleteDialog.x"
                    :y="showDeleteDialog.y"
                    :page-name="showDeleteDialog.pageName"
                    :on-cancel="cancelDelete"
                    :on-submit="submitDelete"
                    v-if="showDeleteDialog !== null"
                />

                <!-- API更新中の操作防止 -->
                <rect
                    width="100%"
                    height="100%"
                    fill="white"
                    fill-opacity="0.1"
                    v-if="isLoading"
                />
            </svg>
        </div>
    </div>

    <!-- ノードの高さ計算用 -->
    <div ref="textForCalc" class="sitemap-text-for-calc" />
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import SitemapAddForm, { sitemapAddFormSize } from './SitemapAddForm';
import SitemapDeleteDialog from './SitemapDeleteDialog';
import SitemapLine from './SitemapLine';
import SitemapNode from './SitemapNode';

const nodeDefaultSize = { width: 240, height: 50 };
const nodeDistance = { x: 40, y: 32 };
const startPoint = { x: 44, y: 45 };
const deleteDialogDefaultPoint = { x: 300, y: 222 };

/**
 * ページ一覧APIのレスポンスをツリー構造に変換
 */
const convertPagesToTree = (pages) => {
    const appendToChildRecursive = (convertedPageArray, page) => {
        for (const convertedPage of convertedPageArray) {
            if (Number(convertedPage.id) === Number(page.parent_id)) {
                convertedPage.children.push({
                    id: page.id,
                    text: page.title,
                    borderColor: page.page_type.color_code,
                    pageTypeId: page.page_type.id,
                    parentId: page.parent_id,
                    order: page.order,
                    children: [],
                });
                return true;
            }

            if (appendToChildRecursive(convertedPage.children, page)) {
                return true;
            }
        }

        return false;
    };

    let convertedPages = [];
    let pendingPages = [];

    for (const page of pages) {
        if (Number(page.parent_id) === 0) {
            convertedPages.push({
                id: page.id,
                text: page.title,
                borderColor: page.page_type.color_code,
                pageTypeId: page.page_type.id,
                parentId: page.parent_id,
                order: page.order,
                children: [],
            });
            continue;
        }

        if (!appendToChildRecursive(convertedPages, page)) {
            // 親ノードがまだ配列に入っていないため最後に追加
            pendingPages.push(page);
        }
    }

    while (pendingPages.length > 0) {
        let tempPendingPages = [];

        for (const page of pendingPages) {
            if (!appendToChildRecursive(convertedPages, page)) {
                tempPendingPages.push(page);
            }
        }

        pendingPages = tempPendingPages;
    }

    const sortRecursive = (pages) => {
        pages.sort((a, b) => a.order - b.order);

        for (const page of pages) {
            sortRecursive(page.children);
        }

        return pages;
    };

    convertedPages = sortRecursive(convertedPages);
    return convertedPages;
};

/**
 * グラフ表示用に座標を計算した配列を作成
 */
const getGraphNodes = (dataArray, startPoint, textElement) => {
    let column = 0;
    let row = 0;
    let grid = [];
    let rowHeights = [];

    const appendToGrid = (id, text, borderColor, order, column, row, from) => {
        for (let i = 0; i <= column; i++) {
            if (!Array.isArray(grid[i])) {
                grid[i] = [];
            }
        }
        for (let i1 = 0; i1 <= row; i1++) {
            if (typeof grid[column][i1] === 'undefined') {
                grid[column][i1] = null;
            }
        }

        // ノードの高さを計算
        textElement.textContent = text;
        let height = textElement.clientHeight + 22;

        if (height < 50) {
            height = 50;
        }

        let y = startPoint.y;

        if (typeof rowHeights[row - 1] !== 'undefined') {
            // 現在行より上の高さを合計
            let totalHeight = 0;
            for (let i = 0; i <= row - 1; i++) {
                const rowHeight = rowHeights[i];
                totalHeight += rowHeight + nodeDistance.y;
            }

            y = startPoint.y + totalHeight;
        }

        grid[column][row] = {
            id,
            text,
            borderColor,
            order,
            x: startPoint.x + (nodeDefaultSize.width + nodeDistance.x) * column,
            y,
            width: nodeDefaultSize.width,
            height,
            to: null,
            from,
        };

        if (
            typeof rowHeights[row] === 'undefined' ||
            rowHeights[row] < height
        ) {
            // 行の高さを保持
            rowHeights[row] = height;
        }

        if (
            from &&
            typeof grid[from.column][from.row] !== 'undefined' &&
            grid[from.column][from.row]
        ) {
            if (grid[from.column][from.row].to) {
                grid[from.column][from.row].to.push({
                    column,
                    row,
                });
            } else {
                grid[from.column][from.row].to = [
                    {
                        column,
                        row,
                    },
                ];
            }
        }
    };

    const appendChildrenRecursive = (data, hasNext, parentPoint) => {
        for (let i = 0; i < data.children.length; i++) {
            const child = data.children[i];
            appendToGrid(
                child.id,
                child.text,
                child.borderColor,
                child.order,
                column,
                row,
                parentPoint
            );
            column += 1;
            appendChildrenRecursive(child, i < data.children.length - 1, {
                column: column - 1,
                row,
            });
        }
        column -= 1;
        if (hasNext) {
            row += 1;
        }
    };

    for (let i = 0; i < dataArray.length; i++) {
        const data = dataArray[i];
        appendToGrid(
            data.id,
            data.text,
            data.borderColor,
            data.order,
            column,
            row,
            null
        );
        column += 1;
        appendChildrenRecursive(data, i < dataArray.length - 1, {
            column: column - 1,
            row,
        });
    }
    return grid;
};

/**
 * ページ配列から該当する ID のページを抜き出す
 */
const popPageRecursive = (pagesArray, targetId) => {
    for (const [index, page] of pagesArray.entries()) {
        if (Number(page.id) === Number(targetId)) {
            pagesArray.splice(index, 1);
            return page;
        }

        if (page.children) {
            const result = popPageRecursive(page.children, targetId);
            if (result) {
                return result;
            }
        }
    }
};

export default {
    name: 'Sitemap',
    components: {
        Loading,
        SitemapAddForm,
        SitemapDeleteDialog,
        SitemapLine,
        SitemapNode,
    },

    props: ['project', 'isExpand', 'onClickFullscreen', 'isFullscreen'],

    data() {
        return {
            // APIから取得したページタイプ一覧
            pageTypes: [],
            // APIから取得したページ一覧
            pages: [],
            // APIから取得したページ一覧をツリー構造に変換したもの
            convertedPages: [],
            // 座標を計算したサイトマップのノード
            nodes: [],
            showFormDialog: null,
            showDeleteDialog: null,
            nodeDefaultSize,
            nodeDistance,
            isMoving: false,
            editingNodes: null,
            movingNodes: null,
            highlightDroppable: null,
            isLoading: false,
            cancelTimeout: null,
            svgContainerHeight: '',
            scrollbarHeight: 0,
        };
    },

    mounted() {
        this.showFormDialog = null;
        this.showDeleteDialog = null;
        this.scrollbarHeight =
            this.$refs.sitemapContainer.getBoundingClientRect().height - 600;

        this.updateSvgContainerHeight();
        this.fetch();
    },

    watch: {
        isFullscreen() {
            this.updateSvgContainerHeight();
        },

        isExpand() {
            this.updateSvgContainerHeight();
        },
    },

    computed: {
        backgroundRows() {
            if (!this.nodes || this.nodes.length === 0) {
                return 0;
            }

            return this.nodes.length + 1 > 5 ? this.nodes.length + 1 : 5;
        },

        width() {
            let rightEdge =
                24 * 2 +
                (nodeDefaultSize.width + nodeDistance.x) * this.backgroundRows;
            const minWidth =
                24 * 2 + (nodeDefaultSize.width + nodeDistance.x * 2) * 2;
            const width = rightEdge >= minWidth ? rightEdge : minWidth;

            if (
                this.showFormDialog &&
                this.showFormDialog.x + sitemapAddFormSize.width > width
            ) {
                return width + sitemapAddFormSize.width + nodeDistance.x + 24;
            }

            return width;
        },

        height() {
            const minHeight = 600;

            if (this.nodes.length === 0) {
                return minHeight;
            }

            let maxBottom = 0;

            for (const columnNodes of this.nodes) {
                for (const node of columnNodes) {
                    if (node === null) {
                        continue;
                    }

                    const bottom = node.y + node.height;
                    if (bottom > maxBottom) {
                        maxBottom = bottom;
                    }
                }
            }

            const bottomEdge = maxBottom + nodeDistance.y + 24;
            const height = bottomEdge > minHeight ? bottomEdge : minHeight;

            if (
                this.showFormDialog &&
                this.showFormDialog.y + sitemapAddFormSize.height > height
            ) {
                return (
                    this.showFormDialog.y +
                    sitemapAddFormSize.height +
                    nodeDistance.y +
                    24
                );
            }

            if (height <= minHeight && this.isFullscreen) {
                const sitemapContainer = this.$refs.sitemapContainer;

                if (sitemapContainer) {
                    const rect = sitemapContainer.getBoundingClientRect();
                    return rect.height - 25;
                }
            }

            return height;
        },

        /**
         * ダイアログが表示中かどうか
         */
        isShowDialog() {
            return (
                this.showDeleteDialog !== null || this.showFormDialog !== null
            );
        },
    },

    methods: {
        updateSvgContainerHeight() {
            if (this.isFullscreen) {
                this.svgContainerHeight = '100vh';
            } else if (this.isExpand) {
                this.svgContainerHeight = `${
                    this.height + this.scrollbarHeight
                }px`;
            } else {
                this.svgContainerHeight = `${600 + this.scrollbarHeight}px`;
            }
        },

        /**
         * サイトマップの情報を取得
         */
        async fetch() {
            const project = this.project;

            if (!project || !project.hasOwnProperty('line_number')) {
                return;
            }

            this.isLoading = true;

            // ページタイプ
            try {
                const result = await axios.get(
                    `/api/project/${project.line_number}/site-flow/page-types`
                );
                this.pageTypes = result.data;
            } catch (error) {
                alert('データの取得に失敗しました');
                this.isLoading = false;
                return;
            }

            // ページ一覧
            try {
                const result = await axios.get(
                    `/api/project/${project.line_number}/site-flow/pages`
                );

                this.pages = result.data;
            } catch (error) {
                alert('データの取得に失敗しました');
                this.isLoading = false;
                return;
            }

            // ページをツリー構造に変換
            this.convertedPages = convertPagesToTree(this.pages);

            // ノード座標の計算
            this.nodes = getGraphNodes(
                this.convertedPages,
                startPoint,
                this.$refs.textForCalc
            );

            this.isLoading = false;
        },

        beginMove(event, nodeId) {
            this.isMoving = true;

            // クリック判定のため少し待ってからノード移動を開始
            setTimeout(() => {
                if (!this.isMoving) {
                    // クリックとして判定
                    let clickedNode;

                    for (const columnNodes of this.nodes) {
                        for (const node of columnNodes) {
                            if (
                                node !== null &&
                                Number(node.id) === Number(nodeId)
                            ) {
                                clickedNode = node;
                                break;
                            }
                        }

                        if (clickedNode) {
                            break;
                        }
                    }

                    if (clickedNode) {
                        this.onClickAddChild(clickedNode, 'edit');
                    }

                    return;
                }

                let editingNodes = [];
                let movingNodes = [];
                let movingBasePoint = null;

                for (const [column, columnNodes] of this.nodes.entries()) {
                    let tempRowNodes = [];

                    for (const [row, node] of columnNodes.entries()) {
                        if (node === null) {
                            tempRowNodes.push(null);
                            continue;
                        } else if (Number(node.id) === Number(nodeId)) {
                            // 子ツリーごと取る
                            const getMovingNodesRecursive = (pagesArray) => {
                                for (const page of pagesArray) {
                                    if (Number(page.id) === Number(node.id)) {
                                        movingNodes = getGraphNodes(
                                            [page],
                                            {
                                                x: 0,
                                                y: 0,
                                            },
                                            this.$refs.textForCalc
                                        );
                                        movingBasePoint = { column, row };
                                        return;
                                    }

                                    if (page.children) {
                                        getMovingNodesRecursive(page.children);
                                    }
                                }
                            };

                            getMovingNodesRecursive(
                                JSON.parse(JSON.stringify(this.convertedPages))
                            );

                            tempRowNodes.push(null);
                            continue;
                        }

                        if (
                            node.from !== null &&
                            editingNodes[node.from.column][node.from.row] ===
                                null
                        ) {
                            tempRowNodes.push(null);
                            continue;
                        }

                        tempRowNodes.push(node);
                    }

                    editingNodes.push(tempRowNodes);
                }

                this.editingNodes = editingNodes;

                if (movingNodes) {
                    const svgRect = this.$refs.svgRoot.getBoundingClientRect();
                    this.movingNodes = {
                        x:
                            event.clientX -
                            svgRect.x -
                            nodeDefaultSize.width / 2 -
                            32,
                        y:
                            event.clientY -
                            svgRect.y -
                            nodeDefaultSize.height / 2 -
                            32,
                        movingNodes,
                        basePoint: movingBasePoint,
                    };
                }

                this.$refs.svgRoot.addEventListener('mousemove', this.move);
                this.$refs.svgRoot.addEventListener(
                    'mouseleave',
                    this.cancelMove
                );
            }, 250);
        },

        endMove(event) {
            const svgRect = this.$refs.svgRoot.getBoundingClientRect();
            const pointerX = event.clientX - svgRect.x;
            const pointerY = event.clientY - svgRect.y;
            this.updatePages(pointerX, pointerY);

            this.$refs.svgRoot.removeEventListener('mousemove', this.move);
            this.$refs.svgRoot.removeEventListener(
                'mouseleave',
                this.cancelMove
            );
            this.editingNodes = null;
            this.movingNodes = null;
            this.highlightDroppable = null;
            this.isMoving = false;
        },

        cancelMove() {
            // mouseleave 後一定時間経ったらドラッグをキャンセルするように
            // ドラッグしながらスクロールした場合にも mouseleave が発生しやすいため一定時間待つ
            this.cancelTimeout = setTimeout(() => {
                this.$refs.svgRoot.removeEventListener('mousemove', this.move);
                this.$refs.svgRoot.removeEventListener(
                    'mouseleave',
                    this.cancelMove
                );
                this.editingNodes = null;
                this.movingNodes = null;
                this.highlightDroppable = null;
                this.isMoving = false;
                this.cancelTimeout = null;
            }, 500);
        },

        move(event) {
            if (this.cancelTimeout) {
                clearTimeout(this.cancelTimeout);
                this.cancelTimeout = null;
            }

            const svgRect = this.$refs.svgRoot.getBoundingClientRect();

            if (!this.movingNodes) {
                return;
            }

            const pointerX = event.clientX - svgRect.x;
            const pointerY = event.clientY - svgRect.y;
            const movingNodes = JSON.parse(JSON.stringify(this.movingNodes));
            movingNodes.x = pointerX - nodeDefaultSize.width / 2 - 32;
            movingNodes.y = pointerY - nodeDefaultSize.height / 2 - 32;
            this.movingNodes = movingNodes;

            this.canDrop(pointerX, pointerY);

            // ノードがスクロール境界に来たら自動でスクロールする
            const container = this.$refs.svgContainer;
            const containerRect = container.getBoundingClientRect();
            const scrollEdgeRight =
                event.clientX -
                svgRect.x +
                movingNodes.movingNodes[0][0].width / 2 +
                20;
            const scrollEdgeLeft =
                event.clientX -
                svgRect.x -
                movingNodes.movingNodes[0][0].width / 2 -
                10;

            const scrollEdgeTop =
                event.clientY -
                svgRect.y -
                movingNodes.movingNodes[0][0].height / 2 -
                10;
            const scrollEdgeBottom =
                event.clientY -
                svgRect.y +
                movingNodes.movingNodes[0][0].height / 2 +
                20;

            if (scrollEdgeRight > containerRect.width + container.scrollLeft) {
                container.scroll(
                    scrollEdgeRight - containerRect.width,
                    container.scrollTop
                );
            } else if (scrollEdgeLeft < container.scrollLeft) {
                container.scroll(scrollEdgeLeft, container.scrollTop);
            }

            if (scrollEdgeBottom > containerRect.height + container.scrollTop) {
                container.scroll(
                    container.scrollLeft,
                    scrollEdgeBottom - containerRect.height
                );
            } else if (scrollEdgeTop < container.scrollTop) {
                container.scroll(container.scrollLeft, scrollEdgeTop);
            }
        },

        canDrop(pointerX, pointerY) {
            this.highlightDroppable = null;

            if (!this.editingNodes) {
                return null;
            } else if (pointerX < startPoint.x - nodeDistance.x) {
                return null;
            }

            const x = pointerX - (startPoint.x - nodeDistance.x);
            const column = Math.floor(
                x / (nodeDefaultSize.width + nodeDistance.x)
            );

            const movingTopNode = this.movingNodes.movingNodes[0][0];

            // ポインタ位置の下のノード
            if (
                typeof this.editingNodes[column] !== 'undefined' &&
                this.editingNodes[column] !== null
            ) {
                const columnNodes = this.editingNodes[column];

                for (const node of columnNodes) {
                    if (node === null) {
                        continue;
                    } else if (
                        pointerY < node.y - nodeDistance.y / 2 ||
                        pointerY > node.y + node.height + nodeDistance.y
                    ) {
                        continue;
                    }

                    if (
                        pointerY <
                        node.y + node.height / 2 + nodeDistance.y / 2
                    ) {
                        // 上半分
                        this.highlightDroppable = {
                            x:
                                startPoint.x -
                                nodeDistance.x +
                                (nodeDefaultSize.width + nodeDistance.x) *
                                    column +
                                nodeDistance.x +
                                10,
                            y:
                                node.y -
                                movingTopNode.height +
                                nodeDefaultSize.height / 2,
                            width: movingTopNode.width,
                            height: movingTopNode.height,
                            color: movingTopNode.borderColor,
                        };

                        // ドロップ対象のノードを返す
                        return { node, position: 'above' };
                    } else {
                        // 下半分
                        this.highlightDroppable = {
                            x:
                                startPoint.x -
                                nodeDistance.x +
                                (nodeDefaultSize.width + nodeDistance.x) *
                                    column +
                                nodeDistance.x +
                                10,
                            y:
                                node.y +
                                node.height -
                                nodeDefaultSize.height / 2,
                            width: movingTopNode.width,
                            height: movingTopNode.height,
                            color: movingTopNode.borderColor,
                        };

                        // ドロップ対象のノードを返す
                        return { node, position: 'below' };
                    }
                }
            }

            // ポインタ位置の左のノード
            if (
                typeof this.editingNodes[column - 1] !== 'undefined' &&
                this.editingNodes[column - 1] !== null
            ) {
                const columnNodes = this.editingNodes[column - 1];

                for (const [row, node] of columnNodes.entries()) {
                    if (node === null) {
                        continue;
                    } else if (
                        pointerY < node.y - nodeDistance.y / 2 ||
                        pointerY > node.y + node.height + nodeDistance.y
                    ) {
                        continue;
                    }

                    const basePoint = this.movingNodes.basePoint;
                    if (
                        Number(basePoint.column) === column &&
                        Number(basePoint.row) == row
                    ) {
                        // 同じ場所へのドロップは不可
                        return null;
                    } else if (node.to && node.to.length > 0) {
                        // 子ノードがある場合は右へのドロップは不可
                        return null;
                    }

                    this.highlightDroppable = {
                        x:
                            startPoint.x -
                            nodeDistance.x +
                            (nodeDefaultSize.width + nodeDistance.x) * column +
                            nodeDistance.x,
                        y: node.y,
                        width: movingTopNode.width,
                        height: movingTopNode.height,
                        color: movingTopNode.borderColor,
                    };

                    // ドロップ対象のノードを返す
                    return { node, position: 'left' };
                }
            }

            return null;
        },

        updatePages(pointerX, pointerY) {
            const canDrop = this.canDrop(pointerX, pointerY);

            if (!canDrop || !canDrop.node) {
                return;
            }

            if (canDrop.position === 'above' || canDrop.position === 'below') {
                // 対象ノードより上の子として移す
                const targetNode = canDrop.node;
                const movingTopNode = this.movingNodes.movingNodes[0][0];
                let tempPages = JSON.parse(JSON.stringify(this.convertedPages));
                const movePage = popPageRecursive(tempPages, movingTopNode.id);

                const addChildPageRecursive = (
                    pageOrPagesArray,
                    targetPageId,
                    childPage,
                    position
                ) => {
                    if (Array.isArray(pageOrPagesArray)) {
                        for (const [
                            index,
                            page,
                        ] of pageOrPagesArray.entries()) {
                            if (
                                page.parentId === null &&
                                Number(page.id) === Number(targetPageId)
                            ) {
                                // トップへの移動の場合
                                if (position === 'above') {
                                    pageOrPagesArray.splice(
                                        index,
                                        0,
                                        childPage
                                    );
                                } else if (position === 'below') {
                                    pageOrPagesArray.splice(
                                        index + 1,
                                        0,
                                        childPage
                                    );
                                }

                                const added = JSON.parse(JSON.stringify(page));
                                added.id = null;
                                return added;
                            }

                            const addedParentPage = addChildPageRecursive(
                                page,
                                targetPageId,
                                childPage,
                                position
                            );
                            if (addedParentPage) {
                                return addedParentPage;
                            }
                        }

                        return null;
                    }

                    const page = pageOrPagesArray;

                    for (const [index, child] of page.children.entries()) {
                        if (Number(child.id) === Number(targetPageId)) {
                            if (position === 'above') {
                                page.children.splice(index, 0, childPage);
                            } else if (position === 'below') {
                                page.children.splice(index + 1, 0, childPage);
                            }

                            return page;
                        }

                        const addedParentPage = addChildPageRecursive(
                            child,
                            targetPageId,
                            childPage,
                            position
                        );
                        if (addedParentPage) {
                            return addedParentPage;
                        }
                    }

                    return null;
                };

                const addedParentPage = addChildPageRecursive(
                    tempPages,
                    targetNode.id,
                    movePage,
                    canDrop.position
                );

                if (addedParentPage) {
                    // orderを再設定
                    const updateOrderRecursive = (pages) => {
                        let tempPages = [];

                        for (const [index, page] of pages.entries()) {
                            page.order = index + 1;

                            if (page.children) {
                                page.children = updateOrderRecursive(
                                    page.children
                                );
                            }

                            tempPages.push(page);
                        }

                        return tempPages;
                    };
                    tempPages = updateOrderRecursive(
                        JSON.parse(JSON.stringify(tempPages))
                    );

                    this.convertedPages = tempPages;
                    this.nodes = getGraphNodes(
                        this.convertedPages,
                        startPoint,
                        this.$refs.textForCalc
                    );

                    // APIへ送信
                    // orderの変更を行っているため, 移動先の親の子すべてを送信する
                    if (addedParentPage.id === null) {
                        // トップ階層への追加の場合
                        let updatedPages = [];

                        for (const page of JSON.parse(
                            JSON.stringify(this.convertedPages)
                        )) {
                            if (page.parentId !== null) {
                                page.parentId = null;
                            }

                            updatedPages.push(page);
                        }

                        this.sendMovedPage(updatedPages);
                    } else {
                        let updatedParentPage = popPageRecursive(
                            JSON.parse(JSON.stringify(this.convertedPages)),
                            addedParentPage.id
                        );

                        // 移動したノードの親IDを変更
                        for (const page of updatedParentPage.children) {
                            if (
                                Number(page.parentId) !==
                                Number(updatedParentPage.id)
                            ) {
                                page.parentId = updatedParentPage.id;
                                break;
                            }
                        }

                        this.sendMovedPage(updatedParentPage.children);
                    }
                }
            } else if (canDrop.position === 'left') {
                // 対象ノードの子として移す
                const leftNode = canDrop.node;
                const movingTopNode = this.movingNodes.movingNodes[0][0];
                let tempPages = JSON.parse(JSON.stringify(this.convertedPages));
                const movePage = popPageRecursive(tempPages, movingTopNode.id);

                if (Number(leftNode.id) === Number(movePage.parentId)) {
                    // 同じ場所へのドロップは不可
                    return;
                }

                const addChildPageRecursive = (
                    pagesArray,
                    parentPageId,
                    childPage
                ) => {
                    for (const page of pagesArray) {
                        if (Number(page.id) === Number(parentPageId)) {
                            if (!page.children || page.children.length === 0) {
                                page.children = [childPage];
                            } else {
                                page.children.push(childPage);
                            }
                            return page;
                        }

                        if (page.children) {
                            const addedParentPage = addChildPageRecursive(
                                page.children,
                                parentPageId,
                                childPage
                            );
                            if (addedParentPage) {
                                return addedParentPage;
                            }
                        }
                    }

                    return null;
                };

                const addedParentPage = addChildPageRecursive(
                    tempPages,
                    leftNode.id,
                    movePage
                );

                if (addedParentPage) {
                    this.convertedPages = tempPages;
                    this.nodes = getGraphNodes(
                        this.convertedPages,
                        startPoint,
                        this.$refs.textForCalc
                    );

                    // APIへ送信
                    movePage.parentId = addedParentPage.id;
                    this.sendMovedPage([movePage]);
                }
            }
        },

        /**
         * APIへノードの移動を送信
         */
        async sendMovedPage(movedPages) {
            if (!this.project) {
                return;
            }

            this.isLoading = true;
            const project = this.project;
            let requestData = [];

            const createRequest = (convertedPages) => {
                for (const convertedPage of convertedPages) {
                    requestData.push({
                        id: convertedPage.id,
                        title: convertedPage.text,
                        parent_id: convertedPage.parentId,
                        order: convertedPage.order,
                        page_type_id: convertedPage.pageTypeId,
                    });
                }
            };
            createRequest(movedPages);

            try {
                await axios.put(
                    `/api/project/${project.line_number}/site-flow/pages`,
                    requestData
                );
            } catch (error) {
                alert('変更に失敗しました');
                this.isLoading = false;
                return;
            }

            // データを再取得
            await this.fetch();
            this.isLoading = false;
        },

        onClickAddChild(node, type) {
            // ページ追加ダイアログを表示
            if (type === 'right') {
                const x = node.x + node.width + nodeDistance.x;
                this.showFormDialog = {
                    x,
                    y: node.y,
                    parentId: node.id,
                    order: 1,
                };

                const container = this.$refs.svgContainer;
                const containerRect = container.getBoundingClientRect();
                if (
                    x + sitemapAddFormSize.width >
                    containerRect.width + container.scrollLeft
                ) {
                    // スクロール外にフォームが表示される場合
                    setTimeout(() => {
                        container.scroll({
                            left: x + sitemapAddFormSize.width,
                            behavior: 'smooth',
                        });
                    }, 100);
                }
            } else if (type === 'bottom') {
                let parentId;

                if (
                    node.from === null ||
                    typeof this.nodes[node.from.column][node.from.row] ===
                        'undefined'
                ) {
                    // ルートの場合
                    parentId = null;
                } else {
                    parentId = this.nodes[node.from.column][node.from.row].id;
                }

                const y = node.y + node.height + nodeDistance.y;
                this.showFormDialog = {
                    x: node.x,
                    y,
                    parentId,
                    order: node.order ? node.order : 1,
                };

                const container = this.$refs.svgContainer;
                const containerRect = container.getBoundingClientRect();
                if (
                    y + sitemapAddFormSize.height >
                    containerRect.height + container.scrollTop
                ) {
                    // スクロール外にフォームが表示される場合
                    setTimeout(() => {
                        container.scroll({
                            top: y + sitemapAddFormSize.height,
                            behavior: 'smooth',
                        });
                    }, 100);
                }
            } else if (type === 'edit') {
                // 編集時
                const editingPage = popPageRecursive(
                    JSON.parse(JSON.stringify(this.convertedPages)),
                    node.id
                );
                this.showFormDialog = {
                    x: node.x,
                    y: node.y,
                    editingPage,
                };

                const y = node.y + node.height + nodeDistance.y;
                const container = this.$refs.svgContainer;
                const containerRect = container.getBoundingClientRect();
                if (
                    y + sitemapAddFormSize.height >
                    containerRect.height + container.scrollTop
                ) {
                    // スクロール外にフォームが表示される場合
                    setTimeout(() => {
                        container.scroll({
                            top: y + sitemapAddFormSize.height,
                            behavior: 'smooth',
                        });
                    }, 100);
                }
            }
        },

        closeDialog() {
            if (this.showFormDialog) {
                this.showFormDialog = null;
            } else if (this.showDeleteDialog) {
                this.showDeleteDialog = null;
            }
        },

        async submitForm(title, pageTypeId) {
            if (!this.showFormDialog || !this.project) {
                return;
            }

            const project = this.project;
            const tempDialog = JSON.parse(JSON.stringify(this.showFormDialog));
            this.isLoading = true;
            this.showFormDialog = null;

            if (tempDialog.editingPage) {
                // ページ編集APIへ送信
                const editingPage = tempDialog.editingPage;

                try {
                    await axios.put(
                        `/api/project/${project.line_number}/site-flow/pages`,
                        [
                            {
                                id: editingPage.id,
                                title,
                                parent_id: editingPage.parentId,
                                order: editingPage.order,
                                page_type_id: pageTypeId,
                            },
                        ]
                    );
                } catch (error) {
                    this.isLoading = false;
                    this.showFormDialog = {...tempDialog, ...{defaultData: {title, pageTypeId}}};
                    alert('保存に失敗しました');
                    return;
                }
            } else {
                // ページ追加APIへ送信
                try {
                    await axios.post(
                        `/api/project/${project.line_number}/site-flow/pages`,
                        {
                            parent_id: tempDialog.parentId,
                            page_type_id: pageTypeId,
                            title,
                            order: tempDialog.order,
                        }
                    );
                } catch (error) {
                    this.isLoading = false;
                    this.showFormDialog = {...tempDialog, ...{defaultData: {title, pageTypeId}}};
                    alert('保存に失敗しました');
                    return;
                }
            }

            // データを再取得
            await this.fetch();
            this.isLoading = false;
        },

        onClickDelete(node) {
            this.showDeleteDialog = {
                x: deleteDialogDefaultPoint.x,
                y: deleteDialogDefaultPoint.y,
                id: node.id,
                pageName: node.text,
            };
        },

        cancelDelete() {
            this.showDeleteDialog = null;
        },

        async submitDelete() {
            if (!this.showDeleteDialog || !this.project) {
                return;
            }

            const tempDialog = JSON.parse(JSON.stringify(this.showDeleteDialog));
            this.isLoading = true;
            this.showDeleteDialog = null;

            const project = this.project;
            const targetId = tempDialog.id;
            const tempPages = JSON.parse(JSON.stringify(this.convertedPages));
            const targetPage = popPageRecursive(tempPages, targetId);
            let deleteIds = [targetPage.id];

            const setDeleteIdsRecursive = (pagesArray) => {
                for (const page of pagesArray) {
                    deleteIds.push(page.id);

                    setDeleteIdsRecursive(page.children);
                }
            };
            setDeleteIdsRecursive(targetPage.children);

            try {
                await axios.delete(
                    `/api/project/${project.line_number}/site-flow/pages`,
                    {
                        data: {
                            data: deleteIds,
                        },
                    }
                );
            } catch (error) {
                this.isLoading = false;
                this.showDeleteDialog = tempDialog;
                alert('保存に失敗しました');
                return;
            }

            // データを再取得
            await this.fetch();
            this.isLoading = false;
        },
    },
};
</script>

<style scoped>
h1 {
    font-size: 16px;
}

.sitemap-container {
    width: 100%;
    background-color: white;
}

.sitemap-container-fullscreen {
    position: fixed;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
}

.sitemap-fullscreen-button-container {
    position: relative;
    width: 100%;
}

.sitemap-fullscreen-button {
    position: absolute;
    left: 16px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
    transition: top 300ms ease;
}

.sitemap-svg-container {
    overflow: scroll;
    width: 100%;
    transition: height 300ms ease;
}

.sitemap-text-for-calc {
    width: 208px;
    visibility: hidden;
    position: absolute;
    font-weight: 700;
    font-size: 16px;
}
</style>
