<template>
    <g :style="isShowDialog ? 'opacity: 0.15' : ''">
        <g
            ref="container"
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            class="sitemap-node-container"
        >
            <rect
                :x="isMoving ? node.x + 32 : node.x"
                :y="isMoving ? node.y + 32 : node.y"
                :width="node.width"
                :height="node.height"
                :stroke="node.borderColor ?? '#607475'"
                fill="#fff"
                stroke-width="2"
                ref="rect"
                :class="isMoving ? 'sitemap-node-rect-shadow' : null"
            />

            <!-- テキスト -->
            <foreignObject
                :x="isMoving ? node.x + 32 : node.x"
                :y="isMoving ? node.y + 32 : node.y"
                :width="node.width"
                :height="node.height"
                pointer-events="none"
                ref="foreignObject"
            >
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    class="sitemap-node-text-container"
                >
                    <p class="sitemap-node-text" ref="text">
                        {{ node.text }}
                    </p>
                </div>
            </foreignObject>
        </g>

        <!-- 削除ボタン -->
        <!-- トップページでは削除ボタンは表示しない -->
        <image
            :x="node.x - 15"
            :y="node.y - 15"
            width="30"
            height="30"
            href="/assets/img/site/icon_delete_small_min.svg"
            @click="deleteNode"
            class="sitemap-node-add-button"
            v-if="!isTopPage && !isMoving"
        />

        <!-- 追加ボタン 下 -->
        <image
            :x="node.x + node.width / 2 - 15"
            :y="node.y + node.height - 15"
            width="30"
            height="30"
            href="/assets/img/site/icon_add_accent_small_min.svg"
            @click="addBelow"
            class="sitemap-node-add-button"
            v-if="!isMoving"
        />

        <!-- 追加ボタン 右 -->
        <image
            :x="node.x + node.width - 15"
            :y="node.y + node.height / 2 - 15"
            width="30"
            height="30"
            href="/assets/img/site/icon_add_accent_small_min.svg"
            @click="addChild"
            class="sitemap-node-add-button"
            v-if="!hasChildren && !isMoving"
        />
    </g>
</template>

<script>
export default {
    name: 'SitemapNode',

    props: [
        'node',
        'isMoving',
        'isShowDialog',
        'onDown',
        'onUp',
        'onClickAddChild',
        'onClickDelete',
    ],

    mounted() {},

    computed: {
        isTopPage() {
            if (!this.node) {
                return false;
            }

            return this.node.from === null && this.node.order === 1;
        },

        hasChildren() {
            if (!this.node) {
                return false;
            }

            return this.node.to !== null && this.node.to.length > 0;
        },
    },

    methods: {
        onMouseDown(event) {
            if (this.onDown) {
                this.onDown(event, this.node.id);
            }
        },

        onMouseUp(event) {
            if (this.onUp) {
                this.onUp(event);
            }
        },

        addChild() {
            if (this.onClickAddChild) {
                this.onClickAddChild(this.node, 'right');
            }
        },

        addBelow() {
            if (this.onClickAddChild) {
                this.onClickAddChild(this.node, 'bottom');
            }
        },

        deleteNode() {
            if (this.onClickDelete) {
                this.onClickDelete(this.node);
            }
        },
    },
};
</script>

<style scoped>
.sitemap-node-container {
    cursor: move;
}

.sitemap-node-rect-shadow {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
}

.sitemap-node-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.sitemap-node-text {
    margin: 0;
    display: -webkit-box;
    /*-webkit-line-clamp: 3;*/
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #334142;
    font-weight: 700;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sitemap-node-add-button {
    cursor: pointer;
}
</style>
