<template>
  <form action="" method="post">
    <div class="__block-depth-2 __block-depth-2--title-area">
      <dl class="u-list-input-label-set __list">
        <div class="__item">
          <dt class="__term">提案項目タイトル</dt>
          <dd class="__body">
            <span class="u-form-input-default u-form-input-text-box">
              <input v-model="title" type="text" placeholder="例）サイトに関するご要望" name="title" class="u-text-bold u-text-leadcopy">
            </span>
            <!-- error message -->
            <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.title.$errors" :key="index">
              {{ error.$message }}
            </p>
            <!-- END error message -->
          </dd>
        </div>
      </dl>
    </div>
    <div class="__block-depth-2 __block-depth-2--repeat-area">
      <dl class="u-list-input-label-set __list">
        <div class="__item">
          <dt class="__term">「{{ title }}」の質問項目を設定</dt>
          <dd class="__body">
            <ul class="__questions">
              <question-survey v-for="question in questions" :key="question.questionId" :items="question" @delete-question="deleteQuestion" :validationScope="validationScope" />
            </ul>
          </dd>
        </div>
      </dl>
    </div>
    <div class="__block-depth-2 __block-depth-2--control-area">
      <div class="u-flex-center-center">
        <button class="__button __button--add-question" type="button" @click="addQuestion()">
          <img class="__image" src="/assets/img/site/icon_add_secondary_large_min.svg" alt="">
          <br>
          <span class="__caption u-text-caption">質問を追加</span>
        </button>
      </div>
    </div>
    <div class="__block-depth-2 __block-depth-2--submit-area">
      <div class="u-flex-center-center">
        <a :href="backToSettingPlanning()" class="u-button-default u-button-default--large u-button-default--negative __button">項目一覧に戻る</a>
        <input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="登録" @click.prevent="submitSettingPlanningDetail">
      </div>
    </div>
  </form>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, required, minLength, maxLength } from '@vuelidate/validators'
  // -- END vuelidate
  import QuestionSurvey from '../components/settings/QuestionSurvey.vue'
  export default {
    setup () {
      const validation_scope = 'planning_detail'
      return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
    },
    components: {
      QuestionSurvey
    },
    props: ['planning_category'],
    data() {
      return {
        title: '',
        currentQuestionsId: 0,
        questions: [],
        // -- add by backend
        validationScope: this.validation_scope,
        // -- END add by backend
      }
    },
    validations() {
      return {
        title: { 
          required: helpers.withMessage('提案項目タイトルは必須項目です。', required), 
          minLength: helpers.withMessage(
            ({ $params }) => `提案項目タイトルは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`提案項目タイトルは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
      }
    },
    created() {
        this.fetchData();
    },
    methods: {
      addQuestion() {
        this.questions.push({
          questionId: ++this.currentQuestionsId,
          questionTitle: null,
          questionType: 'textbox',
          questionOptions: [
            {
              optionId: 0,
              optionName: null
            }
          ]
        })
      },
      deleteQuestion(payload) {
        const result = this.questions.filter((item) => {
          return item.questionId !== payload
        });
        this.questions = result;
      },
      fetchData() {
        // set from planning_categories.title
        this.title = this.planning_category.title;
        this.currentQuestionsId = 0;
        // set from planning_categories.planning_category_details
        this.planning_category.planning_category_details.forEach(planning_category_detail => {
          let planning_detail = {
            id: planning_category_detail.id,
            questionId: this.currentQuestionsId,
            questionTitle: planning_category_detail.title,
            questionType: planning_category_detail.detail_input_type,
            questionOptions: []
          }

          // -- set questionOptions from planning_category_detail_contents
          let questionOptionsId = 0;
          planning_category_detail.planning_category_detail_contents.forEach(planning_category_detail_content => {
            let planning_detail_option = {
              id: planning_category_detail_content.id,
              optionId: questionOptionsId,
              optionName: planning_category_detail_content.content
            }
            questionOptionsId++;
            planning_detail.questionOptions.push(planning_detail_option);
          });
          // -- END set questionOptions from planning_category_detail_contents

          this.questions.push(planning_detail);
          this.currentQuestionsId++;
        });
      },
      // -- add by backend
      async submitSettingPlanningDetail() {
        // -- check validation
        const isFormCorrect = await this.v$.$validate();
        if (isFormCorrect) {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          // -- prepare formData
          const fd = new FormData();
          fd.append('questions', JSON.stringify(this.questions));
          fd.append('title', this.title);
          // -- END prepare formData
  
          // -- submit
          await axios.post(route('contractor.api.setting.planning.update', { planning_category: this.planning_category.id }), fd)
            .then((response) => {
              if (response.status == 200) {
                window.location.href = route('contractor.settings.planning');
              }
            })
            .catch((error) => {
              console.log(error)
            });
          // -- END submit

          // -- hide loader
          loader.hide();
          // -- END hide loader
        }
        // -- END check validation
      },
      backToSettingPlanning() {
        return route('contractor.settings.planning');
      },
      // -- add by backend
    }
  }
</script>

<style scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
