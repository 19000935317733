<template>
	<main class="l-main p-page p-projects-page p-app-page p-service-page">
        <nav class="l-breadcrumb-section">
            <div class="l-container">
                <div class="l-inner">
                    <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                        <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                        <li class="__list"><a class="__link" href="#">案件一覧</a></li>
                    </ul>
                </div>
            </div>
        </nav>
      <article class="p-page-title">
        <section class="l-wrapper">
          <div class="l-container">
            <div class="l-inner">
              <div class="__block-depth-1">
                <div class="__block-depth-2 u-flex-between-center">
                  <h1 class="u-heading-page-title __title">案件一覧</h1>
									<a class="u-button-default u-button-default--medium u-button-default--secondary __button" :href="changePage('contractor.project.create')">
										新規作成
									</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <article class="p-section-01">
        <section class="l-wrapper">
          <div class="l-container">
            <div class="l-inner">
              <div class="__block-depth-1">
                <div class="__block-depth-2">
                  <div class="__block-depth-3">
                    <div class="u-box-content-default">
                      <form class="__form" action="{{ route('contractor.project.index') }}" method="get">
                        <div class="__block-depth-4 __block-depth-4--search-status-date-block u-flex-between-stretch u-flex-wrap">
                          <div class="__block-depth-5 __block-depth-5--search-status-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">案件のステータス</dt>
                                <dd class="__body">
                                  <div class="u-form-input-group">
																		<label class="u-form-checkbox-default u-form-input-checkbox"
																			v-for="(list_status, list_status_id) in list_project_status" :key="list_status_id">
																			<input type="checkbox" name="status_id" :value="list_status_id" v-model="form_filter.status_id">
																			<span class="__text">{{ list_status }}</span>
																		</label>
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div class="__block-depth-5 __block-depth-5--search-date-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">最終更新日</dt>
                                <dd class="__body">
                                  <div class="__block-depth-6 u-flex-between-center">
                                    <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                      <input type="date" placeholder="検索範囲の開始日" name="status_updated_at_min" v-model="form_filter.status_updated_at_min">
                                    </span>
                                    <span class="__separeter u-flex-shrink-0">〜</span>
                                    <span class="u-form-input-default u-form-input-date u-form-input-default--add-icon js-input-icon-effect">
                                      <input type="date" placeholder="検索範囲の終了日" name="status_updated_at_max" v-model="form_filter.status_updated_at_max">
                                    </span>
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                        <div class="__block-depth-4 __block-depth-4--search-client-project-block u-flex-between-stretch u-flex-wrap">
                          <div class="__block-depth-5 __block-depth-5--search-client-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">顧客名</dt>
                                <dd class="__body">
                                  <span class="u-form-input-default u-form-input-text-box">
                                    <!-- use select2 -->
                                    <Select2 v-model="form_filter.customer_id" :options="map_list_customers"
                                      style="border: none;"
                                      :settings="{ width: '100%' }"
                                      name="customer_id" />
                                    <!-- END use select2 -->
                                  </span>
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div class="__block-depth-5 __block-depth-5--search-project-block">
                            <dl class="u-list-input-label-set __list">
                              <div class="__item">
                                <dt class="__term">案件名</dt>
                                <dd class="__body">
                                  <span class="u-form-input-default u-form-input-text-box">
                                    <input type="text" placeholder="検索キーワード" name="name" v-model="form_filter.name">
                                  </span>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                        <div class="__block-depth-4 __block-depth-4--search-submit-block u-flex-center-center">
                          <input class="u-button-default u-button-default--medium u-button-default--negative __button" type="reset" value="リセット" @click.prevent="resetFilter()">
                          <input class="u-button-default u-button-default--medium u-button-default--primary __button" type="submit" value="検索" @click.prevent="loadData()">
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <!-- section 01 end -->
      <article class="p-section-02">
        <section class="l-wrapper">
          <div class="l-container">
            <div class="l-inner">
              <div class="__block-depth-1">
                <div class="__block-depth-2 __block-depth-2--result-pager-block u-flex-right-center">
                  <div class="c-pagenation-projects">
                    <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
                    <!-- check previousPageUrl is null -->
                    <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
                    <!-- <a class="__button __button--next" href="#" v-else @click.prevent="loadData(lists.current_page-1)">次へ</a> -->
                    <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
                    <!-- END check previousPageUrl null -->

                    <!-- check nextPageUrl is null -->
										<span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
										<!-- <a class="__button __button--prev" href="#" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</a> -->
                    <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
                    <!-- END check nextPageUrl is null -->
                  </div>
                </div>
                <div class="__block-depth-2 __block-depth-2--result-list-block">
                  <div class="__block-depth-3">
                    <div class="u-table-wrapper u-table-wrapper--scroll __table">
                      <div class="u-table-list-item">
                        <div class="__table-header">
                          <div class="__row">
                            <span class="__cell __cell--head u-align-center __status">ステータス</span>
                            <span class="__cell __cell--head u-align-center __modification">最終更新日</span>
                            <span class="__cell __cell--head __project-data">顧客名・案件名</span>
                          </div>
                        </div>
                        <div class="__table-body">
                          <!-- loop start-->
                            <a class="__row __link" :href="displayRoute(data.line_number)"
                                v-for="(data, index) in lists.data"
                                :key="index">
                              <span class="__cell __cell--head u-align-center __status">
                                <span class="u-text-label-status" :class="'u-text-label-status--'+data.status_color">
                                    {{ data.project_status_option.display_name }}
                                </span>
                              </span>
                              <span class="__cell __cell--data u-align-center __modification">
                                <span class="__date">
                                    {{ moment(data.status_updated_at).format('YYYY/MM/DD') }}
                                </span>
                              </span>
                              <span class="__cell __cell--data __project-data">
                                <p class="u-text-caption u-line-height-140">
                                    {{ data.customer_name }}
                                </p>
                                <p class="u-text-bold mgt-008">
                                  {{ data.name }}
                                </p>
                              </span>
                            </a>
                          <!-- loop end-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="__block-depth-2 __block-depth-2--result-pager-block u-flex-right-center">
                  <div class="c-pagenation-projects">
                    <span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
                    <!-- check previousPageUrl is null -->
                    <span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
                    <!-- <a class="__button __button--next" href="#" v-else @click.prevent="loadData(lists.current_page-1)">次へ</a> -->
                    <span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
                    <!-- END check previousPageUrl null -->

                    <!-- check nextPageUrl is null -->
										<span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
										<!-- <a class="__button __button--prev" href="#" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</a> -->
                    <span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
                    <!-- END check nextPageUrl is null -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <!-- section 02 end -->
  </main>
    <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" href="#">案件一覧</a></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
  props: {
    list_customers: {
      type: Array,
      default: []
    },
    list_project_status: {
      type: Array,
      default: []
    },
  },
	data() {
		return {
			lists: d_lists,
			filter: d_filter,
			form_filter: {
				customer_id: '',
				status_id: [],
				status_updated_at_min: '',
				status_updated_at_max: '',
				name: '',
			},
      map_list_customers: [],
		}
	},
	async created(){
    this.moment = moment;
    this.setMapListCustomer();
		// this.loadData();
  },
	methods: {
		async loadData(page = 1){
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			this.form_filter.page = page;
			await axios.get(route('contractor.project.index', {page: page, data: this.form_filter}))
				.then((res) => {
					this.lists = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
		changePage(data) {
			let routeTo = route(data);
      return routeTo;
		},
		resetFilter() {
			this.form_filter = {
				customer_id: '',
				status_id: [],
				status_updated_at_min: '',
				status_updated_at_max: '',
				name: '',
				page: 1,
			}

      this.loadData();
		},
    displayRoute(id) {
      let displayRoute = route('contractor.project.show', { project: id});
      return displayRoute;
    },
    setMapListCustomer() {
      if (this.list_customers.length > 0) {
        this.list_customers.forEach(val => {
          this.map_list_customers.push({
            id: val.id,
            text: val.name
          })
        });
      }
    },
	},
};
</script>
