<template>
  <form action="" method="">
		<div class="u-box-content-default">
			<p class="__text mgt-024">{{ generateConfirmationMessage() }}</p>
			<div class="u-form-input-group mgt-024"></div>
		</div>
		<div class="__block-depth-2">
			<div class="u-flex-center-center mgt-048">
				<input class="u-button-default u-button-default--large u-button-default--primary" type="submit" :value="generateButtonText()" @click.prevent="submitUpdateAccount()">
			</div>
		</div>
	</form>
</template>

<script>
export default {
  props: {
		contract: {
			type: Object,
			default: {}
		}
	},
  data() {
    return {
			// 
		};
  },
  created() {
		// 
  },
  methods: {
		async submitUpdateAccount() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			let form = {
				contractors: {
					plan_payment_period_id: this.contract.nextPeriod.paymentPeriodId,
					plan_detail_id: this.contract.nextPeriod.id,
				},
				change_type: this.contract.displayCancelButton ? 'cancel_next_phase' : 'next_phase'
			};

			// -- submit
			await axios.put(route('contractor.api.setting.account.update'), form)
				.then((response) => {
					if (response.status == 200) {
						// -- hide loader
						loader.hide();
						// -- END hide loader

						window.location.href = route('contractor.settings.account');
					}
				})
				.catch((error) => {
					console.log(error)
					// -- hide loader
					loader.hide();
					// -- END hide loader
				});
			// -- END submit
    },
		generateConfirmationMessage() {
			if (this.contract.displayCancelButton) {
				return "Would you like to cancel schedule "+this.contract.nextPeriod.nameEN+" ?";
			}
			else {
				return "Would you like to change period to "+this.contract.nextPeriod.nameEN+" ?";
			}
		},
		generateButtonText() {
			if (this.contract.displayCancelButton) {
				return 'キャンセル';
			}
			else {
				return '更新';
			}
		},
	},
};
</script>
