<template>
  <tr>
    <td colspan="4" style="padding: 0">
      <table class="u-table-assignment-item-row">
        <tbody>
          <tr>
            <td class="__item">
              <div class="u-flex-left-center">
                <div class="u-form-input-group">
                  <label class="u-form-checkbox-default u-form-input-checkbox">
                    <input
                      v-model="item.isBulkAssign"
                      type="checkbox"
                      name="bulk_registration_target"
                      @change="emitBulkRegistrationOff()"
                    /><span class="__text"></span>
                  </label>
                </div>
                <span class="__text u-text-bold">{{ item.itemName }}</span>
              </div>
            </td>
            <td class="__prescribed u-align-center u-text-bold">
              {{ item.itemPrescribed }}人日
            </td>
            <td class="__assignment">
              <ul class="__list">
                <li
                  v-for="(assign, index) in item.itemAssign"
                  :key="assign.assignId"
                  class="__item u-flex-between-center u-flex-wrap"
                >
                  <span
                    class="
                      u-form-select-default
                      u-form-input-select
                      u-form-input-select--large
                      __menber
                      u-flex-grow-1
                    "
                    :class="
                      v$.item.itemAssign.$errors.length > 0
                      ? v$.item.itemAssign.$each.$response.$errors[index].member.length > 0 ? 'backend-border-danger' : ''
                      : ''
                    "
                  >
                    <select v-model="assign.member">
                      <option
                        v-for="(contractorUser, index) of contractorUsers"
                        :key="index"
                        :value="contractorUser.id"
                      >
                        {{ contractorUser.name }}
                      </option>
                    </select>
                  </span>
                  <span
                    class="u-form-input-default __man-hours"
                    :class="
                      v$.item.itemAssign.$errors.length > 0
                      ? v$.item.itemAssign.$each.$response.$errors[index].manHours.length > 0 ? 'backend-border-danger' : ''
                      : ''
                    "
                  >
                    <input
                      v-model="assign.manHours"
                      class="u-align-right"
                      type="number"
                      placeholder="工数入力"
                      name=""
                      @blur="totalManHours()"
                    />
                  </span>
                  <span class="__text">人日</span>
                  <button
                    class="__button __button--delete"
                    type="button"
                    @click="
                      deleteAssign(assign.assignId);
                      totalManHours();
                    "
                  >
                    <img
                      class="__image"
                      src="/assets/img/site/icon_delete_small_min.svg"
                      alt="削除"
                    />
                  </button>
                  <div class="__inner-row-error-messages">
                    <div v-if="v$.item.itemAssign.$errors.length > 0">
                        <p class="mgt-008 u-text-varidate-label" v-for="(memberError, reIndex) of v$.item.itemAssign.$errors[0].$response.$errors[index].member" :key="reIndex">
                            {{ memberError.$message }}
                        </p>
                    </div>
                    <div v-if="v$.item.itemAssign.$errors.length > 0">
                        <p class="mgt-008 u-text-varidate-label" v-for="(manHoursError, reIndex) of v$.item.itemAssign.$errors[0].$response.$errors[index].manHours" :key="reIndex">
                            {{ manHoursError.$message }}
                        </p>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="__add">
                <button
                  class="__button __button--add-assign"
                  type="button"
                  @click="addAssign()"
                >
                  <img
                    class="__image"
                    src="/assets/img/site/icon_add_secondary_small_min.svg"
                    alt="追加"
                  />
                </button>
                <span class="__caption u-text-caption">アサインを追加</span>
              </div>
            </td>
            <td
              class="__total u-align-right u-text-bold"
              :rowspan="item.itemOptions.length + 1"
            >
              <span class="__large">{{ convertDecimal(manHours) }}人日</span>
              <span class="__small">／{{ convertDecimal(prescribed) }}人日</span>
            </td>
          </tr>
          <assignment-table-option-row
            v-for="option in item.itemOptions"
            :key="option.optionId"
            :current-option="option"
            @update-manhours="totalManHours()"
            @bulk-option="emitBulkRegistrationOff()"
            :contractor-users="contractorUsers"
            :validation-scope="validationScope"
            @delete-option="deleteOption"
          />
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
// -- vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
// -- END vuelidate
// -- for custom validate
const decimal42 = helpers.regex(/^-?\d{1,4}(\.\d{1,2})?$/); // for decimal (4,2)
const checkAssigment = (value, siblings, parent, parent2) => {
  // check if value is not null, then check if value is unique
  if (value !== null) {
    // check if parent.itemAssign is undifined
    let check = 0;
    if (parent.itemAssign !== undefined) {
      check = parent.itemAssign.filter(assigmen => assigmen.member == value);
    }
    else {
      check = parent2.itemAssign.filter(assigmen => assigmen.member == value);
    }

    if (check.length > 1) {
      return false;
    }
  }
  return true;
};
// -- END for custom validate
import AssignmentTableOptionRow from "./AssignmentTableOptionRow.vue";
export default {
  setup(props) {
    return { v$: useVuelidate({ $scope: props.validationScope }) };
  },
  components: {
    AssignmentTableOptionRow,
  },
  props: {
    currentItem: {
      type: Object,
      default: {},
    },
    contractorUsers: {
      type: Array,
      default: [],
    },
    validationScope: {
      type: String,
      default: "",
    },
  },
  emits: ["update-total", "bulk-item", "delete-item"],
  data() {
    return {
      item: {},
      currentAssignId: 0,
      manHours: 0,
      prescribed: 0,
      itemAssignTemporary: [],
    };
  },
  // create watch, to monitor the changes of the props contractorUsers.currentItem
  watch: {
    currentItem: {
      handler: function (newVal, oldVal) {
        this.item = newVal;
        this.currentAssignId = this.item.itemAssign.length;
      },
      deep: true,
    },
  },
  validations() {
    return {
      item: {
        itemAssign: {
					$each: helpers.forEach({
						manHours: {
              required: helpers.withMessage(
                "割当工数は必須項目です。",
                required
              ),
              decimal42: helpers.withMessage(
                "割当工数 は整数4桁, 少数2桁で入力してください。",
                decimal42
              ),
            },
            member: {
              required: helpers.withMessage("アサインメンバーは必須項目です。", required),
              checkAssigment: helpers.withMessage(
                "同じメンバーは割り当てられません。",
                checkAssigment
              ),
            },
					})
        },
      },
    };
  },
  created() {
    this.item = this.currentItem;
    this.currentAssignId = this.item.itemAssign.length;
  },
  mounted() {
    this.totalManHours();
    this.totalPrescribed();
  },
  methods: {
    addAssign() {
      let assigment = {
        paId: '',
        assignId: this.currentAssignId,
        manHours: null,
        member: null,
        cost_id: this.item.cost_id
      };
      // -- to check is it for optional_costs
      if (this.item.type == 'optional_cost') {
        assigment.type = 'optional_cost_assignment';
      }
      else {
        assigment.type = 'page_assignments';
      }
      // -- END to check is it for optional_costs
      this.item.itemAssign.push(assigment);
      this.currentAssignId++;
    },
    deleteAssign(assignId) {
      // -- add to array delete id
      let findData = this.item.itemAssign.find(assign => assign.assignId == assignId);
      this.$emit("delete-item", findData);
      // -- END add to array delete id
      const filtered = this.item.itemAssign.filter((assign) => {
        return assign.assignId !== assignId;
      });
      this.item.itemAssign = filtered;
    },
    totalManHours() {
      const optionsManHours = this.item.itemOptions
        .map((option) => {
          return option.optionAssign
            .map((assign) => {
              return assign.manHours;
            })
            .reduce((prev, current) => {
              return prev + current;
            }, 0);
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      const itemManHours = this.item.itemAssign
        .map((item) => {
          return item.manHours;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      this.manHours = optionsManHours + itemManHours;
      this.emitUpdateTotal();
    },
    totalPrescribed() {
      const optionsPrescribed = this.item.itemOptions
        .map((option) => {
          return option.optionPrescribed;
        })
        .reduce((prev, current) => {
          return prev + current;
        }, 0);
      this.prescribed = optionsPrescribed + this.item.itemPrescribed;
    },
    emitUpdateTotal() {
      this.$emit("update-total");
    },
    emitBulkRegistrationOff() {
      this.$emit("bulk-item");
    },
    // -- add by backend
    // for rounding 2 decimal
    convertDecimal(data) {
      return +(Math.round(data + "e+2") + "e-2");
    },
    deleteOption(data) {
      this.$emit("delete-item", data);
    },
    // -- END add by backend
  },
};
</script>
