<template>
  <div>
    <h1 class="u-heading-content-title">
      <!-- 2022年12月31日（日） -->
      {{ moment(data.filter_date).format('YYYY年MM月DD日（dd）') }}大阪 太郎さんの作業実績
    </h1>
    <div class="__block-depth-2 mgt-024" id="vue_management_daily_report">
      <form method="" action="">
        <div class="__block-depth-3 __block-depth-3--repeat">
          <daily-report-item
            v-for="item in items"
            :key="item.itemId"
            :current-items="item"
            :current-items-id="item.itemId"
            @item-update="updateItem"
            @item-delete="deleteItem"
            :projects="data.projects"
            :work_targets="data.work_targets"
            :validation-scope="validation_scope"
          />
        </div>
        <div class="__block-depth-3 __block-depth-3--controller">
          <div class="u-flex-center-center mgt-024">
            <button
              class="__button __button--add-option"
              type="button"
              @click="addItem()"
            >
              <img
                class="__image"
                src="/assets/img/site/icon_add_secondary_large_min.svg"
                alt="追加"
              />
              <br />
              <span class="__caption u-text-caption">作業内容を追加</span>
            </button>
          </div>
          <div class="u-flex-right-center __result">
            <dl class="u-list-format-report __list">
              <div class="__item __total">
                <dt class="__term">稼働時間合計</dt>
                <dd class="__body">{{ totalHours }}時間{{ totalMinutes }}分</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="__block-depth-3 __block-depth-3--submit">
          <div class="u-flex-center-center mgt-048">
            <a :href="generateRoute()" class="u-button-default u-button-default--large u-button-default--secondary __button">生産性管理</a>
            <button
              class="u-button-default u-button-default--large u-button-default--primary __button"
              type="submit"
              @click.prevent="submit"
            >
              作業内容を登録
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
// -- END vuelidate
import moment from 'moment';
// import ja from '/moment/locale/ja';

import DailyReportItem from "../components/management-daily-report/DailyReportItem.vue";
export default {
  setup () {
    const validation_scope = 'daily_report'
    return { v$: useVuelidate({ $scope: validation_scope }), validation_scope }
  },
  components: {
    DailyReportItem,
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      items: [],
      totalHours: 0,
      totalMinutes: 0,
      currentItemId: 0,
      // -- add by backend
      deleteId: [],
      // -- END add by backend
    };
  },
  async created() {
    // -- set moment js to JA
    this.moment = moment;
    moment.locale('ja');
    // -- END set moment js to JA
    await this.fetchData();
    await this.calculateTime();
  },
  methods: {
    addItem() {
      this.items.push({
        dailyReportId: '',
        itemId: this.currentItemId,
        projectName: {
          value: null,
          isEdit: true,
          projectId: null,
        },
        workHistory: {
          value: null,
          isEdit: true,
          assigmentId: null,
        },
        workResult: {
          hours: 0,
          minutes: 0,
          isEdit: true,
        },
        notice: {
          value: null,
          isEdit: true,
        },
      });
      this.currentItemId++;
    },
    updateItem(payload) {
      this.items.forEach((item) => {
        if (item.itemId === payload.id) {
          item = payload.value;
        }
      });
      this.calculateTime();
    },
    deleteItem(payload) {
      this.setDeleteId(payload);
      const filtered = this.items.filter((item) => {
        return item.itemId !== payload;
      });
      this.items = filtered;
      this.calculateTime();
    },
    calculateTime() {
      let hours = 0;
      let minutes = 0;
      if (this.items.length > 0) {
        hours = this.items
          .map((item) => {
            return item.workResult.hours;
          })
          .reduce((prev, current) => {
            return Number(prev) + Number(current);
          });

        minutes = this.items
          .map((item) => {
            return item.workResult.minutes;
          })
          .reduce((prev, current) => {
            return Number(prev) + Number(current);
          });
      }

      if (minutes < 60) {
        this.totalHours = hours;
        this.totalMinutes = minutes;
      } else {
        this.totalHours = hours + 1;
        this.totalMinutes = minutes - 60;
      }
    },
    fetchData(data = []) {
      this.items = [];

      // -- check param
      /**
       * if param data not set use this.data.daily_reports
       * else
      */
      const dataList = data.length > 0 ? data : this.data.daily_reports;
      // -- END check param

      // -- set items
      dataList.forEach((daily_report, dr_index) => {
        let form_item = {
          dailyReportId: daily_report.id,
          itemId: dr_index,
          projectName: {
            value: daily_report.project.name,
            isEdit: false,
            projectId: daily_report.project.id
          },
          workHistory: {
            value: daily_report.workHistory.title,
            isEdit: false,
            assigmentId: daily_report.assignment.id
          },
          workResult: {
            hours: daily_report.worktime_hour,
            minutes: daily_report.worktime_minute,
            isEdit: false,
          },
          notice: {
            value: daily_report.note,
            isEdit: false,
          },
        };

        this.items.push(form_item);
      });
      // -- END set items

      this.currentItemId = this.items.length;
    },
    // -- add by backend
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        console.log('lolos')
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- set form
        let form = {
          items: this.items,
          deleteId : this.deleteId,
          date: this.data.filter_date
        };
        // -- END form

        await axios.put(route('contractor.api.daily_report.update'), form)
          .then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.fetchData(response.data.record);
            }

            // -- reset deleteId
            this.deleteId = [];
            // -- END reset deleteId
          })
          .catch((error) => {
            console.log(error)
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
      else {
        console.log('tidak lolos')
      }
    },
    setDeleteId(payload) {
      const item = this.items.find(item => item.itemId == payload);
      if (item.dailyReportId != '') {
        this.deleteId.push(item.dailyReportId);
      }
    },
    // -- END add by backend
    generateRoute(){
        return route('contractor.productivity.index');
    },
  },
};
</script>
