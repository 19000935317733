<template>
  <div class="u-box-content-default mgt-032 __box">
		<h3 class="u-heading-box-title mgt-024">この案件の応募者一覧</h3>

		<div class="u-table-wrapper u-table-wrapper--scroll __table mgt-024">
			<table class="u-table-list-item u-table-offers-applicant">
				<tbody class="__table-body">
					<!-- loop start-->
					<tr class="__row" v-for="(data, index) in lists.data" :key="index">
						<td class="__cell __cell--data __status">{{ data.offer_status_option.display_name_recruiter }}</td>
						<td class="__cell __cell--data __icon u-align-center">
							<figure class="__thumbnail">
								<img class="__image" :src="getProfileImage(data.contractor_user.profile_image_url)" alt="">
							</figure>
						</td>
						<td class="__cell __cell--data __date u-align-center">{{ moment(data.created_at).format('YYYY/MM/DD HH:mm') }} 応募</td>
						<td class="__cell __cell--data __applicant u-text-bold">{{ data.contractor_user.name }}</td>
						<td class="__cell __cell--data __detail">
							<a class="u-button-default u-button-default--small u-button-default--secondary" :href="goToOfferApplications(data.id)">詳細</a>
						</td>
					</tr>
					<!-- loop end-->
				</tbody>
			</table>
		</div>

		<!-- navigation page -->
		<div class="c-pagenation-projects mgt-024">
			<!-- <span class="__caption">全100件中＊＊件目〜＊＊件目を表示</span>
			<button class="__button __button--next">次へ</button>
			<button class="__button __button--prev">前へ</button> -->

			<span class="__caption">全{{ lists.total }}件中{{ lists.from }}件目〜{{ lists.to }}件目を表示</span>
			<!-- check previousPageUrl is null -->
			<span class="__button __button--next __button--disabled" v-if="lists.prev_page_url == null">次へ</span>
			<span class="__button __button--next" v-else @click.prevent="loadData(lists.current_page-1)">次へ</span>
			<!-- END check previousPageUrl null -->

			<!-- check nextPageUrl is null -->
			<span class="__button __button--prev __button--disabled" v-if="lists.next_page_url == null">前へ</span>
			<span class="__button __button--prev" @click.prevent="loadData(lists.current_page+1)" v-else>前へ</span>
			<!-- END check nextPageUrl is null -->
		</div>
		<!-- END navigation page -->
	</div>
</template>

<script>
import moment from 'moment';
export default {
	props: {
		offers: {
			type: Object,
			default: {}
		},
		recruit: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			lists: {},
		}
	},
	created () {
    this.moment = moment;
    this.lists = this.offers; // init data
  },
	methods: {
		async loadData(page = 1){
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show
			await axios.get(route('contractor.api.offer.list', {page: page, recruitId: this.recruit.id}))
				.then((res) => {
          console.log(res)
					this.lists = res.data.lists;
				})
				.catch((error) => {
					console.log(error)
				});

			// -- loader hide
			loader.hide()
			// -- END loader hide
		},
		getProfileImage(profile_image_url) {
      if (profile_image_url != null) {
        let fileName = profile_image_url.split('/');
        return route('contractor.api.setting.member.image.show', { filename: fileName[2]});
      }
      return '/assets/img/site/thumbnail_default_min.png';
    },
		goToOfferApplications(offer) {
			return route('contractor.offer.application.detail', { offer: offer });
		}
	},
}
</script>

<style>

</style>