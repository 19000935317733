<template>
  <form action="" method="" style="width: 100%;">
    <div class="u-box-content-default mgt-064">
      <p class="__text mgt-024">
        Pyattoをご利用いただきありがとうございます、無料期間が過ぎたため以降の継続利用にはいずれかのプランのご契約が必要となります。プランについて詳しくは<a href="#" target="_blank" class="u-text-link">こちらのサイト</a>をご覧ください。下記より契約の支払い期間と、プランを選択いただき、契約ボタンをクリックしてください。
      </p>
      <h3 class="u-heading-box-title mgt-024">支払い期間</h3>
      <div class="u-form-input-group mgt-024">
        <label class="u-form-radio-default u-form-input-radio __under-contract">
          <input class="js-term-select-trigger" type="radio" name="contract_plan_type" value="yearly" 
            v-model="contractPlanType" :checked="contractPlanType == 'yearly'">
          <span class="__text">年間支払い</span>
        </label>
        <label class="u-form-radio-default u-form-input-radio">
          <input class="js-term-select-trigger" type="radio" name="contract_plan_type" value="monthly" 
            v-model="contractPlanType" :checked="contractPlanType == 'monthly'">
          <span class="__text">毎月支払い</span>
        </label>
      </div>
      <h3 class="u-heading-box-title mgt-032">ご利用プラン</h3>
      <div class="u-flex-between-stretch __plan-item-wrap">
        <!-- plan LIGHT -->
        <input type="radio" name="plan" value="light" id="light" v-model="contractPlan" :checked="contractPlan == 'light'">
        <div class="__plan-item">
          <h2 class="__lead u-align-center"><span>ライトプラン</span></h2>
          <p class="__description pdb-024 mgt-016 u-align-center">
            <span class="__big js-term-select-target" data-year="**,***円" data-month="*,***円">
              {{ getPrice('light') }}円
            </span>
            <span class="__normal js-term-select-target" data-year="&amp;nbsp;/&amp;nbsp;年" data-month="&amp;nbsp;/&amp;nbsp;月">
              &nbsp;/&nbsp;{{ contractPlanType == 'yearly' ? '年' : '月' }}
            </span>
          </p>
          <div class="u-flex-center-center">
            <label class="u-button-default u-button-default--medium u-button-default--secondary" for="light"
              @click.prevent="updatePlan('light')">
              選択
            </label>
          </div>
        </div>
        <!-- END plan LIGHT -->

        <!-- plan STANDARD -->
        <input type="radio" name="plan" value="standard" id="standard" v-model="contractPlan" :checked="contractPlan == 'standard'">
        <div class="__plan-item">
          <h2 class="__lead u-align-center"><span>スタンダードプラン</span></h2>
          <p class="__description pdb-024 mgt-016 u-align-center">
            <span class="__big js-term-select-target" data-year="**,***円" data-month="*,***円">
              {{ getPrice('standard') }}円
            </span>
            <span class="__normal js-term-select-target" data-year="&amp;nbsp;/&amp;nbsp;年" data-month="&amp;nbsp;/&amp;nbsp;月">
              &nbsp;/&nbsp;{{ contractPlanType == 'yearly' ? '年' : '月' }}
            </span>
          </p>
          <div class="u-flex-center-center">
            <label class="u-button-default u-button-default--medium u-button-default--secondary" for="regular"
              @click.prevent="updatePlan('standard')">
              選択
            </label>
          </div>
        </div>
        <!-- END plan STANDARD -->
      </div>
    </div>
    <div class="__block-depth-2">
      <div class="u-flex-center-center mgt-048">
        <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="契約" @click.prevent="checkoutPayment">
      </div>
      <div class="u-flex-center-center mgt-048">
        <input class="u-button-default u-button-default--large u-button-default--primary" type="button" value="ログアウト" @click.prevent="logout">
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
export default {
  props: {
    plan: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      contractPlanType: 'yearly',
      contractPlan: 'light',
      // -- for stripe
      dataPlan: [],
      // -- END for stripe
    }
  },
  created() {
    this.moment = moment;
  },
  mounted () {
    this.setDataPlan();
  },
  methods: {
    getPrice(plan_name) {
			const plan = this.plan.find(plan => plan.name.toLowerCase() == plan_name);
      const priceMultiply = this.contractPlanType == 'yearly' ? 12 : 1;
      const planPrice = plan.price*priceMultiply;
			return this.numberWithCommas(planPrice);
		},
		numberWithCommas(x) {
    	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		},
    updatePlan(plan) {
      this.contractPlan = plan;
    },
    /** 
     * not used for now
    */
    async submit() {
      // -- loader show
			let loader = this.$loading.show();
			// -- END loader show

      // -- prepare form
      const form = {
        plan: this.contractPlan,
        plan_payment_period: this.contractPlanType
      };
      // -- END prepare form

      // -- submit
			await axios.put(route('contractor.api.plan.select'), form)
        .then((response) => {
          if (response.status == 200) {
            // -- hide loader
            loader.hide();
            // -- END hide loader

            // -- redirect
            window.location.href = route('contractor.dashboard');
            // -- END redirect
          }
        })
        .catch((error) => {
          console.log(error)
          // -- hide loader
          loader.hide();
          // -- END hide loader
        });
			// -- END submit
    },
    async checkoutPayment() {
      const planInterval = this.getPlanInterval();
      const findDataPlan = this.dataPlan.find(data => data.name == this.contractPlan && data.interval == planInterval);
      window.location.href = route('contractor.subscription.checkout', { plan: findDataPlan.stripe_id});
    },
    setDataPlan() {
      this.plan.forEach(plan => {
        plan.stripe.forEach(planDetail => {
          const data = {
            name: plan.name.toLowerCase(),
            stripe_id: planDetail.user_plan_detail_stripe_id,
            interval: planDetail.interval,
            price: planDetail.price
          };
          this.dataPlan.push(data);
        });
      });
    },
    /** 
     * for now option just month and year
    */
    getPlanInterval() {
      const result = this.contractPlanType == 'monthly' ? 'month' : 'year';
      return result;
    },
    logout() {
      window.location.href = route('contractor.logout');
    },
  },
};
</script>