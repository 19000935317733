<template>
  <div class="u-box-content-default">
    <div class="__block-depth-2 __block-depth-2--progress-block mgt-024">
      <ul class="u-list-progress-recruiting-config __list" data-items="3">
        <li class="__item __active" data-caption="案件募集要項"></li>
        <li class="__bar __active"></li>
        <li
          class="__item __active __current"
          data-caption="公開用ヒアリングシート設定"
        ></li>
        <li class="__bar"></li>
        <li class="__item" data-caption="公開用提案シート設定"></li>
      </ul>
    </div>
    <div class="__block-depth-2 __block-depth-2--title-block mgt-064">
      <h1 class="__sheet-name u-text-headline">
        <span
          v-if="isEdit.title"
          class="
            u-form-input-default
            u-form-input-default--block
            u-form-input-default--medium
            u-form-input-text-box
          "
        >
          <input
            v-model="title"
            type="text"
            placeholder="ヒアリングシート名を入力"
            name=""
            @blur="updateTitle()"
          />
        </span>
        <span
          v-else
          @click="changeEditMode('title')"
          class="p-estimation-editable-area p-estimation-editable-area--block">
          {{ title }}
        </span>
        <!-- error message -->
        <p v-for="(error, index) of v$.title.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">
            {{ error.$message }}
        </p>
        <!-- error message -->
      </h1>
    </div>
    <survey-repeat-item
      v-for="item in items"
      :key="item.itemId"
      :current-items="item"
      :current-items-id="item.itemId"
      :recruiting-config="true"
      @item-update="updateItem"
    />
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, required, minLength, maxLength } from '@vuelidate/validators'
// -- END vuelidate
import SurveyRepeatItem from "../components/survey/SurveyRepeatItem.vue";
export default {
  setup () {
      return { v$: useVuelidate() }
  },
  components: {
    SurveyRepeatItem,
  },
	props: {
		project: {
			type: Object,
			default: {}
		},
		recruit: {
			type: Object,
			default: {}
		},
		survey_format: {
			type: Object,
			default: {}
		}
	},
  data() {
    return {
      isEdit: {
        title: false,
      },
      title: "Pyatto株式会社様コーポレートサイト制作ヒアリングシート",
      items: [],
      temporaryTitle: '',
    };
  },
  validations() {
    return {
      title: {
        required: helpers.withMessage('ヒアリングシート名は必須項目です。', required),
        minLength: helpers.withMessage(
          ({ $params }) => `ヒアリングシート名は、${$params.min}文字以上で入力してください。`, minLength(1)
        ),
        maxLength: helpers.withMessage(
          ({ $params }) =>`ヒアリングシート名は、${$params.max}文字以下で入力してください。`, maxLength(255)
        )
      },
    }
  },
  created() {
    this.fetchData();
    this.setTitle();
  },
  methods: {
    changeEditMode(key) {
      this.isEdit[key] = !this.isEdit[key];
      if(this.isEdit[key]) {
        this.temporaryTitle = this.title;
      }
      else {
        this.temporaryTitle = '';
      }
    },
    updateItem(payload) {
      this.items.forEach((item) => {
        if (item.itemId === payload.itemId) {
          item.itemQuestions.forEach((question) => {
            if (question.questionId === payload.questionId) {
              question.answers.value = payload.value;
            }
          });
        }
      });
    },
    fetchData() {
      this.survey_format.survey_format_categories.forEach(survey_format_category => {
        let item = {
          itemId: survey_format_category.id,
          itemTitle: survey_format_category.title,
          itemQuestions: [],
          recruitingEnable: survey_format_category.recruiting_public_survey_format_categories[0].is_public_category ? 'true' : 'false',
          rpsfc_id: survey_format_category.recruiting_public_survey_format_categories[0].id
        };

        // -- set itemQuestions
        survey_format_category.survey_format_category_details.forEach(survey_format_category_detail => {
          let itemQuestion = {
            questionId: survey_format_category_detail.id,
            questionTitle: survey_format_category_detail.title,
            isEdit: false,
            answerType: survey_format_category_detail.detail_input_type,
            answers: this.setAnswer(survey_format_category_detail.detail_input_type, survey_format_category_detail.survey_format_category_detail_contents, survey_format_category_detail.answer, survey_format_category_detail.file_image_show),
          };

          // -- push to item.itemQuestions
          item.itemQuestions.push(itemQuestion);
          // -- END push to item.itemQuestions
        });
        // -- END set itemQuestions

        // -- push to this.items
        this.items.push(item);
        // -- END push to this.items
      });
    },
		// -- add by backend
    setTitle() {
      this.title = this.survey_format.title;
    },
		setAnswer(answerType, survey_format_category_detail_contents, is_answer = null, is_file = null) {
      let answer = {
        value: '', // for textbox, textarea and radio
        options: [], // for set options checkbox and radio
        values: [], // for checked option checkbox
        fileName: null, // for updload file
        previewImage: null, // for updload file
        tempValue: null,
        tempFileName: null, // for updload file
      };

      switch (answerType) {
        case 'textbox':
          answer.value = is_answer == null ? '回答タイプ：テキストボックス' : is_answer; // default
          break;
        case 'textarea':
          answer.value = is_answer == null ? '回答タイプ：テキストエリア' : is_answer; // default
          break;
        case 'file':
          // -- check and set file name
          let fileName = 'ファイルが選択されていません';
          if (is_answer != null) {
            let arr_name = is_answer.split("/");
            fileName = arr_name[2];
          }
          answer.fileName = fileName; // default
          answer.tempFileName = fileName;
          // -- END check and set file name

          answer.previewImage = is_answer == null ? null : is_file; // default
          answer.tempPreviewImage = is_answer == null ? null : is_file; // default
          answer.isImage = is_file == null ? false : true; // to check is image exist
          answer.tempIsImage = is_answer == null ? null : is_file;
          answer.isChange = false; // for check if file change
          break;
        case 'date':
          answer.value = is_answer == null ? '日付が選択されていません' : is_answer; // default
          break;
        case 'radio':
        case 'checkbox':
        case 'select':
          // -- set options
          survey_format_category_detail_contents.forEach(survey_format_category_detail_content => {
            let option = { 
              id: survey_format_category_detail_content.id, 
              value: survey_format_category_detail_content.content 
            };
            answer.options.push(option);
          });
          // -- END set options

          if (answerType == 'select') {
            answer.value = is_answer != null ? is_answer : 'オプションが選択されていません';
          }
          else if(answerType == 'radio') {
            answer.value = is_answer != null ? is_answer : null;
          }
          else {
            answer.value = is_answer != null ? is_answer.split('|') : [];
            answer.values = is_answer != null ? is_answer.split('|') : [];
          }
          break;
        default:
          answer.value = '回答タイプ：テキストボックス';
          break;
      }

      return answer;
    },
    async updateTitle() {
      // -- check if the value does not change
      if (this.temporaryTitle != this.title) {
        // -- check validation
        const isFormCorrect = await this.v$.$validate();
        if (isFormCorrect) {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          let form = {
            title: this.title
          };

          // -- submit
          await axios.put(route('contractor.api.survey_format.update', { id: this.survey_format.id }), form)
              .then((response) => {
                if (response.status == 200) {
                  this.survey_format.title = response.data.record.title;
                  this.changeEditMode('title');
                }
              })
              .catch((error) => {
                  console.log(error)
                  // -- hide loader
                  loader.hide();
                  // -- END hide loader
              });
          // -- END submit

          // -- hide loader
          loader.hide();
          // -- END hide loader
        }
        // -- END check validation
      }
      else {
        this.changeEditMode('title');
      }
      // -- END check if the value does not change

    },
		// -- END add by backend
  },
};
</script>
