<template>
  <tr>
    <td class="__item u-align-left u-text-bold">
      <span class="u-form-input-default u-form-input-text-box">
        <input
          v-model="item.itemName"
          type="text"
          placeholder="その他作業項目を入力"
          name=""
        />
    	</span>
    	<div class="u-align-left">
            <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.item.itemName.$errors" :key="index">{{ error.$message }}</p>
        </div>
    </td>
    <td class="__subtotal u-align-center">
      <div class="u-flex-between-center">
        <span class="u-form-input-default __man-hours">
          <input
            v-model="item.manHours"
            class="u-align-right"
            type="number"
            placeholder="工数入力"
            name=""
            @blur="emitTotalManHours()"
          />
        </span>
        <span class="__text">人日</span>
        <button
          class="__button __button--delete"
          type="button"
          @click="emitDeleteOthersItem(item.itemId)"
        >
          <img
            class="__image"
            src="/assets/img/site/icon_delete_small_min.svg"
            alt="削除"
          />
        </button>
      </div>
      <div class="u-align-left">
        <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.item.manHours.$errors" :key="index">{{ error.$message }}</p>
      </div>
    </td>
    <td class="__total u-align-right">
      <span class="__text u-text-bold">{{ item.manHours }}人日</span>
    </td>
  </tr>
</template>

<script>
// -- vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
// -- END vuelidate
// -- for custom validate
const decimal42 = helpers.regex(/^-?\d{1,4}(\.\d{1,2})?$/); // for decimal (4,2)
// -- END for custom validate
export default {
	setup(props) {
			return { v$: useVuelidate({ $scope: props.validationScope }) };
	},
  props: {
    currentItem: {
      type: () => {},
      default: {},
    },
    validationScope: {
      type: String,
      default: "",
    },
  },
  emit: ["delete-others"],
  data() {
    return {
      item: {},
    };
  },
  validations() {
    return {
      item: {
        itemName: {
          required: helpers.withMessage("作業項目は必須項目です。", required),
        },
        manHours: {
          required: helpers.withMessage("設定工数は必須項目です。", required),
          decimal42: helpers.withMessage(
            "設定工数 は整数4桁, 少数2桁で入力してください。",
            decimal42
          ),
        },
      },
    };
  },
  created() {
    this.item = this.currentItem;
  },
  methods: {
    emitDeleteOthersItem(itemId) {
      this.$emit("delete-others", itemId);
    },
    emitTotalManHours() {
      this.$emit("update-manhours", null);
    },
  },
};
</script>
