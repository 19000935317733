<template>
  <form style="width: 100%;">
    <div class="u-box-content-default mgt-064">

      <!-- Guidance on billing the difference -->
      <div>
        <h3 class="u-heading-box-title mgt-024">プラン変更後の差額ご請求案内</h3>
        <p class="__text mgt-024">「{{ plan.newPlanDescription }}」
          <span v-if="plan.isSameInterval == 'same'">
            に変更します。ライトプランからスタンダードプランへの変更は即時反映され、以下のプラン差額日割料金が発生します。
          </span>
          <span v-else>
            Because the selected plan period is different from the current plan, this plan will be activated after the current plan ends
          </span>
        </p>
        <table class="u-table-list-item mgt-024">
          <thead>
            <tr>
              <th class="u-align-left"> 料金明細</th>
              <th class="u-align-right">金額</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="u-align-left">
                「{{ plan.newPlanDescription }}」への変更<br>
                （{{ plan.upComingInvoice.period.start }}〜{{ plan.upComingInvoice.period.end }}）
              </td>
              <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.upComingInvoice.subtotal_excluding_tax) }}円</td>
            </tr>
            <tr>
              <td class="u-align-left">消費税（10%）</td>
              <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.upComingInvoice.tax) }}円</td>
            </tr> -->

            <!-- new_plan -->
            <tr v-if="plan.upComingInvoice.new_plan.length != 0">
              <td class="u-align-left">{{ plan.upComingInvoice.new_plan.description }}</td>
              <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.upComingInvoice.new_plan.amount) }}円</td>
            </tr>
            <!-- END new_plan -->

            <!-- remaining -->
            <tr v-if="plan.upComingInvoice.remaining_time.length != 0">
              <td class="u-align-left" style="white-space: pre;">{{ plan.upComingInvoice.remaining_time.description }}</td>
              <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.upComingInvoice.remaining_time.amount) }}円</td>
            </tr>
            <!-- END remaining -->

            <!-- Unused  -->
            <tr v-if="plan.upComingInvoice.unused_time.length != 0">
              <td class="u-align-left" style="white-space: pre;">{{ plan.upComingInvoice.unused_time.description }}</td>
              <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.upComingInvoice.unused_time.amount) }}円</td>
            </tr>
            <!-- END Unused  -->

            <!-- tax_rate -->
            <tr>
              <td class="u-align-left">{{ plan.upComingInvoice.taxDescription }}</td>
              <td class="u-align-right u-text-bold">{{ plan.upComingInvoice.tax }}</td>
            </tr>
            <!-- END tax_rate -->
          </tbody>
        </table>
        <div class="u-flex-column u-flex-center-right mgt-016">
          <!-- subtotal -->
          <!-- <h3 class="__result">
            <span class="__heading">Subtotal</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.subtotal) }}円</span>
          </h3> -->
          <!-- END subtotal -->

          <!-- subtotal -->
          <!-- <h3 class="__result">
            <span class="__heading">Total excluding tax</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.subtotal_excluding_tax) }}円</span>
          </h3> -->
          <!-- END subtotal -->

          <!-- tax -->
          <!-- <h3 class="__result">
            <span class="__heading">Tax (10%)</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.tax) }}円</span>
          </h3> -->
          <!-- END tax -->

          <!-- total -->
          <!-- <h3 class="__result">
            <span class="__heading">Total</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.total) }}円</span>
          </h3> -->
          <!-- END total -->

          <!-- user balance -->
          <h3 class="__result" v-if="plan.upComingInvoice.applied_balance != 0">
            <span class="__heading">Applied balance</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.applied_balance) }}円</span>
          </h3>
          <!-- END user balance -->

          <!-- amount_paid -->
          <!-- <h3 class="__result" v-if="plan.upComingInvoice.amount_paid != 0">
            <span class="__heading">Amount Paid</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.amount_paid) }}円</span>
          </h3> -->
          <!-- END total -->

          <!-- amount_paid -->
          <!-- Amount Due -->
          <h3 class="__result">
            <span class="__heading">合計</span>
            <span class="__price">{{ numberWithCommas(plan.upComingInvoice.amount_due) }}円</span>
          </h3>
          <!-- END total -->

          <h4 class="__billing">
            <span class="__heading">ご請求日</span>
            <span class="__date">{{ plan.upComingInvoice.billing_date }}</span>
          </h4>
        </div>
      </div>
      <!-- END Guidance on billing the difference -->

      <!-- Plan fee after update/change -->
      <h3 class="u-heading-box-title mgt-032">更新・変更後のプラン料金</h3>
      <p class="__text mgt-024">以降は下記のプラン料金が適用されますのでご確認ください。</p>
      <table class="u-table-list-item mgt-024">
        <tbody>
          <tr>
            <td class="u-align-left">「{{ plan.newPlanDescription }}」：1{{ plan.paymentPeriod }}</td>
            <td class="u-align-right u-text-bold">
              {{ numberWithCommas(plan.newPlanPricePerPeriod) }}円
            </td>
          </tr>
          <tr>
            <td class="u-align-left">消費税（10%）</td>
            <td class="u-align-right u-text-bold">{{ numberWithCommas(plan.newPlanPrice10) }}円</td>
          </tr>
        </tbody>
      </table>
      <div class="u-flex-column u-flex-center-right mgt-016">
        <h3 class="__result">
          <span class="__heading">合計金額</span>
          <span class="__price">{{ numberWithCommas(plan.newPlanPrice) }}円</span>
        </h3>
      </div>
      <p class="__text mgt-024">
        プラン変更及びサービス解約を行うまでこのプランで契約が自動的に更新されます。<br>
        次回のご請求は上記プラン開始以降、{{ plan.upComingInvoice.period.end }}に、{{ plan.upComingInvoice.next_period.start }}〜{{ plan.upComingInvoice.next_period.end }}分の利用料金をご請求いたします。
      </p>
      <!-- END Plan fee after update/change -->
    </div>
    <div class="__block-depth-2">
      <div class="u-flex-center-center mgt-048">
        <input 
          class="u-button-default u-button-default--large u-button-default--primary" 
          type="submit" 
          value="更新" 
          @click.prevent="submitUpdateAccount"
          data-cy="btn-submit_update_plan_confirm">
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
export default {
  props: {
    plan: {
      type: Object,
      default: {}
    }
  },
  created() {
    this.moment = moment;
  },
  methods: {
		numberWithCommas(x) {
    	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		},
    async submitUpdateAccount() {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

      // -- prepare form
			let form = {
				contractors: {
					plan_id: this.plan.newPlanId,
          plan_detail_id: this.plan.newPlanDetailId,
				},
        change_type: 'check_current_phase'
			};
      // -- END prepare form

			// -- submit
			await axios.put(route('contractor.api.setting.account.update'), form)
					.then((response) => {
							if (response.status == 200) {
								// -- hide loader
								loader.hide();
								// -- END hide loader

                window.location.href = route('contractor.settings.account');
							}
					})
					.catch((error) => {
							console.log(error)
							// -- hide loader
							loader.hide();
							// -- END hide loader
					});
			// -- END submit
    },
  },
};
</script>