<template>
  <div class="l-contents-column-section">
    <div class="__block-depth-2">
      <form method="post" action="">
        <div class="u-box-content-default __block-depth-3">
          <h2 class="u-heading-box-title mgt-024">課税設定</h2>
          <dl class="u-list-input-label-set __list mgt-024">
            <div class="__item">
              <dt class="__term">消費税設定</dt>
              <dd class="__body">
                <div class="u-form-input-group">
                  <label class="u-form-radio-default u-form-input-radio"
                    v-for="taxDisplayOption in setting.taxDisplayOption"
                    :key="taxDisplayOption.value">
                    <input type="radio" name="tax_display" v-model="form.taxDisplayOption" :value="taxDisplayOption.value">
                    <span class="__text">{{ taxDisplayOption.name }}</span>
                  </label>
                </div>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">消費税率設定</dt>
              <dd class="__body">
                <div class="u-form-input-group">
                  <label class="u-form-radio-default u-form-input-radio"
                    v-for="taxRate in setting.taxRate"
                    :key="taxRate.value">
                    <input type="radio" name="tax_rate" v-model="form.taxRate" :value="taxRate.value">
                    <span class="__text">{{ taxRate.name }}</span>
                  </label>
                </div>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">消費税端数処理</dt>
              <dd class="__body">
                <div class="u-form-input-group">
                  <label class="u-form-radio-default u-form-input-radio"
                    v-for="taxCalculationMethod in setting.taxCalculationMethod"
                    :key="taxCalculationMethod.value">
                    <input type="radio" name="tax_rounding" v-model="form.taxCalculationMethod" :value="taxCalculationMethod.value">
                    <span class="__text">{{ taxCalculationMethod.name }}</span>
                  </label>
                </div>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">源泉徴収税設定</dt>
              <dd class="__body">
                <div class="u-form-input-group">
                  <label class="u-form-radio-default u-form-input-radio"
                    v-for="taxWithholdingOption in setting.taxWithholdingOption"
                    :key="taxWithholdingOption.value">
                    <input type="radio" name="tax_withholding" v-model="form.taxWithholdingOption" :value="taxWithholdingOption.value">
                    <span class="__text">{{ taxWithholdingOption.name }}</span>
                  </label>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <!-- publisher -->
        <div class="u-box-content-default __block-depth-3 mgt-024">
          <h2 class="u-heading-box-title mgt-024">発行者設定</h2>
          <dl class="u-list-input-label-set __list mgt-024">
            <div class="__item">
              <dt class="__term">発行者名</dt>
              <dd class="__body">
                <span class="u-form-input-default u-form-input-text-box">
                  <input type="text" placeholder="例）株式会社Pyatto" v-model="form.publisher">
                  <p v-for="(error, index) of v$.form.publisher.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                </span>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">発行者住所</dt>
              <dd class="__body">
                <span class="u-form-input-default u-form-input-text-box">
                  <input type="text" placeholder="発行者の住所を入力" v-model="form.publisherAddress">
                  <p v-for="(error, index) of v$.form.publisherAddress.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                </span>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">発行者連絡先（電話番号・メールアドレス）</dt>
              <dd class="__body">
                <div class="u-flex-between-top">
                    <div style="width: calc(50% - 12px)">
                        <span>電話番号</span>
                        <span class="u-form-input-default u-form-input-text-box">
                            <input type="tel" placeholder="例）080-1234-5678" v-model="form.publisherPhone">
                            <p v-for="(error, index) of v$.form.publisherPhone.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                    </div>
                    <div style="width: calc(50% - 12px)">
                        <span>メールアドレス</span>
                        <span class="u-form-input-default u-form-input-text-box">
                            <input type="email" placeholder="例）info@example.com" v-model="form.publisherEmail">
                            <p v-for="(error, index) of v$.form.publisherEmail.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                        </span>
                    </div>
                </div>
              </dd>
            </div>
            <div class="__item">
              <dt class="__term">発行者印</dt>
              <dd class="__body">
                <div class="u-form-file-group js-local-file-upload u-flex-left-center">
                  <figure class="__thumbnail u-flex-shrink-0 mgr-024">
                      <img class="__image" :src="form.publisherSeal.url" style="max-width: 100px; height: auto;" alt="">
                  </figure>
                  <button class="__delete mgr-024" type="button" @click.prevent="resetImage">
                      <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
                  </button>
                  <div class="__input u-flex-shrink-1 u-flex-grow-1 u-flex-column-reverse">
                    <div class="__controller">
                      <input type="file" id="input_file" ref="file" accept="image/gif,image/jpeg,image/jpg,image/png" @change="onFileSelected">
                      <label class="u-form-file-upload" for="input_file">
                        <span class="__icon">
                          <img class="__image" src="" alt="">
                        </span>
                        <span class="__text">ファイルを選択</span>
                      </label>
                      <span class="__file-name">{{ publisherSealFileName }}</span>
                    </div>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div class="u-box-content-default __block-depth-3 mgt-024">
          <h2 class="u-heading-box-title mgt-024">単価設定</h2>
          <dl class="u-list-input-label-set __list mgt-024">
            <div class="__item">
              <dt class="__term">標準単価</dt>
              <dd class="__body">
                <span class="u-form-input-default u-form-input-text-box">
                  <input type="number" placeholder="例）10000" v-model="form.basicUnitPrice">
                  <p v-for="(error, index) of v$.form.basicUnitPrice.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                </span>
                <p class="__caption u-text-caption mgt-008">※標準単価は見積もり及び請求書で用いられる単価の初期値になります。</p>
              </dd>
            </div>
          </dl>
        </div>
        <!-- END publisher -->
        <div class="u-box-content-default __block-depth-3 mgt-024">
          <h2 class="u-heading-box-title mgt-024">その他設定</h2>
          <dl class="u-list-input-label-set __list mgt-024">
            <div class="__item">
              <dt class="__term">適格請求書発行事業者登録番号</dt>
              <dd class="__body">
                <span class="u-form-input-default u-form-input-text-box">
                  <input type="text" placeholder="例）12345678" v-model="form.corporateNumber">
                  <p v-for="(error, index) of v$.form.corporateNumber.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
                </span>
              </dd>
            </div>
          </dl>
        </div>
        <div class="u-flex-center-center __block-depth-3 __block-depth-3--submit-area mgt-064">
          <a class="u-button-default u-button-default--large u-button-default--negative __button" :href="backToSetting()">戻る</a>
          <input class="u-button-default u-button-default--large u-button-default--primary __button" type="submit" value="登録" @click.prevent="submit">
        </div>
      </form>
    </div>
	</div>
</template>

<script>
  // -- vuelidate
  import useVuelidate from '@vuelidate/core'
  import { helpers, minLength, maxLength, required, email } from '@vuelidate/validators'
  // -- END vuelidate
  export default {
    setup () {
      return { v$: useVuelidate() }
    },
    props: {
      setting: {
        type: Object,
        default: {}
      },
    },
    data() {
      return {
        form: {
          taxDisplayOption: '',
          taxRate: '',
          taxCalculationMethod: '',
          taxWithholdingOption: '',
          corporateNumber: '',
          // -- publisher
          publisher: '',
          publisherAddress: '',
          publisherPhone: '',
          publisherEmail: '',
          publisherSeal: {},
          basicUnitPrice  : 0,
          // -- END publisher
        },
        publisherSealFileName: '',
      }
    },
    validations() {
      return {
        form: {
          corporateNumber: {
            minLength: helpers.withMessage(
              ({ $params }) => `適格請求書発行事業者登録番号は、${$params.min}文字以上で入力してください。`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({ $params }) =>`適格請求書発行事業者登録番号は、${$params.max}文字以下で入力してください。`, maxLength(255)
            )
          },
          publisher: {
            required: helpers.withMessage(`---は必須項目です。`, required), 
          },
          publisherAddress: {
            required: helpers.withMessage(`---は必須項目です。`, required), 
          },
          publisherPhone: {
            required: helpers.withMessage(`---は必須項目です。`, required), 
          },
          publisherEmail: {
            required: helpers.withMessage(`---は必須項目です。`, required), 
            email: helpers.withMessage('メールアドレスを正しく入力してください', email),
          },
          basicUnitPrice: {
            required: helpers.withMessage(`---は必須項目です。`, required), 
          }
        },
      }
    },
    created () {
        this.setDocumentSetting();
    },
    mounted () {
      this.setFileName(this.setting.documentSetting.publisher_seal);
    },
    methods: {
      async submit() {
        // -- check validation
        const isFormCorrect = await this.v$.$validate();
        if (isFormCorrect) {
          // -- loader show
          let loader = this.$loading.show();
          // -- END loader show

          // -- prepare formData
          const form = new FormData();

          form.append('tax_display_option', this.form.taxDisplayOption.toString())
          form.append('tax_rate_option', this.form.taxRate.toString())
          form.append('tax_calculation_method', this.form.taxCalculationMethod.toString())
          form.append('tax_withholding_option', this.form.taxWithholdingOption.toString())
          form.append('corporate_number', this.form.corporateNumber)

          // -- publisher
          form.append('publisher', this.form.publisher)
          form.append('publisher_address', this.form.publisherAddress)
          form.append('publisher_phone', this.form.publisherPhone)
          form.append('publisher_email', this.form.publisherEmail)
          form.append('basic_unit_price', this.form.basicUnitPrice)

          if (this.form.publisherSeal.file) {
            form.append('publisher_seal_image', this.form.publisherSeal.file)
          }
          if (this.form.publisherSeal.image_status) {
            form.append('image_status', this.form.publisherSeal.image_status)
          }
          // -- END publisher
          // -- END prepare formData

          // -- submit
          await axios.post(route('contractor.api.setting.document.estimate.invoice.update'), form)
          .then((response) => {
            if (response.status == 200) {
              this.setFileName(response.data.record.publisher_seal);
            }
          })
            .catch((error) => {
              console.log(error)
            });
          // -- END submit

          // -- hide loader
          loader.hide();
          // -- END hide loader
        }
        // -- END check validation

      },
      setDocumentSetting() {
        this.form.taxDisplayOption = this.setting.documentSetting.tax_display_option;
        this.form.taxRate = this.setting.documentSetting.tax_rate_option;
        this.form.taxCalculationMethod = this.setting.documentSetting.tax_calculation_method;
        this.form.taxWithholdingOption = this.setting.documentSetting.tax_withholding_option;
        this.form.corporateNumber = this.setting.documentSetting.corporate_number;

        // -- publisher
        this.form.publisher = this.setting.documentSetting.publisher;
        this.form.publisherAddress = this.setting.documentSetting.publisher_address;
        this.form.publisherPhone = this.setting.documentSetting.publisher_phone;
        this.form.publisherEmail = this.setting.documentSetting.publisher_email;
        this.form.basicUnitPrice = this.setting.documentSetting.basic_unit_price;

        // -- set image
        if (this.setting.documentSetting.publisher_seal != null) {
          this.form.publisherSeal.url = this.setting.documentSetting.url;
        }
        else {
          this.setDefaultImage();
        }
        // -- END set image
        // -- END publisher
      },
      backToSetting() {
        return route('contractor.settings.index');
      },
      onFileSelected(e) {
        if (e && e.target.files[0]) {
          const file = e.target.files[0];
          const url = URL.createObjectURL(file);
          const name_file = e.target.files[0].name;
          const image_status = this.setting.documentSetting.publisher_seal != null ? 'update' : 'new';
          this.form.publisherSeal = { url, file, name_file, image_status };
        }
      },
      resetImage() {
        this.v$.form.$reset();
        this.form.publisherSeal = {};
        this.setDefaultImage();
      },
      setDefaultImage() {
        this.form.publisherSeal.url = '/assets/img/site/image_stamp_none_min.png';
        if (this.setting.documentSetting.publisher_seal != null) {
          this.form.publisherSeal.image_status = 'delete';
        }
      },
      setFileName(publisher_seal) {
        if (publisher_seal == null) {
          this.publisherSealFileName = 'ファイルが選択されていません';
        }
        else {
          const arrFileName = publisher_seal.split("/");
          this.publisherSealFileName = arrFileName[3];
        }
      },
    },
  }
</script>
