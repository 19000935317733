<template>
  <div class="u-table-list-item">
    <div class="__table-header">
      <div class="__row">
        <span class="__cell __cell--head __project u-align-left">案件名 / カテゴリ</span>
        <span class="__cell __cell--head __status u-align-center">ステータス</span>
        <span class="__cell __cell--head __count u-align-center">応募数</span>
        <span class="__cell __cell--head __close u-align-center">募集終了日</span>
      </div>
    </div>
    <div class="__table-body">
      <!-- loop start-->
      <a :href="generateRoute(item.projectLineNumber, item.recruitLineNumber)" class="__row __link" v-for="item in recruits" :key="item.id">
        <span class="__cell __cell--data __project">
          <span class="__category">{{ item.recruitingCategoryOption }}</span>
          <span class="u-text-bold">
            {{ item.title }}
          </span>
        </span>
        <span class="__cell __cell--data u-align-center __status">
          <span>{{ item.recruitingStatusOption }}</span>
        </span>
        <span class="__cell __cell--data u-align-center __count">
          <span>{{ item.offersCount }}件</span>
        </span>
        <span class="__cell __cell--data u-align-center __close">
          <span>{{ moment(item.recruitingEndDate).format('YYYY/MM/DD') }}</span>
        </span>
      </a>
      <!-- loop end-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    recruits: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      // 
    }
  },
  created () {
    this.moment = moment;
  },
  methods: {
    /** 
     * Note :
     * project = project.line_number
     * recruit = recruit.line_number
    */
    generateRoute(project, recruit) {
      return route('contractor.project.recruit.show', {project : project, recruit: recruit});
    }
  },
};
</script>

<style>
</style>