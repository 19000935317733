<template>
    <div class="u-box-content-default">
        <h4 class="u-heading-box-title mgt-016">ご請求・支払い</h4>
        <div class="u-table-wrapper mgt-024">
        <table class="u-table-sheet">
            <tbody>
            <tr>
                <th>現在ご契約中のオプション</th>
                <td>
                <div class="u-flex-between-top">
                    <span class="mgr-024">
                        {{ contractor.contractorOptionDesc }}
                        <span v-if="contractor.option_id != 0">：{{ contractor.contractorPaymentPeriodDesc }}利用</span><br>
                        <span v-if="contractor.option_id != 0">
                        【支払い済み】{{ moment(contractor.option_start_date).format('YYYY/MM/DD') }}〜{{ moment(contractor.option_expiration_date).format('YYYY/MM/DD') }}
                        </span>
                    </span>
                    <div class="u-flex-right-center">
                        <a class="u-button-default u-button-default--small u-button-default--secondary" :href="toOptionEdit()" data-cy="btn-option_edit_page">
                            変更
                        </a>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <th>次回ご請求</th>
                <td>
                    <span v-if="contractor.option_id == 0">
                        -
                    </span>
                    <span v-else>
                        {{ moment(contractor.option_expiration_date).format('YYYY/MM/DD') }}（{{ contractor.contractorOptionDesc }}）
                    </span>
                </td>
            </tr>
            <tr>
                <th>支払い方法・支払い期間</th>
                <td>
                <div class="u-flex-between-top">
                    <span class="mgr-024" v-if="contractor.option_id != 0">
                        {{ contractor.contractorPaymentMethodDesc }}・{{ contractor.contractorPaymentPeriodDesc }}払い
                    </span>
                    <span v-else>
                        {{ contractor.contractorOptionDesc }}
                    </span>
                    <div class="u-flex-right-center" v-if="contractor.option_id !== 0 || !contractor.is_trial">
                        <a class="u-button-default u-button-default--small u-button-default--secondary" :href="toOptionEditContract()" data-cy="btn-option_edit_contract">
                            変更
                        </a>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <th>前回のご請求情報</th>
                <td v-if="contractor.previousPuschaseDate != null">
                    {{ contractor.previousPuschaseDate }} {{ numberWithCommas(contractor.previousPuschaseTotalAmount) }}円 ({{contractor.previousOption}}) クレジットカード払い
                </td>
                <td v-else>
                    -
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: {
            contractor: {
                type: Object,
                default: {}
            },
        },
        created() {
            this.moment = moment;
        },
        methods: {
            toOptionEdit() {
                return route('contractor.settings.option.edit');
            },
            toOptionEditContract() {
                return route('contractor.settings.option.edit.contract');
            },
            numberWithCommas(x) {
    	        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		    },
        },
    }
</script>
