<template>
  <thead> 
    <tr> 
      <th class="__item u-align-left" colspan="2">品目</th>
      <th class="__count">数量</th>
      <th class="__measure">単位</th>
      <th class="__unit">単価</th>
      <th class="__subtotal u-align-right">品目小計</th>
      <th class="__type">種別</th>
      <th class="__tax-type">税区分</th>
      <th class="__delete">&nbsp;</th>
      <th class="__total u-align-right">品目金額</th>
    </tr>
  </thead>
  <draggable
    :list="items"
    item-key="itemId"
    class="__draggable-elements"
    ghost-class="ghost"
    handle=".__order-handle"
    @start="dragging = true"
    @end="dragging = false"
    tag="tbody"
  >
    <template #item="{element}">
      <estimation-table-item-row 
        :item-order="index" 
        :item-value="element" 
        @delete-item="deleteItemRow" 
        @update-taxrate-item="emitUpdateItemTaxRate" 
        :is-created="isCreated" 
        :tax-display-mode="taxDisplayMode"
        @submit-item="emitSubmitItem()" 
        @exit-edit-mode="emitExitEditMode()" 
        :scope="scope"
        :defaultTaxRate="defaultTaxRate" />
    </template>
  </draggable>
  <tfoot>
    <tr>
      <td class="u-align-center" colspan="10">
        <button class="__button __button--add-option" type="button" @click="addItemRow()"><img class="__image" src="/assets/img/site/icon_add_secondary_large_min.svg" alt="追加"></button><br><span class="__caption u-text-caption">品目を追加</span>
      </td>
    </tr>
  </tfoot>
</template> 

<script>
  import draggable from 'vuedraggable';
  import EstimationTableItemRow from '../estimation/EstimationTableItemRow.vue';
  export default {
    components: {
      draggable,
      EstimationTableItemRow
    },
    props: {
      pageName: {
        type: String,
        default: ''
      },
      currentItems: {
        type: Array,
        default: []
      },
      taxDisplayMode: {
        type: String,
        default: ''
      },
      // -- add by backend
      scope: {
        type: String,
        default: ''
      },
      defaultTaxRate: {
        type: String,
        default: '10%'
      }
      // -- add by backend
    },
    emits: [
      'update-amount',
      'update-items',
      'submit-item',
      'exit-edit-mode'
    ],
    data() {
      return {
        items: [],
        currentItemId: this.currentItems.length,
      }
    },
    computed: {
      sumItemTotal() {
        const itemTotalList = this.items.map((item) => {
          return item.itemTotal
        }).reduce((prev, current) => {
          return prev + current
        }, 0);
        return itemTotalList;
      },
    },
    created() {
      this.items = this.currentItems;
    },
    mounted() {
      this.$watch('sumItemTotal', (newValue, oldValue)=> {
        this.emitUpdateAmount(newValue);
      });
      this.$watch('items', (newValue, oldValue)=> {
        this.emitUpdateItems(newValue);
      },{
        deep: true,
        immediate: true
      });
    },
    methods: {
      addItemRow() {
        this.items.push({
          id: '',
          itemId: this.currentItemId,
          itemName: null,
          itemCount: 0,
          itemMeasure: null,
          itemUnit: 0,
          itemSubtotal: 0,
          itemType: '1',
          itemTaxType: this.defaultTaxRate,
          itemTotal: 0,
          itemOptions: []
        });
        this.currentItemId++
      },
      deleteItemRow(payload) {
        const filtered = this.items.filter((item) => {
          return item.itemId !== payload;
        });
        this.items = filtered;
      },
      emitUpdateAmount(amount) {
        this.$emit('update-amount', amount);
      },
      emitUpdateItems(items) {
        this.$emit('update-items', items);
      },
      // -- add by backend 
      addItemRowWithValue(data) {
        this.items = []; // reset items
        data.forEach(page => {
          let itemId = this.currentItemId++
          let data_item = {
              id: '', // page.id,
              itemId: itemId,
              itemName: page.title,
              itemCount: page.cost.cost,
              itemMeasure: '人日',
              itemUnit: 1,
              itemSubtotal: 1*parseFloat(page.cost.cost),
              itemType: 1, // '通常'
              itemTotal: page.hasOwnProperty('total_page_optional_cost') ? page.total_page_optional_cost : page.cost.cost,
              itemOptions: [],
              itemTaxType: '10%',
          };
          if (page.hasOwnProperty('page_optional_costs')) {
            page.page_optional_costs.forEach((cost, i) => {
              let option = {
                id: '',// cost.id,
                optionId: i,
                optionName: cost.title,
                optionCount: cost.cost.cost,
                optionMeasure: '人日',
                // -- pending
                optionUnit: 1,
                optionSubtotal: 1*parseFloat(cost.cost.cost),
                optionType: 1, // '通常'
                optionTaxType: '10%',
                // -- END pending
              }
              data_item.itemOptions.push(option);
            });
          }
          this.items.push(data_item);
        });
      },
      emitSubmitItem() {
        this.$emit('submit-item');
      },
      emitExitEditMode() {
        this.$emit('exit-edit-mode');
      },
      addItemRowWithEstimateReflect(data) {
        // reset items
        this.items = data;
      },
      // -- END add by backend 
    }
  }
</script>