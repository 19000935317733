<template>
<div class="c-panel-man-hours-list js-panel-man-hours-list c-panel-man-hours-list--open">
	<div class="__inner">
		<div class="__headline u-flex-between-center">
			<h3 class="__text">設定中の工数</h3>
			<button class="u-button-default u-button-default--extra-small u-button-default--negative __button--close js-panel-man-hours-list-close" type="button">閉じる</button>
		</div>
		<div class="__content">
			<div class="__top-section">
				<table class="__table">
          <div class="" v-for="(page, page_index) in pages" :key="page_index">
            <tr class="__item __item-page">
              <td class="__term">{{ page.title   }}</td>
              <td class="__subtotal">{{ page.cost.cost }} 人日</td>
              <td class="__total">{{ page.total_page_optional_cost }} 人日</td>
            </tr>
            <div class="" v-if="page.page_optional_costs.length > 0">
              <tr class="__item __item-option" v-for="(page_optional, page_optional_index) in page.page_optional_costs" :key="page_optional_index">
                <td class="__term">{{ page_optional.title }}</td>
                <td class="__subtotal">{{ page_optional.cost.cost }} 人日</td>
                <td class="__total"></td>
              </tr>
            </div>
          </div>
				</table>
			</div>
			<div class="__bottom-section">
				<dl class="__list">
					<div class="__item u-flex-between-center">
						<dt class="__term">合計工数</dt>
						<dd class="__cost">{{ total_all_page_cost }}人日</dd>
					</div>
					<div class="__item u-flex-between-center">
						<dt class="__term">参考価格</dt>
						<dd class="__cost">400,000円 (Pending)</dd>
					</div>
				</dl>
			</div>
			<div class="__button-section">
				<div class="u-flex-center-center">
					<button 
            v-if="isEditEstimation" 
            class="u-button-default u-button-default--small u-button-default--primary __button--push js-panel-man-hours-list-push" 
            @click.prevent="sendPageCost()">
              この工数を見積もりに反映
          </button>
					<button class="u-button-default u-button-default--small u-button-default--secondary __button--open js-panel-man-hours-list-open" type="button">設定工数を確認</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
  export default {
    props: ['pages', 'total_all_page_cost', 'isEditEstimation'],
    methods: {
      sendPageCost() {
        this.$emit('add-page-cost', this.pages);
      },
    },
  }
</script>
