<template>
  <div>
    <table style="width: 100%;">
      <tr class="js-table-order-highlight-row __indent-left __option-row" ref="table_order_highlight_row">
        <th class="__order">
          <span class="__order-handle"></span>
        </th>
        <td class="__item">
          <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box"
            :class="v$.optionValue.optionName.$errors.length > 0 ? 'border-danger' : '' ">
            <input v-model="optionValue.optionName" type="text" placeholder="オプション品目を入力" name="" @keydown.enter.prevent="emitSubmitItem()" @keydown.esc.prevent="emitExitEditMode()">
          </span>
        </td>
        <td class="__count">
          <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium"
            :class="v$.optionValue.optionCount.$errors.length > 0 ? 'border-danger' : '' ">
            <input v-model="optionValue.optionCount" class="u-align-center" type="number" placeholder="" name="" @blur="calculateOptionSubtotal(); emitUpdateOptionSubtotal()" @keydown.enter.prevent="emitSubmitItem()" @keydown.esc.prevent="emitExitEditMode()">
          </span>
        </td>
        <td class="__measure">
          <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium u-form-input-text-box"
            :class="v$.optionValue.optionMeasure.$errors.length > 0 ? 'border-danger' : '' ">
            <input v-model="optionValue.optionMeasure" type="text" placeholder="個、式" name="" @keydown.enter.prevent="emitSubmitItem()" @keydown.esc.prevent="emitExitEditMode()">
          </span>
        </td>
        <td class="__unit">
          <span class="u-form-input-default u-form-input-default--inline u-form-input-default--medium"
            :class="v$.optionValue.optionUnit.$errors.length > 0 ? 'border-danger' : '' ">
            <input v-model="optionValue.optionUnit" class="u-align-right" type="number" placeholder="" name="" @blur="calculateOptionSubtotal(); emitUpdateOptionSubtotal()" @keydown.enter.prevent="emitSubmitItem()" @keydown.esc.prevent="emitExitEditMode()">
          </span>
        </td>
        <td class="__subtotal u-align-right">
          <span class="__number">{{ commaSeparetedOptionSubtotal }}</span>
        </td>
        <td class="__type">
          <span class="u-form-select-default u-form-input-select u-form-input-select--medium"
            :class="v$.optionValue.optionType.$errors.length > 0 ? 'border-danger' : '' ">
            <select v-model="optionValue.optionType" @change="calculateChange(optionValue.optionType); calculateOptionSubtotal(); emitUpdateOptionSubtotal()">
              <option value="1">通常</option>
              <option value="2">割引</option>
            </select>
          </span>
        </td>
        <td class="__tax-type">
          <span v-if="optionValue.optionType == '1' && taxDisplayMode !== 'tax_free'" class="u-form-select-default u-form-input-select u-form-input-select--medium">
            <select v-model="optionValue.optionTaxType" @change="emitUpdateOptionTaxRate()">
              <option value="10%">10%</option>
              <option value="軽減8%">軽減8%</option>
              <option value="8%">8%</option>
              <option value="対象外">対象外</option>
            </select>
          </span>
          <p v-else class="u-align-center">
              <span v-if="taxDisplayMode === 'tax_free'">免税</span>
              <span v-else>-</span>
          </p>
        </td>
        <td class="__delete u-align-center">
          <button class="__button __button--delete" type="button" @click="emitDeleteOptionRow(optionValue.optionId); emitUpdateOptionSubtotal(); emitUpdateOptionTaxRate()"><img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除"></button>
        </td>
      </tr>
      <tr>
        <td class="pdt-000"></td>
        <td class="pdt-000 pdl-032" colspan="8">
            <div class="u-flex-left-center u-flex-wrap">
                <li class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.optionValue.optionName.$errors" :key="index">{{ error.$message }}</li>
                <li class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.optionValue.optionCount.$errors" :key="index">{{ error.$message }}</li>
                <li class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.optionValue.optionMeasure.$errors" :key="index">{{ error.$message }}</li>
                <li class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.optionValue.optionUnit.$errors" :key="index">{{ error.$message }}</li>
                <li class="mgt-008 mgr-008 u-text-varidate-label" v-for="(error, index) of v$.optionValue.optionType.$errors" :key="index">{{ error.$message }}</li>
            </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import useVuelidate from '@vuelidate/core'
  import { helpers , required } from '@vuelidate/validators'
  import { CommaSeparatedNumber } from '../../../modules/comma-separated-number.js'
  import { TableOrderHighlight } from '../../../modules/table-order-highlight.js';
  // -- for custom validate
  // const decimalCost = helpers.regex(/^-?\d+(\.\d{1,2})?$/);
  const decimal42 = helpers.regex(/^-?\d{1,2}(\.\d{1,2})?$/); // for decimal (4,2)
  const decimal112 = helpers.regex(/^-?\d{1,9}(\.\d{1,2})?$/); // for decimal (11,2)
  // -- END for custom validate
  export default {
    setup (props) {
      return { v$: useVuelidate({ $scope: props.scope }) }
    },
    validations() {
      return {
        optionValue: {
          optionName: {
            required: helpers.withMessage('品目は必須項目です。', required)
          },
          optionCount: {
            required: helpers.withMessage('数量は必須項目です。', required),
            decimal42:  helpers.withMessage('数量 は整数4桁, 少数2桁で入力してください。', decimal42)
          },
          optionMeasure: {
            required: helpers.withMessage('単位は必須項目です。', required),
          },
          optionUnit: {
            required: helpers.withMessage('単価は必須項目です。', required),
            decimal112:  helpers.withMessage('単価 should be decimal (11,2)', decimal112)
          },
          optionType: {
            required: helpers.withMessage('種別は必須項目です。', required)
          },
        }
      }
    },
    props: {
      isCreated: {
        type: Boolean,
        default: true
      },
      optionOrder: {
        type: Number,
        default: 0
      },
      optionValue: {
        type: Object,
        default: {}
      },
      taxDisplayMode: {
        type: String,
        default: ''
      },
      // -- add by backend
      scope: {
        type: String,
        default: ''
      }
      // -- END add by backend
    },
    emits: [
      'delete-option',
      'submit-item',
      'exit-edit-mode'
    ],
    computed: {
      commaSeparetedOptionSubtotal() {
        return CommaSeparatedNumber({
          number: this.optionValue.optionSubtotal
        });
      },
    },
    mounted() {
      TableOrderHighlight({
        rowGroupSelector: '.js-table-order-highlight-row-group',
        rowSelector: '.js-table-order-highlight-row'
      });
    },
    methods: {
      emitDeleteOptionRow(optionId) {
        this.$emit('delete-option', optionId);
      },
      emitUpdateOptionSubtotal() {
        this.$emit('update-option');
      },
      emitUpdateOptionTaxRate() {
        this.$emit('update-taxrate-option')
      },
      calculateOptionSubtotal() {
        const result = Math.ceil(this.optionValue.optionCount * this.optionValue.optionUnit);
        this.optionValue.optionSubtotal = result;
      },
      calculateChange(mode) {
        if(mode === '2') {
          this.optionValue.optionUnit = -(this.optionValue.optionUnit);
          this.optionValue.optionTaxType = '対象外';
        } else {
          this.optionValue.optionUnit = Math.abs(this.optionValue.optionUnit);
          this.optionValue.optionTaxType = '10%';
        }
      },
      // -- add by backend
      emitSubmitItem() {
        this.$emit('submit-item');
      },
      emitExitEditMode() {
        this.$emit('exit-edit-mode');
      },
      // -- END add by backend
    }
  }
</script>

<style scoped>
  .alert-danger {
    color: red;
  }
  .border-danger {
    border-color: red;
  }
</style>
