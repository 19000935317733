<template>
	<form action="" method="">
		<div class="u-box-content-default">
			<p class="__text mgt-024">{{ generateConfirmationMessage() }}</p>
			<div class="u-flex-between-stretch __plan-item-wrap">
				<input type="checkbox" name="plan" value="" id="option" checked>
				<div class="__plan-item">
					<h2 class="__lead u-align-center">
						<span>{{ displayOption.name }}</span>
					</h2>
					<p class="__description pdb-024 mgt-016 u-align-center">
						<span class="__big">{{ numberWithCommas(displayOption.price) }}円</span>
						<span class="__normal">&nbsp;/&nbsp;{{ displayOption.paymentPeriod }}</span>
					</p>
				</div>
			</div>
			<p class="__text mgt-024" v-if="contractor.displayCancelButton">
				Want to cancel subscription ?
			</p>
			<p class="__text mgt-024" v-if="!contractor.displayCancelButton && contractor.currentOption != null">
				Your {{ contractor.currentOption.nameEN }} subscription will expire on {{ contractor.endCurrentOption }} <br>
				Want to continue subscription ?
			</p>
		</div>
		<div class="__block-depth-2">
			<div class="u-flex-center-center mgt-048">
				<input class="u-button-default u-button-default--large u-button-default--primary" 
					v-if="contractor.currentOption == null"
					type="submit" 
					value="更新" 
					@click.prevent="checkoutPayment()"
					data-cy="btn-first_update_option">
				<input class="u-button-default u-button-default--large u-button-default--primary" 
					v-else
					type="submit" 
					:value="generateButtonText()" 
					@click.prevent="updateSubscription()"
					data-cy="btn-update_option">
			</div>
		</div>
	</form>
</template>

<script>
    import moment from 'moment';
    export default {
        props: {
            contractor: {
                type: Object,
                default: {}
            },
        },
				data() {
					return {
						displayOption: {
							name: '',
							price: 0,
							paymentPeriod: '',
						},
					}
				},
        created() {
            this.moment = moment;
						this.setDisplayOption();
        },
				methods: {
					async checkoutPayment() {
						window.location.href = route('contractor.subscription.checkout.option', { user_option_detail: this.contractor.nextOption.id });
					},
					async updateSubscription() {
						// -- loader show
						let loader = this.$loading.show();
						// -- END loader show

						let form = {
							contractors: {
								option_detail_id: this.contractor.currentOption.id,
							},
							change_type: this.contractor.displayCancelButton ? 'stop_subscription' : 'next_phase'
						};

						// -- submit
						await axios.post(route('contractor.api.setting.option.contract.update'), form)
							.then((response) => {
								console.log(response)
								if (response.status == 200) {
									// -- hide loader
									loader.hide();
									// -- END hide loader

									window.location.href = route('contractor.settings.option');
								}
							})
							.catch((error) => {
								console.log(error)
								// -- hide loader
								loader.hide();
								// -- END hide loader
							});
						// -- END submit
					},
					numberWithCommas(x) {
						return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
					},
					setDisplayOption() {
						this.displayOption = {
							name: this.contractor.nextOption.nameEN,
							price: this.contractor.nextOption.price,
							paymentPeriod: this.contractor.paymentPeriod,
						}
					},
					generateConfirmationMessage() {
						if (this.contractor.currentOption == null) {
							return 'Would you like to change option to '+this.contractor.nextOption.nameEN+' ?';
						}
						else {
							return 'Your account already subscribed with '+this.contractor.currentOption.nameEN+' option';
						}
					},
					generateButtonText() {
						if (this.contractor.displayCancelButton) {
							return 'キャンセル';
						}
						else {
							return '更新';
						}
					},
				},
    }
</script>
