<template>
  <div class="__block-depth-3 __block-depth-3--history-block">
    <h2 class="u-heading-content-title">作業履歴</h2>
    <div class="u-table-wrapper u-table-wrapper--scroll __table mgt-024">
      <div class="u-table-list-item u-table-list-item--short">
        <div class="__table-header">
          <div class="__row">
            <span class="__cell __cell--head u-align-left">作業日</span>
						<span class="__cell __cell--head u-align-left">作業項目 / オプション項目</span>
						<span class="__cell __cell--head u-align-center">担当者</span>
						<span class="__cell __cell--head u-align-center">作業時間</span>
          </div>
        </div>
        <div class="__table-body">
          <!-- loop daily_reports start-->
          <div class="__row __hover-highlight" v-for="dailyReport in listDailyReports" :key="dailyReport.id">
            <span class="__cell __cell--data u-align-left">
							<span>{{ moment(dailyReport.date).format('YYYY/MM/DD') }}</span>
						</span>
						<span class="__cell __cell--data u-align-left">
							<span>{{ dailyReport.title }}</span>
						</span>
						<span class="__cell __cell--data u-align-center">
							<span>{{ dailyReport.contractor_user_name }}</span>
						</span>
						<span class="__cell __cell--data u-align-center">
							<span>{{ dailyReport.worktime_hour }}時間{{ dailyReport.worktime_minute }}分</span>
						</span>
          </div>
          <!-- loop daily_reports end-->
        </div>
      </div>
    </div>
    <div class="u-flex-right-center mgt-008">
      <button
        class="u-text-submit __submit"
        type="button"
        @click.prevent="getMoreData(5, true, 'dailyReport')"
      >
        さらに表示
      </button>
    </div>
  </div>
  <div class="__block-depth-3 __block-depth-3--result-block">
		<h2 class="u-heading-content-title">作業項目・オプション別実績工数</h2>
		<div class="u-table-wrapper u-table-wrapper--scroll __table mgt-024">
			<table class="u-table-list-item u-table-list-item--short">
				<thead>
					<tr>
							<th class="u-align-left">作業項目 / オプション項目</th>
							<th class="u-align-center">工数小計</th>
							<th class="u-align-center">工数合計</th>
					</tr>
				</thead>
				<tbody>
					<!-- loop start-->
					<tr class="__hover-highlight" v-for="projectCost in listProjectCosts" :key="projectCost.id" 
						:class="[isHasSub(projectCost)]">
						<td class="u-align-left" :class="projectCost.type == 'pageOptional' ? '__indent-left' : ''">
							<span :class="projectCost.type == 'pageOptional' ? '' : 'u-text-bold'">
								{{ projectCost.title }}
							</span>
						</td>
						<td class="u-align-center">
							<span>{{ formatWorkHour(projectCost.cost.cost) }}</span>
						</td>
						<td class="u-align-center" :rowspan="setRowSpan(projectCost)" v-if="projectCost.type == 'pages' || projectCost.type == 'OptionalCost'">
							<span class="u-text-bold">
								{{ projectCost.type == 'pages' ? formatWorkHour(projectCost.total_page_optional_cost) : formatWorkHour(projectCost.cost.cost) }}
							</span>
						</td>
					</tr>
					<!-- loop end-->
				</tbody>
			</table>
		</div>
		<div class="u-flex-right-center mgt-008">
			<button 
				class="u-text-submit __submit" 
				type="button" 
				@click.prevent="getMoreData(5, true, 'pageCost')">
				さらに表示
			</button>
		</div>
  </div>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Loading from "vue-loading-overlay";
export default {
	props: {
		lists: {
			type: Object,
			default: {}
		},
	},
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
			// -- add by backend
			listDailyReports: [],
			listProjectCosts: [],
			// -- END add by backend
    };
  },
	async created() {
		this.moment = moment;
		this.listDailyReports = this.lists.dailyReports;
		this.listProjectCosts = this.lists.projectCosts;
	},
  methods: {
    doAjax() {
      this.isLoading = true;
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
		// -- add by backend
		formatWorkHour(dataCost) {
			const arrCost = String(dataCost).split('.');
			if (arrCost.length > 1) {
				const minute = (parseInt(arrCost[1])/10)*60;
				return dataCost+'時間'+minute+'分';
			}
			else{
				return dataCost+'時間0分';
			}
		},
		isHasSub(data) {
			const checkType = data.type;
			let classTr = '';
			switch (checkType) {
				case 'pages':
					if (data.rowSpan > 1) {
						classTr = '__row __row-has-sub';
					}
					break;
				case 'pageOptional':
					classTr = '__row __row-sub';
					if (data.isLast) {
						classTr+=' __row-sub--last';
					}
					break;
			}

			return classTr;
		},
		setRowSpan(data) {
			return data.rowSpan;
		},
		async getMoreData(count, is_except_id = false, type) {
			// -- loader show
			let loader = this.$loading.show();
			// -- END loader show

			let form = {
				'projectId': this.lists.project.id,
				'countData': count,
				'dataType': type,
				'countExceptId': is_except_id ? this.getDataId(type) : [],
			};
			await axios.get(route('contractor.api.analysis.more', form))
				.then((response) => {
					console.log(response)
					if (response.status == 200) {
						if (type == 'dailyReport') {
							console.log('concat 1')
							console.log(response.data.record)
							this.listDailyReports = this.listDailyReports.concat(response.data.record);
						}
						else {
							console.log('concat 2')
							this.listProjectCosts = this.listProjectCosts.concat(response.data.record);
						}
					}
				})
				.catch((error) => {
					console.log(error)
				});
			
			// -- hide loader
			loader.hide();
			// -- END hide loader

		},
		getDataId(type) {
			if (type == 'dailyReport') {
				return this.listDailyReports.length;
			}
			else {
				const projectCostFilter = this.listProjectCosts.filter(page => page.type != 'pageOptional');
				return projectCostFilter.length;
			}
		}
		// -- END add by backend
  },
};
</script>

<style>
.vld-overlay .vld-background {
  background: #000;
}
</style>
