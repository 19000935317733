require('./bootstrap');

import { createApp } from 'vue'

// -- module
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { Modal } from './modules/modal.js';
import { Accordion } from './modules/accordion.js';
import { PreventEvent } from './modules/prevent-event.js';
import { ShowManHoursPanel } from './modules/show-man-hours-panel.js';
import { InputIconEffect } from './modules/input-icon-effect.js';
import { LocalFileUpload } from './modules/local-file-upload.js';
import { AccountProfilePanel } from './modules/account-profile-panel.js';
import { ToggleSwitchEffect } from './modules/toggle-switch-effect.js';
// -- END module

// -- page
// import VueApp from './vue/app.vue';
import VueEstimationCreate from './vue/Estimation/EstimationCreate.vue';
import VueEstimation from './vue/Estimation/Estimation.vue';
import ProjectIndex from './vue/Project/ProjectIndex.vue';
import ProjectCreate from './vue/Project/ProjectCreate.vue';
import ProjectShow from './vue/Project/ProjectShow.vue';
import InvoiceCreate from './vue/Invoice/InvoiceCreate.vue';
import InvoiceShow from './vue/Invoice/Invoice.vue';
import SettingMemberCreate from './vue/Settings/SettingCreateMember.vue';
import SettingSurveyDetail from './vue/Settings/SettingsDetailSurvey.vue';
import SettingSurveyCreate from './vue/Settings/SettingsCreateSurvey.vue';
import SettingPlanningDetail from './vue/Settings/SettingsDetailPlanning.vue';
import SettingPlanningCreate from './vue/Settings/SettingsCreatePlanning.vue';
import SettingPageType from './vue/Settings/SettingsPageType.vue';
import SurveyFormatCreate from './vue/SurveyFormat/SurveyFormatCreate.vue';
import SurveyFormatShow from './vue/SurveyFormat/Survey.vue';
import PlanningFormatCreate from './vue/PlanningFormat/PlanningFormatCreate.vue';
import PlanningFormatShow from './vue/PlanningFormat/Planning.vue';
import PlanningFormatEdit from './vue/PlanningFormat/PlanningFormatEdit.vue';
import NewsIndex from './vue/News/NewsIndex.vue';
import Signup from './vue/Auth/Signup.vue';
import Register from './vue/Auth/Register.vue';
import ContractorUserEdit from './vue/ContractorUser/ContractorUserEdit.vue';
import Assignment from './vue/Assignment/Assignment.vue';
import RecruitingCreate from './vue/Recruit/RecruitingCreate.vue';
import Recruiting from './vue/Recruit/Recruiting.vue';
import RecruitingConfigSurvey from './vue/Recruit/RecruitingConfigSurvey.vue';
import RecruitingConfigPlanning from './vue/Recruit/RecruitingConfigPlanning.vue';
import OfferDetailSurvey from './vue/Offer/OfferDetailSurvey.vue';
import OfferDetailPlanning from './vue/Offer/OfferDetailPlanning.vue';
import OfferIndex from './vue/Offer/OfferIndex.vue';
import OfferDetailApplicationRegistration from './vue/Offer/OfferDetailApplicationRegistration.vue';
import FlowShow from './vue/Flow/Flow.vue';
import OfferDetailApplicant from './vue/Offer/OfferDetailApplicant.vue';
import OfferApplication from './vue/Offer/OfferAplication.vue';
import OfferRecruitment from './vue/Offer/OfferRecruitment.vue';
import DashBoardRecentProjects from './vue/Dashboard/RecentProjects.vue';
import ManagementDailyReport from './vue/Management/ManagementDailyReport.vue';
import ManagementProductivity from './vue/Management/ManagementProductivity.vue';
import DashboardRecruitingProjects from './vue/Dashboard/RecruitingProjects.vue';
import DashboardLatestDailyReport from './vue/Dashboard/LatestDailyReport.vue';
import ManagementAnalysis from './vue/Management/ProjectAnalysisTables.vue';
import OfferUserList from './vue/Offer/OfferUserList.vue';
import OfferAplicationContent from './vue/Offer/OfferAplicationContent.vue';
import SettingCustomerDetail from './vue/Settings/SettingsDetailCustomer.vue';
import SettingCustomerCreate from './vue/Settings/SettingsCreateCustomer.vue';
import SurveyFormatEdit from './vue/SurveyFormat/SurveyFormatEdit.vue';
import SettingsDocumentEstimateInvoice from './vue/Settings/SettingsDocumentEstimateInvoice.vue';
import ExportCrawler from './vue/Crawler/ExportCsv.vue';
import SettingAccount from './vue/Settings/SettingsAccount.vue';
import SettingAccountEditPlan from './vue/Settings/SettingsAccountEditPlan.vue';
import SettingAccountEditPlanConfirm from './vue/Settings/SettingsAccountEditPlanConfirm.vue';
import SettingAccountEditContract from './vue/Settings/SettingsAccountEditContract.vue';
import SettingOption from './vue/Settings/SettingsOption.vue';
import SettingOptionEdit from './vue/Settings/SettingsOptionEdit.vue';
import SettingOptionEditContract from './vue/Settings/SettingsOptionEditContract.vue';
import SelectPlan from './vue/Plan/SelectPlan.vue';
import UpdatePlan from './vue/Plan/UpdatePlan.vue';
import ResetPassword from './vue/Auth/ResetPassword.vue';
// -- END page

// -- layout
import InvoiceCreateLayout from './vue/Invoice/InvoiceCreateLayout.vue';
import InvoiceLayout from './vue/Invoice/InvoiceLayout.vue';
import EstimationCreateLayout from './vue/Estimation/EstimationCreateLayout.vue';
import EstimationLayout from './vue/Estimation/EstimationLayout.vue';
// -- END layout

// -- additional component
import EstimationButtonDuplicateDelete from './vue/components/estimation/EstimationButtonDuplicateDelete.vue';
import InvoiceButtonDelete from './vue/components/invoice/InvoiceButtonDelete.vue';
import InvoiceButtonDocumentSetting from './vue/components/invoice/InvoiceButtonDocumentSetting.vue';
import EstimationButtonDocumentSetting from './vue/components/estimation/EstimationButtonDocumentSetting.vue';
import ModalConfirmDelete from './vue/components/modal/modalConfirmDelete.vue';
import ModalCreateInvoiceReflectEstimate from './vue/components/modal/modalCreateInvoiceReflectEstimate.vue';
// -- END additional component

// -- plugin
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Select2 from 'vue3-select2-component';
// -- END plugin

// const $ = require('jQuery');

// -- Vue js

// --------------- setting from DESIGN_STATIC
// const vueApp = createApp(VueApp);
// vueApp.mount('#vue_app');

// const vueEstimationCreate = createApp(VueEstimationCreate);
// vueEstimationCreate.mount('#vue_estimation_create');
// vueEstimationCreate.use(createPinia())

// const vueProjectShow = createApp(ProjectShow);
// vueProjectShow.mount('#project_show');
// --------------- END setting from DESIGN_STATIC

// --------------- from old setting
const app = createApp({});

// -- pages
app.component('project-index', ProjectIndex)
    .component('project-create', ProjectCreate)
    .component('project-show', ProjectShow)
    .component('estimate-create', VueEstimationCreate)
    .component('estimate', VueEstimation)
    .component('Select2', Select2)
    .component('invoice-create', InvoiceCreate)
    .component('invoice-show', InvoiceShow)
    .component('setting-member-create', SettingMemberCreate)
    .component('setting-survey-show', SettingSurveyDetail)
    .component('setting-survey-create', SettingSurveyCreate)
    .component('setting-planning-show', SettingPlanningDetail)
    .component('setting-planning-create', SettingPlanningCreate)
    .component('setting-page-type', SettingPageType)
    .component('survey-format-create', SurveyFormatCreate)
    .component('survey-format-show', SurveyFormatShow)
    .component('planning-format-create', PlanningFormatCreate)
    .component('planning-format-show', PlanningFormatShow)
    .component('planning-format-edit', PlanningFormatEdit)
    .component('news-index', NewsIndex)
    .component('signup', Signup)
    .component('register', Register)
    .component('contractor-user-edit', ContractorUserEdit)
    .component('assignment', Assignment)
    .component('recruiting-create', RecruitingCreate)
    .component('recruiting', Recruiting)
    .component('recruiting-config-survey', RecruitingConfigSurvey)
    .component('recruiting-config-planning', RecruitingConfigPlanning)
    .component('offer-detail-survey', OfferDetailSurvey)
    .component('offer-detail-planning', OfferDetailPlanning)
    .component('offer-index', OfferIndex)
    .component('offer-detail-application-registration', OfferDetailApplicationRegistration)
    .component('flow-show', FlowShow)
    .component('offer-detail-applicant', OfferDetailApplicant)
    .component('offer-application', OfferApplication)
    .component('offer-recruitment', OfferRecruitment)
    .component('management-daily-report', ManagementDailyReport)
    .component('management-productivity', ManagementProductivity)
    .component('recent-projects', DashBoardRecentProjects)
    .component('recruiting-projects', DashboardRecruitingProjects)
    .component('latest-daily-report', DashboardLatestDailyReport)
    .component('management-analysis', ManagementAnalysis)
    .component('offer-user-list', OfferUserList)
    .component('offer-aplication-content', OfferAplicationContent)
    .component('setting-customer-show', SettingCustomerDetail)
    .component('setting-customer-create', SettingCustomerCreate)
    .component('survey-format-edit', SurveyFormatEdit)
    .component('setting-document-estimate-invoice', SettingsDocumentEstimateInvoice)
    .component('export-crawler', ExportCrawler)
    .component('setting-account', SettingAccount)
    .component('setting-account-edit-plan', SettingAccountEditPlan)
    .component('setting-account-edit-plan-confirm', SettingAccountEditPlanConfirm)
    .component('setting-account-edit-contract', SettingAccountEditContract)
    .component('setting-option', SettingOption)
    .component('setting-option-edit', SettingOptionEdit)
    .component('setting-option-edit-contract', SettingOptionEditContract)
    .component('select-plan', SelectPlan)
    .component('update-plan', UpdatePlan)
    .component('reset-password', ResetPassword);
// -- END pages

// -- layout
// (some page has layout for combine some component)
app.component('invoice-create-layout', InvoiceCreateLayout)
    .component('invoice-layout', InvoiceLayout)
    .component('estimation-create-layout', EstimationCreateLayout)
    .component('estimation-layout', EstimationLayout);;
// -- END layout

// -- additional component
app.component('estimation-button-duplicate-delete', EstimationButtonDuplicateDelete)
    .component('invoice-button-delete', InvoiceButtonDelete)
    .component('invoice-button-document-setting', InvoiceButtonDocumentSetting)
    .component('estimation-button-document-setting', EstimationButtonDocumentSetting)
    .component('modal-confirm-delete', ModalConfirmDelete)
    .component('modal-create-invoice-reflect-estimate', ModalCreateInvoiceReflectEstimate);
// -- END additional component

// -- use plugin
// -- Vue loading
app.use(VueLoading, {
    loader: 'spinner',
    color: '#000000',
    backgroundColor: '#000000',
    height: 80,
    width: 80,
    canCancel: false,
},{});
// -- END Vue loading
// -- END use plugin

app.mount('#app')
// --------------- from old setting

// -- END Vue js

// jQuery
$(function() {
    // console.log('jQuery is ready.');
});

// Intersection Observer
new IntersectionObserverToggleClass({
    selector: '.observe_target',
    className: 'observed',
    toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();

// modal
Modal({
    triggers: '.js-modal-open',
    element: '.js-modal',
    closes: '.js-modal-close'
});
Modal({
    triggers: '.js-modal-open-tax-settings',
    element: '.js-modal-tax-settings',
    closes: '.js-modal-close-tax-settings'
});
// -- modal for confirmation
// modal to confirm data deletion
Modal({
    triggers: '.js-modal-open-confirm-delete',
    element: '.js-modal-confirm-delete',
    closes: '.js-modal-close-confirm-delete'
});
// -- END modal for confirmation

// accordion
Accordion({
    triggers: '.js-accordion-trigger',
    panels: '.js-accordion-panel'
});

// prevent event
PreventEvent({
    target: '.js-prevent-event-click',
    eventType: 'click'
});

// show man hours panel
ShowManHoursPanel({
    element: '.js-panel-man-hours-list',
    openTrigger: '.js-panel-man-hours-list-open',
    closeTrigger: '.js-panel-man-hours-list-close'
});

// input icon effect
InputIconEffect({
    element: '.js-input-icon-effect'
});

// local file upload
LocalFileUpload({
  element: '.js-local-file-upload'
});

// account profile pane
AccountProfilePanel({
    trigger: '.js-account-profile-panel-trigger',
    element: '.js-account-profile-panel'
});

// toggle switch effect
ToggleSwitchEffect({
    elements: '.js-toggle-switch-effect',
    stringEnabled: '有効です',
    stringDisabled: '無効です',
    isConfirm: false
});
ToggleSwitchEffect({
    elements: '.js-toggle-switch-effect-add-confirm',
    stringEnabled: '有効です',
    stringDisabled: '無効です',
    isConfirm: true
});
ToggleSwitchEffect({
    elements: '.js-toggle-switch-effect-recruiting',
    stringEnabled: '公開する',
    stringDisabled: '公開しない',
    isConfirm: false
});
