<template>
  <div class="u-box-content-default __box">
    <form action="" method="">
      <h3 class="u-heading-box-title mgt-024">応募内容入力</h3>
      <p class="__text mgt-024">あなたの自己紹介や、制作実績、今回の募集に対する具体的なご提案内容を記載することで、発注者がイメージをしやすくなります</p>
      <dl class="u-list-input-label-set __list mgt-024">
        <div class="__item">
          <dt class="__term">本文テキスト</dt>
          <dd class="__body">
            <span class="u-form-input-default u-form-input-text-area">
              <textarea placeholder="応募内容の詳細説明などを入力" name="" v-model="form.content"></textarea>
            </span>
            <!-- error message -->
            <p v-for="(error, index) of v$.form.content.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            <!-- END error message -->
          </dd>
        </div>
        <div class="__item">
          <dt class="__term">概算見積り金額</dt>
          <dd class="__body">
            <span class="u-form-input-default u-form-input-text-box">
              <input type="text" placeholder="提案する見積り金額を入力" name="" v-model="form.estimate">
            </span>
            <!-- error message -->
            <p v-for="(error, index) of v$.form.estimate.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            <!-- END error message -->
          </dd>
        </div>
      </dl>
      <div class="u-flex-between-center mgt-024"><span class="__label u-text-bold">添付ファイル</span>
        <div class="__uploader">
          <div class="u-form-file-group js-local-file-upload">
            <figure class="__thumbnail" style="max-width: 300px;">
              <img class="__image" :src="form.attached_file_url.url" alt="" style="display: none;">
            </figure>
            <button class="__delete" type="button" style="display: none;" @click.prevent="resetImage">
              <img class="__image" src="/assets/img/site/icon_delete_extra_small_min.svg" alt="削除">
            </button>
            <div class="__input">
              <div class="__controller">
                <input type="file" id="input_file"
                  ref="file" 
                  @change="onFileSelected">
                <label class="u-form-file-upload" for="input_file"><span class="__icon"><img class="__image" src="" alt=""></span><span class="__text">ファイルを選択</span></label><span class="__file-name">ファイルが選択されていません</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 class="u-heading-box-title mgt-064">案件情報</h3>
      <table class="u-table-sheet mgt-024 p-survey-result">
        <tbody>
          <tr>
            <th>案件名</th>
            <td>{{ recruit.title }}</td>
          </tr>
          <tr>
            <th>案件カテゴリ</th>
            <td>{{ recruit.recruiting_category_option.display_name }}</td>
          </tr>
          <tr>
            <th>予算</th>
            <td>{{ recruit.estimate }}</td>
          </tr>
          <tr>
            <th>発注者情報</th>
            <td>{{ recruit.project.contractor.name }}</td>
          </tr>
          <tr>
            <th>募集終了日</th>
            <td>{{ moment(recruit.recruiting_end_date).format('YYYY/MM/DD') }}</td>
          </tr>
        </tbody>
      </table>
      <div class="u-flex-center-center mgt-016">
        <input class="u-button-default u-button-default--large u-button-default--primary" type="submit" value="応募" @click.prevent="submit">
      </div>
    </form>
  </div>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, required, minLength, maxLength } from '@vuelidate/validators'
// -- END vuelidate
import moment from 'moment';
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    recruit: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      form: {
        content: '',
        estimate: '',
        attached_file_url: '',
      }
    }
  },
  validations() {
    return {
      form: {
        content: { 
          required: helpers.withMessage('本文テキストは必須項目です。', required), 
          minLength: helpers.withMessage(
            ({ $params }) => `本文テキストは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`本文テキストは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
        estimate: { 
          required: helpers.withMessage('概算見積り金額は必須項目です。', required), 
          minLength: helpers.withMessage(
            ({ $params }) => `概算見積り金額は、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`概算見積り金額は、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
      }
    }
  },
  async created() {
    this.moment = moment;
  },
  methods: {
    async submit() {
      const isFormCorrect = await this.v$.form.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        const fd = new FormData();
        fd.append('recruit_id', this.recruit.id)
        fd.append('content', this.form.content)
        fd.append('estimate', this.form.estimate)
        if (this.form.attached_file_url.file) {
          fd.append('attached_file_url', this.form.attached_file_url.file)
        }

        await axios.post(route('contractor.api.offer.store'), fd)
          .then((response) => {
            if (response.status == 201) {
              window.location.href = route('contractor.offer.detail.application.complete', { recruit: this.recruit.id });
            }
          })
          .catch((error) => {
            console.log(error)
            this.erros_submit = error.response.data;
          });

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
    },
    onFileSelected(e) {
      if (e && e.target.files[0]) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        const name_file = e.target.files[0].name;
        const image_status = 'new';
        this.form.attached_file_url = { url, file, name_file, image_status };
      }
    },
    resetImage() {
      this.v$.form.$reset();
      this.form.attached_file_url = {};
      this.setDefaultImage();
    },
    setDefaultImage() {
      this.form.attached_file_url.url = '/assets/img/site/icon_delete_extra_small_min.svg';
    },
  },
}
</script>

<style>

</style>