<template>
  <div class="u-box-content-default __box">
    <dl class="u-list-input-label-set __list mgt-024">
      <div class="__item">
        <dt class="__term">ページタイプ名</dt>
        <dd class="__body">
          <div class="u-flex-between-center">
            <div class="u-flex-shrink-1 u-flex-grow-1">
              <span class="u-form-input-default u-form-input-text-box">
                <input
                  v-model="item.pageTypeName"
                  type="text"
                  placeholder="ページタイプ名を入力"
                  :name="`page_type_name_${item.itemId}`"
                />
              </span>
              <p v-for="(error, index) of v$.item.pageTypeName.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
            </div>
            <div
              v-if="itemIndex !== 1"
              class="u-flex-shrink-0 u-flex-grow-0 mgl-016"
            >
              <button class="__button" type="button">
                <img
                  class="__image"
                  src="/assets/img/site/icon_delete_small_min.svg"
                  alt="削除"
                  @click.prevent="confirmDelete(true)"
                />
              </button>
            </div>
          </div>
        </dd>
      </div>
      <div class="__item">
        <dt class="__term">ラベルカラー</dt>
        <dd class="__body">
          <div class="u-form-input-group p-pagetype-label-config">
            <label class="u-form-radio-default u-form-input-radio">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="red"
                @change="updateRegularColor('#d50000')"
              />
              <span class="__text">
                <span class="__color-name mgr-008">赤</span>
                <span class="__sapmle __sapmle--red"></span>
              </span>
            </label>
            <label class="u-form-radio-default u-form-input-radio">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="orange"
                @change="updateRegularColor('#ff6d00')"
              />
              <span class="__text">
                <span class="__color-name mgr-008">橙</span>
                <span class="__sapmle __sapmle--orange"></span>
              </span>
            </label>
            <label class="u-form-radio-default u-form-input-radio">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="green"
                @change="updateRegularColor('#00c853')"
              />
              <span class="__text">
                <span class="__color-name mgr-008">緑</span>
                <span class="__sapmle __sapmle--green"></span>
              </span>
            </label>
            <label class="u-form-radio-default u-form-input-radio">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="blue"
                @change="updateRegularColor('#304ffe')"
              />
              <span class="__text">
                <span class="__color-name mgr-008">青</span>
                <span class="__sapmle __sapmle--blue"></span>
              </span>
            </label>
            <label class="u-form-radio-default u-form-input-radio">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="purple"
                @change="updateRegularColor('#aa00ff')"
              />
              <span class="__text">
                <span class="__color-name mgr-008">紫</span>
                <span class="__sapmle __sapmle--purple"></span>
              </span>
            </label>
            <label class="u-form-radio-default u-form-input-radio mgr-008">
              <input
                v-model="item.labelColor.type"
                type="radio"
                :name="`label_color_${item.itemId}`"
                value="custom"
                @change="updateRegularColor(customColorCode)"
              />
              <span class="__text">
                <span class="__color-name">指定色</span>
              </span>
            </label>
            <label
              v-if="item.labelColor.type === 'custom'"
              class="__sapmle __sapmle--other"
              :style="{ background: customColorCode }"
            >
              <input
                :name="`custom_color_${item.itemId}`"
                type="color"
                @change="updateCustomColor($event)"
              />
            </label>
          </div>
          <input
            type="hidden"
            :name="`current_label_color_${item.itemId}`"
            :value="currentLabelColor"
          />
          <p v-for="(error, index) of v$.item.labelColor.code.$errors" :key="index" class="mgt-008 mgr-008 u-text-varidate-label">{{ error.$message }}</p>
        </dd>
      </div>
    </dl>
  </div>
  <!-- modal confirmation -->
	<modal-confirm-delete
    :modal-title="item.countPage > 0 ? '警告' : '確認'"
    :modal-content="item.countPage > 0 ? '先にページを削除してください' : '本当に削除しますか？'"
    :is-confirm-delete="item.countPage > 0 ? '0' : '1'"
    delete-directly="0"
    :accept="{
      cb: () => emitDeleteItem(item.itemId)
    }"
    ref="modalConfirmDelete" />
	<!-- END modal confirmation -->
</template>

<script>
// -- vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
// -- END vuelidate
export default {
	setup(props) {
    return { v$: useVuelidate({ $scope: props.validationScope }) };
  },
  name: "ItemPageType",
  emits: ["delete-item"],
  props: {
    currentItem: {
      type: Object,
      default: {},
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
		// -- add by backend
		validationScope: {
      type: String,
      default: "",
    },
		// -- END add by backend
  },
  data() {
    return {
      item: {},
      currentLabelColor: "#d50000",
      customColorCode: "#000000",
      classModal: '', // for dynamic modal confirm delete
    };
  },
	validations() {
		return {
			item: {
				pageTypeName: {
					required: helpers.withMessage(
            "ページタイプ名は必須項目です。",
            required
          ),
				},
				labelColor: {
					code: {
						required: helpers.withMessage(
            	"ラベルカラーは必須項目です。",
            	required
          	),
					},
					type: {
						required: helpers.withMessage(
            	"ラベルカラーは必須項目です。",
            	required
          	),
					}
				}
			}
		}
	},
  created() {
    this.item = this.currentItem;
    this.currentLabelColor = this.item.labelColor.code;
		this.setCustomColor();
  },
  methods: {
    emitDeleteItem(itemId) {
      this.$emit("delete-item", itemId);
    },
    updateRegularColor(code) {
      this.currentLabelColor = code;
			this.item.labelColor.code = code; // add by backend
    },
    updateCustomColor(event) {
      this.customColorCode = event.target.value;
      this.currentLabelColor = event.target.value;
			this.item.labelColor.code = event.target.value; // add by backend
    },
		// -- add by backend
		setCustomColor() {
			// -- set color
			// if type == 'custom
			if (this.currentItem.labelColor.type == 'custom') {
				this.customColorCode = this.currentItem.labelColor.code;
			}
			// -- END set color
		},
    confirmDelete(isShow = false) {
      this.$refs.modalConfirmDelete.changeModalClass('js-modal--shown')
    },
		// -- END add by backend
  },
};
</script>
