<template>
  <main class="l-main p-page p-planning-update-page p-app-page p-service-page">
    <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
                    <li class="__list"><a class="__link" :href="currentProjectRoute()">{{ project.name }}</a></li>
                    <li class="__list"><a class="__link" :href="backTo()">提案シート</a></li>
                    <li class="__list"><a class="__link" href="#">提案シート項目更新</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <article class="p-page-title">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="__block-depth-2 u-flex-between-center">
                <h1 class="u-heading-page-title __title">提案シート項目更新</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="p-section-01">
      <section class="l-wrapper">
        <div class="l-container">
          <div class="l-inner">
            <div class="__block-depth-1">
              <div class="u-box-content-default">
                <form>
                  <div class="__block-depth-2 __block-depth-2--form-input-block">
                    <p class="__caption mgt-024">
                      「{{ project.name }}」のプロジェクトにおいて使用する提案シートで表示させる項目を有効化してください。<br>
                      <span class="u-text-error u-text-bold">※すでに登録済みの項目を無効にすると登録されている内容もリセットされます。</span>
                    </p>
                    <ul class="u-list-toggle-switch __list mgt-024">
                      <!-- loop here planning_format_categories -->
                      <li class="__item" v-for="(planning_format_category, index) of planning_format_categories" :key="index">
                        <p class="__text u-text-bold">
                          {{ planning_format_category.title }}
                        </p>
                        <!-- 設定済みの項目には確認ダイアログを挟むため「js-toggle-switch-effect-add-confirm」のclassが入ります-->
                        <span class="__toggle js-toggle-switch-effect-add-confirm">
                          <span class="__status __enabled js-toggle-switch-effect-status" :class="form.planning_format_categories[index].is_enable == 'on' ? '__enabled' : '__disabled'">
                            {{ form.planning_format_categories[index].is_enable == 'on' ? '有効です' : '無効です' }}
                          </span>
                          <span class="u-form-toggle-switch">
                            <input class="js-toggle-switch-effect-input js-toggle-switch-effect-input--disabled" type="radio"
                              :id="'off_01_'+planning_format_category.id"
                              v-model="form.planning_format_categories[index].is_enable"
                              value="off">
                            <label class="__label" :for="'off_01'+planning_format_category.id" @click.prevent="changeIsEnable(index, 'off')"></label>

                            <input class="js-toggle-switch-effect-input js-toggle-switch-effect-input--enabled" type="radio"
                              :id="'on_01_'+planning_format_category.id"
                              v-model="form.planning_format_categories[index].is_enable"
                              value="on">
                            <label class="__label" :for="'on_01'+planning_format_category.id" @click.prevent="changeIsEnable(index, 'on')"></label>

                            <span class="__switch"></span>
                          </span>
                        </span>
                      </li>
                      <!-- END loop here planning_format_categories -->
                    </ul>
                  </div>
                  <div class="__block-depth-2 __block-depth-2--form-submit-block">
                    <div class="u-flex-center-center">
                      <input class="js-modal-open js-prevent-event-click u-button-default u-button-default--large u-button-default--primary" type="submit" value="項目を反映" @click.prevent="submit">
                    </div>
                  </div>
                </form>
              </div>
              <div class="u-flex-center-center mgt-048">
                <a class="u-button-default u-button-default--medium u-button-default--negative" :href="backTo()">戻る</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
    <!-- section 01 end-->
  </main>
  <nav class="l-breadcrumb-section">
        <div class="l-container">
            <div class="l-inner">
                <ul class="u-list-breadcrumb u-list-breadcrumb--settings">
                    <li class="__list"><a class="__link" :href="changePage('contractor.dashboard')">HOME</a></li>
                    <li class="__list"><a class="__link" :href="changePage('contractor.project.index')">案件一覧</a></li>
                    <li class="__list"><a class="__link" :href="currentProjectRoute()">{{ project.name }}</a></li>
                    <li class="__list"><a class="__link" :href="backTo()">提案シート</a></li>
                    <li class="__list"><a class="__link" href="#">提案シート項目更新</a></li>
                </ul>
            </div>
        </div>
  </nav>
</template>

<script>
// -- vuelidate
import useVuelidate from '@vuelidate/core'
import { helpers, required, minLength, maxLength } from '@vuelidate/validators'
// -- END vuelidate
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    project: {
      type: Object,
      default: {}
    },
    planning_format: {
      type: Object,
      default: {}
    },
    planning_format_categories: {
      type: Array,
      default: []
    },
  },
  created () {
    this.setPlanningFormatCategoriesForm();
    this.setDefaultTitle();
  },
  mounted () {
    //
  },
  data() {
    return {
      form: {
        title: '「案件名が入ります」の提案シート1（初期値に案件名+提案シート通し番号を想定）',
        planning_format_categories: [],
      },
    }
  },
  validations() {
    const localRules = {
      form: {
        title: {
          required: helpers.withMessage('Titleは必須項目です。', required),
          minLength: helpers.withMessage(
            ({ $params }) => `Titleは、${$params.min}文字以上で入力してください。`, minLength(1)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) =>`Titleは、${$params.max}文字以下で入力してください。`, maxLength(255)
          )
        },
      }
    };

    return localRules;
  },
  methods: {
    async submit() {
      // -- check validation
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        // -- loader show
        let loader = this.$loading.show();
        // -- END loader show

        // -- prepare formData
        const fd = new FormData();
        fd.append('project_id', this.project.id);
        fd.append('title', this.form.title);
        fd.append('planning_format_categories', JSON.stringify(this.form.planning_format_categories));
        // -- END prepare formData

        // -- submit
        await axios.post(route('contractor.api.planning_format.update.is_enable'), fd)
          .then((response) => {
            if (response.status == 200) {
              // need confirm for redirect
              // window.location.href = route('contractor.project.show', { project: this.project.line_number });
            }
          })
          .catch((error) => {
            console.log(error)
          });
        // -- END submit

        // -- hide loader
        loader.hide();
        // -- END hide loader
      }
      // -- END check validation
    },
    setDefaultTitle() {
      this.form.title = this.planning_format.title;
    },
    setPlanningFormatCategoriesForm() {
      const planning_categories = this.planning_format_categories;
      let form_planning_format_categories = [];
      planning_categories.forEach(planning_category => {
        form_planning_format_categories.push({
          planning_category_id : planning_category.id,
          title: planning_category.title,
          is_enable: planning_category.is_enable ? 'on' : 'off',
        })
      });
      this.form.planning_format_categories = form_planning_format_categories;
    },
    changeIsEnable(index, value) {
      if ((value == 'off') && (this.form.planning_format_categories[index].is_enable == 'on')) {
        if(window.confirm('登録済みの項目を無効にすると登録されている内容もリセットされます。無効にしますか？')) {
          this.form.planning_format_categories[index].is_enable = value;
        } else {
          window.alert('変更がキャンセルされました');
        }
      }
      else {
        this.form.planning_format_categories[index].is_enable = value;
      }
    },
    backTo() {
      return route('contractor.project.planning_format.show', { project: this.project.line_number, planning_format: this.planning_format.line_number });
    },
    currentProjectRoute() {
        return route('contractor.project.show', { project: this.project.line_number })
    },
    changePage(data) {
        let routeTo = route(data);
        return routeTo;
    },
  },
}
</script>

<style lang="css" scoped>
  /* for validate messsage */
  .alert-danger {
    color: red;
  }
</style>
