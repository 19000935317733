<template>
    <tr>
        <td colspan="3" style="padding: 0;">
            <table class="u-table-flow-item-row">
                <tbody>
                    <tr>
                        <td class="__item u-align-left">
                            <div class="u-flex-left-center">
                                <span class="__text u-text-bold">{{ page.pageName }}</span>
                                <span class="__text u-text-caption mgl-016">（{{ page.pageType }}）</span>
                            </div>
                        </td>
                        <td class="__subtotal u-align-center">
                            <div class="u-flex-between-center">
                                <span class="u-form-input-default __man-hours">
                                    <input v-model="page.manHours" class="u-align-right" type="number" placeholder="工数入力" name="" @blur="totalPageManHours(); emitTotalManHours()">
                                </span>
                                <span class="__text">人日</span>
                                <button v-if="pageCount > 1" class="__button __button--delete" type="button" @click="emitDeletePageItem(page.pageId)">
                                    <img class="__image" src="/assets/img/site/icon_delete_small_min.svg" alt="削除">
                                </button>
                                <span v-else style="width: 30px;"></span>
                            </div>
                            <div class="u-align-left">
                                <p class="mgt-008 u-text-varidate-label" v-for="(error, index) of v$.page.manHours.$errors" :key="index">{{ error.$message }}</p>
                            </div>
                        </td>
                        <td class="__total u-align-right" :rowspan="page.options.length + 2">
                            <span class="__text u-text-bold">{{ subTotalManHours }}人日</span>
                        </td>
                    </tr>
                    <flow-table-pages-option-row
                        v-for="option in page.options"
                        :key="option.optionId"
                        :current-option="option"
                        :current-page-id="page.pageId"
                        @update-manhours="emitTotalManHours(); totalPageManHours()"
                        @delete-option="deleteOption"
                        :validation-scope="validationScope"
                    />
                    <tr>
                        <td class="u-align-center" colspan="2" style="padding: 0;">
                            <div class="__add">
                                <button class="__button __button--add-option" type="button" @click="addOptions()">
                                    <img class="__image" src="/assets/img/site/icon_add_secondary_small_min.svg" alt="追加">
                                </button>
                                <span class="__caption u-text-caption">オプションを追加</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
    // -- vuelidate
    import useVuelidate from "@vuelidate/core";
    import { helpers, required } from "@vuelidate/validators";
    // -- END vuelidate
    // -- for custom validate
    const decimal42 = helpers.regex(/^-?\d{1,4}(\.\d{1,2})?$/); // for decimal (4,2)
    // -- END for custom validate
    import FlowTablePagesOptionRow from './FlowTablePagesOptionRow.vue';
    export default {
        setup(props) {
            return { v$: useVuelidate({ $scope: props.validationScope }) };
        },
        components: {
            FlowTablePagesOptionRow
        },
        props: {
            currentItem: {
                type: Object,
                default: {}
            },
            orderIndex : {
                type: Number,
                default: 0
            },
            validationScope: {
                type: String,
                default: "",
            },
            pageCount: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                page: {},
                currentOptionId: 0,
                subTotalManHours: 0
            }
        },
        validations() {
            return {
                page: {
                    manHours: {
                        required: helpers.withMessage("設定工数は必須項目です。", required),
                        decimal42: helpers.withMessage("設定工数 は整数4桁, 少数2桁で入力してください。", decimal42),
                    }
                }
            }
        },
        created() {
            this.page = this.currentItem;
            this.currentOptionId = this.page.options.length
            this.totalPageManHours();
        },
        methods: {
            addOptions() {
                this.page.options.push({
                    optionId: this.currentOptionId,
                    optionName: null,
                    manHours: null,
                });
                this.currentOptionId++
            },
            deleteOption(payload) {
                // -- set deleteId
                const optional_cost = this.page.options.find(option => option.optionId == payload.optionId);
                this.$emit('set-delete-id', {id: optional_cost.pocId, type: 'page_optional_costs'})
                // -- END set deleteId

                const filtered = this.page.options.filter((option) => {
                    return option.optionId !== payload.optionId;
                });
                if(this.page.pageId === payload.pageId) {
                    this.page.options = filtered;
                }
                this.totalPageManHours();
            },
            emitDeletePageItem(pageId) {
                this.$emit('delete-page', pageId)
            },
            emitTotalManHours() {
                this.$emit('update-manhours', null)
            },
            totalPageManHours() {
                const optionsTotalManHours = this.page.options.map((option) => {
                    return option.manHours;
                }).reduce((prev, current) => {
                    return prev + current;
                }, 0);
                this.subTotalManHours = this.page.manHours + optionsTotalManHours;
            },
        }
    }
</script>
