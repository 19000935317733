'use strict';

export function Accordion({triggers, panels}) {
  const accordionTriggers = document.querySelectorAll(triggers);
  const accordionPanels = document.querySelectorAll(panels);
  if(accordionPanels) {
    accordionTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        const panel = event.target.previousElementSibling
        panel.classList.toggle('js-accordion-panel--shown');
      });
    });
  }
}